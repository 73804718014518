import React from 'react';

export const CustomCombinedText = ({
  firstText = 'none',
  firstFontSize = 16,
  firstFontWeight = 400,
  firstFontFamily = 'Montserrat',
  mb = '0px',
  mt = '0px',
  mr = '0px',
  ml = '0px',
  onClick,
  firstCNColor = 'text-secondary',
  firstStyleColor,
  firstDivStyle = null,
  firstTextStyle = {},
  firstDivClassName = '',
  firstTextClassName = '',

  secondText = 'none',
  secondFontSize = 16,
  secondFontWeight = 400,
  secondFontFamily = 'Montserrat',
  secondCNColor = 'text-black',
  secondStyleColor,
  secondDivStyle = null,
  secondTextStyle = {},
  secondDivClassName = '',
  secondTextClassName = '',
}) => {
  return (
    <div>
      <p className="p-0 m-0">
        <span
          className={`${
            !firstStyleColor ? firstCNColor : ''
          } ${firstTextClassName}`}
          // className={textClassName}
          onClick={onClick}
          style={{
            fontFamily: firstFontFamily,
            fontSize: firstFontSize,
            fontWeight: firstFontWeight,
            marginTop: mt,
            marginBottom: mb,
            marginLeft: ml,
            marginRight: mr,
            color: firstStyleColor && firstStyleColor,
            ...firstTextStyle,
          }}
        >
          {firstText}
        </span>
        <span
          className={`${
            !secondStyleColor ? secondCNColor : ''
          } ${secondTextClassName}`}
          // className={textClassName}
          onClick={onClick}
          style={{
            fontFamily: secondFontFamily,
            fontSize: secondFontSize,
            fontWeight: secondFontWeight,
            marginTop: mt,
            marginBottom: mb,
            marginLeft: ml,
            marginRight: mr,
            color: secondStyleColor && secondStyleColor,
            ...secondTextStyle,
          }}
        >
          {secondText}
        </span>
      </p>
    </div>
  );
};
