import React from 'react';
import { Modal, Row, Spinner } from 'react-bootstrap';
import { CustomText } from './CustomText';

export const Loader = ({ isFullScreen = true, height, spinnerSize = 'lg' }) => {
  return (
    <Row
      className={`d-flex justify-content-center align-items-center ${
        isFullScreen ? 'min-vh-100' : ''
      }`}
      style={{ height: height }}
    >
      <Spinner variant="primary" size={spinnerSize} />
    </Row>
  );
};

export const LoadingModal = ({
  spinnerSize = 'lg',
  show,
  text,
  onHide = () => {},
}) => {
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="loading-modal"
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" variant="light" size={spinnerSize} />
        <CustomText
          cNColor="text-white"
          textClassName="text-center text-wrap"
          fontSize={13}
          mt={16}
        >
          {text}
        </CustomText>
      </Modal.Body>
    </Modal>
  );
};
