import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import {
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as BankSvg } from 'assets/svgs/bank.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { ReactComponent as DebitCardSvg } from 'assets/svgs/debitCard.svg';
import { ReactComponent as CreditCardSvg } from 'assets/svgs/creditCard.svg';
// import { ReactComponent as VoucherSvg } from 'assets/svgs/voucher.svg';
// import { ReactComponent as Pay4MeSvg} from 'assets/svgs/pay4me.svg';
import { ReactComponent as CashSvg } from 'assets/svgs/cash-stack.svg';
import { ReactComponent as PickupSvg } from 'assets/svgs/pickup-station.svg';
import { ReactComponent as DoorSvg } from 'assets/svgs/door-delivery.svg';
import addSign from 'assets/pngs/add.png';
import CartSummary from 'pages/cart/components/CartSummary';
import CartItem from 'pages/cart/components/CartItem';
import CheckoutSummary from './CheckoutSummary';
import { AddDeliveryAddressModal } from './AddDeliveryAddressModal';
import { DeliveryAddressModal } from './DeliveryAddressModal';
import CheckoutItem from 'pages/cart/components/CheckoutItem';
import { CustomCombinedText } from 'components/CustomCombinedText';
import { useScreenSize } from 'hooks/useScreenSize';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateOrderMutation,
  useCreatePaymentIntentMutation,
  useGetAreaMutation,
  useGetCountryMutation,
  useGetFreightComRatesMutation,
  useGetPublicApiMutation,
  useGetTaxesMutation,
  useGetUserUsableVouchersMutation,
  useValidateCheckoutMutation,
} from 'redux/features/user/userApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { show } from 'helper/toast';
import {
  saveArea,
  saveCountry,
  saveToUserAuth,
  saveUser,
} from 'redux/features/auth/authSlice';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import { saveToUserStore } from 'redux/features/user/userSlice';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  isSubstringInArrayStrict,
  sortedDataByCreatedAt,
} from 'helper/formatArray';
import { errorMessageFormat, formatCurrency } from 'helper/formatText';
import { LoadingModal } from 'components/Loader';
import { PaymentMethodModal } from './PaymentMethodModal';
import { VoucherModal } from './VoucherModal';

import { usePaystackPayment } from 'react-paystack';
import { SuccessModal } from './SuccessModal';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { DeliveryMethodModal } from './DeliveryMethodModal';
import CustomInput from 'components/CustomInput';

const CheckoutDetails = () => {
  const { isMobile, isLaptop, isMobileS } = useScreenSize();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    country: countryInit,
    area: areaInit,
    tempArea,
    tempCountry,
    user,
    countries,
    market: marketAuth,
  } = useSelector((state) => state.userAuth);
  const {
    cart: mainCart,
    tempCart,
    market,
    areas,
    userVouchers,
    minimumOrderValue = 100000,
    currentDeliveryAddress,
    currentDeliveryMethod,
    currentPaymentMethod,
    currentPaymentRef,
    currentVoucher,
    taxes,
  } = useSelector((state) => state.userStore);

  const country =
    location?.state?.checkoutType == 'reorder' ? tempCountry : countryInit;

  const area = location?.state?.checkoutType == 'reorder' ? tempArea : areaInit;

  const cart = location?.state?.checkoutType == 'reorder' ? tempCart : mainCart;
  const [successVisible, setSuccessVisible] = useState(false);
  const [deliveryListVisible, setDeliveryListVisible] = useState(null);
  const [addDeliveryVisible, setAddDeliveryVisible] = useState(null);
  const [initialAddress, setInitialAddress] = useState(null);
  const [addDeliveryAddressVisible, setAddDeliveryAddressVisible] =
    useState(false);
  const [deliveryAddressVisible, setDeliveryAddressVisible] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [voucherDet, setVoucherDet] = useState(null);
  const [voucherVisible, setVoucherVisible] = useState(false);
  const [paymentMethodVisible, setPaymentMethodVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [deliveryMethodVisible, setDeliveryMethodVisible] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [subtotal, setSubtotal] = useState(false);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [vat, setVat] = useState(0);
  const [mov, setMov] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [countryTax, setCountryTax] = useState(0);
  const [canadaTax, setCanadaTax] = useState(null);
  const [basicDeliveryFee, setBasicDeliveryFee] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [doorDeliveryService, setDoorDeliveryService] = useState({});
  const [doorDeliveryFee, setDoorDeliveryFee] = useState(0);
  const [doorDeliveryError, setDoorDeliveryError] = useState(null);
  const [totalKg, setTotalKg] = useState(0);
  const [paymentReference, setPaymentReference] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showCharges, setShowCharges] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [addressModalOpened, setAddressModalOpened] = useState(false);
  const [newOrderDetails, setNewOrderDetails] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(user?.STRIPE_PUBLIC_API_KEY));
  }, []);

  const [getFreightComRates, { isLoading: isLoadingGetFreightComRates }] =
    useGetFreightComRatesMutation();
  const [getArea, { isLoading: isLoadingGetArea }] = useGetAreaMutation();
  const [getCountry, { isLoading: isLoadingCountry }] = useGetCountryMutation();

  const [validateCheckout, { isLoading: isLoadingValidateCheckout }] =
    useValidateCheckoutMutation();
  const [createOrder, { isLoading: isLoadingCreateOrder }] =
    useCreateOrderMutation();

  const [createPaymentIntent, { isLoading: isLoadingPaymentIntent }] =
    useCreatePaymentIntentMutation();

  const [getUserUsableVouchers, { isLoading: isLoadingVouchers }] =
    useGetUserUsableVouchersMutation();
  const validationSchema = Yup.object().shape({
    pay4MeEmail: Yup.string().email().required().label('Email address'),
  });
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      pay4MeEmail: '',
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  const [getPublicApi, { isLoading: isLoadingPublicApi }] =
    useGetPublicApiMutation();

  const fetchPublicApi = async () => {
    const res = await getPublicApi();
    if (res?.data?.status === 'success') {
      const extr = res?.data;
      dispatch(saveUser({ ...extr?.user, ...extr?.publicApi }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const [getTaxes, { isLoading: isLoadingGetTaxes }] = useGetTaxesMutation();

  const fetchTaxes = async () => {
    const res = await getTaxes();
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data;
      dispatch(saveToUserStore({ key: 'taxes', value: extr }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      history.push('/cart');
    }
  };

  useEffect(() => {
    if (
      !isArrayNonEmpty(cart?.cart_items) &&
      location.pathname === '/checkout'
    ) {
      history.push('/cart');
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchPublicApi();
    fetchTaxes();
  }, []);

  const fetchVouchers = async () => {
    const res = await getUserUsableVouchers({
      email: user?.email,
      params: { country_id: extractId(country), status: 'active' },
    });
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data?.map((x) => {
        return convertJsonStringToObject({ ...x });
      });
      dispatch(
        saveToUserStore({
          key: 'userVouchers',
          value: sortedDataByCreatedAt(extr, 'desc'),
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    if (!freeze) {
      setFirstLoad(true);
      dispatch(saveToUserStore({ key: 'currentDeliveryMethod', value: null }));
      dispatch(saveToUserStore({ key: 'currentDeliveryAddress', value: null }));
      dispatch(saveToUserStore({ key: 'currentPaymentMethod', value: null }));
      dispatch(saveToUserStore({ key: 'currentPaymentRef', value: null }));
      dispatch(saveToUserStore({ key: 'currentVoucher', value: null }));
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!freeze) {
      setDeliveryAddress(currentDeliveryAddress || '');
    }
  }, [currentDeliveryAddress]);

  useEffect(() => {
    if (
      deliveryListVisible !== null &&
      isSubstringInArray(currentDeliveryMethod?.name || '#', ['Door']) &&
      !currentDeliveryAddress
    ) {
      setDeliveryMethodVisible(false);
      setDeliveryAddressVisible(true);
    }
  }, [deliveryListVisible, currentDeliveryMethod]);

  useEffect(() => {
    if (addDeliveryVisible !== null) {
      setAddDeliveryAddressVisible(true);
    }
  }, [addDeliveryVisible]);

  useEffect(() => {
    if (!addDeliveryAddressVisible && addressModalOpened) {
      setDeliveryAddressVisible(true);
    }
  }, [addDeliveryAddressVisible]);

  const fetchArea = async () => {
    const res = await getArea(extractId(area));
    if (res?.data?.status === 'success') {
      location?.state?.checkoutType == 'reorder'
        ? dispatch(saveToUserStore({ key: 'tempArea', value: res.data?.data }))
        : dispatch(saveArea(res.data?.data));
      dispatch(
        saveToUserStore({
          key: 'market',
          value: res.data?.data?.market,
        })
      );
      location?.state?.checkoutType == 'reorder'
        ? dispatch(
            saveToUserStore({
              key: 'tempCountry',
              value: res.data?.data?.country,
            })
          )
        : dispatch(saveCountry(res.data?.data?.country));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      history.push('/cart');
    }
  };

  const fetchCountry = async () => {
    const res = await getCountry(extractId(country));
    if (res?.data?.status === 'success') {
      location?.state?.checkoutType == 'reorder'
        ? dispatch(
            saveToUserStore({
              key: 'tempCountry',
              value: res.data?.data,
            })
          )
        : dispatch(saveCountry(res.data?.data));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      history.push('/cart');
    }
  };

  const clearMarket = async () => {
    await dispatch(
      saveToUserStore({
        key: 'market',
        value: null,
      })
    );
  };

  useEffect(() => {
    if (!freeze) {
      // fetchCart();
      if (extractId(area)) {
        fetchArea();
      } else {
        clearMarket();
        fetchCountry();
      }
      fetchVouchers();
    }
  }, [dispatch, refetch, extractId(area)]);

  useEffect(() => {
    if (
      !isSubstringInArray(country?.name, ['nigeria']) &&
      location?.state?.checkoutType != 'reorder'
    ) {
      dispatch(saveToUserAuth({ key: 'area', value: null }));
    }
  }, [area, country]);

  const fetchCanadaPostRates = async () => {
    setDoorDeliveryError(null);
    const resolveValues = {
      origin_postal_code: market.pickup_station_postal_code,
      origin_name: market.pickup_station_name,
      origin_address_line_1: market.pickup_station_street_address || '',
      origin_address_line_2: '',
      origin_unit_number: market.pickup_station_apartment || '',
      origin_city: market.pickup_station_city || '',
      origin_region: market.pickup_station_state_code,
      origin_country: market.pickup_station_country_code,
      origin_residential: false,
      origin_tailgate_required:
        market.pickup_station_tailgate_required || false,
      origin_instructions: market.pickup_station_additional_info || '',
      origin_contact_name: market.pickup_station_contact_name || '',
      origin_phone_number:
        market.pickup_station_contact_phone_prefix +
          market.pickup_station_contact_phone || market.contact_phone,

      destination_name:
        currentDeliveryAddress.tag || currentDeliveryAddress.contact_name || '',
      destination_postal_code: currentDeliveryAddress.postal_code,
      destination_address_line_1: currentDeliveryAddress.street_address,
      destination_address_line_2: '',
      destination_unit_number: currentDeliveryAddress.apartment || '',
      destination_city: currentDeliveryAddress.city,
      destination_region: currentDeliveryAddress.state,
      destination_country: currentDeliveryAddress.country,
      destination_residential: true,
      destination_tailgate_required:
        currentDeliveryAddress.tailgate_required || false,
      destination_instructions: currentDeliveryAddress.additional_info || '',
      destination_contact_name: currentDeliveryAddress.contact_name,
      destination_phone_number: currentDeliveryAddress.contact_phone,

      packaging_type: 'package',
      includes_return_label: false,
      has_dangerous_goods: false,
      weight: {
        unit: 'kg',
        value: Number(totalKg),
      },
      cuboid: {
        unit: 'in',
        l: 15,
        w: 15,
        h: 15,
      },
      description: 'Groceries',
      special_handling_required: true,
    };
    const res = await getFreightComRates(resolveValues);
    if (res?.data?.status === 'success' && res?.data?.data?.lowest_rate) {
      setDoorDeliveryFee(res?.data?.data?.lowest_rate);
      setDoorDeliveryService(res?.data?.data?.lowest_service);
      setDeliveryFee(
        basicDeliveryFee + parseFloat(res?.data?.data?.lowest_rate)
      );
    } else {
      show(
        dispatch,
        res?.error?.data?.message ||
          (typeof res?.error?.error == 'string' &&
            res?.error?.error?.replace('TypeError: ', '')) ||
          'Failed to get delivery service, re-select delivery address or change delivery method',
        {
          type: 'error',
          duration: 6000,
        }
      );
      setDoorDeliveryError(
        'Failed to get delivery service, re-select delivery address or change delivery method'
      );
    }
    return;
  };

  useEffect(() => {
    if (!freeze && !firstLoad) {
      if (
        isSubstringInArray(currentDeliveryMethod?.name || '#', ['Door']) &&
        isSubstringInArray(country?.name, ['canada']) &&
        !!currentDeliveryAddress
      ) {
        // setDeliveryFee(deliveryFee - doorDeliveryFee);
        setDoorDeliveryFee(0);
        setDoorDeliveryService({});
        fetchCanadaPostRates(doorDeliveryFee);
      }
      if (isSubstringInArray(currentDeliveryMethod?.name || '#', ['pickup'])) {
        setDeliveryFee((prev) => prev - doorDeliveryFee);
        setDoorDeliveryFee(0);
        setDoorDeliveryService({});
      }
    }
  }, [currentDeliveryAddress, currentDeliveryMethod]);

  const deliverMethod = useMemo(() => {
    let filtered = null;
    if (currentDeliveryMethod) {
      filtered = {
        ...currentDeliveryMethod,
        name: currentDeliveryMethod?.name,
        svg: isSubstringInArray(currentDeliveryMethod?.name, ['pickup']) ? (
          <PickupSvg />
        ) : (
          <DoorSvg />
        ),
      };
    }

    return filtered;
  }, [currentDeliveryMethod]);

  const payMethod = useMemo(() => {
    let filtered = null;
    if (currentPaymentMethod) {
      filtered = {
        ...currentPaymentMethod,
        name: currentPaymentMethod?.name,
        svg: isSubstringInArray(currentPaymentMethod?.name, [
          'paystack',
          'stripe',
        ]) ? (
          <DebitCardSvg />
        ) : isSubstringInArray(currentPaymentMethod?.name, ['voucher']) ? (
          <VoucherSvg />
        ) : isSubstringInArray(currentPaymentMethod?.name, ['cash']) ? (
          <CashSvg />
        ) : isSubstringInArray(currentPaymentMethod?.name, ['pay4me']) ? (
          <Pay4MeSvg />
        ) : (
          <CreditCardSvg />
        ),
      };
    }

    return filtered;
  }, [currentPaymentMethod]);

  const voucherDetails = useMemo(() => {
    let filtered = null;
    if (currentVoucher) {
      currentVoucher?.value - currentVoucher?.used_value >
        basicDeliveryFee +
          (doorDeliveryFee || 0) +
          subtotal +
          serviceCharge +
          vat +
          countryTax +
          otherCharges &&
        dispatch(saveToUserStore({ key: 'currentPaymentMethod', value: null }));
      filtered = {
        ...currentVoucher,
        name: currentVoucher?.name?.toUpperCase(),
        svg: <VoucherSvg />,
      };
    }

    return filtered;
  }, [currentVoucher]);

  const filteredData = useMemo(() => {
    let filtered = cart?.cart_items || [];
    filtered = filtered?.map((x) => {
      return {
        name: x?.product_id?.name,
        quantity: x?.quantity,
        price: x?.priceByMarket_id?.price,
        uom: x?.priceByMarket_id?.uom_id?.name,
        image: x?.product_id?.imageCover,
        kg: x?.priceByMarket_id?.kg,
        market: x?.priceByMarket_id?.market_id,
        total_kg: x?.priceByMarket_id?.kg * x?.quantity,
        id: x?.product_id?.id,
        cart_item_id: x?.id,
        cart_id: cart?.id,
      };
    });
    if (!freeze) {
      dispatch(
        saveToUserAuth({
          key: 'market',
          value: area?.market || filtered[0]?.market,
        })
      );
      const subT = filtered?.reduce((acc, curr) => {
        return acc + curr?.quantity * curr?.price;
      }, 0);
      setSubtotal(subT);
      if (country?.name?.toLowerCase() == 'nigeria') {
        const mrkt =
          area?.market || cart?.cart_items[0]?.priceByMarket_id?.market_id;
        setDeliveryFee(mrkt?.delivery_fee);
        setBasicDeliveryFee(mrkt?.delivery_fee);
        setServiceCharge(mrkt?.service_charge);
        setMov(mrkt?.minimum_order_value);
      } else {
        const total_delivery_fee = filtered?.reduce((acc, item) => {
          const product_delivery_fee =
            country?.delivery_fee_by_kg * item.total_kg;
          return acc + product_delivery_fee;
        }, 0);
        const total_kg = filtered?.reduce((acc, item) => {
          return acc + item.total_kg;
        }, 0);
        const mrkt = filtered[0]?.market;
        dispatch(
          saveToUserStore({
            key: 'market',
            value: mrkt,
          })
        );
        setTotalKg(total_kg.toFixed(3));
        setDeliveryFee(total_delivery_fee);
        setBasicDeliveryFee(total_delivery_fee);
        setServiceCharge(mrkt?.service_charge);
        setMov(mrkt?.minimum_order_value);
      }
      setOtherCharges(country?.other_charges);
      setVat(country?.vat * 0.01 * subtotal);
      setCountryTax(Number((country?.country_tax * subtotal) / 100));
    }
    return filtered;
  }, [cart, market, area, country]);

  useEffect(() => {
    const tax = taxes?.find((x) =>
      isSubstringInArrayStrict(x?.state, [deliveryAddress?.state])
    );

    tax?.percentage !== undefined &&
      setCountryTax(Number((tax?.percentage * subtotal) / 100));
  }, [subtotal, taxes, deliveryAddress, cart, market, area, country]);

  const totalAmount = useMemo(() => {
    return !!voucherDetails
      ? voucherDetails?.value - voucherDetails?.used_value >
        basicDeliveryFee +
          (doorDeliveryFee || 0) +
          subtotal +
          serviceCharge +
          vat +
          countryTax +
          otherCharges
        ? 0
        : basicDeliveryFee +
          (doorDeliveryFee || 0) +
          subtotal +
          serviceCharge +
          vat +
          countryTax +
          otherCharges -
          (voucherDetails?.value - voucherDetails?.used_value)
      : basicDeliveryFee +
          (doorDeliveryFee || 0) +
          subtotal +
          serviceCharge +
          vat +
          countryTax +
          otherCharges;
  }, [
    deliveryFee,
    basicDeliveryFee,
    subtotal,
    serviceCharge,
    vat,
    countryTax,
    otherCharges,
    voucherDetails,
    voucherDetails?.value,
    voucherDetails?.used_value,
    doorDeliveryFee,
    doorDeliveryService,
    area,
    country,
  ]);

  const voucherDeduction = useMemo(() => {
    return voucherDetails?.value - voucherDetails?.used_value >
      basicDeliveryFee +
        (doorDeliveryFee || 0) +
        subtotal +
        serviceCharge +
        vat +
        countryTax +
        otherCharges
      ? basicDeliveryFee +
          (doorDeliveryFee || 0) +
          subtotal +
          serviceCharge +
          vat +
          countryTax +
          otherCharges
      : voucherDetails?.value - voucherDetails?.used_value;
  }, [
    deliveryFee,
    basicDeliveryFee,
    subtotal,
    serviceCharge,
    vat,
    countryTax,
    otherCharges,
    voucherDetails,
    voucherDetails?.value,
    voucherDetails?.used_value,
    doorDeliveryFee,
    doorDeliveryService,
    area,
    country,
  ]);

  const currencySymbol = country?.name?.toLowerCase()?.includes('nigeria')
    ? '₦'
    : country?.currency?.symbol;

  // const paystackWebViewRef = useRef(paystackProps.PayStackRef);

  const config = {
    email: user?.email,
    phone: user?.phone,
    firstname: user?.firstName,
    lastname: user?.lastName,
    amount: (parseFloat(totalAmount) * 100).toFixed(2),
    currency: 'NGN',
    publicKey: user?.PAYSTACK_PUBLIC_API_KEY,
  };

  const onPayStackClose = (e) => {
    show(dispatch, 'Payment was cancelled', {
      type: 'error',
      placement: 'top',
      duration: 4000,
    });
  };
  const onPayStackSuccess = (res) => {
    handlePaystackPayment(res?.reference || res?.trxref);
  };

  const initializePayStackPayment = usePaystackPayment(config);

  const handlePayment = async () => {
    if (isSubstringInArray(currentPaymentMethod?.name, ['paystack'])) {
      const validated = await handleValidation();
      if (validated) {
        if (currentPaymentRef) {
          handleCheckout({ reference: currentPaymentRef });
        } else {
          initializePayStackPayment(onPayStackSuccess, onPayStackClose);
        }
      } else {
        show(
          dispatch,
          errorMessageFormat(
            'Something went wrong, Go back to cart and try again'
          ),
          {
            type: 'error',
          }
        );
      }
    } else if (isSubstringInArray(currentPaymentMethod?.name, ['stripe'])) {
      const validated = await handleValidation();
      if (validated) {
        setStripeLoading(true);
        const response = await createPaymentIntent({
          amount: Math.floor(parseFloat(totalAmount).toFixed(2) * 100),
          currency: country?.currency?.code,
        });
        if (response.error || !response.data.data) {
          show(
            dispatch,
            response?.error?.data?.message || errorMessageFormat(response),
            {
              type: 'error',
            }
          );
          setStripeLoading(false);
          return;
        }
        setStripeLoading(false);
        setStripeClientSecret(response.data.data);

        // const initRes = await initPaymentSheet({
        //   merchantDisplayName: 'Fooddy NG.',
        //   paymentIntentClientSecret: response.data.data,
        //   defaultBillingDetails: {
        //     name: user?.firstName + ' ' + user?.lastName,
        //   },
        // });
        // const { error: paymentSheetError } = initRes;

        // if (paymentSheetError) {
        //   show(dispatch, errorMessageFormat(paymentSheetError.message), {
        //     type: 'error',
        //   });
        //   setStripeLoading(false);
        //   return;
        // }
        // // const { error: paymentError } = await presentPaymentSheet();
        // const ress = await presentPaymentSheet();
        // if (ress?.error) {
        //   // show( dispatch,
        //   //   `Error code: ${ress?.error.code}\n${ress.error.message}` ||
        //   //     errorMessageFormat(res),
        //   //   {
        //   //     type: 'error',
        //   //   }
        //   // );

        //   show(
        //     dispatch,
        //     `${ress.error.message}` || errorMessageFormat(ress.error.message),
        //     {
        //       type: 'error',
        //     }
        //   );
        //   setStripeLoading(false);
        //   return;
        // }
        // // console.log('ress', ress);

        // await handleStripePayment(response.data.data);
      } else {
        show(
          dispatch,
          errorMessageFormat('Go back to your cart and try again'),
          {
            type: 'error',
          }
        );
      }
    } else if (
      isSubstringInArray(currentPaymentMethod?.name, ['cash']) ||
      isSubstringInArray(currentPaymentMethod?.name, ['pay4me']) ||
      (!currentPaymentMethod && !!currentVoucher)
    ) {
      handleCheckout({ reference: null });
    } else {
      show(dispatch, 'Complete all neccessary options for checkout', {
        type: 'error',
      });
    }
  };

  const handleStripePayment = async (ref) => {
    setPaymentReference(ref);
    dispatch(
      saveToUserStore({
        key: 'currentPaymentRef',
        value: ref,
      })
    );
    await handleCheckout({ reference: ref });
  };

  const handlePaystackPayment = async (ref) => {
    setPaymentReference(ref);
    dispatch(
      saveToUserStore({
        key: 'currentPaymentRef',
        value: ref,
      })
    );
    await handleCheckout({ reference: ref });
  };

  const handleValidation = async () => {
    setFreeze(true);
    const res = await validateCheckout({
      payment_method_id: extractId(currentPaymentMethod),
      cart_id: extractId(cart),
      delivery_address_id: extractId(currentDeliveryAddress),
      delivery_method: isSubstringInArray(currentDeliveryMethod?.name || '#', [
        'pickup',
      ])
        ? 'pickup'
        : 'door',
      voucher_id: extractId(currentVoucher),
      area_id: extractId(area),
      market_id: extractId(market) || extractId(marketAuth),
      country_id: extractId(country),
      calculated_tax: (countryTax * 100) / subtotal,
      calculated_amount: totalAmount,
      freight_service: doorDeliveryService?.service_id,
      checkoutType: location?.state?.checkoutType || null,
    });
    if (res?.data?.status === 'success') {
      return true;
    } else {
      show(
        dispatch,
        res?.error?.data?.message ||
          (typeof res?.error?.error == 'string' &&
            res?.error?.error?.replace('TypeError: ', '')),
        {
          type: 'error',
        }
      );
      setFreeze(false);
      return false;
    }
  };

  const handleCheckout = async ({ reference = null }) => {
    setFreeze(true);
    const res = await createOrder({
      payment_method_id: extractId(currentPaymentMethod),
      cart_id: extractId(cart),
      delivery_address_id: extractId(currentDeliveryAddress),
      delivery_method: isSubstringInArray(currentDeliveryMethod?.name || '#', [
        'pickup',
      ])
        ? 'pickup'
        : 'door',
      voucher_id: extractId(currentVoucher),
      area_id: extractId(area),
      market_id: extractId(market) || extractId(marketAuth),
      country_id: extractId(country),
      payment_status: reference ? 'paid' : 'unpaid',
      payment_reference: reference,
      calculated_tax: (countryTax * 100) / subtotal,
      calculated_amount: totalAmount,
      pay4MeEmail: values?.pay4MeEmail,
      freight_service: doorDeliveryService?.service_id,
      checkoutType: location?.state?.checkoutType || null,
    });
    setStripeLoading(false);
    if (res?.data?.status === 'success') {
      // show( dispatch,res?.data?.message, {
      //   type: 'success',
      // });
      // navigation.navigate('Categories');
      setNewOrderDetails(res?.data?.data?.order || res?.data?.data);
      dispatch(
        saveToUserStore({
          key: location?.state?.checkoutType == 'reorder' ? 'tempCart' : 'cart',
          value: null,
        })
      );
      location?.state?.checkoutType == 'reorder' &&
        dispatch(
          saveToUserStore({
            key: 'cartItemCount',
            value: 0,
          })
        );
      setSuccessVisible(true);
      setFreeze(false);
    } else {
      show(
        dispatch,
        res?.error?.data?.message ||
          (typeof res?.error?.error == 'string' &&
            res?.error?.error?.replace('TypeError: ', '')),
        {
          type: 'error',
        }
      );
      setFreeze(true);
    }
  };

  const handleShowCharges = () => {
    setShowCharges(!showCharges);
  };

  const options = useMemo(() => {
    return [
      {
        label: 'Item total',
        value: formatCurrency(subtotal, 2, currencySymbol),
      },
      {
        label: `Delivery${
          totalKg &&
          !isSubstringInArray(country?.name, ['nigeria']) &&
          !isSubstringInArray(currentDeliveryMethod?.name || '#', ['Door'])
            ? '(' +
              totalKg +
              'kg at ' +
              currencySymbol +
              country?.delivery_fee_by_kg +
              '/kg)'
            : ''
        }`,
        value: formatCurrency(
          basicDeliveryFee + (doorDeliveryFee || 0),
          2,
          currencySymbol
        ),
      },
      {
        label: 'Service charge',
        value: formatCurrency(serviceCharge, 2, currencySymbol),
        show: !!serviceCharge,
      },
      {
        label: 'VAT',
        value: formatCurrency(vat, 2, currencySymbol),
        show: !!country?.vat,
      },
      {
        label: `GST/PST (${Number((countryTax * 100) / subtotal).toFixed(
          3
        )}%)`.replace('.000', ''),
        value: formatCurrency(countryTax, 2, currencySymbol),
        show: !!countryTax,
      },
      {
        label: `Others`,
        value: formatCurrency(otherCharges, 2, currencySymbol),
        show: !!country?.other_charges,
      },
      {
        label: `Voucher Deduction`,
        value: `- ${formatCurrency(voucherDeduction, 2, currencySymbol)}`,
        show: !!voucherDetails,
      },
    ]?.filter((x) => x?.show || x?.show === undefined);
  }, [
    subtotal,
    currencySymbol,
    showCharges,
    basicDeliveryFee,
    doorDeliveryFee,
    serviceCharge,
    vat,
    countryTax,
    otherCharges,
    totalAmount,
    totalKg,
    country,
    voucherDeduction,
    voucherDetails,
    currentDeliveryAddress,
    currentDeliveryMethod,
    currentPaymentMethod,
    mov,
  ]);

  return (
    <Container>
      <LoadingModal
        show={
          isLoadingValidateCheckout ||
          isLoadingCreateOrder ||
          stripeLoading ||
          isLoadingCountry ||
          isLoadingGetArea ||
          isLoadingGetTaxes
        }
        text={
          isLoadingValidateCheckout
            ? 'Validating checkout'
            : isLoadingCreateOrder
            ? `Placing order\nPlease do not leave or close the tab until this process is done`
            : 'Loading...'
        }
      />
      <Row className="mb-3 mt-3 mt-lg-0 mt-md-0">
        <Col lg="auto" md={12} className="d-flex align-items-center py-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={'Checkout'}
            // cNColor="gray"
          />
        </Col>
      </Row>
      <Row>
        {!isSubstringInArray('canada', [country?.name]) ? (
          <Col lg={12}>
            <Card className="border-0 mb-4 mb-lg-0 mb-md-0">
              <Card.Header>
                <Row>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={500}
                      text={'DELIVERY ADDRESS'}
                    />
                  </Col>
                  <Col className="ms-auto d-flex justify-content-end">
                    {currentDeliveryAddress && (
                      <CustomButton
                        buttonTextColor={'text-primary '}
                        text="CHANGE"
                        variant="primary"
                        fontWeight={500}
                        fontSize={14}
                        style={{
                          padding: 0,
                          height: 20,
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                        className="d-flex justify-content-end"
                        onPress={() => {
                          setFreeze(false);
                          setDeliveryAddressVisible(true);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="py-3">
                {!currentDeliveryAddress ? (
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={400}
                    cNColor="text-primary"
                    onPress={() => setDeliveryAddressVisible(true)}
                  >
                    <Image src={addSign} style={{ marginRight: 16 }} />
                    Select delivery address
                  </CustomText>
                ) : (
                  <>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={600}
                    >
                      {currentDeliveryAddress?.title}
                    </CustomText>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      cNColor="text-secondary"
                    >
                      {currentDeliveryAddress?.name}
                    </CustomText>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      cNColor="text-secondary"
                    >
                      {currentDeliveryAddress?.address}
                    </CustomText>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      cNColor="text-secondary"
                    >
                      {currentDeliveryAddress?.phoneNumber}
                    </CustomText>
                    {currentDeliveryAddress?.additional_info && (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        cNColor="text-secondary"
                      >
                        <CustomText fontFamily={'Poppins'} fontSize={14}>
                          Delivery Instructions:
                        </CustomText>
                        {currentDeliveryAddress?.additional_info}
                      </CustomText>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col lg={12}>
            <Card className="border-0 mb-4 mb-lg-0 mb-md-0">
              <Card.Header>
                <Row>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={500}
                      text={'DELIVERY METHOD'}
                    />
                  </Col>
                  <Col className="ms-auto d-flex justify-content-end">
                    {!!deliverMethod && currentDeliveryAddress?.address && (
                      <CustomButton
                        buttonTextColor={'text-primary '}
                        text="CHANGE"
                        variant="primary"
                        fontWeight={500}
                        fontSize={14}
                        style={{
                          padding: 0,
                          height: 20,
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                        className="d-flex justify-content-end"
                        onPress={() => {
                          setFreeze(false);
                          setDeliveryMethodVisible(true);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="py-3">
                {(!deliverMethod || !currentDeliveryAddress?.address) && (
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={400}
                    cNColor="text-primary"
                    onPress={() => setDeliveryMethodVisible(true)}
                  >
                    <Image src={addSign} style={{ marginRight: 16 }} />
                    Select delivery method
                  </CustomText>
                )}
                {deliverMethod && currentDeliveryAddress?.address && (
                  <>
                    <Col className="d-flex align-items-center">
                      {deliveryMethod?.svg}
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        fontWeight={600}
                        divClassName="ms-2 mt-2"
                      >
                        {deliveryMethod?.name}
                      </CustomText>
                    </Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      cNColor="text-secondary"
                    >
                      {currentDeliveryAddress?.name}
                    </CustomText>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      cNColor="text-secondary"
                    >
                      {currentDeliveryAddress?.address}
                    </CustomText>
                    {currentDeliveryAddress?.postal_code && (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        cNColor="text-secondary"
                      >
                        {currentDeliveryAddress?.postal_code}
                      </CustomText>
                    )}
                    {currentDeliveryAddress?.phoneNumber && (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        cNColor="text-secondary"
                      >
                        {currentDeliveryAddress?.phoneNumber}
                      </CustomText>
                    )}
                    {currentDeliveryAddress?.description && (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        cNColor="text-secondary"
                      >
                        {currentDeliveryAddress?.description}
                      </CustomText>
                    )}
                    {currentDeliveryAddress?.additional_info && (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        cNColor="text-secondary"
                      >
                        <CustomText fontFamily={'Poppins'} fontSize={14}>
                          Additional Info/Delivery instructions:{' '}
                        </CustomText>
                        {currentDeliveryAddress?.additional_info}
                      </CustomText>
                    )}
                    {((doorDeliveryFee !== 0 && doorDeliveryFee) ||
                      isLoadingGetFreightComRates) && (
                      <div className="d-flex">
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          divStyle={{ marginRight: 4 }}
                        >
                          Added Delivery fee:{' '}
                        </CustomText>
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          cNColor="text-primary"
                        >
                          {isLoadingGetFreightComRates
                            ? 'Calculating...'
                            : formatCurrency(
                                parseFloat(doorDeliveryFee),
                                2,
                                currencySymbol
                              )}
                        </CustomText>
                      </div>
                    )}
                    {doorDeliveryService?.carrier_name &&
                      doorDeliveryFee &&
                      !isLoadingGetFreightComRates && (
                        <div className="d-flex">
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            divStyle={{ marginRight: 4 }}
                          >
                            Domestic Delivery service:
                          </CustomText>
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            cNColor="text-primary"
                          >
                            {` ${doorDeliveryService?.carrier_name} - ${doorDeliveryService?.service_name}`}
                          </CustomText>
                        </div>
                      )}
                    {!isLoadingGetFreightComRates &&
                      isSubstringInArray(currentDeliveryMethod?.name, [
                        'door',
                      ]) &&
                      doorDeliveryError && (
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          cNColor="text-danger"
                        >
                          {doorDeliveryError}
                        </CustomText>
                      )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col lg={12} className="mt-2 ">
          <Card className="border-0 mt-2 mt-xl-0 mb-4 mb-lg-0 mb-md-0">
            <Card.Header>
              <Row>
                <Col>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={500}
                    text={'PAYMENT METHOD'}
                  />
                </Col>
                {!!payMethod && (
                  <Col className="ms-auto d-flex justify-content-end">
                    <CustomButton
                      buttonTextColor={'text-primary'}
                      text="CHANGE"
                      variant="primary"
                      fontWeight={500}
                      fontSize={14}
                      style={{
                        padding: 0,
                        height: 20,
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                      className="d-flex justify-content-end"
                      onPress={() => {
                        setFreeze(false);
                        setPaymentMethodVisible(true);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card.Header>
            <Card.Body className="py-3">
              {!!voucherDetails &&
              voucherDetails?.value - voucherDetails?.used_value >
                basicDeliveryFee +
                  (doorDeliveryFee || 0) +
                  subtotal +
                  serviceCharge +
                  vat +
                  countryTax +
                  otherCharges ? (
                <Row>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    // onPress={() => setPaymentMethodVisible(true)}
                  >
                    Other payment methods are disabled since the voucher balance
                    will cover the total payment.
                  </CustomText>
                </Row>
              ) : (
                <>
                  {!payMethod && (
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={400}
                      cNColor="text-primary"
                      onPress={() => setPaymentMethodVisible(true)}
                    >
                      <Image src={addSign} style={{ marginRight: 16 }} />
                      Select payment method
                    </CustomText>
                  )}
                  {payMethod && (
                    <Row>
                      <Col xs="auto" className="me-2">
                        {payMethod?.svg}
                      </Col>
                      <Col>
                        <CustomText fontFamily={'Poppins'} fontSize={14}>
                          {payMethod?.altname || payMethod?.name}
                        </CustomText>
                      </Col>
                    </Row>
                  )}
                  {payMethod && payMethod?.name?.toLowerCase() == 'pay4me' && (
                    <Pay4MeComponent
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          <Card className="border-0 mt-2 mb-4 mb-lg-0 mb-md-0">
            <Card.Header>
              <Row>
                <Col>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={500}
                    text={'USE VOUCHER (Optional)'}
                  />
                </Col>
                {!!voucherDetails && (
                  <Col className="ms-auto d-flex justify-content-end">
                    <CustomButton
                      buttonTextColor={'text-primary'}
                      text="REMOVE"
                      variant="primary"
                      fontWeight={500}
                      fontSize={14}
                      style={{
                        padding: 0,
                        height: 20,
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                      className="d-flex justify-content-end"
                      onPress={() => {
                        setVoucherDet(null);
                        dispatch(
                          saveToUserStore({
                            key: 'currentVoucher',
                            value: null,
                          })
                        );
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card.Header>
            <Card.Body className="py-3">
              {!voucherDetails && (
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={14}
                  fontWeight={400}
                  cNColor="text-primary"
                  divClassName="align-items-center"
                  onPress={() => setVoucherVisible(true)}
                >
                  <Image src={addSign} style={{ marginRight: 16 }} />
                  Pick a voucher
                </CustomText>
              )}
              {!!voucherDetails && (
                <div className="d-flex align-items-center">
                  {voucherDetails?.svg}
                  <div className="ms-3 d-flex flex-column align-items-start">
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      text={voucherDetails?.name}
                      divClassName="ps-3"
                    />
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      divClassName="ps-3"
                      cNColor="text-secondary"
                    >
                      Available Balance -{' '}
                      {formatCurrency(
                        voucherDetails?.value - voucherDetails?.used_value,
                        2,
                        currencySymbol
                      )}
                    </CustomText>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="border-0 mb-4 mb-lg-0 mb-md-0 mt-2">
            <Card.Header>
              <Row>
                <Col>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={400}
                    textStyle={{
                      textTransform: 'uppercase',
                    }}
                  >
                    Items
                  </CustomText>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="pt-lg-2">
              {filteredData?.map((item, index) => {
                return (
                  <Card
                    key={item?.id}
                    style={{
                      minHeight: '100px',
                      paddingRight: '20px',
                      paddingLeft: '20px',
                      marginBottom: '10px',
                      paddingTop: isMobile ? 20 : 0,
                      paddingBottom: isMobile ? 20 : 0,
                    }}
                  >
                    <Row>
                      <Col
                        lg="auto"
                        md="auto"
                        sm="auto"
                        className={isMobile ? 'centered' : ''}
                      >
                        <Image
                          src={item?.image}
                          alt=""
                          fluid
                          style={{ maxHeight: 100 }}
                        />
                      </Col>
                      <Col className="py-3 px-2 d-flex flex-column align-items-start justify-content-between">
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          fontWeight={600}
                          text={item?.name}
                          // cNColor="gray"
                          // textClassName="text-capitalize"
                        />
                        <CustomCombinedText
                          firstFontFamily={'Poppins'}
                          firstText="Price per Unit: "
                          firstFontSize={12}
                          secondFontFamily={'Poppins'}
                          secondFontSize={12}
                          secondText={` ${formatCurrency(
                            item?.price,
                            2,
                            currencySymbol
                          )?.replace('.00', '')}`}
                          // cNColor="gray"
                          // textClassName="text-capitalize"
                        />
                        <CustomCombinedText
                          firstFontFamily={'Poppins'}
                          firstText="Quantity:"
                          firstFontSize={12}
                          secondFontFamily={'Poppins'}
                          secondFontSize={12}
                          secondText={` ${item?.quantity} (${item?.uom})`}
                          // cNColor="gray"
                          // textClassName="text-capitalize"
                        />
                      </Col>
                      <Col
                        lg="auto"
                        md="auto"
                        sm="auto"
                        className="mt-lg-3 mt-md-3 mt-sm-0 align-items-end"
                      >
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={!isMobile ? 16 : 14}
                          fontWeight={600}
                          text={formatCurrency(
                            item?.quantity * item?.price,
                            2,
                            currencySymbol
                          )?.replace('.00', '')}
                          textStyle={{ textAlign: 'right' }}
                        />
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="border-0 mb-4 mb-lg-0 mb-md-0 mt-4 mt-lg-2">
            <Card.Header>
              <Row>
                <Col>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={400}
                    textStyle={{
                      textTransform: 'uppercase',
                    }}
                  >
                    SUMMARY
                  </CustomText>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="pt-lg-2 py-0">
              <ListGroup variant="flush">
                {options?.map((x) => (
                  <ListGroup.Item
                    className={`${
                      x?.type === 'col' ? 'd-block' : 'd-flex'
                    } align-items-center justify-content-between px-0 py-0 ${
                      x?.className ? x?.className : ''
                    }`}
                    style={
                      x?.style
                        ? { minHeight: 50, ...x?.style }
                        : { minHeight: 50 }
                    }
                  >
                    {typeof x?.label === 'string' ? (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        fontWeight={400}
                        textClassName={x?.labelClassName}
                        textStyle={x?.labelStyle}
                      >
                        {x?.label}
                      </CustomText>
                    ) : (
                      x?.label
                    )}
                    {typeof x?.value === 'string' ? (
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={16}
                        fontWeight={600}
                        textClassName={x?.valueClassName}
                        textStyle={x?.valueStyle}
                      >
                        {x?.value}
                      </CustomText>
                    ) : (
                      x?.value
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col
                  lg="auto"
                  md="auto"
                  sm={12}
                  className={`${isMobile ? 'rowcentered mb-3' : ''}`}
                >
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={15}
                    fontWeight={400}
                    text={'TOTAL AMOUNT DUE:'}
                    // cNColor="text-secondary"
                  />

                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={20}
                    fontWeight={600}
                    text={formatCurrency(totalAmount, 2, currencySymbol)}
                    cNColor="text-primary"
                  />
                </Col>
                <Col className="d-flex align-items-end justify-content-end px-0">
                  <CustomButton
                    className={'w-100'}
                    text="Confirm"
                    variant="primary"
                    fontWeight={500}
                    fontSize={14}
                    style={{
                      maxWidth: isMobile ? '100%' : 300,
                      boxShadow:
                        '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                    }}
                    onClick={handlePayment}
                    disabled={
                      subtotal < mov ||
                      !currentDeliveryAddress ||
                      (currentPaymentMethod?.name?.toLowerCase() == 'pay4me' &&
                        !!errors.pay4MeEmail) ||
                      (!currentPaymentMethod &&
                        !(
                          !!voucherDetails &&
                          voucherDetails?.value - voucherDetails?.used_value >
                            basicDeliveryFee +
                              (doorDeliveryFee || 0) +
                              subtotal +
                              serviceCharge +
                              vat +
                              countryTax +
                              otherCharges
                        )) ||
                      (isSubstringInArray(currentDeliveryMethod?.name || '#', [
                        'Door',
                      ]) &&
                        isSubstringInArray(country?.name, ['canada']) &&
                        !doorDeliveryFee)
                    }
                  />
                  {console.log(
                    'disabled',
                    subtotal < mov,
                    !currentDeliveryAddress,
                    currentPaymentMethod?.name?.toLowerCase() == 'pay4me' &&
                      !!errors.pay4MeEmail,
                    errors.pay4MeEmail,
                    !currentPaymentMethod &&
                      !(
                        !!voucherDetails &&
                        voucherDetails?.value - voucherDetails?.used_value >
                          basicDeliveryFee +
                            (doorDeliveryFee || 0) +
                            subtotal +
                            serviceCharge +
                            vat +
                            countryTax +
                            otherCharges
                      ),
                    isSubstringInArray(currentDeliveryMethod?.name || '#', [
                      'Door',
                    ]) &&
                      isSubstringInArray(country?.name, ['canada']) &&
                      !doorDeliveryFee
                  )}
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <PaymentMethodModal
        visible={paymentMethodVisible}
        setVisible={(val) => setPaymentMethodVisible(val)}
        selectedMethod={paymentMethod}
        setSelectedMethod={(val) => setPaymentMethod(val)}
        excludeMethods={['voucher']}
        tempCountry={country}
      />
      <DeliveryAddressModal
        visible={deliveryAddressVisible}
        setVisible={(val) => {
          setDeliveryAddressVisible(val);
        }}
        selectedAddress={deliveryAddress}
        setSelectedAddress={(addr) => {
          setDeliveryAddress(addr);
        }}
        setInitialAddress={(val) => {
          setInitialAddress(val);
        }}
        addDeliveryVisible={addDeliveryVisible}
        setAddDeliveryVisible={(val) => {
          setAddDeliveryVisible(val);
        }}
        addressModalOpened={addressModalOpened}
        setAddressModalOpened={(val) => {
          setAddressModalOpened(val);
        }}
      />
      <DeliveryMethodModal
        visible={deliveryMethodVisible}
        setVisible={(val) => setDeliveryMethodVisible(val)}
        selectedMethod={deliveryMethod}
        setSelectedMethod={(val) => setDeliveryMethod(val)}
        deliveryListVisible={deliveryListVisible}
        setDeliveryListVisible={(val) => setDeliveryListVisible(val)}
      />
      <AddDeliveryAddressModal
        visible={addDeliveryAddressVisible}
        setVisible={(val) => setAddDeliveryAddressVisible(val)}
        initial={initialAddress}
      />
      <VoucherModal
        visible={voucherVisible}
        setVisible={setVoucherVisible}
        selectedMethod={voucherDet}
        setSelectedMethod={setVoucherDet}
        tempCountry={country}
      />
      <SuccessModal
        visible={successVisible}
        setVisible={setSuccessVisible}
        details={newOrderDetails}
        text={`Your Order, ID ${
          newOrderDetails?.order?.order_id || newOrderDetails?.order_id
        } was placed successfully.\nCheck order details to track this order`}
      />
      <Modal
        centered
        backdrop="static"
        onHide={() => setStripeClientSecret(null)}
        show={
          stripeClientSecret &&
          stripePromise &&
          !stripeLoading &&
          !isLoadingCreateOrder
        }
      >
        <Modal.Header closeButton>
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text="Payment"
          />
        </Modal.Header>
        <Modal.Body>
          {stripeClientSecret && stripePromise && !stripeLoading && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: stripeClientSecret,
                appearance: { theme: 'stripe' },
              }}
            >
              <StripeCheckoutForm
                handleSuccess={() => {
                  handleStripePayment(stripeClientSecret);
                }}
                loading={isLoadingCreateOrder}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

const Pay4MeComponent = ({ values, errors, handleChange }) => {
  return (
    <Row className="mt-3 mb-2">
      <CustomInput
        label={'Recipient email address'}
        placeholder="Email address"
        id="pay4MeEmail"
        onChange={handleChange('pay4MeEmail')}
        value={values.pay4MeEmail}
        error={errors?.pay4MeEmail}
      />
    </Row>
  );
};

export default CheckoutDetails;
