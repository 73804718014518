// import node module libraries
import React from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactComponent as EmptySvg } from 'assets/svgs/emptySavedItems.svg';

const EmptyState = ({
  icon = <EmptySvg />,
  title,
  subtitle,
  btnText = 'Start Shopping',
  btnAction,
  btnComponent,
  showBtn = true,
}) => {
  const { isMobile } = useScreenSize();
  const history = useHistory();

  return (
    <Col
      className={`bg-white d-flex align-items-center text-center p-5 colcentered ${
        isMobile ? 'mt-3 mb-3' : ''
      }`}
      style={{ minHeight: isMobile ? 500 : 400 }}
    >
      {icon && icon}

      {title && (
        <CustomText
          divClassName={subtitle ? 'mt-4' : 'my-4'}
          fontFamily={'Inter'}
          fontSize={16}
          fontWeight={600}
          text={title}
          // cNColor="gray"
        />
      )}
      {subtitle && (
        <CustomText
          divClassName={title ? 'mb-4' : 'my-4'}
          fontFamily={'Inter'}
          fontSize={14}
          fontWeight={500}
          cNColor="text-secondary"
          text={subtitle}
          // cNColor="gray"
        />
      )}

      {btnComponent ? (
        btnComponent
      ) : showBtn ? (
        <CustomButton
          text={btnText}
          variant="primary"
          fontFamily={'Poppins'}
          style={{ width: isMobile ? '100%' : 400 }}
          onClick={btnAction || (() => history.push('/'))}
        />
      ) : (
        <></>
      )}
    </Col>
  );
};

export default EmptyState;
