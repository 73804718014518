import { Fragment } from 'react';
import { Col, Form, ListGroup, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useUpdatePasswordMutation } from 'redux/features/user/userApi';
import { extractId } from 'helper/formatObject';
import { errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';
import { useSelector } from 'react-redux';
import { ReactComponent as EyeSvg } from 'assets/svgs/eye-close.svg';
import { ReactComponent as EyeOnSvg } from 'assets/svgs/eyeOpen.svg';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRef } from 'react';

const ChangePassword = ({ setActiveTab, activeTab }) => {
  const { user } = useSelector((state) => state.userAuth);

  const dispatch = useDispatch();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [loadEnd, setLoadEnd] = useState(false);
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = (value) => {
    value === 'password'
      ? setPasswordShown(!passwordShown)
      : value === 'newPassword'
      ? setPasswordShown1(!passwordShown1)
      : setPasswordShown2(!passwordShown2);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref('password')],
        "Old password and new password can't be the same"
      )
      .required()
      .min(8)
      .label('New Password'),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'New passwords must match')
      .required()
      .label('Confirm New Password'),
  });

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: validateOnChange,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handlePasswordChange(values);
    },
  });

  useEffect(() => {
    setLoadEnd(false);
    resetForm();
    setFieldValue('password', '');
    setFieldValue('newPassword', '');
    setFieldValue('newPasswordConfirm', '');
    setValidateOnChange(false);
    setPasswordShown(false);
    setPasswordShown1(false);
    setPasswordShown2(false);
    setLoadEnd(true);
  }, [activeTab]);

  const handlePasswordChange = async (values) => {
    const res = await updatePassword({
      id: extractId(user),
      data: values,
    });
    if (res?.data?.status === 'success') {
      show(dispatch, res?.data?.message || 'Password changed successfully', {
        type: 'success',
        placement: 'top',
        duration: 4000,
      });
      setFieldValue('password', '');
      setFieldValue('newPassword', '');
      setFieldValue('newPasswordConfirm', '');
      setValidateOnChange(false);
      resetForm();
      setActiveTab('profile');
    } else {
      show(dispatch, errorMessageFormat(res, 'Password change failed'), {
        type: 'error',
        placement: 'top',
        duration: 4000,
      });
    }
  };

  const options = [
    {
      label: 'Current Password',
      placeholder: 'Enter your current password',
      value: 'password',
    },
    {
      label: 'New Password',
      placeholder: 'Enter your new password',
      value: 'newPassword',
    },
    {
      label: 'Confirm New Password',
      placeholder: 'Re-enter new password',
      value: 'newPasswordConfirm',
    },
  ];

  return (
    activeTab === 'change-password' &&
    loadEnd && (
      <Row className="px-5" style={{ paddingTop: '20px' }}>
        {options?.map((x) => (
          <Col lg={12} md={12} className="mb-1">
            <CustomInput
              type={
                (
                  x.value === 'password'
                    ? passwordShown
                    : x.value === 'newPassword'
                    ? passwordShown1
                    : passwordShown2
                )
                  ? 'text'
                  : 'password'
              }
              required={true}
              label={x.label}
              labelFontSize={13}
              id={x?.value}
              placeholder={x.placeholder}
              onChange={handleChange}
              error={errors[x.value]}
              value={values[x.value]}
              rightAction={() => togglePassword(x.value)}
              rightComp={
                (
                  x.value === 'password'
                    ? passwordShown
                    : x.value === 'newPassword'
                    ? passwordShown1
                    : passwordShown2
                ) ? (
                  <EyeSvg
                    style={{ width: 40 }}
                    onClick={() => togglePassword(x.value)}
                  />
                ) : (
                  <EyeOnSvg
                    style={{ width: 40 }}
                    onClick={() => togglePassword(x.value)}
                  />
                )
              }
            />
          </Col>
        ))}

        <Col
          style={{ paddingTop: '15px', paddingBottom: '23px' }}
          className="d-flex justify-content-between"
        >
          <Col sm="auto">
            {/* <CustomButton
              text="Cancel"
              onClick={() => setIsEdit(false)}
              variant="outline-secondary"
              fontFamily={'Poppins'}
            /> */}
          </Col>
          <Col md="auto" className="d-flex justify-content-end">
            <CustomButton
              text="Confirm"
              onPress={() => {
                setValidateOnChange(true);
                handleSubmit();
              }}
              disabled={!isValid}
              loading={isLoading}
              variant="primary"
              fontFamily={'Poppins'}
            />
          </Col>
        </Col>
      </Row>
    )
  );
};

export default ChangePassword;
