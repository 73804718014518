import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    currentScreen: null,
    redirectScreen: null,
  },
  reducers: {
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setRedirectScreen: (state, action) => {
      state.redirectScreen = action.payload;
    },
  },
});

export const { setCurrentScreen, setRedirectScreen } = navigationSlice.actions;

export default navigationSlice.reducer;
