// import node module libraries
import React, { Fragment, useMemo, useState } from 'react';
import CheckoutDetails from './components/CheckoutDetails';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

const Checkout = () => {
  return <CheckoutDetails />;
};

export default Checkout;
