import { Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
const ProductImage = ({ images }) => {
  return (
    <Col>
      <ImageGallery
        items={images}
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
        originalHeight={350}
        originalWidth={350}
        thumbnailHeight="70px"
        thum
      />
    </Col>
  );
};

export default ProductImage;
