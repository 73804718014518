import React from 'react';
import { InputGroup, Button, Form, Image, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { CustomText } from './CustomText';

const CustomInput = ({
  value,
  inputType = 'textinput', //options -> textinput or select
  selectOptions = [],
  variant = 'form', // options are [ form, auth ]
  label,
  labelFontSize = '13px',
  labelStyle,
  labelClassName,
  labelCNcolor,
  inputHeight = '45px',
  leftComp,
  rightComp,
  rightCompStyle,
  leftCompStyle,
  accept,
  inputPadding = 24,
  type = 'text',
  required = true,
  id,
  placeholder = 'input',
  onChange = () => {},
  handleChange = () => {},
  inputStyle,
  rightAction = () => {},
  leftAction = () => {},
  rightIcon,
  inputBorderRadius = '5px',
  leftIcon,
  inputClassName,
  inputGroupStyle,
  error,
  mt = 0,
  inputTop = 5,
  inputBottom = 5,
  errorClassName = 'text-danger mb-0',
  errorStyle = { fontSize: 12 },
  disabled,
  returnSelectObject,
  leftCompClassName,
  selectClassNames,
  minLength,
  maxLength,
  as,
  rows,
  numberOfLines,
}) => {
  return (
    <div style={{ marginTop: mt }}>
      {label && (
        <CustomText
          fontFamily={'Poppins'}
          fontSize={labelFontSize}
          fontWeight={400}
          text={label || ''}
          textStyle={labelStyle}
          cNColor={labelCNcolor || 'text-black'}
          textClassName={
            (labelClassName ? labelClassName : '') + ' text-capitalize'
          }
        />
      )}
      {inputType === 'textinput' || inputType === 'textarea' ? (
        <InputGroup
          style={{
            marginTop: inputTop,
            marginBottom: inputBottom,
            alignItems: 'center',
            flexDirection: 'row',
            ...inputGroupStyle,
          }}
        >
          <a
            href="#"
            className={
              leftCompClassName ||
              'position-absolute ps-3 start-0 align-items-center'
            }
            style={{ top: '50%', transform: 'translateY(-50%)', zIndex: 100 }}
            onClick={(e) => {
              e.preventDefault();
              leftAction();
            }}
          >
            {leftComp && leftComp}
            {leftIcon && leftIcon}
          </a>
          <Form.Control
            defaultValue={value}
            type={type}
            id={id}
            as={as}
            placeholder={placeholder}
            required={required}
            accept={accept}
            onChange={onChange || handleChange}
            style={{
              backgroundColor: variant === 'form' ? '#f8f8f8' : 'transparent',
              borderColor: variant === 'form' ? '#eeeeee' : '#d9d9d9',
              height: as !== 'textarea' && inputHeight,
              borderRadius: inputBorderRadius,
              paddingLeft: inputPadding,
              paddingRight: inputPadding,
              ...inputStyle,
            }}
            rows={rows || numberOfLines}
            className={inputClassName}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
          />
          <a
            href="#"
            className="position-absolute ps-3 end-0 align-items-center"
            style={{ top: '50%', transform: 'translateY(-50%)', zIndex: 100 }}
            onClick={(e) => {
              e.preventDefault();
              rightAction();
            }}
          >
            {rightComp && rightComp}
            {rightIcon && rightIcon}
          </a>
        </InputGroup>
      ) : inputType === 'select' ? (
        <ReactSelect
          options={selectOptions}
          defaultValue={value}
          value={value}
          placeholder={placeholder}
          required={required}
          isDisabled={disabled}
          onChange={(e) => {
            returnSelectObject
              ? onChange(e) || handleChange(e)
              : onChange(e?.value) || handleChange(e?.value);
          }}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: variant === 'form' ? '#f8f8f8' : 'transparent',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: state.focused
                ? variant === 'form'
                  ? '#eeeeee'
                  : '#f9f9f9'
                : variant === 'form'
                ? '#eeeeee'
                : '#f9f9f9',
              borderRadius: inputBorderRadius,
              height: inputHeight,
              paddingLeft: inputPadding || '14px',
              ...inputStyle,
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#538135' : null,
              color: state.isSelected ? 'white' : 'black',
            }),
            container: (provided, state) => ({
              ...provided,
              marginTop: inputTop,
              marginBottom: inputBottom,
              width: '100%',
              ...inputGroupStyle,
            }),
          }}
        />
      ) : (
        <></>
      )}

      {error && (
        <p className={errorClassName} style={errorStyle}>
          {error}
        </p>
      )}
    </div>
  );
};

export default CustomInput;
