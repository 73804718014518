import React from 'react';

export const CustomText = ({
  text = '---',
  fontSize = 16,
  fontWeight = 400,
  fontFamily = 'Poppins',
  mb = '0px',
  mt = '0px',
  mr = '0px',
  ml = '0px',
  onClick,
  onPress,
  cNColor = 'text-black',
  styleColor,
  divStyle = null,
  textStyle = {},
  divClassName = '',
  className = '',
  textClassName = '',
  children,
  removeView,
}) => {
  return removeView ? (
    <p
      className={`${!styleColor ? cNColor : ''} ${textClassName}`}
      // className={textClassName}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        color: styleColor && styleColor,
        ...textStyle,
      }}
    >
      {children || text}
    </p>
  ) : (
    <div
      style={divStyle}
      className={divClassName || className}
      onClick={onClick || onPress}
    >
      <p
        className={`${!styleColor ? cNColor : ''} ${textClassName}`}
        // className={textClassName}
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeight: fontWeight,
          marginTop: mt,
          marginBottom: mb,
          marginLeft: ml,
          marginRight: mr,
          color: styleColor && styleColor,
          ...textStyle,
        }}
      >
        {children || text}
      </p>
    </div>
  );
};
