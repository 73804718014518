import { Card, Col, Image, Spinner } from 'react-bootstrap';
import Naira from 'react-naira';
import CustomButton from './CustomButton';
import { CustomText } from './CustomText';
import { ReactComponent as HeartUnfilled } from 'assets/svgs/heart-unfilled.svg';
import { ReactComponent as HeartFilled } from 'assets/svgs/heart-filled.svg';
import { ReactComponent as AddToCart } from 'assets/svgs/product-cart.svg';
import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  capitalize,
  errorMessageFormat,
  formatCurrency,
} from 'helper/formatText';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'helper/toast';
import { saveToUserStore } from 'redux/features/user/userSlice';
import {
  useAddToSavedItemsMutation,
  useDeleteSavedItemMutation,
} from 'redux/features/user/userApi';
import { extractId } from 'helper/formatObject';
import { AddToCartModal } from './AddToCartModal';

const Product = ({
  loadingProducts,
  item,
  price,
  wish = true,
  url,
  maxWidth,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.userAuth);
  const { isMobile, isMobileS } = useScreenSize();

  const [addModalVisible, setAddModalVisible] = useState(false);

  const { token, countries, area } = useSelector((state) => state.userAuth);
  const { savedItems } = useSelector((state) => state.userStore);
  const [addToSavedItems, { isLoading: isLoadingAddToSavedItems }] =
    useAddToSavedItemsMutation();
  const [deleteSavedItem, { isLoading: isLoadingDeleteSavedItems }] =
    useDeleteSavedItemMutation();

  const handleDeleteSavedItem = async ({ item_id, saved_item_id, name }) => {
    const res = await deleteSavedItem({ item_id, saved_item_id });
    if (res?.data?.status === 'success') {
      show(dispatch, `${name} has been removed from saved items`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(
        saveToUserStore({
          key: 'savedItems',
          value: res?.data?.data,
        })
      );
      // setRefetchSavedItems(!refetchSavedItems);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };
  const handleAddToSavedItems = async ({
    product_id,
    area_id,
    country_id,
    name,
  }) => {
    const res = await addToSavedItems({ product_id, area_id, country_id });
    if (res?.data?.status === 'success') {
      show(dispatch, `${name} has been added to saved items`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(
        saveToUserStore({
          key: 'savedItems',
          value: res?.data?.data,
        })
      );
      // setRefetchSavedItems(!refetchSavedItems);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const handleAddToCartPress = useCallback((item) => {
    if (token) {
      setAddModalVisible(true);
    } else {
      show(dispatch, 'Please sign in to allow us manage your cart', {
        type: 'warning',
        duration: 4000,
      });
    }
  }, []);

  return (
    <Col
      className={`px-0 ${maxWidth ? '' : 'w-100'}`}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        maxWidth: maxWidth || '100%',
      }}
    >
      <Card
        className={`shadow mb-3 ${!isMobile || isMobileS ? 'w-100' : ''}`}
        id="product-card"
        style={{
          width: isMobile && 172,
          // height: isMobile && 243,
        }}
      >
        <Card.Body className="p-0">
          <Col style={{ paddingTop: '12px' }}>
            <Image
              onClick={() => history.push(url, { item })}
              fluid
              className="center img-fluid"
              src={item?.imageCover}
              alt="prod-img"
              style={{
                maxWidth: isMobile ? 120 : '100%',
                height: isMobile ? 120 : 200,
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '15px',
                objectFit: 'contain',
              }}
            />
            <Col
              className={!wish ? 'd-none' : ''}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 100,
              }}
            >
              {token ? (
                isLoadingAddToSavedItems || isLoadingDeleteSavedItems ? (
                  <Spinner size="sm" variant="primary" />
                ) : savedItems?.items &&
                  savedItems?.items?.find((x) => x?.id == extractId(item)) ? (
                  <HeartFilled
                    onClick={() => {
                      if (token) {
                        savedItems?.items &&
                        savedItems?.items?.find((x) => x?.id == extractId(item))
                          ? handleDeleteSavedItem({
                              item_id: extractId(item),
                              saved_item_id: savedItems?.id,
                              name: item?.name,
                            })
                          : handleAddToSavedItems({
                              name: item?.name,
                              product_id: extractId(item),
                              area_id: extractId(area),
                              country_id: extractId(country),
                            });
                      } else {
                        show(
                          dispatch,
                          'Sign in to use the saved items feature',
                          { type: 'warning', duration: 4000 }
                        );
                      }
                    }}
                  />
                ) : (
                  <HeartUnfilled
                    onClick={() => {
                      if (token) {
                        savedItems?.items &&
                        savedItems?.items?.find((x) => x?.id == extractId(item))
                          ? handleDeleteSavedItem({
                              item_id: extractId(item),
                              saved_item_id: savedItems?.id,
                              name: item?.name,
                            })
                          : handleAddToSavedItems({
                              name: item?.name,
                              product_id: extractId(item),
                              area_id: extractId(area),
                              country_id: extractId(country),
                            });
                      } else {
                        show(
                          dispatch,
                          'Sign in to use the saved items feature',
                          { type: 'warning', duration: 4000 }
                        );
                      }
                    }}
                  />
                )
              ) : (
                <></>
              )}
            </Col>
          </Col>

          <Col
            lg={12}
            md={12}
            sm={12}
            onClick={() => history.push(url, { item })}
          >
            <CustomText
              fontFamily={'Inter'}
              fontSize={14}
              fontWeight={500}
              text={item?.name}
              // cNColor="gray"
              textClassName={`px-2 ${
                type === 'carousel' ? 'text-truncate' : ''
              }`}
            />

            <CustomText
              fontFamily={'Inter'}
              fontSize={12}
              fontWeight={300}
              text={`(${capitalize(item?.priceByMarket?.uom_id?.name)})`}
              textClassName="px-2"
              // cNColor="gray"
            />

            <CustomText
              fontFamily={'Inter'}
              fontSize={14}
              fontWeight={600}
              text={formatCurrency(
                item?.priceByMarket?.price,
                2,
                country?.name?.toLowerCase()?.includes('nigeria')
                  ? '₦'
                  : country?.currency?.symbol
              )}
              textClassName="px-2"
              // cNColor="gray"
            />
          </Col>
        </Card.Body>
        <Card.Footer className="p-0" style={{ borderTop: 'none' }}>
          <Col lg={12} md={12} sm={12} className="p-2">
            <CustomButton
              height={25}
              className={'w-100 p-1 text-white'}
              text="Add  to cart"
              // onClick={=}
              variant="primary"
              buttonTextColor="white"
              fontSize={11}
              // style={{
              //   boxShadow:
              //     '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
              // }}
              onPress={() => handleAddToCartPress(item)}
              disabled={loadingProducts}
              loading={loadingProducts}
            />
          </Col>
        </Card.Footer>
      </Card>
      <AddToCartModal
        visible={addModalVisible}
        handleClose={() => setAddModalVisible(false)}
        item={item}
      />
    </Col>
  );
};

export default Product;
