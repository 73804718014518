import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { ReactComponent as DebitCardSvg } from 'assets/svgs/debitCard.svg';
import { ReactComponent as CreditCardSvg } from 'assets/svgs/creditCard.svg';
import { ReactComponent as CashSvg } from 'assets/svgs/cash-stack.svg';
import { ReactComponent as CheckSvg } from 'assets/svgs/checked.svg';
import { ReactComponent as UncheckSvg } from 'assets/svgs/unchecked.svg';
import { CustomText } from 'components/CustomText';
import {
  areArraysEqual,
  isArrayNonEmpty,
  isSubstringInArray,
  sortByHighestBalance,
  sortedDataByCreatedAt,
} from 'helper/formatArray';
import {
  capitalize,
  errorMessageFormat,
  formatCurrency,
} from 'helper/formatText';
import { show } from 'helper/toast';
import { saveToUserStore } from 'redux/features/user/userSlice';
import {
  useGetPaymentMethodsMutation,
  useGetUserUsableVouchersMutation,
} from 'redux/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import EmptyState from 'components/EmptyState';

export const VoucherModal = ({
  visible,
  setVisible,
  selectedMethod,
  setSelectedMethod,
  excludeMethods,
  tempCountry,
}) => {
  const dispatch = useDispatch();
  const { user, country: countryInit } = useSelector((state) => state.userAuth);
  const country = tempCountry || countryInit;
  const { userVouchers } = useSelector((state) => state.userStore);

  const closeModal = () => {
    setVisible(false);
  };

  const [getUserUsableVouchers, { isLoading: isLoadingVouchers, isFetching }] =
    useGetUserUsableVouchersMutation();

  const fetchVouchers = async () => {
    let res = await getUserUsableVouchers({
      email: user?.email,
      params: { country_id: extractId(country), status: 'active' },
    });
    if (res?.data?.status === 'success') {
      let extr = res?.data?.data?.map((x) => {
        return convertJsonStringToObject({ ...x });
      });
      console.log('extr', extr);
      dispatch(
        saveToUserStore({
          key: 'userVouchers',
          value: sortedDataByCreatedAt(extr, 'desc'),
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    fetchVouchers();
  }, [user, visible]);

  const options = useMemo(() => {
    let filtered = userVouchers || [];

    filtered = filtered?.filter(
      (x) => x?.status == 'active' && x?.country_id?.id == extractId(country)
    );

    filtered = filtered?.map((x) => {
      return {
        ...x,
        name: String(x?.code)?.toUpperCase(),
        svg: <VoucherSvg />,
      };
    });
    filtered = sortByHighestBalance(filtered);
    return filtered;
  }, [userVouchers, country]);

  const EmptyComponent = () => {
    const emptyProps = {
      title: isLoadingVouchers ? 'Loading vouchers' : 'No vouchers yet',
      subtitle: `Vouchers available in ${country?.currency?.name} (${country?.currency?.code}) will appear here`,
      showBtn: false,
    };
    return <EmptyState {...emptyProps} />;
  };

  return (
    <Modal show={visible} onHide={closeModal} centered>
      <Modal.Title className="px-4 py-4 rowcentered">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Pick a voucher"
        />
      </Modal.Title>
      <Modal.Body className="px-4 py-1 mb-4">
        {isLoadingVouchers ? (
          <div style={{ height: 180, width: '100%' }} className="centered">
            <Spinner variant="primary" />
          </div>
        ) : (
          <div
            style={{ maxHeight: window.innerHeight * 0.75, overflowY: 'auto' }}
            className="px-2"
          >
            {!isArrayNonEmpty(options) ? (
              <EmptyComponent />
            ) : (
              options?.map((option) => {
                return (
                  <div
                    className="border border-2 border-primary rounded py-3 px-3 rowcentered mb-2"
                    onClick={() => {
                      setSelectedMethod(option);
                      dispatch(
                        saveToUserStore({
                          key: 'currentVoucher',
                          value: { ...option, svg: <></> },
                        })
                      );
                      closeModal();
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {option?.svg}
                      <div className="ms-3 d-flex flex-column align-items-start">
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          text={option?.name}
                          divClassName="ps-3"
                        />
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          divClassName="ps-3"
                          cNColor="text-secondary"
                        >
                          Available Balance -{' '}
                          {formatCurrency(
                            option?.value - option?.used_value,
                            2,
                            country?.name?.toLowerCase()?.includes('nigeria')
                              ? '₦'
                              : country?.currency?.symbol
                          )}
                        </CustomText>
                      </div>
                    </div>
                    {selectedMethod?.name == option?.name ? (
                      <CheckSvg />
                    ) : (
                      <UncheckSvg />
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="px-4 py-4 justify-content-center p-1">
        <CustomButton
          variant="primary"
          type="submit"
          text="Confirm"
          className={'w-100'}
          onClick={closeModal}
        />
      </Modal.Footer> */}
    </Modal>
  );
};
