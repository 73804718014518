import CustomButton from 'components/CustomButton';
import { CustomText } from 'components/CustomText';
import { formatCurrency } from 'helper/formatText';
import { Card, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
const CartSummary = ({
  subtotal,
  mov,
  filteredData,
  country,
  isLoadingGetCart,
  isLoadingUpdateCartItemQuantity,
  isLoadingDeleteCartItem,
}) => {
  const history = useHistory();
  const { cart } = useSelector((state) => state.userStore);
  // const cartLength = {data?.length}
  return (
    <Card className={'mb-3'}>
      <Card.Header>
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={400}
          text={'Summary'}
          className={'text-capitalise'}
          // cNColor="gray"
        />
      </Card.Header>
      <Card.Body className="pt-0 pb-0">
        {subtotal < mov && filteredData?.length > 0 && (
          <Col className="mt-3">
            <CustomText
              fontFamily={'Poppins'}
              fontSize={12}
              fontWeight={400}
              text={
                'Your cart subtotal is lower than minimum order value for your delivery country, add more items or increase the quantity of items in your cart to checkout'
              }
              cNColor={'text-danger'}
            />
          </Col>
        )}
        <Col className="rowcentered mt-3">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={14}
            fontWeight={400}
            text={'No. of Items:'}
            // textStyle={{ paddingBottom: '20px' }}
            // styleColor={'#636363'}
            // cNColor="gray"
          />
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={500}
            text={cart?.cart_items?.length}
            // styleColor={'#636363'}
            // cNColor="gray"
          />
        </Col>
        <Col className="rowcentered mt-3 mb-3">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={14}
            fontWeight={400}
            text={'Minimum Order Value'}
          />
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={500}
            text={formatCurrency(mov, 2, country?.currency?.symbol)}
          />
        </Col>
      </Card.Body>
      <Card.Footer>
        <Col className="rowcentered mb-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={14}
            fontWeight={400}
            text={'Delivery fee not included'}
            cNColor="text-secondary"
          />
        </Col>
        <Col className="rowcentered mb-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={500}
            text={'Subtotal:'}
          />

          <CustomText
            fontFamily={'Poppins'}
            fontSize={18}
            fontWeight={600}
            text={formatCurrency(
              subtotal,
              2,
              country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : country?.currency?.symbol
            ).replace('.00', '')}
            // styleColor={'#636363'}
            // cNColor="gray"
          />
        </Col>

        <CustomButton
          className={'w-100 p-1'}
          text="Checkout"
          variant="primary"
          fontWeight={500}
          fontSize={16}
          style={{
            boxShadow:
              '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
          }}
          onClick={() => history.push('/checkout')}
          disabled={
            !cart.cart_items ||
            cart.cart_items.length == 0 ||
            subtotal < mov ||
            isLoadingDeleteCartItem ||
            isLoadingGetCart ||
            isLoadingUpdateCartItemQuantity
          }
          loading={isLoadingGetCart}
        />
      </Card.Footer>
    </Card>
  );
};

export default CartSummary;
