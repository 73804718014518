import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from './Login';
import Register from './Register';
import { Fragment, useRef, useState } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import {
  saveToAuth,
  saveToken,
  saveToUserAuth,
  saveUser,
} from 'redux/features/auth/authSlice';
import {
  useForgotPasswordByEmailMutation,
  useForgotPasswordByPhoneMutation,
  useResetPasswordMutation,
  useVerifyOtpMutation,
  useVerifyTokenMutation,
} from 'redux/features/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
// import Login from "./Login";
import * as Yup from 'yup';
import { show } from 'helper/toast';
import { Formik } from 'formik';
import CustomInput from 'components/CustomInput';
import { errorMessageFormat, obfuscateEmail } from 'helper/formatText';
import { ReactComponent as EyeSvg } from 'assets/svgs/eye-close.svg';
import { ReactComponent as EyeOnSvg } from 'assets/svgs/eyeOpen.svg';

const ResetPassword = (props) => {
  const [activeTab, setActiveTab] = useState('resetPassword');
  const route = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const togglePassword = (value) => {
    value === 'password'
      ? setPasswordShown(!passwordShown)
      : setPasswordShown1(!passwordShown1);
  };

  const token = location?.state?.token;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6)
      .label('Password'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
      .label('Password'),
  });

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleReset = async (values) => {
    const resolveValues = { ...values, token: token };
    const res = await resetPassword(resolveValues);
    if (res?.data?.status === 'success') {
      show(dispatch, res?.data?.message || 'Password reset successfully', {
        type: 'success',
      });
      dispatch(saveToken(res?.data?.token));
      dispatch(saveToAuth(['isLoggedIn', true]));
      dispatch(saveUser({ ...res?.data?.user, ...res?.data?.publicApi }));
      history.push('/');
    } else {
      show(dispatch, errorMessageFormat(res, 'Password reset failed'), {
        type: 'error',
      });
    }
  };
  const options = [
    {
      label: 'Password',
      placeholder: 'Enter your password',
      value: 'password',
    },
    {
      label: 'Confirm Password',
      placeholder: 'Re-enter your password',
      value: 'passwordConfirm',
    },
  ];
  return (
    <Col style={{ marginTop: 60 }} xl={12} lg={12}>
      <Tab.Container
        id="verify"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
      >
        <Row
          className="d-flex justify-content-center"
          style={{ paddingBottom: 30 }}
        >
          <CustomButton
            onClick={() => {}}
            style={{
              marginRight: 5,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
          >
            <Col
              style={{ paddingBottom: activeTab == 'resetPassword' ? 0 : 5 }}
            >
              <CustomText
                text="Reset Password"
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={activeTab == 'resetPassword' ? 20 : 18}
                cNColor="text-black"
              />
              {activeTab == 'resetPassword' && (
                <Col className="d-flex justify-content-center align-items-center">
                  <TabLine />
                </Col>
              )}
            </Col>
          </CustomButton>
        </Row>
        <Col xl={12} lg={12}>
          <Tab.Content>
            <Tab.Pane eventKey={'resetPassword'}>
              <Row
                className="align-items-center justify-content-center g-0"
                xl={12}
                lg={12}
              >
                <Col
                  className="px-6 py-1 align-items-md-center"
                  style={{ maxWidth: 500 }}
                >
                  {/* <CustomText
                    text={
                      'Provide your registered email address to get the verification code'
                    }
                    fontFamily="Poppins"
                    fontWeight={500}
                    fontSize={14}
                    cNColor="text-secondary"
                    className="mb-2"
                  /> */}
                  <Formik
                    initialValues={{
                      password: '',
                      passwordConfirm: '',
                    }}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleReset(values)}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      isValid,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Row>
                        <Col lg={12} md={12}>
                          {/* Username or email */}
                          {options?.map((x) => (
                            <Col lg={12} md={12} className="mb-1">
                              <CustomInput
                                type={
                                  (
                                    x.value === 'password'
                                      ? passwordShown
                                      : passwordShown1
                                  )
                                    ? 'text'
                                    : 'password'
                                }
                                required={true}
                                label={x.label}
                                labelFontSize={13}
                                id={x?.value}
                                placeholder={x.placeholder}
                                onChange={handleChange}
                                error={errors[x.value]}
                                value={values[x.value]}
                                rightAction={() => togglePassword(x.value)}
                                rightComp={
                                  (
                                    x.value === 'password'
                                      ? passwordShown
                                      : passwordShown1
                                  ) ? (
                                    <EyeSvg
                                      style={{ width: 40 }}
                                      onClick={() => togglePassword(x.value)}
                                    />
                                  ) : (
                                    <EyeOnSvg
                                      style={{ width: 40 }}
                                      onClick={() => togglePassword(x.value)}
                                    />
                                  )
                                }
                              />
                            </Col>
                          ))}
                        </Col>

                        <Col lg={12} md={12} className="mb-3 text-end">
                          <Col className="mb-3"></Col>
                          <Col
                            style={{ paddingTop: '10px' }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <CustomButton
                              variant="primary"
                              type="submit"
                              fontWeight={'500'}
                              className="w-100"
                              text={'Submit'}
                              disabled={!isValid}
                              loading={isLoading}
                              style={{ height: 45, marginBottom: 0 }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            />
                          </Col>
                        </Col>
                      </Row>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </Col>
  );
};

function LineComponent() {
  return <div className="active-line"></div>;
}

const TabLine = () => (
  <div
    style={{
      width: '36px',
      height: '5px',
      backgroundColor: '#000',
      borderRadius: '5px',
      marginTop: 3,
    }}
  ></div>
);

export default ResetPassword;
