// import node module libraries
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  Row,
  Stack,
} from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';

// simple bar scrolling used for notification item scrolling
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// import custom components

// import media files
import Logo from 'assets/pngs/logo2.png';
import { ReactComponent as CartActiveSvg } from 'assets/svgs/cart_active.svg';
import { ReactComponent as CouponSvg } from 'assets/svgs/coupon.svg';
import { ReactComponent as CouponASvg } from 'assets/svgs/couponA.svg';
import { ReactComponent as DownSvg } from 'assets/svgs/drop-down.svg';
import { ReactComponent as ExitSvg } from 'assets/svgs/exit.svg';
import { ReactComponent as LikeSvg } from 'assets/svgs/like.svg';
import { ReactComponent as LikeASvg } from 'assets/svgs/likeA.svg';
import { ReactComponent as LockSvg } from 'assets/svgs/lock.svg';
import { ReactComponent as LockASvg } from 'assets/svgs/lockA.svg';
import { ReactComponent as NotificationASvg } from 'assets/svgs/notificationA.svg';
import { ReactComponent as NotificationSvg } from 'assets/svgs/notificationB.svg';
import { ReactComponent as OrdersInactiveSvg } from 'assets/svgs/order_inactive.svg';
import personSvg, { ReactComponent as PersonSvg } from 'assets/svgs/person.svg';
import { ReactComponent as PersonASvg } from 'assets/svgs/personA.svg';
import { ReactComponent as SearchYellowSvg } from 'assets/svgs/search_yellow.svg';
// import data files
import CustomButton from 'components/CustomButton';
import CustomNavIcon from 'components/CustomNavIcon';
import { CustomText } from 'components/CustomText';

import { SelectAreaModal } from 'components/SelectAreaModal';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  sortedDataByCreatedAt,
  updateObjects,
} from 'helper/formatArray';
import { extractId } from 'helper/formatObject';
import {
  errorMessageFormat,
  formatDate,
  truncateText,
} from 'helper/formatText';
import { show } from 'helper/toast';
import { useScreenSize } from 'hooks/useScreenSize';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCountriesMutation } from 'redux/features/auth/authApi';
import {
  logout,
  saveArea,
  saveCountries,
  saveCountry,
  saveToken,
} from 'redux/features/auth/authSlice';
import {
  useGetCartItemCountMutation,
  useGetNotificationsMutation,
  useLogoutUserMutation,
  useUpdateNotificationMutation,
} from 'redux/features/user/userApi';
import {
  clearUserDetails,
  saveToStore,
  saveToUserStore,
} from 'redux/features/user/userSlice';

const NavbarDefault = ({ headerstyle, login, reset }) => {
  const { isMobile, isMobileS, isMobileM, isTablet, isLaptop, isDesktop } =
    useScreenSize();
  const { isLoggedIn, pushToken, token } = useSelector(
    (state) => state.userAuth
  );
  const { unreadNotificationsCount, userNotifications, cartItemCount } =
    useSelector((state) => state.userStore);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [showArea, setShowArea] = useState(false);

  const checkLink = (link) => {
    return location?.pathname === link;
  };

  const [logoutUser, { isLoading: isLoggingOut }] = useLogoutUserMutation();

  const handleLogout = async () => {
    const res = await logoutUser({ pushToken });
    dispatch(clearUserDetails());
    dispatch(logout());
    dispatch(saveToken(null));
    history.push('/');
  };

  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { currentScreen, redirectScreen } = useSelector(
    (state) => state.navigation
  );
  const { countries, country, user, area } = useSelector(
    (state) => state.userAuth
  );
  const [getCountries, { isLoading }] = useGetCountriesMutation();

  const fetchCountries = async () => {
    const res = await getCountries({ status: true });
    if (res?.data?.status === 'success') {
      dispatch(saveCountries(res?.data?.data));
    }
  };

  const [getCart, { isLoading: isLoadingGetCart }] =
    useGetCartItemCountMutation();

  const fetchCart = async () => {
    if (!!token) {
      const res = await getCart({
        area_id: area?.id,
        country_id: country?.id,
      });
      dispatch(
        saveToUserStore({ key: 'cartItemCount', value: res?.data?.data || 0 })
      );
    }
  };

  useEffect(() => {
    !isLoading && !visible && fetchCountries();
  }, [reset, location?.pathname, token]);

  useEffect(() => {
    token && fetchNotifications();
    fetchCart();
  }, [reset, location?.pathname, token, area, country]);

  useEffect(() => {
    if (!country && isArrayNonEmpty(countries)) {
      const userCountry = navigator?.language?.split('-')[1] || '';
      dispatch(
        saveCountry(
          country
            ? countries?.find(
                (ctry) =>
                  ctry?.name?.toLowerCase() == country?.name?.toLowerCase()
              )
            : userCountry &&
              countries?.find(
                (ctry) =>
                  ctry?.country_short_code?.toLowerCase() ==
                  userCountry?.toLowerCase()
              )
            ? countries?.find(
                (ctry) =>
                  ctry?.country_short_code?.toLowerCase() ==
                  userCountry?.toLowerCase()
              )
            : countries?.find((ctry) => ctry?.name?.toLowerCase() == 'nigeria')
        )
      );
      if (location.pathname === '/checkout') {
        history.push('/cart');
      }
    }
  }, [countries]);

  const countryRef = useRef(null);
  useEffect(() => {
    if (isSubstringInArray(country?.name, ['Nigeria']) && !area && !showArea) {
      setShowArea(true);
    }
  }, [country]);

  const handleCountryToggle = (e) => {
    setVisible(true);
  };

  const handleCountrySelect = useCallback((item) => {
    dispatch(saveCountry(item));
    setVisible(false);
    !isSubstringInArray(item?.name, ['Nigeria'])
      ? dispatch(saveArea(null))
      : setShowArea(true);
    if (location.pathname === '/checkout') {
      history.push('/cart');
    }
    dispatch(
      saveToUserStore({
        key: 'userNotifications',
        value: [],
      })
    );
  }, []);

  const handleAreaClose = () => {
    setShowArea(false);
  };

  const [getNotifications, { isLoading: isLoadingNotifications }] =
    useGetNotificationsMutation();
  const [updateNotification, { isLoading: isUpdatingNotifications }] =
    useUpdateNotificationMutation();

  const markAsRead = async (id) => {
    const res = await updateNotification({ id: id, data: { status: 'read' } });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'userNotifications',
          value: sortedDataByCreatedAt(
            updateObjects(
              [...userNotifications],
              (e) => extractId(e) === id,
              'status',
              'read'
            ),
            'desc'
          ),
        })
      );
    }
  };
  const fetchNotifications = async () => {
    const res = await getNotifications();
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'userNotifications',
          value: sortedDataByCreatedAt([...res?.data?.data], 'desc'),
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const notificationList = useMemo(() => {
    let filtered = [];
    filtered = userNotifications;
    filtered = sortedDataByCreatedAt(filtered, 'desc');
    filtered = filtered?.map((x) => {
      return {
        ...x,
        screen:
          x?.data?.screen && typeof x?.data?.screen === 'string'
            ? isSubstringInArray('Order', [x?.data?.screen])
              ? isArrayNonEmpty(x?.body?.match(/\b[fdngFDNG]+\d+\b/))
                ? 'OrderDetails'
                : 'Orders'
              : x?.data?.screen
            : 'Categories',
        order_id:
          isSubstringInArray('Order', [
            x?.data?.screen && typeof x?.data?.screen === 'string'
              ? x?.data?.screen
              : 'Categories',
          ]) &&
          isArrayNonEmpty(x?.body?.match(/\b[fdngFDNG]+\d+\b/)) &&
          x?.body?.match(/\b[fdngFDNG]+\d+\b/)[0],
      };
    });

    return filtered;
  }, [country, area, userNotifications]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      // className="text-link"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#538135',
        borderRadius: 5,
        padding: 5,
        paddingRight: 0,
        width: 60,
        height: 36,
      }}
      className="centered me-2"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ position }) => {
    return (
      <Dropdown align={{ lg: 'end' }}>
        <Dropdown.Toggle
          as={CustomToggle}
          bsPrefix="dt"
          // id="dropdownNotification"
          // onClick={handleCountryToggle}
        >
          <Image
            src={country?.flags?.png || country?.flags?.svg}
            style={{ width: 30, height: 15 }}
          />
          <DownSvg />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`dashboard-dropdown notifications-dropdown ${
            isMobile || isTablet ? 'dropdown-menu-md' : 'dropdown-menu-lg'
          } dropdown-menu-end mt-4 py-0`}
          aria-labelledby="dropdownNotification"
        >
          <div>
            <div
              className={`border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-start`}
            >
              <span>
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={16}
                  fontWeight={400}
                  text={'Select Country'}
                  divStyle={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </span>
            </div>
            <SimpleBar style={{ maxHeight: '150px' }}>
              <ListGroup variant="flush">
                {countries.map(function (item, index) {
                  return (
                    <ListGroup.Item
                      className={index === 0 ? 'bg-light' : ''}
                      key={index}
                      onClick={() => handleCountrySelect(item)}
                    >
                      <Row>
                        <Col>
                          <Link className="text-body" to="#">
                            <div className="d-flex">
                              <Image
                                src={item?.flags?.png || item?.flags?.svg}
                                alt=""
                                style={{ width: 30, height: 20 }}
                              />
                              <div className="ms-3">
                                <CustomText
                                  fontFamily={'Poppins'}
                                  fontSize={12}
                                  fontWeight={600}
                                  text={item.name}
                                  divStyle={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                />
                              </div>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </SimpleBar>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const QuickMenu = () => {
    return (
      <Stack direction="horizontal">
        {location.pathname !== '/checkout' && <ActionMenu position="end" />}

        {isLoggedIn && (
          <Dropdown
            as={Nav.Item}
            className={`${isDesktop || isLaptop ? 'me-2 colcentered' : 'me-2'}`}
          >
            <Dropdown.Toggle
              as={Nav.Link}
              bsPrefix="dt"
              className={`text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle ${
                isArrayNonEmpty(
                  notificationList?.filter((x) => x.status !== 'read')
                )
                  ? 'indicator indicator-primary'
                  : ''
              } text-muted`}
              id="dropdownNotification"
            >
              {isArrayNonEmpty(
                notificationList?.filter((x) => x.status !== 'read')
              ) && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    minWidth: 16,
                    minHeight: 16,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className={'bg-primary'}
                >
                  <span style={{ fontSize: 11 }} className="text-white mb-0">
                    {notificationList?.filter((x) => x.status !== 'read')
                      .length < 100
                      ? notificationList
                          ?.filter((x) => x.status !== 'read')
                          .length?.toString()
                      : '99+'}
                  </span>
                </div>
              )}
              {isArrayNonEmpty(
                notificationList?.filter((x) => x.status !== 'read')
              ) ? (
                <NotificationASvg />
              ) : (
                <NotificationSvg />
              )}
              {/* <i className="fe fe-bell"></i> */}
            </Dropdown.Toggle>
            <Dropdown.Menu
              show={isDesktop ? true : false}
              className={`dashboard-dropdown notifications-dropdown ${
                isMobile || isTablet ? 'dropdown-menu-md' : 'dropdown-menu-lg'
              } dropdown-menu-end mt-4 py-0`}
              aria-labelledby="dropdownNotification"
            >
              <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
                <span>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={16}
                    fontWeight={400}
                    text={'Notifications'}
                    divStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </span>
                {/* <Link to="# " className="text-muted">
                  <span className="align-middle">
                    <i className="fe fe-settings me-1"></i>
                  </span>
                </Link> */}
              </div>
              <SimpleBar style={{ maxHeight: '300px' }}>
                <ListGroup variant="flush">
                  {!isArrayNonEmpty(notificationList) ? (
                    <ListGroup.Item className={'bg-light centered'} key={1}>
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={12}
                        fontWeight={400}
                        text={'No notification yet'}
                        divStyle={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    </ListGroup.Item>
                  ) : (
                    notificationList?.slice(0, 10)?.map(function (item, index) {
                      return (
                        <ListGroup.Item
                          className={index === 0 ? 'bg-light' : ''}
                          key={index}
                          onClick={() => {
                            item?.status == 'unread' &&
                              markAsRead(extractId(item));
                            item?.order_id &&
                              history.push(
                                item?.order_id
                                  ? '/orders/' + item?.order_id
                                  : '/orders'
                              );
                          }}
                        >
                          <Row>
                            <Col>
                              <Link className="text-body" to="#">
                                <CustomText
                                  fontFamily={'Poppins'}
                                  fontSize={12}
                                  fontWeight={600}
                                  text={item.title}
                                />
                              </Link>
                            </Col>
                            <Col
                              sm="auto"
                              className="col-auto text-center me-2"
                            >
                              <span className="fs-6 text-muted">
                                <CustomText
                                  fontFamily={'Poppins'}
                                  fontSize={11}
                                  fontWeight={400}
                                  text={formatDate(item.createdAt)}
                                  divStyle={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  cNColor="text-secondary"
                                />
                              </span>
                            </Col>
                            <Col xs={12}>
                              <CustomText
                                fontFamily={'Poppins'}
                                fontSize={12}
                                fontWeight={400}
                                text={item.body}
                                divStyle={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                cNColor="text-secondary"
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })
                  )}
                </ListGroup>
              </SimpleBar>
              <div className="border-top px-3 pt-3 pb-3">
                <Link to="/notifications">
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={12}
                    fontWeight={600}
                    text={'See all Notifications'}
                    divStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {isLoggedIn && (
          <Dropdown
            as={Nav.Item}
            className={`${
              isDesktop || isLaptop ? 'mt-1 me-0 colcentered' : 'mt-1 me-2'
            }`}
          >
            <Dropdown.Toggle
              as={Nav.Link}
              bsPrefix="dt"
              className="text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle text-muted avatar avatar-md avatar-indicators avatar-online"
              id="dropdownUser"
            >
              <div>
                {user?.picture ? (
                  <Image
                    alt="avatar"
                    src={user?.picture}
                    className="rounded-circle"
                    style={{ width: 30, height: 30 }}
                  />
                ) : (
                  <i className="fe fe-user fs-3"></i>
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={`dashboard-dropdown notifications-dropdown ${
                isMobile || isTablet ? 'dropdown-menu-md' : 'dropdown-menu-lg'
              } dropdown-menu-end mt-4 py-0`}
              aria-labelledby="dropdownUser"
            >
              <Dropdown.Item
                className="mt-3"
                onClick={() => history.push('/profile')}
              >
                <div className="d-flex align-items-center">
                  <div className="avatar avatar-md avatar-indicators avatar-online">
                    <Image
                      alt="avatar"
                      src={user?.picture || personSvg}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="ms-3 lh-1">
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={500}
                      text={`${user?.firstName} ${user?.lastName}`}
                      divStyle={{
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      divClassName="mb-1"
                    />
                    <CustomText
                      fontSize={12}
                      fontWeight={500}
                      className="mb-0 text-muted"
                    >
                      {isMobileS ? truncateText(user?.email, 23) : user?.email}
                    </CustomText>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                eventKey="2"
                className="d-flex align-items-center"
                onClick={() => history.push('/profile')}
              >
                {checkLink('/profile') ? (
                  <PersonASvg width={20} height={20} />
                ) : (
                  <PersonSvg width={20} height={20} />
                )}
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  className={'ps-2'}
                  cNColor={checkLink('/profile') ? 'text-primary' : ''}
                >
                  Profile
                </CustomText>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                className="d-flex align-items-center"
                onClick={() => history.push('/saved-items')}
              >
                {checkLink('/saved-items') ? (
                  <LikeASvg width={20} height={20} />
                ) : (
                  <LikeSvg width={20} height={20} />
                )}
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  className={'ps-2'}
                  cNColor={checkLink('/saved-items') ? 'text-primary' : ''}
                >
                  Saved Items
                </CustomText>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                className="d-flex align-items-center"
                onClick={() => history.push('/vouchers')}
              >
                {checkLink('/vouchers') ? (
                  <CouponASvg width={20} height={20} />
                ) : (
                  <CouponSvg width={20} height={20} />
                )}
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  className={'ps-2'}
                  cNColor={checkLink('/vouchers') ? 'text-primary' : ''}
                >
                  Vouchers
                </CustomText>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                className="d-flex align-items-center"
                onClick={() => history.push('/change-password')}
              >
                {checkLink('/change-password') ? (
                  <LockASvg width={20} height={20} />
                ) : (
                  <LockSvg width={20} height={20} />
                )}
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  className={'ps-2'}
                  cNColor={checkLink('/change-password') ? 'text-primary' : ''}
                >
                  Change Password
                </CustomText>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="d-flex mb-3 align-items-center"
                onClick={handleLogout}
              >
                <ExitSvg width={20} height={20} />
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  className={'ps-2'}
                >
                  Sign Out
                </CustomText>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Stack>
    );
  };
  return (
    <div className="bg-white">
      {/* <Container fluid style={{ maxWidth: 1400 }}> */}
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className={`${login ? 'bg-white' : ''} navbar p-2 ${
          headerstyle === 'dark' ? 'navbar-dark bg-dark' : 'navbar-default py-2'
        }`}
        fixed="top"
      >
        <Container fluid className="px-0">
          <Navbar.Brand as={Link} to="/">
            <Image
              src={Logo}
              alt=""
              style={{
                maxHeight: 56,
                maxWidth: (isMobileS || isMobileM) && 100,
              }}
            />
          </Navbar.Brand>

          <div
            className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
              !login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
            }`}
          >
            <QuickMenu />
          </div>

          {!login && (
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="icon-bar top-bar mt-0"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </Navbar.Toggle>
          )}
          {!login && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Form className="mt-3 mt-lg-0 ms-lg-2 d-flex align-items-center">
                <span className="position-absolute ps-3 search-icon">
                  <SearchYellowSvg />
                </span>

                <Form.Control
                  type="Search"
                  id="formSearch"
                  className="ps-6"
                  placeholder="Search Groceries and Food items"
                  style={{
                    height: 40,
                    minWidth: isMobileS
                      ? 100
                      : isMobile
                      ? 200
                      : isTablet
                      ? 300
                      : isLaptop
                      ? isLoggedIn
                        ? 260
                        : 220
                      : 400,
                  }}
                  onChange={(e) => setSearchValue(e?.target?.value)}
                />
                <CustomButton
                  variant="primary"
                  type="button"
                  text="SEARCH"
                  fontWeight={600}
                  style={{
                    height: 40,
                    width: 100,
                    marginBottom: 0,
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    dispatch(saveToStore(['searchTerm', searchValue]));
                    history.push('/category');
                  }}
                />
              </Form>

              <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                <Row
                  className={`d-flex ms-auto me-1 mt-3 mt-lg-0 align-items-center ${
                    login ? 'd-none' : ''
                  } ${
                    isDesktop || isLaptop ? '' : 'w-100 justify-content-start'
                  }`}
                >
                  <Col
                    lg="auto"
                    md={6}
                    sm={6}
                    xs={6}
                    className={`d-flex mb-3 mb-lg-0 justify-content-center`}
                  >
                    <CustomNavIcon
                      onClick={() => setExpandedMenu(false)}
                      icon={
                        <div
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              minWidth: 16,
                              minHeight: 16,
                              borderRadius: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            className={'bg-primary'}
                          >
                            <span
                              style={{ fontSize: 11 }}
                              className="text-white mb-0"
                            >
                              {!cartItemCount
                                ? '0'
                                : Number(cartItemCount) < 100
                                ? (cartItemCount || '0')?.toString()
                                : '99+'}
                            </span>
                          </div>
                          <CartActiveSvg />
                        </div>
                      }
                      text={'Cart'}
                      address="/cart"
                      textColor="text-white"
                      lineColor="bg-white"
                      showLine={false}
                      className={'bg-black'}
                      style={{
                        // backgroundColor: 'black',
                        padding: 6,
                        paddingRight: 12,
                        borderRadius: 5,
                        width: 88,
                      }}
                    />
                  </Col>
                  <Col
                    lg="auto"
                    md={6}
                    sm={6}
                    xs={6}
                    className={`d-flex mb-3 mb-lg-0 justify-content-center`}
                  >
                    <CustomNavIcon
                      icon={<OrdersInactiveSvg />}
                      text={'Orders'}
                      address="/orders"
                      onClick={() => setExpandedMenu(false)}
                      style={{
                        // marginRight: 10,
                        padding: 6,
                        paddingRight: 12,
                        borderRadius: 5,
                        width: 88,
                      }}
                    />
                  </Col>
                  {!isLoggedIn && (
                    <Col
                      lg="auto"
                      md={6}
                      sm={6}
                      xs={6}
                      className={`d-flex mb-3 mb-lg-0 justify-content-center`}
                    >
                      <CustomButton
                        text="Sign in"
                        variant="secondary"
                        fontSize={13}
                        style={{
                          width: '88px',
                          height: '40px',
                          borderRadius: '5px',
                        }}
                        className={'text-black bg-white border-primary'}
                        onClick={() => {
                          history.push('/login', {
                            redirect: location?.pathname,
                          });
                          setExpandedMenu(false);
                        }}
                      />
                    </Col>
                  )}
                  {!isLoggedIn && (isDesktop || !isLaptop) && (
                    <Col
                      lg="auto"
                      md={6}
                      sm={6}
                      xs={6}
                      className={`d-flex mb-3 mb-lg-0 justify-content-center`}
                    >
                      <CustomButton
                        text="Register"
                        fontSize={13}
                        style={{
                          width: '88px',
                          height: '40px',
                          borderRadius: '5px',
                        }}
                        variant="primary"
                        onClick={() => {
                          history.push('/register', {
                            redirect: location?.pathname,
                          });
                          setExpandedMenu(false);
                        }}
                      />
                    </Col>
                  )}
                </Row>

                <span
                  className={`${
                    !login
                      ? isDesktop || isLaptop
                        ? 'd-flex ms-2'
                        : 'd-none'
                      : 'd-none'
                  }`}
                >
                  <QuickMenu />
                </span>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      {/* </Container> */}
      <SelectAreaModal visible={showArea} setVisible={setShowArea} />
    </div>
  );
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
  headerstyle: 'navbar-default',
  login: false,
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
  headerstyle: PropTypes.string,
  login: PropTypes.bool,
};

export default NavbarDefault;
