import { Fragment, useMemo, useState } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import {
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link } from 'react-router-dom';
import CustomInput from 'components/CustomInput';
import { useScreenSize } from 'hooks/useScreenSize';
import React from 'react';
import { ReactComponent as DownSvg } from 'assets/svgs/drop-down.svg';
import { useSelector } from 'react-redux';
import {
  useGetProductsMutation,
  useGetSavedItemsMutation,
  useGetUnrestrictedProductsMutation,
} from 'redux/features/user/userApi';
import { extractId } from 'helper/formatObject';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { show } from 'helper/toast';
import { errorMessageFormat } from 'helper/formatText';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  extractValueFromArray,
  isArrayNonEmpty,
  removeDuplicates,
  removeUndefinedOrNull,
} from 'helper/formatArray';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import EmptyState from 'components/EmptyState';

const AllProducts = ({ loadingProducts, changeLoadingProduct }) => {
  const { isMobileS, isMobile, isTablet } = useScreenSize();

  const { country, token, area, user } = useSelector((state) => state.userAuth);
  const { categories, products, savedItems } = useSelector(
    (state) => state.userStore
  );
  const {
    areas,
    filterByPriceFrom,
    filterByPriceTo,
    filterByBrand,
    filterByUom,
    unreadNotifications,
    unreadNotificationsCount,
    initialLoad,
    initialLoadArea,
    lastProductLoadtime,
  } = useSelector((state) => state.userStore);

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [resetFilter, setResetFilter] = useState(true);
  const [sortFilter, setSortFilter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const CustomToggleRight = React.forwardRef(({ children, onClick }, ref) => (
    <Col
      className={`centered`}
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius:
          isMobileS || isMobile || isTablet ? '5px' : '0px 5px 5px 0px',
        paddingTop: 5,
        paddingBottom: 5,
        // borderLeft: isMobileS || isMobile || isTablet ? '1px solid' : 'none',
        paddingLeft: isMobile ? 10 : 10,
        paddingRight: isMobile ? 0 : 5,
      }}
    >
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <DownSvg />
      </a>
    </Col>
  ));
  const CustomToggleLeft = React.forwardRef(({ children, onClick }, ref) => (
    <Col
      className={`centered`}
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius:
          isMobileS || isMobile || isTablet ? '5px' : '5px 0px 0px 5px',
        paddingTop: 5,
        paddingBottom: 5,
        // borderRight: isMobileS || isMobile || isTablet ? '1px solid' : 'none',
        paddingLeft: isMobile ? 10 : 10,
        paddingRight: isMobile ? 0 : 5,
      }}
    >
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <DownSvg />
      </a>
    </Col>
  ));

  const [getSavedItems, { isLoading: isLoadingGetSavedItems }] =
    useGetSavedItemsMutation();
  const [getProducts, { isLoading: isLoadingProducts }] =
    useGetProductsMutation();
  const [
    getUnrestrictedProducts,
    { isLoading: isLoadingUnrestrictedProducts },
  ] = useGetUnrestrictedProductsMutation();

  const fetchSavedItems = async () => {
    const res = await getSavedItems({
      area_id: area?.id,
      country_id: extractId(country),
    });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'savedItems', value: res.data?.data }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };
  const fetchProducts = async () => {
    const res = token
      ? await getProducts({ country_id: extractId(country) })
      : await getUnrestrictedProducts({
          country_id: extractId(country),
        });

    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'products', value: res.data?.data }));
      setResetFilter(!resetFilter);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    token && !isLoadingGetSavedItems && fetchSavedItems();
    !isLoadingProducts && !isLoadingUnrestrictedProducts && fetchProducts();
    setSortFilter(null);
    setSelectedCategory('all');
  }, [token, country]);

  useEffect(() => {
    changeLoadingProduct(isLoadingProducts || isLoadingUnrestrictedProducts);
  }, [isLoadingProducts, isLoadingUnrestrictedProducts]);

  const filteredProducts = useMemo(() => {
    let filtered = products || [];
    if (filtered && filtered?.length > 0) {
      filtered = filtered?.filter(
        (prod) => prod?.available == 'yes' || prod?.available === true
      );
      if (selectedCategory !== 'all') {
        filtered = filtered?.filter(
          (prod) => extractId(prod?.category_id) == extractId(selectedCategory)
        );
      }
      filtered = filtered?.filter(
        (prod) => extractId(prod?.country_id) == extractId(country)
      );
      filtered = filtered?.map((x) => {
        const connectedMarket = area?.market;

        let selectedObject = null;
        for (let i = 0; i < x?.priceByMarketId.length; i++) {
          const priceByMarket = x?.priceByMarketId[i];

          if (
            extractId(priceByMarket?.market_id) === extractId(connectedMarket)
          ) {
            selectedObject = priceByMarket;
            break;
          }
        }
        if (!selectedObject) {
          selectedObject = x?.priceByMarketId?.find(
            (priceByMarket) =>
              priceByMarket?.market_id?.name?.toLowerCase() === 'default'
          );
        }
        if (selectedObject) {
          return { ...x, priceByMarket: selectedObject };
        }
      });

      filtered = removeUndefinedOrNull(filtered);

      dispatch(
        saveToUserStore({
          value: removeDuplicates(
            removeUndefinedOrNull(
              extractValueFromArray(
                removeUndefinedOrNull(
                  extractValueFromArray(filtered, 'uom_id')
                ),
                'name'
              )
            )
          ),
          key: 'uomsFilter',
        })
      );
      dispatch(
        saveToUserStore({
          value: removeDuplicates(
            removeUndefinedOrNull(extractValueFromArray(filtered, 'brand')),
            true,
            true
          ),
          key: 'brandsFilter',
        })
      );
      dispatch(
        saveToUserStore({
          value: Math.max(
            ...[
              ...removeDuplicates(
                removeUndefinedOrNull(extractValueFromArray(filtered, 'price'))
              ).map((x) => {
                return parseInt(x);
              }),
            ].sort((a, b) => a - b)
          ),
          key: 'priceToFilter',
        })
      );
      dispatch(
        saveToUserStore({
          value: Math.min(
            ...[
              ...removeDuplicates(
                removeUndefinedOrNull(extractValueFromArray(filtered, 'price'))
              ).map((x) => {
                return parseInt(x);
              }),
            ].sort((a, b) => a - b)
          ),
          key: 'priceFromFilter',
        })
      );
      if (filterByPriceFrom && filtered?.length > 0) {
        filtered = filtered?.filter(
          (x) => x?.priceByMarket?.price >= filterByPriceFrom
        );
      }
      if (filterByPriceTo && filtered?.length > 0) {
        filtered = filtered?.filter(
          (x) => x?.priceByMarket?.price <= filterByPriceTo
        );
      }
      if (isArrayNonEmpty(filterByBrand) && filtered?.length > 0) {
        filtered = filtered?.filter((x) => filterByBrand?.includes(x?.brand));
      }
      if (isArrayNonEmpty(filterByUom) && filtered?.length > 0) {
        filtered = filtered?.filter((x) =>
          filterByUom?.includes(x?.priceByMarket?.uom_id?.name)
        );
      }
      if (sortFilter == 'Lowest Price') {
        filtered = [...filtered]?.sort(
          (a, b) => a?.priceByMarket?.price - b?.priceByMarket?.price
        );
      }
      if (sortFilter == 'Highest Price') {
        filtered = [...filtered]?.sort(
          (a, b) => b?.priceByMarket?.price - a?.priceByMarket?.price
        );
      }
      if (searchValue !== '' && searchValue?.length > 0) {
        filtered = filtered?.filter((x) =>
          x?.name?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    }
    return filtered;
  }, [
    products,
    searchValue,
    area,
    sortFilter,
    filterByPriceFrom,
    filterByPriceTo,
    filterByBrand,
    filterByUom,
    categories,
    isLoadingProducts,
    isLoadingUnrestrictedProducts,
    selectedCategory,
    country,
    token,
  ]);

  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 8;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(filteredProducts.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    changePage({ selected: 0 });
  }, []);

  const displayItems = filteredProducts
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((item) => {
      return (
        <Col
          lg={3}
          md={4}
          sm={6}
          xs={isMobileS ? 12 : 6}
          className="d-flex justify-content-center"
        >
          <Product
            type="all"
            item={item}
            loadingProducts={isLoadingProducts || isLoadingUnrestrictedProducts}
            url={`/product/${extractId(item)}`}
          />
        </Col>
      );
    });

  const EmptyComponent = () => {
    const emptyProps = {
      title:
        isLoadingProducts || isLoadingUnrestrictedProducts
          ? 'Loading products'
          : 'No product found',
      subtitle:
        isLoadingProducts || isLoadingUnrestrictedProducts
          ? ''
          : 'There seems to no product in this category. Check back later',
      showBtn: false,
    };
    return <EmptyState {...emptyProps} />;
  };

  return (
    <Container>
      <Card style={{ paddingTop: 20, paddingBottom: 20, marginTop: 10 }}>
        <Container fluid>
          <Row
            style={{
              paddingRight: 24,
              paddingLeft: 24,
              paddingBottom: 24,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col className="p-0">
              <CustomText
                fontFamily={'Inter'}
                fontSize={18}
                fontWeight={600}
                text={'All products'}
                // cNColor="gray"
              />
            </Col>

            <Col
              lg="auto"
              md={12}
              sm={12}
              style={{ marginTop: isMobile || isTablet ? 10 : 0 }}
              className={`${'rowcentered'} p-0`}
            >
              <Dropdown align="end">
                <Dropdown.Toggle
                  as={CustomToggleLeft}
                  id="dropdown-custom-components"
                >
                  {
                    [
                      { name: 'All categories', id: 'all' },
                      ...categories,
                    ]?.find((x) => x?.id == selectedCategory)?.name
                  }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {[{ name: 'All categories', id: 'all' }, ...categories]?.map(
                    (x) => (
                      <Dropdown.Item
                        eventKey={x.id}
                        onClick={() => setSelectedCategory(x?.id)}
                      >
                        {x?.name}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown align="end">
                <Dropdown.Toggle
                  as={CustomToggleRight}
                  id="dropdown-custom-components"
                >
                  {sortFilter == 'Lowest Price'
                    ? 'Lowest to Highest Price'
                    : sortFilter == 'Highest Price'
                    ? 'Highest to Lowest Price'
                    : 'Sort by'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {[
                    // {
                    //   id: 'Popularity',
                    //   name: 'Popularity',
                    // },
                    {
                      id: 'Highest Price',
                      name: 'Highest to Lowest Price',
                    },
                    {
                      id: 'Lowest Price',
                      name: 'Lowest to Highest Price',
                    },
                  ]?.map((x) => (
                    <Dropdown.Item
                      eventKey={x?.id}
                      onClick={() => setSortFilter(x?.id)}
                    >
                      {x?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
        <Row className="m-0 p-0 d-flex align-items-top">
          {displayItems.length > 0 ? (
            displayItems
          ) : (
            <Col className="centered">
              <EmptyComponent />
            </Col>
          )}
          <ReactPaginate
            previousLabel={<ChevronLeft size="14px" />}
            nextLabel={<ChevronRight size="14px" />}
            pageCount={pageCount}
            onPageChange={changePage}
            marginPagesDisplayed={isMobile ? 1 : 3}
            pageRangeDisplayed={isMobile ? 1 : 2}
            containerClassName={'justify-content-center mb-0 pagination'}
            previousLinkClassName={'page-link mx-1 rounded'}
            nextLinkClassName={'page-link mx-1 rounded'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link mx-1 rounded'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'active text-white'}
            renderOnZeroPageCount={null}
          />
        </Row>
      </Card>
    </Container>
  );
};

export default AllProducts;
