import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as BankSvg } from 'assets/svgs/bank.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { CustomText } from 'components/CustomText';
import { useMemo } from 'react';
import { useCallback } from 'react';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  removeDuplicates,
  sortByProperty,
} from 'helper/formatArray';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { saveArea } from 'redux/features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  useGetAllAreasMutation,
  useGetAreasMutation,
} from 'redux/features/user/userApi';
import { show } from 'helper/toast';
import { errorMessageFormat } from 'helper/formatText';
import { ReactComponent as CheckSvg } from 'assets/svgs/checked.svg';
import { ReactComponent as UncheckSvg } from 'assets/svgs/unchecked.svg';
import { extractId } from 'helper/formatObject';

import { ReactComponent as SearchYellowSvg } from 'assets/svgs/search_yellow.svg';
import { useScreenSize } from 'hooks/useScreenSize';

export const SelectAreaModal = ({ visible, setVisible }) => {
  const { isMobile, isMobileS, isTablet, isLaptop, isDesktop } =
    useScreenSize();
  const [searchValue, setSearchValue] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [initiated, setInitiated] = useState(false);

  const { area, user, country } = useSelector((state) => state.userAuth);
  const { areas } = useSelector((state) => state.userStore);

  const [id, setId] = useState(null);
  const [selected, setSelected] = useState(area?.name || null);
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);

  const [stateId, setStateId] = useState(null);

  const [getAreas, { isLoading: isLoadingAreas }] = useGetAreasMutation();
  const [getAllAreas, { isLoading: isLoadingAllAreas }] =
    useGetAllAreasMutation();

  const fetchAreas = async () => {
    setRefreshing(true);
    const res = await getAllAreas();
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'areas',
          value: res.data?.data?.filter((x) => {
            return !!x?.status;
          }),
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
    setInitiated(true);
    setRefreshing(false);
  };

  useEffect(() => {
    visible && setInitiated(false);
  }, [visible]);

  useEffect(() => {
    visible && fetchAreas();
  }, [visible]);

  const handleRefresh = () => {
    visible &&
      initiated &&
      !isLoadingAllAreas &&
      !isLoadingAreas &&
      fetchAreas();
    visible && setInitiated(true);
  };

  useEffect(() => {
    if (visible) {
      setStates(
        removeDuplicates(
          areas?.map((x) => {
            return x?.state_name;
          })
        )?.map((x) => {
          return { label: x, value: x, id: x };
        })
      );
      setStateId(
        removeDuplicates(
          areas?.map((x) => {
            return x?.state_name;
          })
        )[0]
      );
      if (
        !removeDuplicates(
          areas?.map((x) => {
            return x?.state_name;
          })
        )[0]
      ) {
        setStates(
          ['Lagos State']?.map((x) => {
            return { label: x, value: x, id: x };
          })
        );
        setStateId('Lagos State');
      }
    }
  }, [country, areas, visible, refetch]);

  const closeModal = async () => {
    if (id) {
      dispatch(saveArea(filteredAreas.find((x) => x?.id === id)));
      dispatch(saveToUserStore({ key: 'initialLoadArea', value: '' }));
      setVisible(false);
    }
  };

  const handleSelect = useCallback((id) => {
    setId((prev) => id);
  }, []);

  const filteredAreas = useMemo(() => {
    let filtered = areas || [];
    if (visible) {
      filtered = filtered?.filter((x) => {
        return x?.status;
      });
      if (!!stateId || stateId?.length > 0 || stateId !== '') {
        filtered = filtered?.filter((x) =>
          isSubstringInArray(x?.state_name, [stateId])
        );
      }
      if (searchValue !== '' && searchValue?.length > 0) {
        filtered = filtered?.filter(
          (x) =>
            x?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            x?.lga?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            x?.name
              ?.toLowerCase()
              ?.replace('/', '')
              ?.replace('-', '')
              .includes(searchValue?.toLowerCase()) ||
            x?.lga
              ?.toLowerCase()
              ?.replace('/', '')
              ?.replace('-', '')
              .includes(searchValue?.toLowerCase())
        );
      }
      filtered = sortByProperty(filtered, 'name');
    }
    return filtered;
  }, [searchValue, stateId, areas, states, country, visible]);

  const handleChange = (e) => {
    console.log('e', e);
    setSearchValue(e.target.value || '');
  };

  return (
    <Modal show={visible} centered>
      <Modal.Title className="px-4 py-4">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Select Delivery Area"
          cNColor="primary"
        />
      </Modal.Title>
      <Modal.Body className="px-6 py-1" style={{ minHeight: 180 }}>
        <Col>
          <CustomInput
            type={'text'}
            variant="auth"
            required={false}
            id={'search'}
            label={''}
            placeholder={'Search for your delivery area'}
            onChange={handleChange}
            value={searchValue}
            leftComp={<SearchYellowSvg />}
            inputStyle={{
              height: 40,
              paddingRight: 24,
              borderColor: '#d9d9d9',
              borderRadius: 7,
              paddingLeft: 44,
            }}
          />
        </Col>
        {refreshing ? (
          <div style={{ height: 180, width: '100%' }} className="centered">
            <Spinner variant="primary" />
          </div>
        ) : (
          <div style={{ maxHeight: 300, overflowY: 'auto' }}>
            {!isArrayNonEmpty(filteredAreas) && searchValue && (
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={500}
                text={'No search results'}
                cNColor="primary"
                divClassName="py-2 text-center"
              />
            )}
            {filteredAreas?.map((item) => {
              return (
                <div
                  className="border-bottom py-3 rowcentered"
                  onClick={() => handleSelect(extractId(item))}
                >
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={500}
                    text={`${item?.name} (${item?.lga})`}
                    cNColor="primary"
                  />
                  {item?.id == id ? <CheckSvg /> : <UncheckSvg />}
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="px-4 py-4 justify-content-center p-1">
        <CustomButton
          variant="primary"
          type="submit"
          text="Confirm"
          className={'w-100'}
          onPress={closeModal}
        />
      </Modal.Footer>
    </Modal>
  );
};
