import CustomInput from 'components/CustomInput';
import React, { Fragment, useState } from 'react';
import { Card, Col, Dropdown, Image, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import {
  useGoogleSigninMutation,
  useLoginUserMutation,
  useSignupUserMutation,
} from '../../redux/features/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
import continueWithGoogle from 'assets/pngs/continueWithGoogle.png';
import { ReactComponent as EyeSvg } from 'assets/svgs/eye-close.svg';
import { ReactComponent as EyeOnSvg } from 'assets/svgs/eyeOpen.svg';
import { ReactComponent as EyeOffSvg } from 'assets/svgs/eyeClose.svg';
import * as Yup from 'yup';
import {
  logout,
  saveCountry,
  saveToAuth,
  saveToken,
  saveUser,
  setAuthState,
  setCredentials,
} from '../../redux/features/auth/authSlice';
import CustomButton from 'components/CustomButton';
import { CustomText } from 'components/CustomText';
import GoogleLogo from 'assets/pngs/google-logo.png';
import { setCurrentScreen } from 'redux/features/navigation/navigationSlice';
import { useEffect } from 'react';
import { isSubstringInArray, sortByProperty } from 'helper/formatArray';
import { show } from 'helper/toast';
import { getValue } from 'helper/formatObject';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { saveInitiaLoad } from 'redux/features/user/userSlice';
import { errorMessageFormat } from 'helper/formatText';
const yourhandle = require('countrycitystatejson');

const Register = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { countries, country: selectedCountry } = useSelector(
    (state) => state.userAuth
  );

  const [states, setStates] = useState([]);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const [stateId, setStateId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const phoneRegExp = /^[7-9][0-1][0-9]{8}$/im;
  const canadaPhoneRegExp =
    /^(1[\s-]?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    state: Yup.mixed().required().label('State'),
    country: Yup.string().required().label('Country'),
    email: Yup.string().email().required().label('Email address'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .when('country', {
        is: (value) => value === 'Nigeria',
        then: () =>
          Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Phone number is required')
            .label('Phone number'),
        otherwise: (schema) => schema,
      })
      .when('country', {
        is: (value) => value === 'Canada',
        then: () =>
          Yup.string()
            .matches(canadaPhoneRegExp, 'Phone number is not valid')
            .required('Phone number is required')
            .label('Phone number'),
      }),

    password: Yup.string()
      .required('Password is required')
      .min(6)
      .label('Password'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
      .label('Password'),
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      passwordConfirm: '',
      country: '',
      state: '',
    },
    validateOnMount: false,
    validateOnChange,
    validationSchema: validationSchema,
    onSubmit: (values) => handleSignUp(values),
  });

  useEffect(() => {
    dispatch(setCurrentScreen('SignUp'));
    if (selectedCountry?.country_short_code) {
      const updatedStates = sortByProperty(
        yourhandle
          ?.getStatesByShort(selectedCountry?.country_short_code)
          ?.map((x) => {
            return {
              label: x,
              value: x,
              id: x,
            };
          }),
        'label'
      );
      setStates(updatedStates);

      if (values?.state && selectedCountry?.name !== values?.country) {
        setFieldValue('state', '');
      }
      setFieldValue('country', selectedCountry?.name);
    }
  }, [
    selectedCountry?.country_short_code,
    dispatch,
    countries,
    selectedCountry,
  ]);

  const [signupUser, { isLoading }] = useSignupUserMutation();

  const handleSignUp = async (values) => {
    const resolveValues = {
      ...values,
      phoneNumber: isSubstringInArray(values.phoneNumber.charAt(0), ['+', '('])
        ? `+${selectedCountry?.calling_code}` + values.phoneNumber
        : values.phoneNumber,
      state: getValue(values.state),
    };
    const res = await signupUser(resolveValues);
    if (res?.data?.status === 'success') {
      show(dispatch, res?.data?.message, {
        type: 'success',
        placement: 'top',
        duration: 4000,
      });
      dispatch(saveUser(resolveValues));
      history.push('/verify-account', {
        from: 'signup',
        formType: 'email',
      });
    } else {
      show(
        dispatch,
        res?.error?.data?.message ||
          (typeof res?.error?.error == 'string' &&
            res?.error?.error?.replace('TypeError: ', '')) ||
          'Signup failed',
        {
          type: 'error',
          placement: 'top',
          duration: 4000,
        }
      );
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [passwordShown1, setPasswordShown1] = useState(false);
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  useEffect(() => {
    dispatch(logout());
    googleLogout();
  }, []);
  const [googleSignin, { isLoading: isLoadingGoogleSignin }] =
    useGoogleSigninMutation();

  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [googleSigninCheck, setGoogleSigninCheck] = useState(false);

  const [loadingGoogle, setLoadingGoogle] = useState(false);

  useEffect(() => {
    setLoadingGoogle(false);
    setValidateOnChange(false);
    setGoogleSigninCheck(false);
    setModalVisible(false);
    // setRememberMe(false);
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      setGoogleSigninCheck(true);
      setToken(response.access_token);
      getUserInfo(response.access_token);
    },
    onError: () => setLoadingGoogle(false),
  });

  const getUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        'https://www.googleapis.com/userinfo/v2/me',
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const user = await response.json();
      setUserInfo(user);
      const res = await googleSignin({
        ...user,
        country: selectedCountry?.name,
      });
      if (res?.data?.status === 'success') {
        dispatch(saveToken(res?.data?.token));
        dispatch(saveToAuth(['isLoggedIn', res?.data?.token]));
        dispatch(saveInitiaLoad());
        dispatch(saveUser({ ...res?.data?.user, ...res?.data?.publicApi }));
        setGoogleSigninCheck(false);
        setLoadingGoogle(false);
        history.push('/');
      } else {
        setGoogleSigninCheck(false);
        setLoadingGoogle(false);
        show(dispatch, errorMessageFormat(res), {
          type: 'error',
          placement: 'top',
          duration: 4000,
        });
      }
    } catch (error) {
      // Add your own error handler here
      setGoogleSigninCheck(false);
      setLoadingGoogle(false);
    }
  };
  return (
    <Fragment>
      <Row className="py-1 align-items-center justify-content-center g-0">
        <Col
          lg={9}
          md={12}
          sm={12}
          className="d-block justify-content-center align-items-center bg-white p-4"
        >
          <Row>
            <Col lg={6} md={6} sm={12} style={{ marginBottom: '10px' }}>
              <CustomInput
                type={'text'}
                variant="auth"
                required={true}
                id={'firstName'}
                label={'First Name'}
                placeholder={'First Name'}
                onChange={handleChange}
                value={values?.firstName}
                error={errors?.firstName}
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft: 24,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} style={{ marginBottom: '10px' }}>
              <CustomInput
                type={'text'}
                variant="auth"
                required={true}
                id={'lastName'}
                label={'Last Name'}
                placeholder={'Last Name'}
                onChange={handleChange}
                value={values?.lastName}
                error={errors?.lastName}
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft: 24,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} style={{ marginBottom: '10px' }}>
              {/* Username or email */}
              <CustomInput
                type={'email'}
                variant="auth"
                required={true}
                id={'email'}
                label={'Email address'}
                placeholder={'Enter your email address'}
                onChange={handleChange}
                // leftIcon={<EmailSvg />}
                value={values?.email}
                error={errors?.email}
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft: 24,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} style={{ marginBottom: '10px' }}>
              <CustomInput
                type={'text'}
                variant="auth"
                required={true}
                id={'phoneNumber'}
                label={'Phone Number'}
                placeholder={'Phone Number'}
                onChange={handleChange}
                value={values?.phoneNumber}
                error={errors?.phoneNumber}
                leftCompClassName="position-absolute ps-2 start-0 align-items-center"
                leftComp={
                  <Dropdown className="m-0 p-0 bg-white">
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="countryDrops"
                      className="m-0 bg-white border-0"
                    >
                      <div className="d-flex align-items-center">
                        {selectedCountry?.flags?.png ? (
                          <Image
                            style={{
                              width: 20,
                              height: 10,
                              backgroundColor: 'red',
                            }}
                            src={selectedCountry?.flags?.png}
                          />
                        ) : selectedCountry?.flags?.svg ? (
                          <Image
                            style={{
                              width: 20,
                              height: 10,
                              backgroundColor: 'red',
                            }}
                            src={selectedCountry?.flags?.svg}
                          />
                        ) : (
                          <></>
                        )}
                        <CustomText
                          fontFamily="Poppins"
                          text={selectedCountry?.calling_code}
                          fontSize={14}
                          divStyle={{ marginLeft: 5 }}
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {countries?.map((x) => (
                        <Dropdown.Item
                          eventKey={x?.id}
                          onClick={() => {
                            dispatch(saveCountry(x));
                          }}
                          className="d-flex align-items-center"
                        >
                          {x?.flags?.png ? (
                            <Image
                              style={{
                                width: 20,
                                height: 10,
                                marginRight: 10,
                              }}
                              src={x?.flags?.png}
                            />
                          ) : x?.flags?.svg ? (
                            <Image
                              style={{
                                width: 20,
                                height: 10,
                                marginRight: 10,
                              }}
                              src={x?.flags?.svg}
                            />
                          ) : (
                            <></>
                          )}
                          {x?.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft:
                    selectedCountry?.calling_code?.toString()?.length > 2
                      ? 64
                      : 44,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <CustomInput
                type={passwordShown ? 'text' : 'password'}
                variant="auth"
                required={true}
                id={'password'}
                label={'Password'}
                value={values?.password}
                error={errors?.password}
                placeholder={'Enter password'}
                onChange={handleChange}
                rightAction={togglePassword}
                rightComp={
                  passwordShown ? (
                    <EyeSvg
                      style={{ width: 40, zIndex: 100 }}
                      onClick={togglePassword}
                    />
                  ) : (
                    <EyeOnSvg
                      style={{ width: 40, zIndex: 100 }}
                      onClick={togglePassword}
                    />
                  )
                }
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft: 24,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <CustomInput
                type={passwordShown1 ? 'text' : 'password'}
                variant="auth"
                required={true}
                id={'passwordConfirm'}
                label={'Re-enter password'}
                placeholder={'Re-enter password'}
                value={values?.passwordConfirm}
                error={errors?.passwordConfirm}
                onChange={handleChange}
                rightAction={togglePassword1}
                rightComp={
                  passwordShown1 ? (
                    <EyeSvg
                      style={{ width: 40, zIndex: 100 }}
                      onClick={togglePassword1}
                    />
                  ) : (
                    <EyeOnSvg
                      style={{ width: 40, zIndex: 100 }}
                      onClick={togglePassword1}
                    />
                  )
                }
                inputStyle={{
                  height: 42,
                  // marginRight: 24,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                  paddingLeft: 24,
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={12} style={{ marginBottom: '10px' }}>
              <CustomInput
                inputType="select"
                // placeholder={'State'}
                value={values?.state}
                error={errors?.state}
                label={'State/Province'}
                placeholder={'Select state/province'}
                selectOptions={states}
                onChange={(e) => setFieldValue('state', e)}
                inputStyle={{
                  height: 42,
                  paddingLeft: 14,
                  borderColor: '#d9d9d9',
                  borderRadius: 7,
                }}
                returnSelectObject={true}
              />
            </Col>
          </Row>
          <Col
            style={{ paddingTop: '15px' }}
            className="d-flex justify-content-center align-items-center mb-3 text-end"
          >
            <CustomButton
              variant="primary"
              type="submit"
              fontWeight={'500'}
              className="w-100"
              text={'Register'}
              disabled={!isValid}
              loading={isLoading}
              style={{ height: 45, marginBottom: 0 }}
              onClick={(e) => {
                e.preventDefault();
                setValidateOnChange(true);
                handleSubmit();
              }}
            />
          </Col>
          <Col className="pt-2">
            <div className="line"></div>
          </Col>
          <Col lg={12} md={12} className="login-seperator">
            <CustomText
              fontFamily={'Poppins'}
              fontSize={16}
              fontWeight={400}
              text={'or'}
              divClassName="or-seperator"
              // cNColor="gray"
            />
          </Col>
          <Col className="d-flex justify-content-center">
            <CustomButton
              variant="primary"
              fontWeight={'500'}
              className="w-100 bg-white border-1"
              height={'45px'}
              disabled={isLoading}
              loading={isLoadingGoogleSignin}
              spinnerVariant={'primary'}
              style={{ marginBottom: 0 }}
              onPress={googleLogin}
            >
              <Image src={GoogleLogo} alt="" />
              <CustomText
                fontFamily={'Poppins'}
                fontSize={'14px'}
                fontWeight={500}
                text={'Sign up with Google'}
                ml="10px"
              />
            </CustomButton>
          </Col>
        </Col>
      </Row>
    </Fragment>
  );
};

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default Register;
