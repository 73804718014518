import { Card, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import { useScreenSize } from 'hooks/useScreenSize';
import { useHistory } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { isDesktop, isLaptop } = useScreenSize();
  const history = useHistory();

  return (
    <Container>
      <Row className="mb-3 mt-3 mt-lg-0 mt-md-0 bg-white p-3">
        <Col xs={12} className="d-flex align-items-center py-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={'Privacy Policy'}
            // cNColor="gray"
          />
        </Col>
        <Col xs={12}>
          <FormattedParagraph
            type={'heading'}
            text={'Introduction'}
            noLine
            newLine={false}
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy Technologies (“Fooddy” or “We” “our” “us”) have created this Privacy Policy to explain how we collect, use, disclose and protect your Personal Data (in this Privacy Policy also including personal information or personal identifiable information), including any other information.\n\n You accept this Privacy Policy and hereby give Fooddy consent to save, process and use your Personal Data to the extent as allowed by law when you provide us with details of your Personal Data or by clicking on the “accept ” button. You have the right to withdraw your consent at any time and we will accordingly discontinue the processing of your Personal Data, provided that we do not have another legal basis to keep processing your Personal Data.\n\n Fooddy is concerned about online privacy issues and wants you to be familiar with how we collect, use and disclose personal information that individually identifies you (e.g., your name, address, phone number, e-mail address). As used in this Privacy Policy personal information means information about an identifiable individual i.e you, our customer(s). Therefore, we will only collect, use and disclose your personal information per this Privacy Policy. Fooddy is committed to protecting the privacy of our site visitors and customers.\n\n We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide you with additional notice (such as by adding a statement to the homepage of our website/application or by sending you an email notification).\n\n We encourage you to review the Privacy Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'What this Privacy Policy Covers'}
            noLine
          />
          <FormattedParagraph
            type={'bullet'}
            text={
              'How we collect information from you\nHow we protect customers’ information'
            }
          />
          <FormattedParagraph
            type={'bullet'}
            text={
              'How we use the information and share all personal information which you provide when you use our websites'
            }
          />
          <FormattedParagraph
            type={'bullet'}
            text={
              'General information in line with extant laws and regulations.'
            }
          />
          <FormattedParagraph
            type={'bullet'}
            text={
              'Your rights as a Data Subject under applicable data protection laws.'
            }
          />
          <FormattedParagraph
            type={'bullet'}
            text={
              'This policy does not apply to the practices of third-party agents or people of which we do not exercise direct control, employment, or management.'
            }
          />
          <FormattedParagraph
            type={'heading'}
            text={'COLLECTION OF PERSONAL IDENTIFIABLE INFORMATION'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`We collect personally identifiable information (email address, name, phone number, etc.) from you when you SIGN UP and set up a free account either on our website at Fooddy.ng or on our software application. While you can browse some sections of our site without being a registered member, certain activities (such as placing an order) require registration.\nWe use your contact information to send you offers based on your previous orders and your interests.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'USE OF DEMOGRAPHIC & PROFILE DATA'}
            noLine
          />
          <FormattedParagraph
            type={'bullet'}
            text={`We use your Personal Data for the following`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To provide you with our services`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To resolve disputes or any conflicts arising from your use of our products and services`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To troubleshoot problems and help promote safe services`}
          />
          <FormattedParagraph type={'bullet'} text={`To collect fees owed`} />
          <FormattedParagraph
            type={'bullet'}
            text={`To measure consumer interest in our products and services`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To inform you about online and offline offers, products, services, and updates.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To customize your experience`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To detect and protect us against error, fraud, and other criminal activity.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`To enforce our terms and conditions; and as otherwise described to you at the time of collection.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`In our efforts to continually improve our product and service offerings, we collect and analyze demographic and profile data about our users' activity on our website.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`We identify and use your IP address to help diagnose our server problems and administer our website. In addition, your IP address is used to help identify you and to gather broad demographic information.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact and demographic information (like location, local government area, age, or income level). We use this data to tailor your experience on our site/platform and provide you with content that we think you might be interested in.`}
          />
          <FormattedParagraph type={'heading'} text={'COOKIES'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`A "cookie" is a small piece of information stored by a Web server on a Web browser to be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. Fooddy.ng places both permanent and temporary cookies in your computer's hard drive. Our cookies do not contain any of your personally identifiable information but is only geared at customizing your user experience.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'HOW LONG WE KEEP YOUR PERSONAL DATA'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`We will hold your Personal Data on our systems for as long as it is necessary to fulfil the purpose for which it was collected or to comply with legal, regulatory or internal policy requirements.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'WHERE WE STORE YOUR PERSONAL DATA'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The Personal Data we collect from you may be transferred to and stored at a destination outside Nigeria. By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely with an appropriate level of protection and that the transfer is lawful.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'YOUR RIGHTS AS A DATA SUBJECT'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The law gives you certain rights in respect to your Personal Data that we hold about you. Below is a highlight of some of those rights. At any point while we are in possession of or processing your Personal Data, you, the Data Subject has the following rights:`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT OF ACCESS – You have the right to request a copy of the information that we hold about you . We will respond to your access request within one-month of receiving your request.Where we are not able to provide this personal data to you within the One-Month timeline provided by law, we will communicate same to you, and may request an additional time within which we will provide the information to you. Your Personal Data shall be provided to you in a structured, commonly used and machine-readable format.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT TO RECTIFY – You have the right to correct the Personal Data we hold about you that is inaccurate at any point in time.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT TO BE FORGOTTEN – In certain circumstances you may ask for the data we hold about you to be erased from our records and where we do not have another legal basis for continuing the processing of your personal data, we will erase same from our records.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT TO RESTRICT PROCESSING – Where certain conditions apply, you have a right to restrict processing of your Personal Data.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT TO PORTABILITY – You have the right to have your Personal Data transferred to another organisation and we will do same upon receipt of your written instruction.`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`LODGE COMPLAINT – You have a right to lodge a complaint about the handling of your Personal Data with the Nigeria Data Protection Bureau (NDPB) at info@ndpb.gov.ng`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`RIGHT TO OBJECT – You have the right to object to the processing of your Personal Data. If you want to remove your contact information from all Fooddy.ng lists and newsletters, please visit https://www.Fooddy.ng/unsubscribe. In the future, you can unsubscribe by clicking the one-click unsubscribe button located at the footer of every email NDPB’s website (https://ndpb.gov.ng/) has a wealth of useful information in respect of your rights over your Personal Data. If you wish to exercise your rights, you may contact our Data Protection Officer at gbenga@fooddy.ng or you may write to us at [insert postal address]`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'SHARING OF PERSONAL DATA'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`We may share personal information with other corporate entities and affiliates to help detect and prevent identity theft, fraud, and other potentially illegal acts, correlate related or multiple accounts to avoid the abuse of our services, and facilitate joint or co-branded services that you request where such services are provided by more than one corporate entity. However, those entities and affiliates may not market to you due to sharing unless you explicitly opt-in.\n\nWe may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal processes. In addition, we may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting, or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.\n\nFooddy.ng and its affiliates will share some or all of your personal information with another business entity should we (or our assets) plan to merge with or be acquired by that business entity. Accordingly, should such a transaction occur, the other business entity (or the new combined entity) will be required to follow this privacy policy concerning the management of your personal information.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'LINKS TO OTHER SITES'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Our site links to other websites that may collect personally identifiable information about you. Fooddy.ng is not responsible for the privacy practices or the content of those linked websites. We accordingly advise that you read the Privacy Policy as applicable on such site links, prior to providing your information to such other sites.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'SECURITY PRECAUTIONS'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Our site has stringent security measures to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secured server. Furthermore, once your information is in our possession, we adhere to strict security guidelines, protecting it against unauthorized access.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'BREACH/ PRIVACY VIOLATION'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data, we shall within 72 (Seventy-Two) hours of having knowledge of such breach report the details of the breach to NDPB, in addition to such internal breach remediation process as we may activate to mitigate the breach.\n\nFurthermore, where we ascertain that such breach is detrimental to your rights and freedoms in relation to your Personal Data, we shall within 7 (Seven) days of having knowledge of the occurrence of such breach take steps to inform you of the breach incident, the risk to your rights and freedoms resulting from such breach and any course of action to remedy said breach.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Advertisements on Fooddy.ng'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`We use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites to provide advertisements about goods and services of interest to you.`}
          />
          <FormattedParagraph type={'heading'} text={'Questions'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Questions regarding this statement should be directed to the following address: sales@fooddy.ng`}
          />
        </Col>
      </Row>
    </Container>
  );
};

export const FormattedParagraph = ({ text, type, noLine, newLine = true }) => {
  return type == 'body' ? (
    <Row
      style={{
        marginBottom: noLine ? 0 : 10,
      }}
    >
      <CustomText fontFamily="Poppins" fontSize={12} cNColor="text-secondary">
        {text
          // .replace(/[\r\n]/gm, ' ')
          // .replace(/\s+/g, ' ')
          .trim()}
      </CustomText>
    </Row>
  ) : type == 'heading' ? (
    <Row
      style={{
        marginTop: noLine ? 0 : 10,
        marginBottom: 2,
      }}
    >
      <CustomText
        fontFamily="Poppins"
        fontSize={14}
        style={{ textDecorationLine: noLine ? 'none' : 'underline' }}
      >
        {newLine
          ? `\n` +
            text
              .replace(/[\r\n]/gm, ' ')
              .replace(/\s+/g, ' ')
              .trim()
          : text
              .replace(/[\r\n]/gm, ' ')
              .replace(/\s+/g, ' ')
              .trim()}
      </CustomText>
    </Row>
  ) : (
    type == 'bullet' && (
      <Row
        style={{
          marginTop: 5,
          paddingRight: 15,
        }}
        className="d-flex"
      >
        <Col xs="auto">
          <div
            style={{
              width: 5,
              height: 5,
              borderRadius: 2.5,
              backgroundColor: 'black',
              marginRight: 15,
              marginTop: 5,
            }}
          ></div>
        </Col>
        <Col>
          <CustomText
            fontFamily="Poppins"
            fontSize={12}
            cNColor="text-secondary"
          >
            {text
              // .replace(/[\r\n]/gm, ' ')
              // .replace(/\s+/g, ' ')
              .trim()}
          </CustomText>
        </Col>
      </Row>
    )
  );
};

export default PrivacyPolicy;
