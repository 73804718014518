// import node module libraries
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Tab,
  Nav,
  Spinner,
} from 'react-bootstrap';
import {Trash, Send, Inbox, MoreVertical} from 'react-feather';

// import custom components
import GlobalFilter from 'components/advance-table/GlobalFilter';
import Pagination from 'components/advance-table/Pagination';

// import sub components
// import data files

import {useDispatch} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {simulateFetch} from 'components/simulateFetch';

const LocalUsers = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const fetchApplications = async () => {
    console.log('====================================');
    console.log('fetching');
    console.log('====================================');
  };

  useEffect(() => {
    fetchApplications();
  }, [refresh]);

  const [simulatedData, setSimulatedData] = useState(null);

  useEffect(() => {
    simulateFetch('Some data', 5000)
      .then(response => setSimulatedData(response))
      .catch(error => console.log(error));
  }, []);

  const handleViewTrigger = details => {
    console.log(details?.original?.id);
  };
  const handleRejectTrigger = () => {};
  const handleReapplyTrigger = () => {};

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <Link
      to=''
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </Link>
  ));

  const ActionMenu = ({details}) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size='15px' className='text-secondary' />
        </Dropdown.Toggle>
        <Dropdown.Menu align='end'>
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item
            eventKey='1'
            onClick={() => handleViewTrigger(details)}>
            {' '}
            <Send size='18px' className='dropdown-item-icon' /> View
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      {accessor: 'sn', Header: 'S/N'},
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'firstname',
        Header: 'FIRST NAME',
        Cell: ({value, row}) => {
          // console.log(row?.original?.id);
          return (
            <Link
              className='text-inherit'
              to={`/admin/application/${row?.original?.id}`}>
              <h5 className='text-primary-hover'>{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'lastname',
        Header: 'Last Name',
        Cell: ({value, row}) => {
          return (
            <Link
              className='text-inherit'
              to={`/admin/application/${row?.original?.id}`}>
              <h5 className='text-primary-hover'>{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'accountType',
        Header: 'Account Type',
      },
      {
        accessor: 'phoneNumber',
        Header: 'Phone Number',
        Cell: ({value, row}) => {
          return value.toUpperCase();
        },
      },

      {
        accessor: 'marketArea',
        Header: 'Market Area',
      },

      {
        accessor: 'created_at',
        Header: 'Date Created',
        Cell: ({value}) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = Array(20).fill(null) || usersList;
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        firstname: 'Abraham',
        lastname: 'John',
        accountType: 'Customer' || 'Shopper',
        phoneNumber: '09012345678',
        marketArea: 'Oshodi' || 'Mile 12',
        createdAt: new Date().toDateString(),
      };
    });

    return filtered;
  }, [usersList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
  );

  const {pageIndex, globalFilter} = state;

  if (!simulatedData) {
    return (
      <Row className='d-flex justify-content-center align-items-center min-vh-100'>
        <Spinner style={{color: '#e0bf00'}} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className='border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between'>
            <div className='mb-3 mb-md-0'>
              <h1 className='mb-1 h2 fw-bold'>Application List</h1>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey='all'>
            <Card>
              <Card.Body className='p-0'>
                <div
                  className='table-responsive'
                  // style={{position: 'relative', zIndex: 9999}}
                >
                  <div className=' overflow-hidden'>
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        className='mb-lg-0 mb-2 px-5 py-4'>
                        <GlobalFilter
                          filter={globalFilter}
                          setFilter={setGlobalFilter}
                          placeholder='Search List'
                        />
                      </Col>
                    </Row>
                  </div>
                  <Table
                    {...getTableProps()}
                    className='text-nowrap'
                    style={{position: 'relative', zIndex: 9999}}>
                    <thead className='table-light'>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {/* Pagination @ Footer */}
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                </div>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default LocalUsers;
