import { CustomText } from 'components/CustomText';
import { Fragment } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { ReactComponent as OrderPlacedSvg } from 'assets/svgs/placed.svg';
import CustomButton from 'components/CustomButton';
import { Link, useHistory } from 'react-router-dom';
export const SuccessModal = ({ visible, setVisible, details, text }) => {
  const history = useHistory();
  return (
    <Modal centered show={visible}>
      <Modal.Body style={{ borderRadius: 24 }}>
        <Col className={`text-center p-2 p-md-4 p-lg-4 pt-2`}>
          <Col>
            <OrderPlacedSvg />
          </Col>
          <Col style={{ paddingTop: '17px' }}>
            <CustomText
              fontFamily={'Poppins'}
              fontSize={13}
              fontWeight={400}
              text={text}
              // cNColor="gray"
            />
          </Col>
          <Col style={{ paddingTop: '25px', paddingBottom: '10px' }}>
            <CustomButton
              text="View Order details"
              fontFamily={'Poppins'}
              className={'w-100'}
              variant="primary"
              onClick={() =>
                history.push('/orders/' + (details?.id || details?.order?.id), {
                  item: details,
                })
              }
            />
          </Col>
          <Col>
            <CustomButton
              fontFamily={'Poppins'}
              text="Continue Shopping"
              onClick={() => {
                setVisible(false);
                history.push('/');
              }}
              buttonTextColor="text-primary"
              className={'w-100'}
              style={{
                backgroundColor: '#ffffff',
                borderRadius: '5px',
              }}
            />
          </Col>
        </Col>
      </Modal.Body>
    </Modal>
  );
};
