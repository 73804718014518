import { Fragment } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { ReactComponent as FAQSvg } from 'assets/svgs/faqsvg.svg';
import { ReactComponent as TrackOrdersSvg } from 'assets/svgs/tracksvg.svg';
import { ReactComponent as ProfileOrdersSvg } from 'assets/svgs/profileorders.svg';
import { CustomText } from 'components/CustomText';
import phonePng from 'assets/pngs/phone.png';
import emailPng from 'assets/pngs/email.png';
import facebookPng from 'assets/pngs/facebook.png';
import whatsappPng from 'assets/pngs/whatsapp.png';
import instagramPng from 'assets/pngs/instagram.png';
import twitterPng from 'assets/pngs/x.png';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { extractId } from 'helper/formatObject';
import { isSubstringInArray } from 'helper/formatArray';

const Help = () => {
  const { userSupport } = useSelector((state) => state.userStore);

  const options = [
    {
      label: 'Phone',
      image: phonePng,
      value: `tel:08123443333`,
    },
    {
      label: 'WhatsApp',
      image: whatsappPng,
      value: `https://api.whatsapp.com/send?phone=09024547592`,
    },
    {
      label: 'Twitter',
      image: twitterPng,
      value: 'https://twitter.com',
    },
    {
      label: 'Facebook',
      image: facebookPng,
      value: 'https://www.facebook.com',
    },
  ];

  const icons = [
    { type: 'comm', icon: phonePng },
    { type: 'whats', icon: whatsappPng },
    { type: 'email', icon: emailPng },
    { type: 'twitter', icon: twitterPng },
    { type: 'facebook', icon: facebookPng },
    { type: 'phone', icon: phonePng },
    { type: 'telephone', icon: phonePng },
    { type: 'instagram', icon: instagramPng },
  ];

  const filteredOptions = useMemo(() => {
    let filtered = userSupport || [];
    filtered = filtered?.map((x) => {
      return {
        id: extractId(x),
        ...x,
        icon: (
          icons?.find((y) => isSubstringInArray(y?.type, [x?.type])) || icons[0]
        )?.icon,
      };
    });
    return filtered;
  }, [userSupport]);

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Fragment>
      <Row className="py-5">
        {filteredOptions.map((x) => (
          <Col
            lg={3}
            md={4}
            sm={6}
            xs={6}
            className="text-center"
            style={{ height: 160 }}
            onClick={() => handleClick(x?.link)}
          >
            <Image src={x.icon} style={{ width: 60 }} />
            <CustomText
              fontFamily={'Poppins'}
              fontSize={13}
              divClassName="py-3"
              fontWeight={500}
              text={x.name}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default Help;
