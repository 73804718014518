import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { ReactComponent as ExitSvg } from 'assets/svgs/exit.svg';
import { ReactComponent as LockSvg } from 'assets/svgs/lock.svg';
import { ReactComponent as PersonSvg } from 'assets/svgs/person.svg';
import { ReactComponent as CouponSvg } from 'assets/svgs/coupon.svg';
import { ReactComponent as NotificationSvg } from 'assets/svgs/notification.svg';
import { ReactComponent as LikeSvg } from 'assets/svgs/like.svg';
import { ReactComponent as HelpSvg } from 'assets/svgs/help-circle.svg';
import { ReactComponent as ExitASvg } from 'assets/svgs/exitA.svg';
import { ReactComponent as LockASvg } from 'assets/svgs/lockA.svg';
import { ReactComponent as PersonASvg } from 'assets/svgs/personA.svg';
import { ReactComponent as CouponASvg } from 'assets/svgs/couponA.svg';
import { ReactComponent as NotificationASvg } from 'assets/svgs/notificationA.svg';
import { ReactComponent as LikeASvg } from 'assets/svgs/likeA.svg';
import { ReactComponent as HelpASvg } from 'assets/svgs/help-circleA.svg';
import ProfileDetails from './components/ProfileDetails';
import { CustomText } from 'components/CustomText';
import ChangePassword from './components/ChangePassword';
import DeliveryAdddress from './components/DeliveryAddress';
import { Card, Container, Dropdown, ListGroup, Spinner } from 'react-bootstrap';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import CustomButton from 'components/CustomButton';
import ProfileSections from 'pages/more/components/ProfileSections';
import Help from 'pages/more/components/Help';
import Vouchers from 'pages/more/components/Vouchers';
import { scrollToSection } from 'helper/utils';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  sortByZeroBalanceLast,
  sortedDataByCreatedAt,
} from 'helper/formatArray';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { show } from 'helper/toast';
import { errorMessageFormat } from 'helper/formatText';
import {
  useGetNotificationsMutation,
  useGetSavedItemsMutation,
  useGetSupportsMutation,
  useGetUserVouchersAllMutation,
  useGetUserVouchersForOthersMutation,
  useGetUserVouchersMutation,
  useGetUserVouchersSharedMutation,
  useGetUserVouchersSingleMutation,
  useGetVouchersMutation,
  useMarkNotificationsMutation,
} from 'redux/features/user/userApi';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import { ReactComponent as DownSvg } from 'assets/svgs/drop-down.svg';
import React from 'react';
import { useMemo } from 'react';

function Profile(props) {
  const { isMobile, isTablet, isLaptop, isMobileS } = useScreenSize();
  const { countries, country, user, area } = useSelector(
    (state) => state.userAuth
  );
  const [activeTab, setActiveTab] = useState('profile');
  const [editProfile, setEditProfile] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (props.location?.pathname?.replace('/', '')?.includes('/')) {
      history.push('/');
    } else if (props.location?.pathname?.replace('/', '') !== activeTab) {
      setActiveTab(props.location?.pathname?.replace('/', ''));
      (isTablet || isMobile) && scrollToSection('tabcontent');
    }
  }, [props.location?.pathname]);

  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { userVouchers, cart, userNotifications, userSupport } = useSelector(
    (state) => state.userStore
  );

  const [getNotifications, { isLoading: isLoadingNotifications }] =
    useGetNotificationsMutation();
  const [markNotifications, { isLoading: isMarking }] =
    useMarkNotificationsMutation();
  const [getSavedItems, { isLoading: isLoadingGetSavedItems }] =
    useGetSavedItemsMutation();
  const fetchSavedItems = async () => {
    const res = await getSavedItems({
      area_id: extractId(area),
      country_id: extractId(country),
    });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'savedItems', value: res.data?.data }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
    setRefreshing(false);
  };

  const markNotificationsAsRead = async () => {
    const res = await markNotifications();
    if (res?.data?.status !== 'success') {
      dispatch(
        saveToUserStore({
          key: 'unreadNotifications',
          value: [],
        })
      );
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };
  const fetchNotifications = async () => {
    const res = await getNotifications();
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'userNotifications',
          value: sortedDataByCreatedAt(res?.data?.data, 'desc'),
        })
      );
      if (res?.data?.data?.filter((x) => x?.status !== 'read')?.length > 0) {
        await markNotificationsAsRead();
      }
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
    setRefreshing(false);
  };

  useEffect(() => {
    activeTab === 'notifications' &&
      !isLoadingNotifications &&
      fetchNotifications();
  }, [user, refetch, activeTab]);
  useEffect(() => {
    activeTab === 'saved-items' && !isLoadingGetSavedItems && fetchSavedItems();
  }, [user, refetch, activeTab, country, area]);

  const [sortOptions, setSortOptions] = useState([
    {
      id: 'All',
      name: 'All',
    },
    {
      id: 'Personal',
      name: 'Me only',
    },
    {
      id: 'Shared',
      name: 'Shared',
    },
    {
      id: 'Others',
      name: 'Others only',
    },
  ]);

  const [sortModalVisible, setSortModalVisible] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [getUserVouchers, { isLoading: isLoadingVouchers }] =
    useGetUserVouchersMutation();
  const [getUserVouchersForOthers, { isLoading: isLoadingVouchersForOthers }] =
    useGetUserVouchersForOthersMutation();
  const [getUserVouchersShared, { isLoading: isLoadingVouchersShared }] =
    useGetUserVouchersSharedMutation();
  const [getUserVouchersSingle, { isLoading: isLoadingVouchersSingle }] =
    useGetUserVouchersSingleMutation();
  const [getUserVouchersAll, { isLoading: isLoadingVouchersAll }] =
    useGetUserVouchersAllMutation();
  const [getVouchers, { isLoading }] = useGetVouchersMutation();

  const fetchVouchers = async () => {
    const res =
      filterType === 'All'
        ? await getUserVouchersAll()
        : filterType === 'Personal'
        ? await getUserVouchersSingle()
        : filterType === 'Others'
        ? await getUserVouchersForOthers()
        : await getUserVouchersShared();
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data;
      dispatch(
        saveToUserStore({
          key: 'userVouchers',
          value: sortedDataByCreatedAt(extr, 'desc'),
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    activeTab === 'vouchers' && fetchVouchers();
  }, [user, refetch, activeTab, filterType]);

  const handleRefresh = () => {
    setRefreshing(true);
    activeTab === 'notifications' &&
      !isLoadingNotifications &&
      fetchNotifications();
    activeTab === 'help' && fetchSupport();
    activeTab === 'saved-items' && !isLoadingGetSavedItems && fetchSavedItems();
    activeTab === 'vouchers' && fetchVouchers();
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Col className={`centered`}>
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        style={{
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '5px',
          paddingLeft: 10,
          paddingRight: 5,
          minWidth: 100,
        }}
        className="rowcentered"
      >
        {children}
        <DownSvg />
      </a>
    </Col>
  ));

  const filteredVouchers = useMemo(() => {
    let filtered = userVouchers || [];
    if (
      isLoadingVouchersForOthers ||
      isLoadingVouchersShared ||
      isLoadingVouchersSingle ||
      isLoadingVouchersAll
    ) {
      filtered = [];
    }
    if (Array.isArray(filtered) && filtered?.length > 1) {
      filtered = filtered?.map((x) => {
        return {
          ...x,
          name: String(x?.code || 'XXXXXX')?.toUpperCase(),
          country_id: x?.country || x?.country_id,
        };
      });
      filtered = sortByZeroBalanceLast(filtered);
    }

    return filtered;
  }, [
    userVouchers,
    isLoadingVouchersForOthers,
    isLoadingVouchersShared,
    isLoadingVouchersAll,
    isLoadingVouchersSingle,
  ]);

  const [getSupports, { isLoading: isLoadingSupport }] =
    useGetSupportsMutation();

  const fetchSupport = async () => {
    const res = await getSupports({
      status: true,
    });
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data?.map((x) => {
        return convertJsonStringToObject({ ...x });
      });
      dispatch(
        saveToUserStore({
          key: 'userSupport',
          value: extr,
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      handleTabSelect('profile');
    }
  };

  useEffect(() => {
    activeTab === 'help' && fetchSupport();
  }, [refetch, activeTab]);

  const handleTabSelect = (e) => {
    setActiveTab(e);
    history.push('/' + e);
    if (e === 'profile') {
      setEditProfile(false);
    }
    if (e === 'vouchers') {
      setFilterType('All');
    }
  };
  const handleEditProfile = () => {
    setEditProfile((prev) => !prev);
  };

  const options = [
    {
      label: 'Profile',
      link: 'profile',
      icon: <PersonSvg />,
      iconA: <PersonASvg />,
      labelBtn: editProfile ? '' : 'Edit Profile',
      labelBtnAction: handleEditProfile,
    },
    {
      label: 'Notifications',
      link: 'notifications',
      icon: <NotificationSvg />,
      iconA: <NotificationASvg />,
      labelBtn: isLoadingNotifications ? (
        <Col md="auto" sm="auto" className="d-flex align-items-end">
          <Spinner size="sm" variant="primary" />
        </Col>
      ) : (
        'Refresh'
      ),
      labelBtnAction: handleRefresh,
    },
    {
      label: 'Saved Items',
      link: 'saved-items',
      icon: <LikeSvg />,
      iconA: <LikeASvg />,
      labelBtn: isLoadingGetSavedItems ? (
        <Col md="auto" sm="auto" className="d-flex align-items-end">
          <Spinner size="sm" variant="primary" />
        </Col>
      ) : (
        'Refresh'
      ),
      labelBtnAction: handleRefresh,
    },
    {
      label: 'Vouchers',
      link: 'vouchers',
      icon: <CouponSvg />,
      iconA: <CouponASvg />,
      labelBtn:
        isLoadingVouchers ||
        isLoadingVouchersForOthers ||
        isLoadingVouchersShared ||
        isLoadingVouchersSingle ||
        isLoadingVouchersAll ? (
          <Col md="auto" sm="auto" className="d-flex align-items-end">
            <Spinner size="sm" variant="primary" />
          </Col>
        ) : (
          <Col xs="auto" className={`${'rowcentered'} p-0`}>
            <Dropdown align="end">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {sortOptions?.find((x) => x?.id == filterType)?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortOptions?.map((x) => (
                  <Dropdown.Item
                    eventKey={x?.id}
                    onClick={() => setFilterType(x.id)}
                  >
                    {x.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        ),
      labelBtnAction: handleRefresh,
    },
    {
      label: 'Change Password',
      link: 'change-password',
      icon: <LockSvg />,
      iconA: <LockASvg />,
    },
    {
      label: 'Help & Support',
      link: 'help',
      icon: <HelpSvg />,
      iconA: <HelpASvg />,
      labelBtn: isLoadingSupport ? (
        <Col md="auto" sm="auto" className="d-flex align-items-end">
          <Spinner size="sm" variant="primary" />
        </Col>
      ) : (
        'Refresh'
      ),
      labelBtnAction: handleRefresh,
    },
    {
      label: 'Sign Out',
      link: 'sign-out',
      icon: <ExitSvg />,
      iconA: <ExitASvg />,
    },
    // { label: 'Profile', link: 'profile', icon: <PersonSvg /> },
    // { label: 'Profile', link: 'profile', icon: <PersonSvg /> },
    // { label: 'Profile', link: 'profile', icon: <PersonSvg /> },
  ];

  return (
    <Container>
      <Tab.Container
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        <Row>
          <Col lg={3}>
            <Card className="border-0 mb-4 mb-lg-0 mb-md-0 mt-4 mt-lg-2 mt-md-0">
              <Card.Header>
                <Row>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={400}
                      textStyle={{
                        textTransform: 'uppercase',
                      }}
                    >
                      ACCOUNT
                    </CustomText>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="pt-lg-2 py-0 px-0">
                <ListGroup variant="flush">
                  {options?.map((x) => (
                    <ListGroup.Item
                      action
                      className={`${
                        x?.type === 'col' ? 'd-block' : 'd-flex'
                      } align-items-center justify-content-start px-3 py-0 bg-white border-0 ${
                        x?.className ? x?.className : ''
                      }`}
                      style={
                        x?.style
                          ? { minHeight: 50, ...x?.style }
                          : { minHeight: 50 }
                      }
                      eventKey={x?.eventKey || x?.link}
                      onClick={() =>
                        (isTablet || isMobile) && scrollToSection('tabcontent')
                      }
                    >
                      <div
                        style={{ width: 30, height: 30, marginRight: 16 }}
                        className="centered"
                      >
                        {(x?.eventKey || x?.link) === activeTab
                          ? x?.iconA
                          : x?.icon}
                      </div>
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        fontWeight={400}
                        cNColor={
                          (x?.eventKey || x?.link) === activeTab
                            ? 'text-primary'
                            : 'text-black'
                        }
                        textStyle={x?.labelStyle}
                      >
                        {x?.label}
                      </CustomText>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={9}>
            <Tab.Content className="bg-white" id="tabcontent">
              {options?.map((option) => (
                <Tab.Pane eventKey={option.link}>
                  <Card className="border-0 mb-4 mb-lg-0 mb-md-0 mt-2 mt-lg-2 mt-md-3">
                    <Card.Header className={`py-0`}>
                      <Row
                        className="d-flex align-items-center"
                        style={{
                          paddingTop: option?.labelBtn ? 12 : 16,
                          paddingBottom: option?.labelBtn ? 12 : 16,
                        }}
                      >
                        <Col>
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={400}
                            textStyle={{
                              textTransform: 'uppercase',
                            }}
                          >
                            {option.label}
                          </CustomText>
                        </Col>
                        {option?.labelBtn &&
                          (typeof option?.labelBtn === 'string' ? (
                            <Col
                              md="auto"
                              sm="auto"
                              className="d-flex justify-content-end"
                              style={{
                                width: 130,
                              }}
                            >
                              <CustomButton
                                text={option.labelBtn}
                                variant="primary"
                                fontWeight={500}
                                fontSize={14}
                                style={{
                                  maxWidth: 180,
                                  height: 30,
                                }}
                                onClick={option.labelBtnAction}
                              />
                            </Col>
                          ) : (
                            option?.labelBtn
                          ))}
                      </Row>
                    </Card.Header>
                    <Card.Body className="pt-lg-2 py-0 px-0">
                      {option.link === 'profile' ? (
                        <ProfileDetails
                          initialValues={[]}
                          isEdit={editProfile}
                          setIsEdit={setEditProfile}
                        />
                      ) : option.link === 'change-password' ? (
                        <ChangePassword
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      ) : option.link === 'help' ? (
                        <Help />
                      ) : option.link === 'vouchers' ? (
                        <Vouchers
                          loading={
                            isLoadingVouchers ||
                            isLoadingVouchersForOthers ||
                            isLoadingVouchersShared ||
                            isLoadingVouchersSingle ||
                            isLoadingVouchersAll
                          }
                          type={option.link}
                          data={filteredVouchers}
                        />
                      ) : (
                        <ProfileSections
                          type={option.link}
                          loading={
                            isLoadingGetSavedItems || isLoadingNotifications
                          }
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              ))}
              {/* <Tab.Pane eventKey="update_password">
                <ChangePassword />
              </Tab.Pane>
              <Tab.Pane eventKey="address">
                <DeliveryAdddress />
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default Profile;
