import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as PickupSvg } from 'assets/svgs/pickup-station.svg';
import { ReactComponent as DoorSvg } from 'assets/svgs/door-delivery.svg';
import { ReactComponent as CheckSvg } from 'assets/svgs/checked.svg';
import { ReactComponent as UncheckSvg } from 'assets/svgs/unchecked.svg';
import { CustomText } from 'components/CustomText';
import {
  areArraysEqual,
  isArrayNonEmpty,
  isSubstringInArray,
  sortByHighestBalance,
  sortedDataByCreatedAt,
} from 'helper/formatArray';
import {
  capitalize,
  errorMessageFormat,
  formatCurrency,
} from 'helper/formatText';
import { show } from 'helper/toast';
import { saveToUserStore } from 'redux/features/user/userSlice';
import {
  useGetPaymentMethodsMutation,
  useGetUserUsableVouchersMutation,
} from 'redux/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import EmptyState from 'components/EmptyState';

export const DeliveryMethodModal = ({
  visible,
  setVisible,
  selectedMethod,
  setSelectedMethod,
  deliveryListVisible,
  setDeliveryListVisible,
}) => {
  const dispatch = useDispatch();
  const { user, country, market } = useSelector((state) => state.userAuth);

  const closeModal = () => {
    setVisible(false);
    if (!isSubstringInArray(selectedMethod?.name, ['Pickup Station'])) {
      setDeliveryListVisible(!deliveryListVisible);
    }
  };

  const options = useMemo(() => {
    let filtered = [
      {
        name: 'Pickup Station',
        altname: 'Pickup Station',
        svg: <PickupSvg />,
      },
      {
        name: 'Door Delivery',
        altname: 'Door Delivery',
        svg: <DoorSvg />,
      },
    ];

    return filtered;
  }, []);

  const handleSelect = async (option) => {
    setSelectedMethod(option);
    dispatch(
      saveToUserStore({
        key: 'currentDeliveryMethod',
        value: { ...option, svg: <></> },
      })
    );

    if (isSubstringInArray(option?.name, ['Pickup Station'])) {
      const deliveryAdd = {
        title: market?.pickup_station_name || '',
        address: `${
          market?.pickup_station_apartment
            ? market?.pickup_station_apartment + ', '
            : ''
        }${
          market?.pickup_station_street_address
            ? market?.pickup_station_street_address + ', '
            : ''
        }${market?.pickup_station_city}, ${
          market?.pickup_station_state_name ||
          market?.pickup_station_state_code ||
          market?.pickup_station_state
        }, ${
          market?.pickup_station_country_name ||
          market?.pickup_station_country_code ||
          market?.pickup_station_country
        }`,

        description: market?.pickup_station_description || '',
        phoneNumber: '+' + market?.pickup_station_contact_phone || '',
        additional_info: market?.pickup_station_additional_info || '',
        postal_code: market?.pickup_station_postal_code || '',
        name: market?.pickup_station_contact_name || market?.contact_name || '',
        id: extractId(market),
      };
      dispatch(
        saveToUserStore({
          key: 'currentDeliveryAddress',
          value: deliveryAdd,
        })
      );
      closeModal();
    } else {
      dispatch(
        saveToUserStore({
          key: 'currentDeliveryAddress',
          value: null,
        })
      );
      closeModal();
    }
  };

  return (
    <Modal show={visible} onHide={closeModal} centered>
      <Modal.Title className="px-4 py-4 rowcentered">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Select Delivery Method"
        />
      </Modal.Title>
      <Modal.Body className="px-4 py-1 mb-4">
        <div
          style={{ maxHeight: window.innerHeight * 0.75, overflowY: 'auto' }}
          className="px-2"
        >
          {options?.map((option) => {
            return (
              <div
                className="border border-2 border-primary rounded py-3 px-3 rowcentered mb-2"
                onClick={() => {
                  handleSelect(option);
                }}
              >
                <div className="d-flex align-items-center">
                  {option?.svg}
                  <div className="ms-3 d-flex flex-column align-items-start">
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      text={option?.altname || option?.name}
                      divClassName="ps-3"
                    />
                  </div>
                </div>
                {selectedMethod?.name == option?.name ? (
                  <CheckSvg />
                ) : (
                  <UncheckSvg />
                )}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="px-4 py-4 justify-content-center p-1">
        <CustomButton
          variant="primary"
          type="submit"
          text="Confirm"
          className={'w-100'}
          onClick={closeModal}
        />
      </Modal.Footer> */}
    </Modal>
  );
};
