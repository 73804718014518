// import node module libraries
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// import sub components

// import data files
import CategoryCard from './CategoryCard';
import { Card, Container } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import { ReactComponent as CarouselLeft } from 'assets/svgs/carousel-left1.svg';
import { ReactComponent as CarouselRight } from 'assets/svgs/carousel-right1.svg';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';
import {
  useGetCategoriesMutation,
  useGetProductsCategoriesMutation,
  useGetUnrestrictedProductsMutation,
} from 'redux/features/user/userApi';
import { useDispatch } from 'react-redux';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { show } from 'helper/toast';
import { errorMessageFormat } from 'helper/formatText';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const CategorySlider = ({ type }) => {
  const { categories } = useSelector((state) => state.userStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, isLaptop, isDesktop } = useScreenSize();

  const [getCategories, { isLoading: isLoadingCategories }] =
    useGetCategoriesMutation();

  const fetchCategories = async () => {
    const res = await getCategories();
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'categories', value: res.data?.data }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: 'center',
    centerPadding: '30px',
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const filteredCategories = useMemo(() => {
    let filtered = categories || [];

    return filtered;
  }, [categories]);

  return (
    <Container>
      <Card
        style={{
          marginBottom: '20px',
          paddingTop: '20px',
          paddingBottom: isMobile && '50px',
        }}
        className="pb-lg-2 pb-md-5 pb-sm-8"
      >
        <CustomText
          fontFamily={'Inter'}
          fontSize={18}
          fontWeight={600}
          text={'Categories'}
          divStyle={{ marginBottom: 20, marginLeft: 24 }}
          textClassName="text-capitalize"
        />
        <div
          style={{
            paddingLeft: isLaptop || isDesktop ? '50px' : '0px',
            paddingRight: isLaptop || isDesktop ? '50px' : '0px',
          }}
        >
          <Slider
            {...settings}
            className="pb-sm-5 slick-slider-wrapper ms-2 me-2"
            prevArrow={<CarouselLeft />}
            nextArrow={<CarouselRight />}
          >
            {filteredCategories?.map((item, index) => (
              <div
                className="category-item"
                key={item.id}
                onClick={() => history.push(`/category/${item.id}`, { item })}
              >
                <CategoryCard key={index} item={item} extraclass="mx-2" />
              </div>
            ))}
          </Slider>
        </div>
      </Card>
    </Container>
  );
};

export default CategorySlider;
