import { Fragment, useMemo } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link } from 'react-router-dom';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';
import { extractId } from 'helper/formatObject';
import { removeUndefinedOrNull } from 'helper/formatArray';

const ViewedProducts = ({ products = [] }) => {
  const { isMobileM, isMobileS } = useScreenSize();
  const { country, area } = useSelector((state) => state.userAuth);
  const { recentProducts } = useSelector((state) => state.userStore);

  const filteredProducts = useMemo(() => {
    let filtered = products || [];
    if (filtered && filtered?.length > 0) {
      filtered = filtered?.filter(
        (prod) => extractId(prod?.country_id) == extractId(country)
      );

      filtered = filtered?.map((x) => {
        const connectedMarket = area?.market;

        let selectedObject = null;
        for (let i = 0; i < x?.priceByMarketId.length; i++) {
          const priceByMarket = x?.priceByMarketId[i];

          if (
            extractId(priceByMarket?.market_id) === extractId(connectedMarket)
          ) {
            selectedObject = priceByMarket;
            break;
          }
        }
        if (!selectedObject) {
          selectedObject = x?.priceByMarketId?.find(
            (priceByMarket) =>
              priceByMarket?.market_id?.name?.toLowerCase() === 'default'
          );
        }
        if (selectedObject) {
          return { ...x, priceByMarket: selectedObject };
        }
      });
      filtered = removeUndefinedOrNull(filtered);
    }

    return filtered;
  }, [products]);

  return (
    <Row className="p-2 bg-white py-3 mb-3">
      <Col
        lg={12}
        md={12}
        sm={12}
        style={{
          paddingBottom: '24px',
        }}
      >
        <CustomText
          fontFamily={'Inter'}
          fontSize={18}
          fontWeight={600}
          text={'Recently viewed'}
          // cNColor="gray"
        />
      </Col>

      {/* <div className="d-flex justify-content-center align-items-center flex-wrap m-0 p-0"> */}
      <Row className="m-0 p-0 d-flex align-items-top">
        {filteredProducts?.map((item) => (
          <Col
            lg={3}
            md={4}
            sm={6}
            xs={isMobileS ? 12 : 6}
            className="d-flex justify-content-center"
          >
            <Product item={item} url={`/product/${extractId(item)}`} />
          </Col>
        ))}
      </Row>
    </Row>
  );
};

export default ViewedProducts;
