import GlobalFilter from 'components/advance-table/GlobalFilter';
import Pagination from 'components/advance-table/Pagination';
import { CustomText } from 'components/CustomText';
import { isSubstringInArray } from 'helper/formatArray';
import { extractId } from 'helper/formatObject';
import { capitalize } from 'helper/formatText';
import { useScreenSize } from 'hooks/useScreenSize';
import { useMemo } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';

const OrderItems = ({
  items,
  loading,
  handleRefetch,
  searchValue,
  handleSearchChange,
}) => {
  const { isDesktop, isLaptop } = useScreenSize();
  const history = useHistory();
  const data = useMemo(() => items, []);
  const columns = useMemo(
    () => [
      {
        accessor: 'orderId',
        Header: 'Order ID & Date',
        Cell: ({ value, row }) => {
          return (
            <div
              className="d-flex flex-column align-items-stretch py-2"
              style={{ minHeight: 80 }}
            >
              <div className="mb-auto">
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={14}
                  fontWeight={600}
                  text={value}
                  // cNColor="gray"
                  // textClassName="text-capitalize"
                />
              </div>
              <div className="mb-auto">
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={12}
                  fontWeight={500}
                  text={row?.original?.extTime}
                />
              </div>
            </div>
          );
        },
      },
      {
        accessor: 'extItemName',
        Header: 'Items',
        Cell: ({ value, row }) => {
          return (
            <div className="py-2">
              {value?.slice(0, 3)?.map((x) => (
                <span className="d-flex align-items-center">
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={13}
                    fontWeight={500}
                    text={x}
                  />
                  {/* <CustomText
                    fontFamily={'Poppins'}
                    fontSize={12}
                    fontWeight={400}
                    // text={``}
                    textClassName={'text-gray-500'}
                  >
                    {'('}Unit -&nbsp;
                  </CustomText>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={12}
                    fontWeight={400}
                    text={`${x?.priceByMarket_id?.price * x?.quantity})`}
                    textClassName={'text-gray-500'}
                  /> */}
                </span>
              ))}
              {value?.length > 3 && (
                <span className="d-flex align-items-center">
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={12}
                    fontWeight={400}
                    text={`and more`}
                    textClassName={'text-gray-500'}
                  />
                </span>
              )}
            </div>
          );
        },
      },
      {
        accessor: 'order_status',
        Header: 'Status',
        Cell: ({ value, row }) => {
          return (
            <div className="py-2">
              <CustomText
                fontFamily={'Poppins'}
                fontSize={12}
                fontWeight={400}
                text={capitalize(
                  isSubstringInArray(value, [
                    'delivered',
                    'canceled',
                    'cancelled',
                  ])
                    ? value
                    : isSubstringInArray(value, ['intransit'])
                    ? 'in transit'
                    : 'Pending'
                )}
                styleColor={
                  isSubstringInArray(value, ['cancelled', 'canceled'])
                    ? '#FA3737'
                    : isSubstringInArray(value, ['delivered'])
                    ? '#039D00'
                    : '#FF8804'
                }
                divStyle={{
                  padding: 5,
                  borderRadius: 20,
                  backgroundColor: isSubstringInArray(value, [
                    'cancelled',
                    'canceled',
                  ])
                    ? '#FA37371A'
                    : isSubstringInArray(value, ['delivered'])
                    ? 'rgba(3, 157, 0, 0.2)'
                    : 'rgba(255, 200, 4, 0.2)',
                }}
                className="centered"
              />
            </div>
          );
        },
      },
      {
        accessor: 'extAmount',
        Header: 'Total Amount',
        Cell: ({ value, row }) => {
          return (
            <div className="py-2">
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={600}
                text={value}
              />
            </div>
          );
        },
      },
      {
        accessor: 'message',
        Header: '',
        Cell: ({ value, row }) => {
          return (
            <div className="py-2">
              <CustomText
                fontFamily={'Poppins'}
                fontSize={12}
                fontWeight={500}
                text={'View details'}
                cNColor="text-primary"
                divStyle={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push('/orders/' + extractId(row?.original), {
                    item: row?.original,
                  })
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  const sortByOptions = [
    { value: 'newest', label: 'Newest' },
    { value: 'free', label: 'Free' },
    { value: 'most-popular', label: 'Most Popular' },
    { value: 'highest-rated', label: 'Highest Rated' },
  ];

  return (
    <Container>
      <Row className="mb-3 mt-3 mt-lg-0 mt-md-0">
        <Col lg="auto" md={12} className="d-flex align-items-center py-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={'Orders'}
            // cNColor="gray"
          />
        </Col>
        <Col
          className={`d-flex ${
            isLaptop || isDesktop ? 'justify-content-end' : ''
          }`}
        >
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder="Search"
            className={isLaptop || isDesktop ? '' : 'w-100'}
          />
        </Col>
      </Row>
      <div className="table-responsive p-2 bg-white">
        <Table {...getTableProps()} className="text-nowrap">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
        className="mt-3 mb-3 mb-md-0 mb-lg-0"
      />
    </Container>
  );
};

export default OrderItems;
