import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as BankSvg } from 'assets/svgs/bank.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { CustomText } from 'components/CustomText';
import { isSubstringInArray, sortByProperty } from 'helper/formatArray';
import { saveArea } from 'redux/features/auth/authSlice';
import { capitalize, errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';
import {
  useCreateDeliveryAddressMutation,
  useUpdateDeliveryAddressMutation,
} from 'redux/features/user/userApi';
import { extractId } from 'helper/formatObject';
import { useDispatch, useSelector } from 'react-redux';
import { useScreenSize } from 'hooks/useScreenSize';
import { CustomToggle } from 'pages/auth/Register';
const paymentMethods = [
  { icon: <BankSvg />, mode: 'Bank Transfer' },
  { icon: <Pay4MeSvg />, mode: 'Pay4Me' },
  { icon: <VoucherSvg />, mode: 'Voucher' },
];
export const AddDeliveryAddressModal = ({ visible, setVisible, initial }) => {
  const dispatch = useDispatch();

  const {
    user,
    country,
    area,
    countries: countriesList,
  } = useSelector((state) => state.userAuth);
  const { areas } = useSelector((state) => state.userStore);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [validateOnChange, setValidateOnChange] = useState(!!initial);
  const phoneRegExp = /^[7-9][01]\d{8}$/im;
  const canadaPostalCodeRegExp =
    /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;
  const canadaPostalCodeRegExp2 =
    /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/;
  const canadaPhoneRegExp =
    /^(1[\s-]?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}$/;

  const validationSchema = Yup.object().shape({
    customer_id: Yup.string()
      .required('Customer Info is required')
      .label('User not found'),
    street_address: Yup.string()
      .required('Street Address is required')
      .label(''),
    apartment: Yup.string().label('Apartment/Suite No'),
    city: Yup.string().required('City is required').label('City'),
    state: Yup.string().required('State is required').label('State'),
    country: Yup.string().required('Country is required').label('Country'),
    postal_code: Yup.string()
      .when('country', {
        is: (val) => val === 'Canada',
        then: () =>
          Yup.string()
            .matches(canadaPostalCodeRegExp, 'Postal/Zip code is invalid')
            .required('Postal code is required'),
      })
      .label('Postal/Zip code'),
    tag: Yup.string().label('Address Tag'),
    additional_info: Yup.string().label('Delivery instructions'),
    contact_name: Yup.string()
      .required('Contact Name is required')
      .label('Contact Name'),
    contact_phone: Yup.string()
      .required('Phone number is required')
      .when('country', {
        is: (val) => val === 'Nigeria',
        then: () =>
          Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Phone number is required')
            .label('Phone number'),
      })
      .when('country', {
        is: (val) => val === 'Canada',
        then: () =>
          Yup.string()
            .matches(canadaPhoneRegExp, 'Phone number is not valid')
            .required('Phone number is required')
            .label('Phone number'),
      }),
    status: Yup.boolean()
      .required('Status is required')
      .default(true)
      .label('Status'),
  });

  useEffect(() => {
    setValidateOnChange(!!initial);
  }, [initial]);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      customer_id: user?.id,
      street_address: initial?.street_address || '',
      apartment: initial?.apartment || '',
      country: initial?.country || country?.name,
      state:
        initial?.state ||
        (country?.name?.toLowerCase() == 'nigeria' ? area?.state_name : ''),
      city:
        initial?.city ||
        (country?.name?.toLowerCase() == 'nigeria' ? area?.name : ''),
      postal_code: initial?.postal_code || '',
      tag: initial?.tag || '',
      additional_info: initial?.additional_info || '',
      contact_name:
        initial?.contact_name ||
        user?.country?.toLowerCase() === country?.name?.toLowerCase()
          ? capitalize(user?.firstName + ' ' + user?.lastName)
          : '',
      contact_phone:
        (initial?.contact_phone?.startsWith('0')
          ? initial?.contact_phone?.slice(1)
          : initial?.contact_phone?.replace(`+${country?.calling_code}`, '')) ||
        (user?.country?.toLowerCase() === country?.name?.toLowerCase()
          ? user?.phoneNumber?.startsWith('0')
            ? user?.phoneNumber?.slice(1)
            : user?.phoneNumber?.replace(`+${country?.calling_code}`, '')
          : ''),
      status: true,
    },
    validateOnMount: !!initial,
    validateOnChange: validateOnChange,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleAddAddress(values),
  });
  // console.log('errors', errors, values);
  useEffect(() => {
    if (country) {
      const extrCountries = countriesList?.map((x) => {
        return {
          label: x?.name,
          value: x?.name,
          id: extractId(x),
        };
      });
      const extrStates = country?.states?.map((x) => {
        return {
          label: x?.name,
          value: x?.name,
          id: extractId(x),
        };
      });
      const extrAreas = areas?.map((x) => {
        return {
          label: x?.name,
          value: x?.name,
          id: extractId(x),
        };
      });
      if (
        user?.country?.toLowerCase() != country?.name?.toLowerCase() &&
        !initial?.contact_phone
      ) {
        setFieldValue(
          'contact_name',
          capitalize(user?.firstName + ' ' + user?.lastName)
        );
        setFieldValue(
          'contact_phone',
          user?.phoneNumber?.startsWith('0')
            ? user?.phoneNumber?.slice(1)
            : user?.phoneNumber?.replace(`+${country?.calling_code}`, '')
        );
      }
      setCountries(sortByProperty(extrCountries, 'label'));
      setStates(sortByProperty(extrStates, 'label'));
      setCities(sortByProperty(extrAreas, 'label'));
    }
  }, [country, countriesList, user, areas]);

  const [createDeliveryAddress, { isLoading }] =
    useCreateDeliveryAddressMutation();
  const [updateDeliveryAddress, { isLoading: isUpdatingDeliveryAddress }] =
    useUpdateDeliveryAddressMutation();

  const handleAddAddress = async (values) => {
    const resolveValues = {
      ...values,
      postal_code: values?.postal_code?.toUpperCase()?.trim()?.replace(' ', ''),
      state: values?.state?.value || values?.state,
      contact_phone: `+${country?.calling_code}` + values?.contact_phone,
    };
    const res = initial?.id
      ? await updateDeliveryAddress({ id: initial?.id, data: resolveValues })
      : await createDeliveryAddress(resolveValues);
    if (res?.data?.status === 'success') {
      show(
        dispatch,
        errorMessageFormat(
          res,
          initial?.id
            ? 'Address updated successfully'
            : 'Address added successfully'
        ),
        {
          type: 'success',
          placement: 'top',
          duration: 4000,
        }
      );
      setVisible(false);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        placement: 'top',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    if (!isSubstringInArray(country?.name, ['nigeria'])) {
      dispatch(saveArea(null));
    }
  }, [visible]);

  const closeModal = () => {
    resetForm();
    setVisible(false);
  };

  const { isTablet } = useScreenSize();

  return (
    <Modal
      size={isTablet ? 'xl' : 'lg'}
      show={visible}
      onHide={closeModal}
      centered
    >
      <Modal.Title className="px-4 py-4">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Add New Address"
        />
      </Modal.Title>
      <Modal.Body className="px-6 py-1">
        <Row
          style={{ maxHeight: window.innerHeight * 0.75, overflowY: 'auto' }}
        >
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              label={'Street address'}
              id={'street_address'}
              name={'street_address'}
              placeholder="Enter your street address"
              onChange={handleChange}
              value={values?.street_address}
              error={errors?.street_address}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              id={'apartment'}
              name={'apartment'}
              label={'Suite/Apartment Info (Optional)'}
              placeholder="Enter your suite or Apartment No."
              onChange={handleChange}
              value={values?.apartment}
              error={errors?.apartment}
            />
          </Col>

          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              inputType="select"
              id={'country'}
              name={'country'}
              selectOptions={countries}
              onChange={(e) => setFieldValue('country', e)}
              label={'Country'}
              placeholder="Select your country"
              value={countries?.find((x) => x?.value == values?.country)}
              error={errors?.country}
              disabled={true}
              inputStyle={{
                paddingLeft: 10,
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              inputType="select"
              selectOptions={states}
              id={'state'}
              name={'state'}
              onChange={(e) => setFieldValue('state', e)}
              label={'State/Province'}
              placeholder="Select your state/province"
              value={states?.find((x) => x?.value == values?.state)}
              error={errors?.state}
              disabled={country?.name?.toLowerCase() == 'nigeria'}
              inputStyle={{
                paddingLeft: 10,
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            {country?.name?.toLowerCase()?.includes('nigeria') ? (
              <CustomInput
                inputType="select"
                id={'city'}
                name={'city'}
                selectOptions={cities}
                onChange={(e) => setFieldValue('city', e)}
                label={'City'}
                placeholder="Select your city"
                value={cities?.find((x) => x?.value == values?.city)}
                error={errors?.city}
                disabled={country?.name?.toLowerCase() == 'nigeria'}
                inputStyle={{
                  paddingLeft: 10,
                }}
              />
            ) : (
              <CustomInput
                type={'text'}
                required={true}
                id={'city'}
                name={'city'}
                label={'City'}
                placeholder="Enter your city"
                onChange={handleChange}
                value={values?.city}
                error={errors?.city}
              />
            )}
          </Col>

          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              id={'postal_code'}
              name={'postal_code'}
              label={`Postal/Zip code ${
                values?.country?.toLowerCase() == 'nigeria' ? '(Optional)' : ''
              }`}
              placeholder="Enter a valid postal/zip code"
              onChange={(e) => {
                setFieldValue('postal_code', e?.target?.value);
              }}
              value={values?.postal_code}
              error={errors?.postal_code}
              maxLength={7}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              id={'tag'}
              name={'tag'}
              label={'Address Tags(Optional)'}
              placeholder="Enter a useful tag(e.g. Home, Office ...)"
              onChange={handleChange}
              value={values?.tag}
              error={errors?.tag}
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              id={'additional_info'}
              name={'additional_info'}
              label="Delivery Instructions(Optional)"
              placeholder="Delivery Instructions"
              onChange={handleChange}
              value={values?.additional_info}
              error={errors?.additional_info}
              as={'textarea'}
              numberOfLines={4}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <CustomText
              fontFamily={'Poppins'}
              fontSize={14}
              fontWeight={600}
              text="Contact Information"
            />
          </Col>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <CustomInput
              type={'text'}
              required={true}
              id={'contact_name'}
              name={'contact_name'}
              label={'Name'}
              returnValue={true}
              placeholder="Enter your name"
              onChange={handleChange}
              value={values?.contact_name}
              error={errors?.contact_name}
            />
          </Col>

          <Col lg={6} md={6} sm={12} className="mb-3 ">
            <CustomInput
              required={true}
              label={'Phone Number'}
              // id={'contact_phone'}
              // name={'contact_phone'}
              placeholder="Enter your Phone number"
              returnValue={true}
              onChange={(e) => {
                setFieldValue(
                  'contact_phone',
                  e?.target?.value?.startsWith('0')
                    ? e?.target?.value?.slice(1)
                    : e?.target?.value
                );
              }}
              value={
                values.contact_phone?.startsWith('0')
                  ? values.contact_phone?.slice(1)
                  : values.contact_phone
              }
              error={errors.contact_phone}
              leftCompClassName="position-absolute ps-2 start-0 align-items-center"
              leftComp={
                <Dropdown className="m-0 p-0 bg-white">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="countryDrops"
                    className="m-0 bg-white border-0"
                  >
                    <div className="d-flex align-items-center">
                      {country?.flags?.png ? (
                        <Image
                          style={{
                            width: 20,
                            height: 10,
                            backgroundColor: 'red',
                          }}
                          src={country?.flags?.png}
                        />
                      ) : country?.flags?.svg ? (
                        <Image
                          style={{
                            width: 20,
                            height: 10,
                            backgroundColor: 'red',
                          }}
                          src={country?.flags?.svg}
                        />
                      ) : (
                        <></>
                      )}
                      <CustomText
                        fontFamily="Poppins"
                        text={country?.calling_code}
                        fontSize={14}
                        divStyle={{ marginLeft: 5 }}
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey={country?.id}
                      className="d-flex align-items-center"
                    >
                      {country?.flags?.png ? (
                        <Image
                          style={{
                            width: 20,
                            height: 10,
                            marginRight: 10,
                          }}
                          src={country?.flags?.png}
                        />
                      ) : country?.flags?.svg ? (
                        <Image
                          style={{
                            width: 20,
                            height: 10,
                            marginRight: 10,
                          }}
                          src={country?.flags?.svg}
                        />
                      ) : (
                        <></>
                      )}
                      {country?.name}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
              inputStyle={{
                paddingLeft:
                  country?.calling_code?.toString()?.length > 2 ? 64 : 44,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="px-4 py-4 justify-content-center p-1">
        <CustomButton
          variant="primary"
          type="submit"
          text="Submit"
          disabled={!isValid}
          onPress={() => {
            setValidateOnChange(true);
            handleSubmit();
          }}
          loading={isLoading || isUpdatingDeliveryAddress}
          className={'w-100'}
          // onClick={() => handleOnSelect(selected)}
        />
      </Modal.Footer>
    </Modal>
  );
};
