// import node module libraries
import React, { Fragment } from 'react';
import { Col, Row, Card, Dropdown, Carousel } from 'react-bootstrap';

import { ReactComponent as EmptyOrderSvg } from 'assets/svgs/emptyorder.svg';
import { ReactComponent as NotificationIconSvg } from 'assets/svgs/notificationicon.svg';
import { ReactComponent as VouchersIconSvg } from 'assets/svgs/vouchericon.svg';
import { ReactComponent as SavedIconSvg } from 'assets/svgs/saveditem.svg';

import EmptyState from 'components/EmptyState';

const EmptySection = ({ type, loading, loadingText = 'Loading' }) => {
  const emptyProps = {
    icon:
      type === 'saved-items' ? (
        <SavedIconSvg />
      ) : type === 'notifications' ? (
        <NotificationIconSvg />
      ) : type === 'vouchers' ? (
        <VouchersIconSvg />
      ) : (
        <VouchersIconSvg />
      ),
    title: loading
      ? loadingText
      : `No ${
          type === 'saved'
            ? 'Saved items'
            : type === 'notifications'
            ? 'Notification'
            : type === 'vouchers'
            ? 'Vouchers'
            : type || ''
        } yet`,
    subtitle: loading
      ? ''
      : type === 'saved-items'
      ? 'All your saved items will appear here'
      : type === 'notifications'
      ? 'All your notification will appear here'
      : type === 'vouchers'
      ? 'All vouchers purchased, owned or shared by you can be found here'
      : '',
    btnText: 'Continue Shopping',
    showBtn: !loading,
  };

  return <EmptyState {...emptyProps} />;
};

export default EmptySection;
