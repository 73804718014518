import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { CustomText } from './CustomText';

const CustomButton = ({
  text,
  type,
  variant = 'primary',
  disabled,
  loading,
  spinnerVariant,
  className,
  height = '45px',
  width,
  style,
  fontFamily,
  size,
  fontSize = '14px',
  fontWeight,
  buttonTextColor,
  onClick,
  onPress,
  children,
}) => {
  return (
    <Button
      size={size || 'sm'}
      variant={variant}
      onClick={onClick || onPress}
      type={type}
      disabled={disabled || loading}
      className={className}
      style={{
        height: height,
        width: width,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        ...style,
      }}
    >
      {loading ? (
        <Spinner size="sm" variant={spinnerVariant || 'light'} />
      ) : text ? (
        <CustomText
          fontFamily={fontFamily || 'Poppins'}
          fontSize={fontSize}
          fontWeight={fontWeight || 400}
          text={text}
          cNColor={
            buttonTextColor ||
            (variant === 'primary' ? 'text-white' : 'text-black')
          }
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
