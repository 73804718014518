import { Fragment, useMemo } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link } from 'react-router-dom';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect } from 'react';
import { errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { extractId } from 'helper/formatObject';
import {
  useGetProductsMutation,
  useGetUnrestrictedProductsMutation,
} from 'redux/features/user/userApi';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getRandomObjectsFromArray,
  removeUndefinedOrNull,
} from 'helper/formatArray';

const FeaturedProducts = ({ loadingProducts }) => {
  const { isMobileM, isMobileS } = useScreenSize();
  const { country, token, area, user } = useSelector((state) => state.userAuth);
  const { categories, products, savedItems } = useSelector(
    (state) => state.userStore
  );
  const { areas } = useSelector((state) => state.userStore);

  const dispatch = useDispatch();

  const filteredProducts = useMemo(() => {
    let filtered = products || [];
    if (filtered && filtered?.length > 0) {
      filtered = filtered?.filter(
        (prod) => prod?.available == 'yes' || prod?.available === true
      );
      filtered = filtered?.filter(
        (prod) => extractId(prod?.country_id) == extractId(country)
      );
      filtered = filtered?.map((x) => {
        const connectedMarket = area?.market;

        let selectedObject = null;
        for (let i = 0; i < x?.priceByMarketId.length; i++) {
          const priceByMarket = x?.priceByMarketId[i];

          if (
            extractId(priceByMarket?.market_id) === extractId(connectedMarket)
          ) {
            selectedObject = priceByMarket;
            break;
          }
        }
        if (!selectedObject) {
          selectedObject = x?.priceByMarketId?.find(
            (priceByMarket) =>
              priceByMarket?.market_id?.name?.toLowerCase() === 'default'
          );
        }
        if (selectedObject) {
          return { ...x, priceByMarket: selectedObject };
        }
      });
      filtered = getRandomObjectsFromArray(filtered, 9);
      filtered = removeUndefinedOrNull(filtered);
    }
    return filtered;
  }, [products, area, categories]);

  return (
    <Container>
      <Card
        style={{ paddingTop: '20px', paddingBottom: '20px', marginTop: '10px' }}
      >
        <Col
          style={{
            paddingRight: '24px',
            paddingLeft: '24px',
            paddingBottom: '24px',
          }}
        >
          <CustomText
            fontFamily={'Inter'}
            fontSize={18}
            fontWeight={600}
            text={'Featured products'}
            // cNColor="gray"
          />
        </Col>

        {/* <div className="d-flex justify-content-center align-items-center flex-wrap m-0 p-0"> */}
        <Row className="m-0 p-0 d-flex align-items-top">
          {filteredProducts.map((item) => (
            <Col
              lg={3}
              md={4}
              sm={6}
              xs={isMobileS ? 12 : 6}
              className="d-flex justify-content-center"
            >
              <Product
                item={item}
                type="featured"
                url={`/product/${extractId(item)}`}
                loadingProducts={loadingProducts}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </Container>
  );
};

export default FeaturedProducts;
