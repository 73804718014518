import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { isSubstringInArray } from 'helper/formatArray';
import { logout, saveToken, setCredentials } from './auth/authSlice';
import {
  setCurrentScreen,
  setRedirectScreen,
} from './navigation/navigationSlice';

import { BASE_URL } from './url';
import { clearUserDetails } from './user/userSlice';
const mutex = new Mutex();
const baseUrl = BASE_URL;
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // get token from useAuth reducer
    const data = getState().userAuth.token;
    if (data) {
      headers.set('Authorization', `Bearer ${data}`);
    }
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    isSubstringInArray('expired', [result?.error?.data?.message]) ||
    isSubstringInArray('logged out', [result?.error?.data?.message]) ||
    isSubstringInArray('unauthori', [result?.error?.data?.message]) ||
    isSubstringInArray('invalid signature', [result?.error?.data?.message])
  ) {
    const pushToken = api.getState()?.userAuth?.pushToken;

    const logoutResult = await baseQuery(
      {
        credentials: 'omit',
        url: 'customers/log-out',
        method: 'POST',
        body: { pushToken },
      },
      api,
      extraOptions
    );
    api.dispatch(setCurrentScreen('SignIn'));
    api.dispatch(clearUserDetails());
    api.dispatch(saveToken(null));
    api.dispatch(logout());
    return {
      error: {
        data: { message: 'Login session expired, Please sign in again' },
        screen: 'SignIn',
      },
    };
  }

  return result;
};

export default customFetchBase;
