// import node module libraries
import man from 'assets/pngs/man.png';
import { Fragment } from 'react';
import {
  Card,
  Carousel,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import custom components
import { ReactComponent as CarouselLeft } from 'assets/svgs/carousel-left.svg';
import { ReactComponent as CarouselRight } from 'assets/svgs/carousel-right.svg';
import { ReactComponent as PersonIn } from 'assets/svgs/personin.svg';

import CustomButton from 'components/CustomButton';
import CategorySlider from 'components/customSlider/CategorySlider';
import { CustomText } from 'components/CustomText';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AllProducts from './components/AllProducts';
import FeaturedProducts from './components/FeaturedProducts';

const HeroHeader = () => {
  const { isLoggedIn } = useSelector((state) => state.userAuth);
  // const isLoggedIn = false;
  const { isDesktop, isLaptop, isTablet, isMobile } = useScreenSize();
  return (
    <Container>
      <Row
        className="align-items-center my-0 mb-4"
        style={isDesktop || isLaptop ? { height: 350 } : { minHeight: 350 }}
      >
        <Col
          xl={isLoggedIn ? 12 : 8}
          lg={isLoggedIn ? 12 : 8}
          md={12}
          className="h-100"
          style={{ marginTop: 10 }}
        >
          <Carousel prevIcon={<CarouselLeft />} nextIcon={<CarouselRight />}>
            <Carousel.Item interval={1000}>
              <Card
                className="p-sm-5 p-md-5 p-lg-3 text-center d-flex align-items-center"
                // style={
                //   isDesktop || isLaptop ? { height: 350 } : { minHeight: 350 }
                // }
              >
                <Row className="rowcentered justify-content-center">
                  <Col lg="6" md="12" className="p-4">
                    <CustomText
                      fontFamily={'Inter'}
                      fontSize={isMobile ? 20 : isTablet ? 28 : 30}
                      fontWeight={700}
                      text={'Order Your Fresh Groceries'}
                      // cNColor="gray"
                      textClassName="text-capitalize text-sm-30 text-md-32 text-lg-40"
                    />
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={400}
                      text="Skip the market stress and get your best-priced groceries delivered to you. Fooddy makes grocery shopping easy - browse, order, and get it delivered"
                      // cNColor="gray"
                      textClassName="text-capitalize text-sm-10 text-md-12 text-lg-14"
                    />
                  </Col>
                  <Col lg="auto" md="12" className="mt-md-3">
                    <Image
                      className="img-fluid"
                      src={man}
                      alt="First slide"
                      style={{
                        width: isMobile || isTablet ? '50%' : '100%',
                        height: isMobile || isTablet ? 200 : 300,
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={12}
          style={{ marginTop: 10 }}
          className={`text-lg-end  text-center  h-100 ${
            isLoggedIn ? 'd-none' : 'd-block'
          } align-items-center justify-content-center`}
        >
          <Card
            className={`${
              !isLoggedIn ? 'p-5' : 'p-0'
            } text-center d-flex align-items-center justify-content-center h-100`}
          >
            {isLoggedIn ? (
              <ListGroup className="w-100">
                <ListGroup.Item
                  variant="primary"
                  className="d-flex align-items-center"
                >
                  <i className="fe fe-user me-2"></i>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    Profile
                  </CustomText>
                </ListGroup.Item>
              </ListGroup>
            ) : (
              <Row className="rowcentered">
                <PersonIn />
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={15}
                  fontWeight={500}
                  text="Welcome  To Fooddy"
                  // cNColor="gray"
                />
                <Col
                  lg={12}
                  md={12}
                  className="d-flex justify-content-between mt-3"
                >
                  <Link to="/login" active={'signIn'}>
                    <CustomButton
                      text="Sign in"
                      variant="secondary"
                      fontSize={13}
                      style={{
                        width: '88px',
                        height: '40px',
                        borderRadius: '5px',
                      }}
                      className={'text-black bg-white border-primary'}
                    />
                  </Link>
                  <Link to="/register" active={'register'}>
                    <CustomButton
                      text="Register"
                      fontSize={13}
                      style={{
                        width: '88px',
                        height: '40px',
                        borderRadius: '5px',
                      }}
                      variant="primary"
                    />
                  </Link>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Dashboard = () => {
  const [loadingProducts, setLoadingProducts] = useState(true);

  const changeLoadingProduct = (val) => {
    setLoadingProducts(val);
  };

  return (
    <Fragment>
      <HeroHeader />
      <CategorySlider />
      <AllProducts
        loadingProducts={loadingProducts}
        changeLoadingProduct={changeLoadingProduct}
      />
      <FeaturedProducts
        loadingProducts={loadingProducts}
        setLoadingProducts={setLoadingProducts}
      />
    </Fragment>
  );
};

export default Dashboard;
