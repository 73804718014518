// import node module libraries
import {BrowserRouter as Router} from 'react-router-dom';

// import layouts
import ScrollToTop from 'layouts/ScrollToTop';
import AllRoutes from 'routes/AllRoutes';

// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

import {Provider} from 'react-redux';
import {store} from 'redux/store';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';

import WebFont from 'webfontloader';
import {useEffect} from 'react';

function App() {
  let persistor = persistStore(store);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat', 'Poppins'],
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className='App'>
            <ScrollToTop />
            <AllRoutes />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
