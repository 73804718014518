import { Fragment, useEffect, useMemo, useState } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CartItem from './CartItem';
import SavedProducts from './SavedProducts';
import CartSummary from './CartSummary';
import { useScreenSize } from 'hooks/useScreenSize';
import CustomToast from 'components/CustomToast';
import NewSuccess from 'components/Newsuccess';
import { useDispatch, useSelector } from 'react-redux';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  removeObjectFromArray,
  removeStringFromArray,
  updateObjects,
} from 'helper/formatArray';
import { saveArea, saveToUserAuth } from 'redux/features/auth/authSlice';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { errorMessageFormat } from 'helper/formatText';
import { setCurrentScreen } from 'redux/features/navigation/navigationSlice';
import { extractId } from 'helper/formatObject';
import { show } from 'helper/toast';
import {
  useDeleteCartItemMutation,
  useGetAreaMutation,
  useGetCartMutation,
  useUpdateCartItemQuantityMutation,
} from 'redux/features/user/userApi';
import { useHistory, useLocation } from 'react-router-dom';
import { DeleteModal } from 'components/DeleteModal';

const CartItems = () => {
  const { isMobile } = useScreenSize();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { token, countries, country, area, pushToken } = useSelector(
    (state) => state.userAuth
  );

  const { cart, market } = useSelector((state) => state.userStore);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [cartEmpty, setCartEmpty] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [itemsProcessing, setItemsProcessing] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updateQty, setUpdateQty] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [mov, setMov] = useState(0);
  const [proceedToLoginModal, setProceedToLoginModal] = useState(false);
  const [selectAreaModalVisible, setSelectAreaModalVisible] = useState(false);

  const [getCart, { isLoading: isLoadingGetCart }] = useGetCartMutation();
  const [deleteCartItem, { isLoading: isLoadingDeleteCartItem }] =
    useDeleteCartItemMutation();
  const [
    updateCartItemQuantity,
    { isLoading: isLoadingUpdateCartItemQuantity },
  ] = useUpdateCartItemQuantityMutation();

  useEffect(() => {
    setItemsProcessing([]);
  }, [location?.pathname]);

  const handleDeleteCartItem = async ({
    id: cart_item_id,
    cart_id: cart_id,
  }) => {
    let remaining = removeObjectFromArray(
      [...cart?.cart_items],
      'id',
      cart_item_id
    );
    // dispatch(
    //   saveToUserStore({
    //     key: 'cart',
    //     value: {
    //       ...cart,
    //       cart_items: remaining,
    //     },
    //   })
    // );
    const res = await deleteCartItem({ cart_item_id, cart_id });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'cart', value: res.data?.data }));
      dispatch(
        saveToUserStore({
          key: 'cartItemCount',
          value: res?.data?.data?.cart_items?.length || 0,
        })
      );
      setDeleteVisible(false);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 2000,
      });
    }
  };

  const handleUpdateCartItemQuantity = async ({
    id: cart_item_id,
    cart_id: cart_id,
    quantity,
  }) => {
    const oldCart = cart;
    setItemsProcessing([...itemsProcessing, cart_item_id]);
    dispatch(
      saveToUserStore({
        key: 'cart',
        value: {
          ...cart,
          cart_items: updateObjects(
            cart?.cart_items,
            (e) => e?.id == cart_item_id,
            'quantity',
            quantity
          ),
        },
      })
    );
    setUpdateQty(!updateQty);

    const res = await updateCartItemQuantity({
      cart_item_id,
      cart_id,
      quantity,
    });
    setItemsProcessing(removeStringFromArray(itemsProcessing, cart_item_id));
    if (res?.data?.status === 'success') {
      // dispatch(saveToUserStore({ key: 'cart', value: res.data?.data }));
    } else {
      dispatch(saveToUserStore({ key: 'cart', value: oldCart }));
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 2000,
      });
    }
  };

  const filteredData = useMemo(() => {
    let filtered = cart?.cart_items || [];
    filtered = filtered?.map((x) => {
      return {
        ...x,
        name: x?.product_id?.name,
        quantity: x?.quantity,
        price: x?.priceByMarket_id?.price,
        uom: x?.priceByMarket_id?.uom_id?.name,
        image: x?.product_id?.imageCover,
        product_id: x?.product_id?.id,
        id: x?.id,
        cart_id: cart?.id,
      };
    });
    setSubtotal(
      filtered?.reduce((acc, curr) => {
        return acc + curr?.quantity * curr?.price;
      }, 0)
    );
    if (!area?.id) {
      const mrkt = filtered[0]?.priceByMarket_id?.market_id;
      dispatch(
        saveToUserStore({
          key: 'market',
          value: mrkt,
        })
      );
      setMov(mrkt?.minimum_order_value);
    }

    if (searchValue !== '' && searchValue?.length > 0) {
      filtered = filtered?.filter(
        (x) =>
          x?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          x?.uom?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          String(x?.price)
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          String(x?.quantity)
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
      );
    }
    setCartEmpty(
      filtered?.length <= 0 && searchValue !== '' && searchValue?.length > 0
    );
    return filtered;
  }, [cart, updateQty, searchValue, cart?.cart_items]);

  return (
    <Container>
      <Row>
        <Col
          lg={8}
          md={12}
          sm={12}
          style={{ marginTop: isMobile ? '20px' : 0 }}
        >
          <Card className={'mb-3'}>
            <Card.Header>
              <CustomText
                fontFamily={'Poppins'}
                fontSize={16}
                fontWeight={500}
                text={'Cart (6)'}
                // cNColor="gray"
              />
            </Card.Header>
            <Card.Body className="pt-0">
              {filteredData?.map((item) => (
                <CartItem
                  handleUpdateCartItemQuantity={handleUpdateCartItemQuantity}
                  country={country}
                  isLoadingDeleteCartItem={isLoadingDeleteCartItem}
                  isLoadingUpdateCartItemQuantity={
                    isLoadingUpdateCartItemQuantity
                  }
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  deleteVisible={deleteVisible}
                  setDeleteVisible={setDeleteVisible}
                  itemsProcessing={itemsProcessing}
                  setItemsProcessing={setItemsProcessing}
                  itemx={item}
                />
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={12} sm={12}>
          <CartSummary
            subtotal={subtotal}
            mov={mov}
            filteredData={filteredData}
            country={country}
            isLoadingGetCart={isLoadingGetCart}
            isLoadingUpdateCartItemQuantity={isLoadingUpdateCartItemQuantity}
            isLoadingDeleteCartItem={isLoadingDeleteCartItem}
          />
        </Col>
      </Row>
      <DeleteModal
        visible={deleteVisible}
        handleClose={() => setDeleteVisible(false)}
        item={selectedItem}
        handleConfirm={() => handleDeleteCartItem(selectedItem)}
        isLoading={isLoadingDeleteCartItem}
      />
      <SavedProducts />
    </Container>
  );
};

export default CartItems;
