import { Fragment, useEffect, useMemo } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { useState } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  getRandomObjectsFromArray,
  removeUndefinedOrNull,
} from 'helper/formatArray';
import { extractId } from 'helper/formatObject';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSavedItemsMutation } from 'redux/features/user/userApi';
const SavedProducts = () => {
  const { isMobile, isLaptop, isDesktop } = useScreenSize();
  const { token, area, country, user } = useSelector((state) => state.userAuth);
  const { savedItems } = useSelector((state) => state.userStore);
  const dispatch = useDispatch();
  const location = useLocation();
  const [getSavedItems, { isLoading }] = useGetSavedItemsMutation();
  const fetchSavedItems = async () => {
    const res = await getSavedItems({
      area_id: area?.id,
      country_id: country?.id,
    });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({ key: 'savedItems', value: res.data?.data || [] })
      );
    }
  };
  useEffect(() => {
    token && !savedItems && fetchSavedItems();
  }, [location?.pathname]);

  const filteredProducts = useMemo(() => {
    let filtered = savedItems?.items || [];
    if (filtered && filtered?.length > 0) {
      filtered = filtered?.filter(
        (prod) => extractId(prod?.country_id) == extractId(country)
      );

      filtered = filtered?.map((x) => {
        const connectedMarket = area?.market;

        let selectedObject = null;
        for (let i = 0; i < x?.priceByMarketId.length; i++) {
          const priceByMarket = x?.priceByMarketId[i];

          if (
            extractId(priceByMarket?.market_id) === extractId(connectedMarket)
          ) {
            selectedObject = priceByMarket;
            break;
          }
        }
        if (!selectedObject) {
          selectedObject = x?.priceByMarketId?.find(
            (priceByMarket) =>
              priceByMarket?.market_id?.name?.toLowerCase() === 'default'
          );
        }
        if (selectedObject) {
          return { ...x, priceByMarket: selectedObject };
        }
      });
      filtered = removeUndefinedOrNull(filtered);
      filtered = getRandomObjectsFromArray(filtered, 7);
    }

    return filtered;
  }, [savedItems]);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    className: 'center',
    centerPadding: '30px',
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Row className="p-2 bg-white py-3">
        <Col
          lg={12}
          md={12}
          sm={12}
          style={{
            paddingBottom: '24px',
          }}
        >
          <CustomText
            fontFamily={'Inter'}
            fontSize={18}
            fontWeight={600}
            text={'Saved Items'}
            // cNColor="gray"
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <div
            style={{
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
          >
            <Slider {...settings} className="slick-slider-wrapper">
              {filteredProducts.map((item) => (
                <div className="slider-product-item" key={item.id}>
                  {/* <Link to={`product/${item.id}`}> */}
                  <Product
                    type="carousel"
                    item={item}
                    wish={false}
                    url={`/product/${item.id}`}
                    loadingProducts={isLoading}
                  />
                  {/* </Link> */}
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SavedProducts;
