import { useScreenSize } from 'hooks/useScreenSize';
import { Col, Image } from 'react-bootstrap';
import { CustomText } from './CustomText';
const FeatureElement = ({ title, icon, text }) => {
  const { isMobile } = useScreenSize();
  return (
    <Col lg={3} md={3} sm={6} xs={6} className="text-center p-3">
      <Col>
        <Image src={icon} alt="" fluid style={{ height: isMobile ? 30 : 45 }} />
      </Col>
      <Col>
        <CustomText
          fontFamily={'Inter'}
          fontSize={16}
          fontWeight={400}
          text={title}
        />
      </Col>
      <Col>
        <CustomText
          fontFamily={'Inter'}
          fontSize={11}
          fontWeight={400}
          text={text}
        />
      </Col>
    </Col>
  );
};

export default FeatureElement;
