import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import SavedItems from './components/Sections';
import SavedProducts from './components/SavedProducts';
import ProfileSections from './components/ProfileSections';
import RecentlyViewed from './components/RecentlyViewed';
import Help from './components/Help';
import { CustomText } from 'components/CustomText';
import { ReactComponent as RecentlyViewedSvg } from 'assets/svgs/recentlyviewed.svg';
import { ReactComponent as CouponSvg } from 'assets/svgs/coupon.svg';
import { ReactComponent as NotificationSvg } from 'assets/svgs/notification.svg';
import { ReactComponent as LikeSvg } from 'assets/svgs/like.svg';
import { ReactComponent as LocationSvg } from 'assets/svgs/location.svg';
import { ReactComponent as HelpSvg } from 'assets/svgs/help-circle.svg';
function More() {
  return (
    <Tab.Container defaultActiveKey="notifications">
      <Row>
        <Col sm={3}>
          <Nav variant="tabs" id="profile" className="flex-column bg-white">
            <Nav.Item>
              <Nav.Link eventKey="notifications">
                <Row>
                  <Col lg={2} md={2} sm={2}>
                    <NotificationSvg />
                  </Col>
                  <Col lg={10} md={10} sm={10}>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Notifications"
                      // cNColor="gray"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="saved">
                <Row className="align-items-center">
                  <Col lg={2} md={2} sm={2}>
                    <LikeSvg />
                  </Col>
                  <Col lg={10} md={10} sm={10}>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Saved Products"
                      // cNColor="gray"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="vouchers">
                <Row>
                  <Col lg={2} md={2} sm={2}>
                    <CouponSvg />
                  </Col>
                  <Col lg={10} md={10} sm={10}>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Vouchers"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="shoppingArea">
                <Row>
                  <Col lg={2} md={2} sm={2}>
                    <LocationSvg />
                  </Col>
                  <Col lg={10} md={10} sm={10}>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Shopping Area"
                      // cNColor="gray"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="recentlyViewed">
                <Row>
                  <Col lg={2} md={2} sm={2}>
                    <RecentlyViewedSvg />
                  </Col>
                  <Col lg={10} md={10} sm={10}>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Recently Viewed"
                      // cNColor="gray"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="help">
                <Row>
                  <Col lg={2} md={2} sm={2}>
                    <HelpSvg />
                  </Col>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={13}
                      fontWeight={400}
                      text="Help"
                      // cNColor="gray"
                    />
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content className="bg-white">
            <Tab.Pane eventKey="notifications">
              <ProfileSections type={'notifications'} />
            </Tab.Pane>
            <Tab.Pane eventKey="saved">
              <ProfileSections type={'saved'} />
            </Tab.Pane>
            <Tab.Pane eventKey="vouchers">
              <ProfileSections type={'vouchers'} />
            </Tab.Pane>
            <Tab.Pane eventKey="recentlyViewed">
              <RecentlyViewed />
            </Tab.Pane>
            <Tab.Pane eventKey="help">
              <Help />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default More;
