import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { CustomText } from './CustomText';

const CustomNavIcon = ({
  onClick,
  address,
  text,
  icon,
  textSize = 11,
  textColor = 'text-secondary',
  lineColor = 'bg-secondary',
  style,
  className,
  textStyle,
  showLine = true,
}) => {
  return (
    <>
      {address ? (
        <NavLink to={address} onClick={onClick}>
          <Col
            className={`d-flex align-items-center ${
              className ? className : ''
            }`}
            style={style}
          >
            <div
              style={{
                width: 30,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: 5,
              }}
            >
              {icon}
            </div>
            <div className="colcentered">
              <CustomText
                text={text}
                fontSize={textSize}
                fontFamily={'Poppins'}
                fontWeight={500}
                cNColor={textColor}
                textStyle={textStyle}
                // cNColor={path === "/" ? "text-primary" : "text-secondary"}
              />
              {showLine && (
                <div
                  className={`bg-secondary ${lineColor ? lineColor : ''}`}
                  style={{ height: 1, width: 20, marginTop: 2 }}
                ></div>
              )}
            </div>
          </Col>
        </NavLink>
      ) : (
        <Col
          className={`d-flex align-items-center ${className ? className : ''}`}
          style={style}
          onClick={onClick}
        >
          <div
            style={{
              width: 30,
              height: 30,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginRight: 5,
            }}
          >
            {icon}
          </div>
          <div className="colcentered">
            <CustomText
              text={text}
              fontSize={textSize}
              fontFamily={'Poppins'}
              fontWeight={500}
              cNColor={textColor}
              textStyle={textStyle}
              // cNColor={path === "/" ? "text-primary" : "text-secondary"}
            />
            {showLine && (
              <div
                className={`bg-secondary ${lineColor ? lineColor : ''}`}
                style={{ height: 1, width: 20, marginTop: 2 }}
              ></div>
            )}
          </div>
        </Col>
      )}
    </>
  );
};

export default CustomNavIcon;
