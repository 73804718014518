// import node module libraries
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import custom components
// import { ReactComponent as Decrease } from "assets/svgs/minus.svg";
// import SavedProducts from "./components/SavedProducts";
import { ReactComponent as EmptyOrderSvg } from 'assets/svgs/emptyorder.svg';
import EmptyState from 'components/EmptyState';
import {
  deepCopy,
  isArrayNonEmpty,
  isSubstringInArray,
} from 'helper/formatArray';
import { convertJsonStringToObject } from 'helper/formatObject';
import { errorMessageFormat, formatCurrency } from 'helper/formatText';
import { show } from 'helper/toast';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentScreen } from 'redux/features/navigation/navigationSlice';
import { useGetUserOrdersMutation } from 'redux/features/user/userApi';
import { saveToUserStore } from 'redux/features/user/userSlice';
import OrderItems from './components/OrderItems';

const Orders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, countries, country } = useSelector((state) => state.userAuth);
  const { orders, stopOrdersReload } = useSelector((state) => state.userStore);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [getUserOrders, { isLoading: isLoadingGetUserOrders }] =
    useGetUserOrdersMutation();

  const fetchOrders = async () => {
    const res = await getUserOrders({ populate: false, selective: true });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'orders',
          value:
            convertJsonStringToObject(
              JSON.parse(JSON.stringify(res.data?.data))
            ) || [],
        })
      );
      dispatch(
        saveToUserStore({
          key: 'stopOrdersReload',
          value: true,
        })
      );

      setLoading(false);
    } else {
      setLoading(false);
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      history.push('/');
    }
  };

  useEffect(() => {
    dispatch(setCurrentScreen('Orders'));
    token && setLoading(false);
    setOpenSearch(false);
    token && !isLoadingGetUserOrders && fetchOrders();
  }, [token]);

  const handleRefetch = () => {
    setLoading(true);
    fetchOrders();
  };
  const filteredData = useMemo(() => {
    let filtered = (token && orders) || [];
    filtered = deepCopy(filtered);
    filtered = filtered?.map((x) => {
      return {
        ...x,
        orderId: `Order ID. ${x?.order_id}`,
        extAmount: formatCurrency(
          x?.total_amount,
          2,
          x?.country?.name?.toLowerCase()?.includes('nigeria')
            ? '₦'
            : x?.country?.currency?.symbol
        ),
        extOrderStatus: isSubstringInArray(x?.order_status, [
          'pending, shopped, open, accepted',
        ])
          ? 'pending'
          : isSubstringInArray(x?.order_status, ['intransit'])
          ? ['intransit', 'in transit']
          : isSubstringInArray(x?.order_status, ['canceled', 'cancelled'])
          ? ['canceled', 'cancelled']
          : x?.order_status,
        extTime: moment(x?.createdAt).format('ddd MMM DD, yyyy'),
        extItemNo: `${JSON.parse(x?.items)?.length} ${
          JSON.parse(x?.items)?.length > 1 ? 'Items' : 'Item'
        }`,
        extItemName: JSON.parse(x?.items)?.map((x) => {
          return x?.product_id?.name;
        }),
      };
    });
    if (searchValue !== '' && searchValue?.length > 0) {
      filtered = filtered?.filter(
        (x) =>
          x?.orderId?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          x?.extAmount?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          x?.country_id?.name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          x?.extTime?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          x?.extItemNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          isSubstringInArray(
            searchValue,
            Array.isArray(x?.extOrderStatus)
              ? x?.extOrderStatus
              : [x?.extOrderStatus]
          ) ||
          isSubstringInArray(searchValue, x?.extItemName || [])
      );
    }
    return filtered;
  }, [searchValue, country, orders]);

  const emptyOrder = !isArrayNonEmpty(orders);
  const emptyProps = {
    icon: <EmptyOrderSvg />,
    title: isLoadingGetUserOrders ? 'Loading orders' : 'No Orders',
    subtitle: isLoadingGetUserOrders
      ? ''
      : 'You have not placed any orders yet',
    btnText: 'Start Shopping',
    showBtn: !isLoadingGetUserOrders,
  };
  return emptyOrder ? (
    <EmptyState {...emptyProps} />
  ) : (
    <OrderItems
      items={filteredData}
      loading={loading || isLoadingGetUserOrders}
      handleRefetch={handleRefetch}
      searchValue={searchValue}
      handleSearchChange={(text) => setSearchValue(text)}
    />
  );
};

export default Orders;
