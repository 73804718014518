// import node module libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

const FooterSignin = () => {
  return (
    <Fragment>
      <div className="text-right p-2 bg-black text-white">Fooddy © 2022</div>
    </Fragment>
  );
};

export default FooterSignin;
