import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';

import { CustomText } from 'components/CustomText';

import { useScreenSize } from 'hooks/useScreenSize';

export const DeleteModal = ({
  visible,
  handleClose,
  item,
  handleConfirm,
  isLoading,
  title,
  subtitle,
}) => {
  const { isMobile, isMobileS, isTablet, isLaptop, isDesktop } =
    useScreenSize();

  return (
    <Modal show={visible} onHide={handleClose} centered size="md">
      <Modal.Header className="px-4 py-3 border-0" closeButton>
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text={title || 'Remove from Cart'}
        />
      </Modal.Header>
      <Modal.Body className="px-4 py-2 pb-3">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={14}
          text={
            subtitle ||
            `Are you sure you want to remove ${item?.name} from your cart?`
          }
          cNColor="text-secondary"
        />
      </Modal.Body>
      <Modal.Footer className="px-4 py-2 justify-content-center border-0">
        <Col className="d-flex justify-content-between">
          <Col sm="auto">
            <CustomButton
              text="Cancel"
              onClick={handleClose}
              variant="outline-secondary"
              fontFamily={'Poppins'}
            />
          </Col>
          <Col md="auto" className="d-flex justify-content-end">
            <CustomButton
              text="Confirm"
              onPress={handleConfirm}
              loading={isLoading}
              variant="primary"
              fontFamily={'Poppins'}
            />
          </Col>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
