// import node module libraries
// import custom components
import { ReactComponent as EmptyCartSvg } from 'assets/svgs/emptycart.svg';
// import data files
import EmptyState from 'components/EmptyState';

const EmptyCart = ({ loading }) => {
  const emptyProps = {
    icon: <EmptyCartSvg />,
    title: loading ? 'Loading cart items' : 'Cart is empty',
    subtitle: loading ? '' : 'Proceed to add items to your cart',
    btnText: 'Start Shopping',
    showBtn: !loading,
  };
  return <EmptyState {...emptyProps} />;
};

export default EmptyCart;
