import { Fragment, useMemo, useState } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link } from 'react-router-dom';
import { ReactComponent as DeleteSvg } from 'assets/svgs/delete.svg';
import { ReactComponent as NotificationSvg } from 'assets/svgs/notificationcard.svg';
import decrease from 'assets/svgs/minus.svg';
import increase from 'assets/svgs/plus.svg';
import Notifications from './Notifications';
import Vouchers from './Vouchers';
import { useScreenSize } from 'hooks/useScreenSize';
import CartItem from 'pages/cart/components/CartItem';
import { useSelector } from 'react-redux';
const Section = ({ id, type, ...item }) => {
  const { isMobile } = useScreenSize();
  const { country } = useSelector((state) => state.userAuth);

  return (
    <Fragment>
      {type === 'saved-items' ? (
        <CartItem key={id} type="saved-item" country={country} {...item} />
      ) : type === 'notifications' ? (
        <Notifications key={id} {...item} />
      ) : type === 'vouchers' ? (
        <Vouchers />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default Section;
