import { Route, Switch, Redirect } from 'react-router-dom';

import 'assets/scss/theme.scss';

import DefaultLayout from 'layouts/SigninLayout';

import { useSelector } from 'react-redux';
import Login from 'pages/auth/Login';
import DashboardIndex from 'layouts/DashboardIndex';
import Dashboard from 'pages/dashboard/Dashboard';
import LocalUsers from 'pages/users/LocalUsers';
import SignIn from './../pages/auth/SignIn';
import ProductDetails from 'pages/products/ProductDetails';
import ProductPageLayout from 'layouts/ProductPage';
import Cart from 'pages/cart/Cart';
import DefaultFooter from 'layouts/DefaultFooter';
import Checkout from 'pages/checkout/Checkout';
import Orders from 'pages/orders/Orders';
import More from 'pages/more/More';
import Profile from 'pages/profile/Profile';
import AuthLayout from 'layouts/AuthLayout';
import OrderIndex from 'layouts/OrderIndex';
import CheckOutLayout from 'layouts/CheckOutLayout';
import OrderDetails from 'pages/orders/components/OrderDetails';
import VerifyAccount from 'pages/auth/VerifyAccount';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import CategoryPage from 'pages/dashboard/CategoryPage';
import PrivacyPolicy from 'pages/more/components/PrivacyPolicy';
import TermsAndConditions from 'pages/more/components/TermsAndConditions';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

const ProtectedAppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const { user, token, isAuthenticated, role } = useSelector(
    (state) => state.userAuth
  );
  const { path } = rest;
  // console.log('user', user, isAuthenticated, path);
  return token ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      )}
    ></Route>
  ) : (
    <Redirect to="/login" />
  );
};

function AllRoutes() {
  return (
    <Switch>
      <AppRoute exact path="/login" layout={AuthLayout} component={SignIn} />
      <AppRoute exact path="/register" layout={AuthLayout} component={SignIn} />
      <AppRoute
        exact
        path="/forgot-password"
        layout={AuthLayout}
        component={ForgotPassword}
      />
      <AppRoute
        exact
        path="/verify-account"
        layout={AuthLayout}
        component={VerifyAccount}
      />
      <AppRoute
        exact
        path="/reset-password"
        layout={AuthLayout}
        component={ResetPassword}
      />
      <AppRoute
        exact
        path="/privacy-policy"
        layout={DashboardIndex}
        component={PrivacyPolicy}
      />
      <AppRoute
        exact
        path="/terms-and-conditions"
        layout={DashboardIndex}
        component={TermsAndConditions}
      />
      <AppRoute exact path="/" layout={DashboardIndex} component={Dashboard} />
      <AppRoute
        exact
        path="/product/:id"
        layout={DashboardIndex}
        component={ProductDetails}
      />
      <AppRoute
        exact
        path="/category/:id"
        layout={DashboardIndex}
        component={CategoryPage}
      />
      <AppRoute
        exact
        path="/category"
        layout={DashboardIndex}
        component={CategoryPage}
      />
      <ProtectedAppRoute
        exact
        path="/cart"
        layout={DashboardIndex}
        component={Cart}
      />
      <ProtectedAppRoute
        exact
        path="/orders"
        layout={DashboardIndex}
        component={Orders}
      />
      <ProtectedAppRoute
        exact
        path="/orders/:id"
        layout={DashboardIndex}
        component={OrderDetails}
      />
      <ProtectedAppRoute
        exact
        path="/checkout"
        layout={DashboardIndex}
        component={Checkout}
      />
      <ProtectedAppRoute
        exact
        path="/profile"
        layout={DashboardIndex}
        component={Profile}
      />
      <ProtectedAppRoute
        exact
        path="/saved-items"
        layout={DashboardIndex}
        component={Profile}
      />
      <ProtectedAppRoute
        exact
        path="/vouchers"
        layout={DashboardIndex}
        component={Profile}
      />
      <ProtectedAppRoute
        exact
        path="/change-password"
        layout={DashboardIndex}
        component={Profile}
      />
      <ProtectedAppRoute
        exact
        path="/notifications"
        layout={DashboardIndex}
        component={Profile}
      />
      <ProtectedAppRoute
        exact
        path="/help"
        layout={DashboardIndex}
        component={Profile}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default AllRoutes;
