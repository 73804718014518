import { Fragment } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { ReactComponent as FAQSvg } from 'assets/svgs/faqsvg.svg';
import { ReactComponent as TrackOrdersSvg } from 'assets/svgs/tracksvg.svg';
import { ReactComponent as ProfileOrdersSvg } from 'assets/svgs/profileorders.svg';
import { CustomText } from 'components/CustomText';
import chip from 'assets/pngs/chip.png';

import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { isArrayNonEmpty } from 'helper/formatArray';
import EmptySection from './EmptySection';
import { formatCurrency } from 'helper/formatText';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useEffect } from 'react';

const Vouchers = ({ loading, type, data }) => {
  const { isMobile } = useScreenSize();
  const { userVouchers, cart } = useSelector((state) => state.userStore);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 8;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(data.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    loading && changePage({ selected: 0 });
  }, [loading]);

  const displayItems = data
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((item) => {
      return (
        <Card className="mb-3 d-flex flex-row shadow">
          <Col
            lg={'auto'}
            md={'auto'}
            sm={'auto'}
            className="text-centerd-flex p-0 rowcentered"
            style={{
              height: 100,
              width: isMobile ? 100 : 160,
              paddingLeft: -1,
              backgroundColor:
                item?.value - item?.used_value < 1
                  ? 'rgba(83, 129, 53, 0.67)'
                  : '#538135',
            }}
          >
            <Col
              sm="auto"
              className="p-0 bg-white"
              style={{
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                height: 30,
                width: 24,
              }}
            ></Col>
            <Col>
              <CustomText
                fontFamily={'Montserrat'}
                fontSize={14}
                text={'Balance'}
                cNColor="text-white"
                className="mt-2"
              />
              <CustomText
                fontFamily={'Montserrat'}
                fontSize={15}
                fontWeight={600}
                text={formatCurrency(
                  item?.value - item?.used_value,
                  2,
                  item?.country_id?.currency?.symbol
                )?.replace('.00', '')}
                cNColor="text-white"
              />
            </Col>
          </Col>
          <Col
            className="text-left d-flex flex-column justify-content-center ps-3"
            style={{ height: 100 }}
          >
            <CustomText
              fontFamily={'Poppins'}
              fontSize={14}
              fontWeight={600}
              text={'Code: ' + String(item?.name)}
            />
            <CustomText
              fontFamily={'Poppins'}
              fontSize={12}
              text={
                'Initial value: ' +
                formatCurrency(
                  item?.value,
                  2,
                  item?.country_id?.currency?.symbol
                )?.replace('.00', '')
              }
              className="mt-2"
            />
          </Col>
        </Card>
      );
    });

  return (
    <Col className="px-4 py-3">
      {isArrayNonEmpty(data) ? (
        <>
          {displayItems.length > 0 ? (
            displayItems
          ) : (
            <Col>No matching results found.</Col>
          )}
          <ReactPaginate
            previousLabel={<ChevronLeft size="14px" />}
            nextLabel={<ChevronRight size="14px" />}
            pageCount={pageCount}
            onPageChange={changePage}
            marginPagesDisplayed={isMobile ? 1 : 3}
            pageRangeDisplayed={isMobile ? 1 : 2}
            containerClassName={'justify-content-center mb-0 pagination'}
            previousLinkClassName={'page-link mx-1 rounded'}
            nextLinkClassName={'page-link mx-1 rounded'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link mx-1 rounded'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'active text-white'}
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        <EmptySection
          type={type}
          loading={loading}
          loadingText={'Loading vouchers'}
        />
      )}
    </Col>
  );
};

export default Vouchers;
