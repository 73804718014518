import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { CustomText } from 'components/CustomText';
import { useFormik } from 'formik';
import { extractId } from 'helper/formatObject';
import { show } from 'helper/toast';
import { useScreenSize } from 'hooks/useScreenSize';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Form, Image, ListGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveUser } from 'redux/features/auth/authSlice';
import {
  useGetUserMutation,
  useUpdateUserMutation,
} from 'redux/features/user/userApi';
import * as Yup from 'yup';
const ProfileDetails = ({ initialValues, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userAuth);
  const { isMobile, isMobileS, isTablet, isLaptop, isDesktop } =
    useScreenSize();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: Yup.string()
      .matches(/^[\d+]+$/, 'Invalid phone number')
      .required('Required'),
  });
  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const [loadingImage, setLoadingImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const history = useHistory();

  const [getUser, { isLoading: isLoadingUser }] = useGetUserMutation();
  const [updateUser, { isLoading: isLoadingUpdateUser }] =
    useUpdateUserMutation();

  const fetchUser = async () => {
    const id = extractId(user);
    const res = await getUser(id);
    if (res?.data?.status === 'success') {
      dispatch(saveUser({ ...user, ...res.data?.data }));
    } else {
      show(dispatch, 'Failed to fetch profile, try signing in again', {
        type: 'error',
        duration: 4000,
      });
      history.push('/login');
    }
  };

  const handleUpdate = async (values) => {
    const id = extractId(user);
    const res = await updateUser({
      id: id,
      data: values,
    });
    if (res?.data?.status === 'success') {
      dispatch(saveUser({ ...user, ...res.data?.data?.data }));
      show(dispatch, 'Profile update successful', {
        type: 'success',
        duration: 4000,
      });
    } else {
      show(dispatch, 'Profile update failed', {
        type: 'error',
        duration: 4000,
      });
    }
    setIsEdit(false);
  };

  useEffect(() => {
    setLoadingImage(false);
    fetchUser();
  }, []);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
        let base64Img = reader.result;

        let data = {
          file: base64Img,
          upload_preset:
            user?.CLOUDINARY_UPLOAD_PRESET ||
            process.env.CLOUDINARY_UPLOAD_PRESET,
        };

        setLoadingImage(true);
        fetch(user?.CLOUDINARY_BASE_URL || process.env.CLOUDINARY_URL, {
          body: JSON.stringify(data),
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST',
        })
          .then(async (r) => {
            let data = await r.json();
            await handleUpdate({ picture: data?.url });
            setSelectedImage(data?.url);
            setLoadingImage(false);
          })
          .catch((err) => {
            show(dispatch, err?.error?.message || 'Image upload Failed', {
              type: 'error',
              placement: 'top',
              duration: 4000,
            });
            setSelectedImage(null);
            setLoadingImage(false);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const options = [
    {
      value: (
        <Col
          className={`${
            isMobile ? 'd-block my-3' : 'd-flex justify-content-between'
          }`}
        >
          <Col
            md={'auto'}
            sm={12}
            xs={12}
            className={`d-flex ${
              isMobile ? 'justify-content-center' : 'justify-content-start'
            }`}
          >
            {user?.picture ? (
              <Image
                src={selectedImage || user?.picture}
                style={{ width: 100, height: 100 }}
                className="rounded-circle"
              />
            ) : (
              <div
                className="text-muted me-lg-1 btn btn-light btn-icon rounded-circle avatar avatar-lg"
                style={{ height: 100, width: 100 }}
              >
                <i className="fe fe-user fs-2"></i>
              </div>
            )}
          </Col>
          <Col
            md={'auto'}
            sm={12}
            xs={12}
            className={`d-flex ${
              isMobile ? 'justify-content-center mt-2' : 'justify-content-end'
            }`}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <CustomButton
              loading={loadingImage}
              text={'Change'}
              fontSize={12}
              height={24}
              onClick={handleButtonClick}
            />
          </Col>
        </Col>
      ),
      label: 'Profile Picture',
    },
    {
      label: 'First Name',
      value: user?.firstName,
    },
    {
      label: 'Last Name',
      value: user?.lastName,
    },
    {
      label: 'Email Address',
      value: user?.email,
    },
    {
      label: 'Phone Number',
      value: user?.phoneNumber || 'N/A',
    },
  ];

  return (
    <Fragment>
      {isEdit ? (
        <Form>
          <Row className="px-5" style={{ paddingTop: '20px' }}>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={'text'}
                required={true}
                label={'First Name'}
                labelFontSize={13}
                id={'firstName'}
                variant="form"
                placeholder={'First name'}
                onChange={formik?.handleChange}
                error={formik?.errors?.firstName}
                value={formik?.values?.firstName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={'text'}
                required={true}
                label={'Last Name'}
                labelFontSize={13}
                id={'lastName'}
                placeholder={'Last name'}
                onChange={formik?.handleChange}
                error={formik?.errors?.lastName}
                value={formik?.values?.lastName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={'text'}
                required={true}
                id={'phoneNumber'}
                label={'Phone Number'}
                labelFontSize={13}
                placeholder={'Phone Number'}
                onChange={formik?.handleChange}
                error={formik?.errors?.phoneNumber}
                value={formik?.values?.phoneNumber}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={'email'}
                disabled={true}
                id={'email'}
                label={'Email address'}
                labelFontSize={13}
                placeholder={'Email address'}
                onChange={formik?.handleChange}
                error={formik?.errors?.email}
                value={formik?.values?.email}
              />
            </Col>
            <Col
              style={{ paddingTop: '15px', paddingBottom: '23px' }}
              className="d-flex justify-content-between"
            >
              <Col sm="auto">
                <CustomButton
                  text="Cancel"
                  onClick={() => setIsEdit(false)}
                  variant="outline-secondary"
                  fontFamily={'Poppins'}
                  disabled={isLoadingUpdateUser}
                />
              </Col>
              <Col sm="auto" className="d-flex justify-content-end">
                <CustomButton
                  text="Save Changes"
                  onClick={formik.handleSubmit}
                  variant="primary"
                  fontFamily={'Poppins'}
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                  loading={isLoadingUpdateUser}
                />
              </Col>
            </Col>
          </Row>
        </Form>
      ) : (
        options?.map((x) => (
          <ListGroup.Item
            className={`${
              x?.type !== 'row' ? 'd-block' : 'd-flex'
            } align-items-center justify-content-between py-2 px-4 ${
              x?.className ? x?.className : ''
            }`}
            style={{
              minHeight: 50,
              borderStyle: 'solid',
              borderWidth: '0px 0px 1px 0px',
              borderColor: '#f0f0f0',
            }}
          >
            {typeof x?.label === 'string' ? (
              <CustomText
                fontFamily={'Poppins'}
                fontSize={13}
                fontWeight={400}
                textClassName={'text-uppercase mb-1'}
                cNColor="text-secondary"
                textStyle={x?.labelStyle}
              >
                {x?.label}
              </CustomText>
            ) : (
              x?.label
            )}
            {typeof x?.value === 'string' ? (
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={500}
                textClassName={x?.valueClassName}
                textStyle={x?.valueStyle}
              >
                {x?.value}
              </CustomText>
            ) : (
              x?.value
            )}
          </ListGroup.Item>
        ))
      )}
    </Fragment>
  );
};

export default ProfileDetails;
