import { Fragment, useMemo, useState } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import CustomInput from 'components/CustomInput';
import { useScreenSize } from 'hooks/useScreenSize';
import { extractId } from 'helper/formatObject';
const SimilarProducts = ({ products }) => {
  const { isMobile, isLaptop, isDesktop } = useScreenSize();

  const settings = {
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    className: 'center',
    centerPadding: '30px',
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <Row className="p-2 bg-white py-3 mb-3">
      <Col
        lg={12}
        md={12}
        sm={12}
        style={{
          paddingBottom: '24px',
        }}
      >
        <CustomText
          fontFamily={'Inter'}
          fontSize={18}
          fontWeight={600}
          text={'Related products'}
          // cNColor="gray"
        />
      </Col>
      <Col lg={12} md={12} sm={12}>
        <div
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <Slider {...settings} className="slick-slider-wrapper">
            {products.map((item) => (
              <div className="slider-product-item" key={item.id}>
                <Product
                  type={'carousel'}
                  item={item}
                  url={`/product/${extractId(item)}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Col>
    </Row>
  );
};

export default SimilarProducts;
