import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBaseQuery';
import { BASE_URL } from '../url';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    logoutUser: builder.mutation({
      query: (data) => {
        return {
          url: 'customers/log-out',
          method: 'POST',
          body: data,
        };
      },
    }),
    updatePushToken: builder.mutation({
      query: (data) => {
        return {
          url: 'customers/push-token',
          method: 'POST',
          body: data,
        };
      },
    }),
    getUser: builder.mutation({
      query: (id) => ({
        url: `customers/${id}`,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `customers/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ id, data }) => ({
        url: `customers/update-password/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getTaxes: builder.mutation({
      query: (params) => ({
        url: `tax`,
        method: 'GET',
        params: params,
      }),
    }),
    getAllAreas: builder.mutation({
      query: (params) => ({
        url: `areas/all`,
        method: 'GET',
        params: params,
      }),
    }),
    getAreas: builder.mutation({
      query: (params) => ({
        url: `areas`,
        method: 'GET',
        params: params,
      }),
    }),
    getArea: builder.mutation({
      query: (id) => ({
        url: `areas/${id}`,
        method: 'GET',
      }),
    }),
    getCategories: builder.mutation({
      query: (params) => ({
        url: `categories`,
        method: 'GET',
        params: params,
      }),
    }),
    getCategory: builder.mutation({
      query: (id) => ({
        url: `categories/${id}`,
        method: 'GET',
      }),
    }),
    getProducts: builder.mutation({
      query: (params) => ({
        url: `products`,
        method: 'GET',
        params: params,
      }),
    }),
    getProduct: builder.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: 'GET',
      }),
    }),
    getProductsCategories: builder.mutation({
      query: (id) => ({
        url: `products/category/${id}`,
        method: 'GET',
      }),
    }),
    getUnrestrictedProducts: builder.mutation({
      query: (params) => ({
        url: `products/unrestricted`,
        method: 'GET',
        params: params,
      }),
    }),
    getUnrestrictedProduct: builder.mutation({
      query: (id) => ({
        url: `products/unrestricted/${id}`,
        method: 'GET',
      }),
    }),
    getMarkets: builder.mutation({
      query: (params) => ({
        url: `markets`,
        method: 'GET',
        params: params,
      }),
    }),
    getMarket: builder.mutation({
      query: (id) => ({
        url: `markets/${id}`,
        method: 'GET',
      }),
    }),
    getSupports: builder.mutation({
      query: (params) => ({
        url: `supports`,
        method: 'GET',
        params: params,
      }),
    }),
    getSupport: builder.mutation({
      query: (id) => ({
        url: `supports/${id}`,
        method: 'GET',
      }),
    }),
    getCountries: builder.mutation({
      query: (params) => ({
        url: `countries`,
        method: 'GET',
        params: params,
      }),
    }),
    getCountry: builder.mutation({
      query: (id) => ({
        url: `countries/${id}`,
        method: 'GET',
      }),
    }),
    getUoms: builder.mutation({
      query: (params) => ({
        url: `uoms`,
        method: 'GET',
        params: params,
      }),
    }),
    getUom: builder.mutation({
      query: (id) => ({
        url: `uoms/${id}`,
        method: 'GET',
      }),
    }),
    getDeliveryAddresses: builder.mutation({
      query: (params) => ({
        url: `delivery-addresses`,
        method: 'GET',
        params: params,
      }),
    }),
    updateDeliveryAddress: builder.mutation({
      query: ({ id, data }) => ({
        url: `delivery-addresses/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getDeliveryAddress: builder.mutation({
      query: (id) => ({
        url: `delivery-addresses/${id}`,
        method: 'GET',
      }),
    }),
    deleteDeliveryAddress: builder.mutation({
      query: (id) => ({
        url: `delivery-addresses/${id}`,
        method: 'DELETE',
      }),
    }),
    createDeliveryAddress: builder.mutation({
      query: (data) => ({
        url: `delivery-addresses`,
        method: 'POST',
        body: data,
      }),
    }),
    getPaymentMethods: builder.mutation({
      query: (params) => ({
        url: `payment-methods`,
        method: 'GET',
        params: params,
      }),
    }),
    getPaymentMethod: builder.mutation({
      query: (id) => ({
        url: `payment-methods/${id}`,
        method: 'GET',
      }),
    }),
    deletePaymentMethod: builder.mutation({
      query: (id) => ({
        url: `payment-methods/${id}`,
        method: 'DELETE',
      }),
    }),
    createPaymentMethod: builder.mutation({
      query: (data) => ({
        url: `payment-methods`,
        method: 'POST',
        body: data,
      }),
    }),
    updatePaymentMethod: builder.mutation({
      query: ({ id, data }) => ({
        url: `payment-methods/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getPriceByMarkets: builder.mutation({
      query: (params) => ({
        url: `price-by-markets`,
        method: 'GET',
        params: params,
      }),
    }),
    getPriceByMarket: builder.mutation({
      query: (id) => ({
        url: `price-by-markets/${id}`,
        method: 'GET',
      }),
    }),
    getSavedItems: builder.mutation({
      query: ({ area_id, country_id }) => ({
        url: `saved-items`,
        method: 'GET',
        params: { area_id, country_id },
      }),
    }),
    addToSavedItems: builder.mutation({
      query: (data) => ({
        url: `saved-items`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteSavedItem: builder.mutation({
      query: (data) => ({
        url: `saved-items`,
        method: 'DELETE',
        body: data,
      }),
    }),
    getCart: builder.mutation({
      query: (data) => ({
        url: `cart`,
        method: 'GET',
        params: data,
      }),
    }),
    getCartItemCount: builder.mutation({
      query: (data) => ({
        url: `cart/item-count`,
        method: 'GET',
        params: data,
      }),
    }),
    fetchCart: builder.query({
      query: (data) => ({
        url: `cart`,
        method: 'GET',
        params: data,
      }),
    }),
    addToCart: builder.mutation({
      query: (data) => ({
        url: `cart`,
        method: 'POST',
        body: data,
      }),
    }),
    addToTempCart: builder.mutation({
      query: (data) => ({
        url: `temp-cart`,
        method: 'POST',
        body: data,
      }),
    }),
    updateCartItemQuantity: builder.mutation({
      query: (data) => ({
        url: `cart/quantity`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteCartItem: builder.mutation({
      query: (data) => ({
        url: `cart`,
        method: 'DELETE',
        body: data,
      }),
    }),
    validateCheckout: builder.mutation({
      query: (data) => ({
        url: `orders/validate-checkout`,
        method: 'POST',
        body: data,
      }),
    }),
    createOrder: builder.mutation({
      query: (data) => ({
        url: `orders/checkout`,
        method: 'POST',
        body: data,
      }),
    }),
    getUserOrders: builder.mutation({
      query: (params) => ({
        url: `orders`,
        method: 'GET',
        params: params,
      }),
    }),
    getUserOrder: builder.mutation({
      query: (id) => ({
        url: `orders/${id}`,
        method: 'GET',
      }),
    }),
    verifyPaystackPayment: builder.mutation({
      query: (reference) => ({
        url: `paystack/verify/${reference}`,
        method: 'GET',
      }),
    }),
    createPaymentIntent: builder.mutation({
      query: (data) => ({
        url: 'stripe/intent',
        method: 'POST',
        body: data,
      }),
    }),
    getCanadaPostRates: builder.mutation({
      query: (data) => ({
        url: 'canada-post/get-rates',
        method: 'POST',
        body: data,
      }),
    }),
    getFreightComRates: builder.mutation({
      query: (data) => ({
        url: 'canada-post/freightcom/get-rates',
        method: 'POST',
        body: data,
      }),
    }),
    deleteAccount: builder.mutation({
      query: ({ id, password }) => {
        return {
          url: `customers/delete/${id}`,
          method: 'DELETE',
          body: { password },
        };
      },
    }),
    getVouchers: builder.mutation({
      query: ({ email, params }) => ({
        url: `vouchers/user/${email}`,
        method: 'GET',
        params: params,
      }),
    }),
    getUserVouchers: builder.mutation({
      query: ({ id, params }) => ({
        url: `vouchers/customer/${id}`,
        method: 'GET',
        params: params,
      }),
    }),
    getUserVouchersForOthers: builder.mutation({
      query: () => ({
        url: `vouchers/user/others`,
        method: 'GET',
      }),
    }),
    getUserVouchersShared: builder.mutation({
      query: () => ({
        url: `vouchers/user/shared`,
        method: 'GET',
      }),
    }),
    getUserVouchersSingle: builder.mutation({
      query: () => ({
        url: `vouchers/user/single`,
        method: 'GET',
      }),
    }),
    getUserVouchersAll: builder.mutation({
      query: () => ({
        url: `vouchers/user/all`,
        method: 'GET',
      }),
    }),
    getUserUsableVouchers: builder.mutation({
      query: () => ({
        url: `vouchers/user/usable`,
        method: 'GET',
      }),
    }),
    getVoucher: builder.mutation({
      query: (id) => ({
        url: `vouchers/${id}`,
        method: 'GET',
      }),
    }),
    createVoucher: builder.mutation({
      query: (data) => ({
        url: `vouchers`,
        method: 'POST',
        body: data,
      }),
    }),
    getNotifications: builder.mutation({
      query: (data) => ({
        url: `notification/user`,
        method: 'GET',
        params: data,
      }),
    }),
    updateNotification: builder.mutation({
      query: ({ id, data }) => ({
        url: `notification/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    markNotifications: builder.mutation({
      query: (data) => ({
        url: `notification/update-all`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getPublicApi: builder.mutation({
      query: (data) => ({
        url: `customers/public-api`,
        method: 'GET',
        params: data,
      }),
    }),
  }),
});

export const {
  useLogoutUserMutation,
  useUpdatePushTokenMutation,
  useGetUserMutation,
  useUpdateUserMutation,
  useGetTaxesMutation,
  useGetAllAreasMutation,
  useGetAreasMutation,
  useGetAreaMutation,
  useGetMarketsMutation,
  useGetMarketMutation,
  useGetSupportsMutation,
  useGetSupportMutation,
  useGetCountriesMutation,
  useGetCountryMutation,
  useGetPriceByMarketMutation,
  useGetUomsMutation,
  useGetUomMutation,
  useGetProductsMutation,
  useGetProductMutation,
  useGetProductsCategoriesMutation,
  useGetUnrestrictedProductsMutation,
  useGetUnrestrictedProductMutation,
  useGetCategoriesMutation,
  useGetCategoryMutation,
  useGetCartMutation,
  useGetCartItemCountMutation,
  useFetchCartQuery,
  useAddToCartMutation,
  useAddToTempCartMutation,
  useDeleteCartItemMutation,
  useUpdateCartItemQuantityMutation,
  useGetSavedItemsMutation,
  useAddToSavedItemsMutation,
  useDeleteSavedItemMutation,
  useUpdatePasswordMutation,
  useGetDeliveryAddressesMutation,
  useGetDeliveryAddressMutation,
  useCreateDeliveryAddressMutation,
  useUpdateDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useGetPaymentMethodsMutation,
  useGetPaymentMethodMutation,
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useValidateCheckoutMutation,
  useCreateOrderMutation,
  useGetUserOrdersMutation,
  useGetUserOrderMutation,
  useVerifyPaystackPaymentMutation,
  useCreatePaymentIntentMutation,
  useGetCanadaPostRatesMutation,
  useGetFreightComRatesMutation,
  useDeleteAccountMutation,
  useGetVoucherMutation,
  useGetVouchersMutation,
  useGetUserVouchersMutation,
  useGetUserUsableVouchersMutation,
  useGetUserVouchersForOthersMutation,
  useGetUserVouchersSharedMutation,
  useGetUserVouchersSingleMutation,
  useGetUserVouchersAllMutation,
  useCreateVoucherMutation,
  useGetNotificationsMutation,
  useGetPublicApiMutation,
  useMarkNotificationsMutation,
  useUpdateNotificationMutation,
} = userApi;
