import { Col, Container, Image, Row } from 'react-bootstrap';
import { ReactComponent as AppStoreSVG } from 'assets/svgs/appstore.svg';
import playStore from 'assets/pngs/googleplay.png';
import appStore from 'assets/pngs/appstore.png';
import logo from 'assets/pngs/logo2.png';
import { CustomText } from './CustomText';
import CustomInput from './CustomInput';
import CustomButton from './CustomButton';
import { Fragment } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import backgroundImage from 'assets/svgs/Illustrations.svg';

const Subscription = () => {
  const { isMobile, isMobileS, isMobileL } = useScreenSize();
  return (
    <div
      className="bg-primary"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container>
        <Row
          className="rowcentered"
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
            paddingLeft: '100px',
            paddingRight: '100px',
          }}
        >
          <Col>
            <Row className="rowcentered">
              <Col lg="auto">
                <Image
                  src={logo}
                  alt=""
                  fluid
                  style={{
                    maxHeight: 80,
                  }}
                />
              </Col>
              <Col>
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={16}
                  fontWeight={500}
                  text="Download the Fooddy App"
                  cNColor="text-white"
                />
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={13}
                  fontWeight={400}
                  text="And enjoy exclusive offers"
                  cNColor="text-white"
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg="auto"
            md={12}
            className={isMobile ? 'colcentered' : 'centered'}
          >
            <Row className="rowcentered">
              <Col>
                <a
                  href={
                    'https://play.google.com/store/apps/details?id=com.fooddy.customer'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={playStore}
                    alt=""
                    style={{ maxWidth: isMobileS && 140 }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  href={'https://apps.apple.com/ng/app/fooddy/id6450612842'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appStore}
                    alt=""
                    style={{ maxWidth: isMobileS && 140 }}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscription;
