import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  products: [],
  areas: [],
  uoms: [],
  uomsFilter: [],
  brandsFilter: [],
  markets: [],
  sortFilter: '',
  paymentMethods: [],
  pushToken: '',
  initialLoad: true,
  toastMessage: null,
  toastType: null,
  toastDuration: 4000,
};

export const userSlice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    saveInitiaLoad: (state, { payload }) => {
      state.initialLoad = 'true';
    },
    clearInitialLoad: (state, { payload }) => {
      state.initialLoad = '';
    },
    saveToUserStore: (state, { payload }) => {
      state[payload?.key] = payload.value;
    },
    saveToStore: (state, { payload }) => {
      state[payload[0]] = payload[1];
    },
    clearUserDetails: (state, { payload }) => {
      state.userVouchers = null;
      state.orders = null;
      state.cart = null;
      state.savedItems = null;
      state.userNotifications = [];
      state.unreadNotifications = [];
      state.unreadNotificationsCount = 0;
      state.cartItemCount = 0;
      state.pushToken = null;
      state.initialLoad = true;
    },
  },
});

export const {
  saveToUserStore,
  saveToStore,
  clearUserDetails,
  saveInitiaLoad,
  clearInitialLoad,
} = userSlice.actions;

export default userSlice.reducer;
