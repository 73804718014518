import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Image, Row, Spinner } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import deletePng from 'assets/pngs/delete.png';
import decrease from 'assets/svgs/minus.svg';
import increase from 'assets/svgs/plus.svg';
import { CustomCombinedText } from 'components/CustomCombinedText';
import { useScreenSize } from 'hooks/useScreenSize';
import CustomToast from 'components/CustomToast';
import CustomButton from 'components/CustomButton';
import { formatCurrency } from 'helper/formatText';
import { useDispatch, useSelector } from 'react-redux';
import { extractId } from 'helper/formatObject';
import { Link, useHistory } from 'react-router-dom';
import { isSubstringInArray } from 'helper/formatArray';
import { show } from 'helper/toast';
import { AddToCartModal } from 'components/AddToCartModal';

const CartItem = ({
  type,
  handleUpdateCartItemQuantity,
  country,
  isLoadingDeleteCartItem,
  isLoadingUpdateCartItemQuantity,
  selectedItem,
  setSelectedItem,
  deleteVisible,
  setDeleteVisible,
  itemsProcessing,
  setItemsProcessing,
  itemx,
  ...item
}) => {
  const { isMobile, isMobileS } = useScreenSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userAuth);

  const [quantity, setQuantity] = useState(itemx?.quantity?.toString() || '');

  useEffect(() => {
    setQuantity(itemx?.quantity?.toString());
  }, [itemx?.quantity]);

  const onQuantityChange = (text = 'not') => {
    if (!quantity) {
      setQuantity('1');
      handleUpdateCartItemQuantity({
        ...itemx,
        quantity: 1,
      });
    } else if (parseInt(quantity) >= 1) {
      handleUpdateCartItemQuantity({
        ...itemx,
        quantity: parseInt(quantity),
      });
    }
  };

  const [addModalVisible, setAddModalVisible] = useState(false);

  const handleAddToCartPress = useCallback(() => {
    if (token) {
      setAddModalVisible(true);
    } else {
      show(dispatch, 'Please sign in to allow us manage your cart', {
        type: 'warning',
        duration: 4000,
      });
    }
  }, []);

  return type === 'saved-item' ? (
    <>
      <AddToCartModal
        visible={addModalVisible}
        handleClose={() => setAddModalVisible(false)}
        item={item}
      />
      <Card
        style={{
          minHeight: '139px',
          paddingRight: '20px',
          paddingLeft: '20px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Row>
          <Col
            lg="auto"
            md="auto"
            sm="auto"
            className={isMobile ? 'w-100 colcentered' : ''}
            // onClick={() => history.push('/product/' + extractId(item), { item })}
          >
            <Image
              src={item?.imageCover}
              alt=""
              style={{ width: 120, height: 120, objectFit: 'contain' }}
              className={isMobile ? 'mx-auto d-block' : ''}
            />
          </Col>
          <Col
            className={`py-3 ${
              isMobile ? 'pb-0' : ''
            } px-2 d-flex flex-column align-items-start justify-content-between`}
          >
            <CustomText
              fontFamily={'Poppins'}
              fontSize={16}
              fontWeight={600}
              text={item?.name}
              // onClick={() =>
              //   history.push('/product/' + extractId(item), { item })
              // }
            />
            <CustomCombinedText
              firstFontFamily={'Poppins'}
              firstText="Unit of Measure: "
              firstFontWeight={300}
              firstFontSize={14}
              secondFontFamily={'Poppins'}
              secondFontWeight={500}
              secondFontSize={14}
              secondText={item?.priceByMarket?.uom_id?.name}
              // onClick={() =>
              //   history.push('/product/' + extractId(item), { item })
              // }
            />
            <CustomCombinedText
              firstFontFamily={'Poppins'}
              firstText="Price per Unit: "
              firstFontWeight={300}
              firstFontSize={14}
              secondFontFamily={'Poppins'}
              secondFontWeight={500}
              secondFontSize={14}
              secondText={formatCurrency(
                item?.priceByMarket?.price,
                2,
                country?.name?.toLowerCase()?.includes('nigeria')
                  ? '₦'
                  : country?.currency?.symbol
              )?.replace('.00', '')}
              // onClick={() =>
              //   history.push('/product/' + extractId(item), { item })
              // }
            />
          </Col>
          <Col
            lg="auto"
            md="auto"
            sm="auto"
            className={`${isMobile ? 'mt-0' : 'mt-3'} align-items-end`}
          >
            <CustomText
              fontFamily={'Poppins'}
              fontSize={16}
              fontWeight={600}
              text={formatCurrency(
                item?.priceByMarket?.price,
                2,
                country?.name?.toLowerCase()?.includes('nigeria')
                  ? '₦'
                  : country?.currency?.symbol
              ).replace('.00', '')}
              textStyle={{
                textAlign: 'right',
                marginBottom: isMobile ? 10 : 0,
              }}
            />
          </Col>
        </Row>
        <Card.Footer className={`p-2 border-0`}>
          <Row>
            <Col className="d-flex align-items-center">
              <Image
                src={deletePng}
                alt="delete"
                fluid
                style={{ height: 20 }}
              />
            </Col>
            <Col
              lg="auto"
              md="auto"
              sm="auto"
              xs={6}
              className="p-0 d-flex"
              style={{ justifyContent: 'flex-end' }}
            >
              <div className={`d-flex row-centered ${'justify-content-end'}`}>
                {type === 'saved-item' && (
                  <CustomButton
                    text={'Add to Cart'}
                    variant="primary"
                    style={{ height: 36 }}
                    onClick={handleAddToCartPress}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  ) : (
    <Card
      style={{
        minHeight: '139px',
        paddingRight: '20px',
        paddingLeft: '20px',
        marginTop: '16px',
        marginBottom: '16px',
      }}
    >
      <Row>
        <Col
          lg="auto"
          md="auto"
          sm="auto"
          className={isMobile ? 'w-100 colcentered' : ''}
          // onClick={() =>
          //   history.push('/product/' + itemx?.product_id, {
          //     item: {
          //       ...itemx,
          //       id: itemx?.product_id,
          //       _id: itemx?.product_id,
          //     },
          //   })
          // }
        >
          <Image
            src={itemx?.image}
            alt=""
            style={{ width: 120, height: 120, objectFit: 'contain' }}
            className={isMobile ? 'mx-auto d-block' : ''}
          />
        </Col>
        <Col
          className={`py-3 ${
            isMobile ? 'pb-0' : ''
          } px-2 d-flex flex-column align-items-start justify-content-between`}
        >
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={itemx?.name}
            // onClick={() =>
            //   history.push('/product/' + itemx?.product_id, {
            //     item: {
            //       ...itemx,
            //       id: itemx?.product_id,
            //       _id: itemx?.product_id,
            //     },
            //   })
            // }
          />
          <CustomCombinedText
            firstFontFamily={'Poppins'}
            firstText="Unit of Measure: "
            firstFontWeight={300}
            firstFontSize={14}
            secondFontFamily={'Poppins'}
            secondFontWeight={500}
            secondFontSize={14}
            secondText={itemx?.uom}
            // onClick={() =>
            //   history.push('/product/' + itemx?.product_id, {
            //     item: {
            //       ...itemx,
            //       id: itemx?.product_id,
            //       _id: itemx?.product_id,
            //     },
            //   })
            // }
          />
          <CustomCombinedText
            firstFontFamily={'Poppins'}
            firstText="Price per Unit: "
            firstFontWeight={300}
            firstFontSize={14}
            secondFontFamily={'Poppins'}
            secondFontWeight={500}
            secondFontSize={14}
            secondText={formatCurrency(
              itemx?.price,
              2,
              country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : country?.currency?.symbol
            ).replace('.00', '')}
            // onClick={() =>
            //   history.push('/product/' + itemx?.product_id, {
            //     item: {
            //       ...itemx,
            //       id: itemx?.product_id,
            //       _id: itemx?.product_id,
            //     },
            //   })
            // }
          />
        </Col>
        <Col
          lg="auto"
          md="auto"
          sm="auto"
          className={`${isMobile ? 'mt-0' : 'mt-3'} align-items-end`}
        >
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={formatCurrency(
              itemx?.quantity * itemx?.price,
              2,
              country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : country?.currency?.symbol
            ).replace('.00', '')}
            textStyle={{
              textAlign: 'right',
              marginBottom: isMobile ? 10 : 0,
            }}
          />
        </Col>
      </Row>
      <Card.Footer className={`p-2 border-0`}>
        <Row>
          <Col className="d-flex align-items-center">
            {isLoadingDeleteCartItem &&
            selectedItem?.uom + extractId(selectedItem) ==
              itemx?.uom + itemx?.product_id ? (
              <Spinner size="sm" variant="primary" />
            ) : (
              <Image
                src={deletePng}
                alt="delete"
                fluid
                style={{ height: 20 }}
                onClick={() => {
                  setSelectedItem(itemx);
                  setDeleteVisible(true);
                }}
              />
            )}
          </Col>
          <Col
            lg="auto"
            md="auto"
            sm="auto"
            xs={6}
            className="p-0 d-flex"
            style={{ justifyContent: 'flex-end' }}
          >
            <div className={`d-flex row-centered ${'justify-content-end'}`}>
              <div
                className="d-flex align-items-center"
                style={{ maxHeight: 36 }}
              >
                <Image
                  alt="-"
                  src={decrease}
                  fluid
                  style={{ height: 28 }}
                  onClick={() => {
                    if (
                      itemx?.quantity > 1 &&
                      !isLoadingUpdateCartItemQuantity &&
                      !isSubstringInArray(itemx.id, itemsProcessing)
                    ) {
                      setItemsProcessing([itemx?.product_id]);
                      handleUpdateCartItemQuantity({
                        ...itemx,
                        quantity: itemx?.quantity - 1,
                      });
                    }
                  }}
                />
                {isLoadingUpdateCartItemQuantity &&
                isSubstringInArray(itemx.id, itemsProcessing) ? (
                  <div
                    className="p-1 text-center"
                    style={{ width: 40, height: 28 }}
                  >
                    <Spinner size="sm" variant="primary" />
                  </div>
                ) : (
                  <Form.Control
                    className="p-1 text-center"
                    style={{ maxWidth: 40, height: 28 }}
                    type="number"
                    onChange={(e) => setQuantity(e?.target?.value)}
                    value={quantity}
                    placeholder={1}
                    onBlur={() => {
                      if (itemx?.quantity != quantity) {
                        onQuantityChange('textinput');
                      }
                    }}
                  />
                )}

                <Image
                  alt="increase"
                  src={increase}
                  onClick={() => {
                    if (
                      !isLoadingUpdateCartItemQuantity &&
                      !isSubstringInArray(itemx.id, itemsProcessing)
                    ) {
                      setItemsProcessing([itemx?.product_id]);
                      handleUpdateCartItemQuantity({
                        ...itemx,
                        quantity: itemx?.quantity + 1,
                      });
                    }
                  }}
                  disabled={
                    isLoadingUpdateCartItemQuantity &&
                    isSubstringInArray(itemx.id, itemsProcessing)
                  }
                  fluid
                  style={{ height: 28 }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default CartItem;
