import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from './Login';
import Register from './Register';
import { Fragment, useRef, useState } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import {
  saveToken,
  saveToUserAuth,
  saveUser,
} from 'redux/features/auth/authSlice';
import {
  useForgotPasswordByEmailMutation,
  useForgotPasswordByPhoneMutation,
  useVerifyOtpMutation,
  useVerifyTokenMutation,
} from 'redux/features/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
// import Login from "./Login";
import * as Yup from 'yup';
import { show } from 'helper/toast';
import { Formik } from 'formik';
import CustomInput from 'components/CustomInput';
import { errorMessageFormat, obfuscateEmail } from 'helper/formatText';

const ForgotPassword = (props) => {
  const [activeTab, setActiveTab] = useState('forgotPassword');
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.userAuth);
  const [maxPhoneLength, setMaxPhoneLength] = useState(11);
  const [selectedForm, setSelectedForm] = useState('email');
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
  });

  const [forgotPasswordByEmail, { isLoading }] =
    useForgotPasswordByEmailMutation();
  const [forgotPasswordByPhone, { isLoading: isLoadingPhone }] =
    useForgotPasswordByPhoneMutation();

  const handleForgotPassword = async (values) => {
    const resolveValues =
      selectedForm === 'email'
        ? { email: values.email }
        : { phoneNumber: values.phone };
    const res =
      selectedForm === 'email'
        ? await forgotPasswordByEmail(resolveValues)
        : await forgotPasswordByPhone(resolveValues);
    if (res?.data?.status === 'success') {
      show(dispatch, res?.data?.message, {
        type: 'success',
        placement: 'top',
        duration: 7000,
      });
      dispatch(saveUser(values));

      history.push('/verify-account', {
        formType: selectedForm,
        from: 'forgotpassword',
      });
    } else {
      show(
        dispatch,
        errorMessageFormat(res, 'Verification code failed to send'),
        {
          type: 'error',
          placement: 'top',
          duration: 4000,
        }
      );
    }
  };

  return (
    <Col style={{ marginTop: 60 }} xl={12} lg={12}>
      <Tab.Container
        id="verify"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
      >
        <Row
          className="d-flex justify-content-center"
          style={{ paddingBottom: 30 }}
        >
          <CustomButton
            onClick={() => {}}
            style={{
              marginRight: 5,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
          >
            <Col
              style={{ paddingBottom: activeTab == 'forgotPassword' ? 0 : 5 }}
            >
              <CustomText
                text="Forgot Password"
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={activeTab == 'forgotPassword' ? 20 : 18}
                cNColor="text-black"
              />
              {activeTab == 'forgotPassword' && (
                <Col className="d-flex justify-content-center align-items-center">
                  <TabLine />
                </Col>
              )}
            </Col>
          </CustomButton>
        </Row>
        <Col xl={12} lg={12}>
          <Tab.Content>
            <Tab.Pane eventKey={'forgotPassword'}>
              <Row
                className="align-items-center justify-content-center g-0"
                xl={12}
                lg={12}
              >
                <Col
                  className="px-6 py-1 align-items-md-center"
                  style={{ maxWidth: 500 }}
                >
                  <CustomText
                    text={
                      selectedForm === 'email'
                        ? 'Provide your registered email address to get the verification code'
                        : 'Provide your registered phone number to get the verification code'
                    }
                    fontFamily="Poppins"
                    fontWeight={500}
                    fontSize={14}
                    cNColor="text-secondary"
                    className="mb-2"
                  />
                  <Formik
                    initialValues={{
                      phone: '',
                      email: '',
                    }}
                    initialTouched={{
                      field: true,
                    }}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleForgotPassword(values)}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      isValid,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Row>
                        <Col lg={12} md={12}>
                          {/* Username or email */}
                          <CustomInput
                            type={'email'}
                            variant="auth"
                            required={true}
                            label={'Email address'}
                            id={'email'}
                            placeholder={'Enter your email address'}
                            onChange={handleChange}
                            // leftIcon={<EmailSvg />}
                            value={values.email}
                            errors={errors.email}
                            inputStyle={{
                              height: 42,
                              // marginRight: 24,
                              borderColor: '#d9d9d9',
                              borderRadius: 7,
                              paddingLeft: 24,
                            }}
                          />
                        </Col>

                        <Col lg={12} md={12} className="mb-3 text-end">
                          <Col className="mb-3">
                            <Link to="/login" className="text-primary">
                              Go back to sign in
                            </Link>
                          </Col>
                          <Col
                            style={{ paddingTop: '10px' }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <CustomButton
                              variant="primary"
                              type="submit"
                              fontWeight={'500'}
                              className="w-100"
                              text={'Send code'}
                              disabled={!isValid}
                              loading={isLoading || isLoadingPhone}
                              style={{ height: 45, marginBottom: 0 }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            />
                          </Col>
                        </Col>
                      </Row>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </Col>
  );
};

function LineComponent() {
  return <div className="active-line"></div>;
}

const TabLine = () => (
  <div
    style={{
      width: '36px',
      height: '5px',
      backgroundColor: '#000',
      borderRadius: '5px',
      marginTop: 3,
    }}
  ></div>
);

export default ForgotPassword;
