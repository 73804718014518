import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from './Login';
import Register from './Register';
import { Fragment, useRef, useState } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import {
  saveToAuth,
  saveToken,
  saveToUserAuth,
  saveUser,
} from 'redux/features/auth/authSlice';
import {
  useForgotPasswordByEmailMutation,
  useForgotPasswordByPhoneMutation,
  useVerifyOtpMutation,
  useVerifyTokenMutation,
} from 'redux/features/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
// import Login from "./Login";
import * as Yup from 'yup';
import { show } from 'helper/toast';
import { Formik } from 'formik';
import CustomInput from 'components/CustomInput';
import { obfuscateEmail } from 'helper/formatText';

const VerifyAccount = (props) => {
  const [activeTab, setActiveTab] = useState('verifyAccount');
  const route = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const STATUS = {
    STARTED: 'Started',
    STOPPED: 'Stopped',
  };

  const INITIAL_COUNT = 120;

  const formType = location?.state?.formType;
  const from = location?.state?.from;

  const [counter, setCounter] = useState(120);

  const dispatch = useDispatch();
  const { countries, user, end_time } = useSelector((state) => state.userAuth);
  const [maxPhoneLength, setMaxPhoneLength] = useState(11);
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;
  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .required('Code is required')
      .min(6, '6 digits expected')
      .max(6, '6 digits expected'),
  });

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(0);
      clearTimeout(timer);
    }
  }, [counter]);

  const [validateOnChange, setValidateOnChange] = useState(false);
  const [initial, setIntial] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [otpSent, setOtpSent] = useState(true);

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [verifyToken, { isLoading: isLoadingToken }] = useVerifyTokenMutation();
  const [forgotPasswordByEmail, { isLoading: isLoadingEmail }] =
    useForgotPasswordByEmailMutation();
  const [forgotPasswordByPhone, { isLoading: isLoadingPhone }] =
    useForgotPasswordByPhoneMutation();

  const handleResend = async (values) => {
    const resolveValues =
      formType === 'email'
        ? { email: user?.email }
        : { phoneNumber: user?.phone || user?.phoneNumber };
    const res =
      formType === 'email'
        ? await forgotPasswordByEmail(resolveValues)
        : await forgotPasswordByPhone(resolveValues);
    if (res?.data?.status === 'success') {
      show(
        dispatch,
        res?.data?.message || 'Verification code sent successfully',
        {
          type: 'success',
          placement: 'top',
          duration: 4000,
        }
      );
      handleReset();
    } else {
      show(
        dispatch,
        res?.error?.data?.message ||
          (typeof res?.error?.error == 'string' &&
            res?.error?.error?.replace('TypeError: ', '')) ||
          'Verification code failed to send',
        {
          type: 'error',
          placement: 'top',
          duration: 4000,
        }
      );
    }
  };

  useEffect(() => {
    handleStart();
  }, []);

  const handleStart = () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
    setIntial(INITIAL_COUNT);
    dispatch(
      saveToUserAuth({
        key: 'end_time',
        value: new Date().getTime() + INITIAL_COUNT * 1000,
      })
    );
  };

  const handleReset = () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(initial);
    setIntial(initial);
    dispatch(
      saveToUserAuth({
        key: 'end_time',
        value: new Date().getTime() + INITIAL_COUNT * 1000,
      })
    );
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        // setSecondsRemaining(secondsRemaining - 1);
        setSecondsRemaining(
          Math.floor(Math.max(0, end_time - new Date().getTime()) / 1000)
        );
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const twoDigits = (num) => String(num).padStart(2, '0');

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString()}:${twoDigits(minutes)}:${twoDigits(
      remainingSeconds
    )}`;
  };

  const handleVerifyOtp = async (values) => {
    const res =
      from == 'signup' || from == 'signin'
        ? await verifyToken(values)
        : await verifyOtp(values);
    if (res?.data) {
      if (from == 'signup' || from == 'signin') {
        dispatch(saveToken(res?.data?.token));
        dispatch(saveToAuth(['isLoggedIn', true]));
        dispatch(saveUser({ ...res?.data?.user, ...res?.data?.publicApi }));
        show(
          dispatch,
          from == 'signin'
            ? 'Account verified successfully'
            : 'Your signup was successful',
          {
            type: 'success',
            placement: 'top',
            duration: 4000,
          }
        );
        history.push('/');
      } else {
        history.push('/reset-password', { ...values });
      }
    } else {
      show(dispatch, 'Invalid Verification code', {
        type: 'error',
      });
    }
  };
  return (
    <Col style={{ marginTop: 60 }} xl={12} lg={12}>
      <Tab.Container
        id="verify"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
      >
        <Row
          className="d-flex justify-content-center"
          style={{ paddingBottom: 30 }}
        >
          <CustomButton
            onClick={() => {}}
            style={{
              marginRight: 5,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
          >
            <Col
              style={{ paddingBottom: activeTab == 'verifyAccount' ? 0 : 5 }}
            >
              <CustomText
                text="Verify Account"
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={activeTab == 'verifyAccount' ? 20 : 18}
                cNColor="text-black"
              />
              {activeTab == 'verifyAccount' && (
                <Col className="d-flex justify-content-center align-items-center">
                  <TabLine />
                </Col>
              )}
            </Col>
          </CustomButton>
        </Row>
        <Col xl={12} lg={12}>
          <Tab.Content>
            <Tab.Pane eventKey={'verifyAccount'}>
              <Row
                className="align-items-center justify-content-center g-0"
                xl={12}
                lg={12}
              >
                <Col
                  className="px-6 py-1 align-items-md-center"
                  style={{ maxWidth: 500 }}
                >
                  <CustomText
                    text={
                      formType === 'email' ||
                      from === 'signup' ||
                      from == 'signin'
                        ? `Provide the verification code sent to ${obfuscateEmail(
                            user?.email
                          )}.\nDon't forget to check your spam/junk folder, just in case`
                        : 'Provide the verification code sent to your registered phone number'
                    }
                    fontFamily="Poppins"
                    fontWeight={500}
                    fontSize={14}
                    cNColor="text-secondary"
                    className="mb-2"
                  />
                  <Formik
                    initialValues={{ token: '' }}
                    validationSchema={validationSchema}
                    validateOnChange={validateOnChange}
                    onSubmit={(values) => handleVerifyOtp(values)}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      isValid,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Row>
                        <Col lg={12} md={12} style={{ marginBottom: '5px' }}>
                          {/* Username or email */}
                          <CustomInput
                            type={'token'}
                            variant="auth"
                            required={true}
                            label={'Verification Code'}
                            id={'token'}
                            placeholder={'Enter verification code'}
                            onChange={handleChange}
                            // leftIcon={<EmailSvg />}
                            value={values?.token}
                            error={errors?.token}
                            inputStyle={{
                              height: 42,
                              // marginRight: 24,
                              borderColor: '#d9d9d9',
                              borderRadius: 7,
                              paddingLeft: 24,
                            }}
                          />
                        </Col>

                        <Col lg={12} md={12} className="mb-3 text-end">
                          <Col className="mb-3">
                            <Link to="/login" className="text-primary">
                              Go back to sign in
                            </Link>
                          </Col>
                          <Col
                            style={{ paddingTop: '10px' }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <CustomButton
                              variant="primary"
                              type="submit"
                              fontWeight={'500'}
                              className="w-100"
                              text={'Verify'}
                              disabled={!isValid || isLoadingEmail}
                              loading={isLoading || isLoadingToken}
                              style={{ height: 45, marginBottom: 0 }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            />
                          </Col>
                          {!isLoading && !isLoadingToken && !isLoadingEmail && (
                            <Row className="centered mt-3">
                              {otpSent && (
                                <CustomText
                                  fontSize={13}
                                  fontFamily={'Poppins'}
                                >
                                  {secondsRemaining === 0
                                    ? 'Didn’t receive code?'
                                    : 'Resend in'}
                                </CustomText>
                              )}
                              <CustomButton
                                type="button"
                                fontWeight={'500'}
                                className="border-0"
                                text={
                                  otpSent &&
                                  (secondsRemaining === 0
                                    ? 'Resend'
                                    : formatTime(secondsRemaining))
                                }
                                buttonTextColor={
                                  secondsRemaining === 0
                                    ? 'text-primary'
                                    : 'text-secondary'
                                }
                                loading={isLoadingEmail || isLoadingPhone}
                                style={{
                                  height: 20,
                                  marginBottom: 0,
                                  backgroundColor: 'transparent',
                                }}
                                onPress={handleResend}
                                disabled={secondsRemaining !== 0}
                              />
                              {/* <Button
                                bgColor={COLORS?.white}
                                textColor={
                                  secondsRemaining === 0
                                    ? COLORS?.primary
                                    : COLORS?.primarytext
                                }
                                height={'18px'}
                                style={{
                                  width: secondsRemaining === 0 ? 80 : 60,
                                }}
                                text={
                                  otpSent &&
                                  (secondsRemaining === 0
                                    ? 'Resend'
                                    : formatTime(secondsRemaining))
                                }
                                textSize={13}
                                textTop={0}
                                align={'flex-start'}
                                fontFamily={fonts.PoppinsMedium}
                                onPress={handleResend}
                                disabled={secondsRemaining !== 0}
                              /> */}
                            </Row>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </Col>
  );
};

function LineComponent() {
  return <div className="active-line"></div>;
}

const TabLine = () => (
  <div
    style={{
      width: '36px',
      height: '5px',
      backgroundColor: '#000',
      borderRadius: '5px',
      marginTop: 3,
    }}
  ></div>
);

export default VerifyAccount;
