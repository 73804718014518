import { Fragment, useMemo } from 'react';
import cabbage from 'assets/pngs/cabbage.png';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import Naira from 'react-naira';
import man from 'assets/pngs/man.png';
import CustomButton from 'components/CustomButton';
import Product from 'components/Product';
import { Link } from 'react-router-dom';
import Section from './Section';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import ReactPaginate from 'react-paginate';
import { extractId } from 'helper/formatObject';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  removeUndefinedOrNull,
  sortedDataByCreatedAt,
} from 'helper/formatArray';
import { useEffect } from 'react';

const Sections = ({ type, loading }) => {
  const { isMobile } = useScreenSize();
  const { area, country } = useSelector((state) => state.userAuth);
  const { savedItems, userNotifications } = useSelector(
    (state) => state.userStore
  );
  const [searchTerm, setSearchTerm] = useState('');
  const data = useMemo(() => {
    let filtered = [];
    if (type === 'notifications') {
      filtered = userNotifications;
      filtered = sortedDataByCreatedAt(filtered, 'desc');
      filtered = filtered?.map((x) => {
        return {
          ...x,
          screen:
            x?.data?.screen && typeof x?.data?.screen === 'string'
              ? isSubstringInArray('Order', [x?.data?.screen])
                ? isArrayNonEmpty(x?.body?.match(/\b[fdngFDNG]+\d+\b/))
                  ? 'OrderDetails'
                  : 'Orders'
                : x?.data?.screen
              : 'Categories',
          order_id:
            isSubstringInArray('Order', [
              x?.data?.screen && typeof x?.data?.screen === 'string'
                ? x?.data?.screen
                : 'Categories',
            ]) &&
            isArrayNonEmpty(x?.body?.match(/\b[fdngFDNG]+\d+\b/)) &&
            x?.body?.match(/\b[fdngFDNG]+\d+\b/)[0],
        };
      });
    }
    if (type === 'saved-items') {
      filtered = savedItems?.items;
      if (filtered && filtered?.length > 0) {
        filtered = filtered?.filter(
          (prod) => extractId(prod?.country_id) == extractId(country)
        );

        filtered = filtered?.map((x) => {
          const connectedMarket = area?.market;

          let selectedObject = null;
          if (area?.market) {
            for (let i = 0; i < x?.priceByMarketId.length; i++) {
              const priceByMarket = x?.priceByMarketId[i];

              if (
                extractId(priceByMarket?.market_id) ===
                extractId(connectedMarket)
              ) {
                selectedObject = priceByMarket;
                break;
              }
            }
          }
          if (!selectedObject) {
            selectedObject = x?.priceByMarketId?.find(
              (priceByMarket) =>
                priceByMarket?.market_id?.name?.toLowerCase() === 'default'
            );
          }
          if (selectedObject) {
            return { ...x, priceByMarket: selectedObject };
          }
        });
        filtered = removeUndefinedOrNull(filtered);
      }
    }
    if (searchTerm) {
      filtered = filtered.filter((student) => {
        return Object.values(student)
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    }
    return filtered;
  }, [type, country, area, userNotifications, searchTerm, savedItems?.items]);

  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 8;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(data.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    loading && changePage({ selected: 0 });
  }, [loading]);

  const displayItems = data
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((data) => {
      return <Section key={data.id} type={type} id={data.id} {...data} />;
    });

  return (
    <Col className="px-4 py-3">
      {displayItems.length > 0 ? (
        displayItems
      ) : (
        <Col>No matching results found.</Col>
      )}
      <ReactPaginate
        previousLabel={<ChevronLeft size="14px" />}
        nextLabel={<ChevronRight size="14px" />}
        pageCount={pageCount}
        onPageChange={changePage}
        marginPagesDisplayed={isMobile ? 1 : 3}
        pageRangeDisplayed={isMobile ? 1 : 2}
        containerClassName={'justify-content-center mb-0 pagination'}
        previousLinkClassName={'page-link mx-1 rounded'}
        nextLinkClassName={'page-link mx-1 rounded'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link mx-1 rounded'}
        disabledClassName={'paginationDisabled'}
        activeClassName={'active text-white'}
        renderOnZeroPageCount={null}
      />
    </Col>
  );
};

export default Sections;
