import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from './Login';
import Register from './Register';
import { Fragment, useState } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
// import Login from "./Login";

const Signin = (prop) => {
  const [activeTab, setActiveTab] = useState('signIn');
  const route = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    setActiveTab(route?.path === '/register' ? 'register' : 'signIn');
  }, [route]);

  return (
    <Col
      style={{ marginTop: 40, paddingTop: 20, paddingBottom: 20 }}
      xl={12}
      lg={12}
      // className="bg-white"
    >
      <Tab.Container
        id="login"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
      >
        <Row
          className="d-flex justify-content-center"
          style={{ paddingBottom: 40 }}
        >
          <CustomButton
            onClick={() => history.push('/login')}
            style={{
              width: 100,
              marginRight: 5,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
          >
            <Col style={{ paddingBottom: activeTab == 'signIn' ? 0 : 5 }}>
              <CustomText
                text="Sign in"
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={activeTab == 'signIn' ? 20 : 18}
                cNColor={
                  activeTab == 'signIn' ? 'text-black' : 'text-secondary'
                }
              />
              {activeTab == 'signIn' && (
                <Col className="d-flex justify-content-center align-items-center">
                  <TabLine />
                </Col>
              )}
            </Col>
          </CustomButton>
          <CustomButton
            onClick={() => history.push('/register')}
            style={{
              width: 100,
              marginRight: 5,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
          >
            <Col style={{ paddingBottom: activeTab == 'register' ? 0 : 5 }}>
              <CustomText
                text="Register"
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={activeTab == 'register' ? 20 : 18}
                cNColor={
                  activeTab == 'register' ? 'text-black' : 'text-secondary'
                }
              />
              {activeTab == 'register' && (
                <Col className="d-flex justify-content-center align-items-center">
                  <TabLine />
                </Col>
              )}
            </Col>
          </CustomButton>
        </Row>
        <Col xl={12} lg={12}>
          <Tab.Content>
            <Tab.Pane eventKey={'signIn'}>
              <Login />
            </Tab.Pane>
            <Tab.Pane eventKey={'register'}>
              <Register />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </Col>
  );
};

function LineComponent() {
  return <div className="active-line"></div>;
}

const TabLine = () => (
  <div
    style={{
      width: '36px',
      height: '5px',
      backgroundColor: '#000',
      borderRadius: '5px',
      marginTop: 3,
    }}
  ></div>
);

const SigninX = (prop) => {
  return (
    <Col>
      <Tabs id="login" className="justify-content-center">
        <Tab eventKey="signIn" title="Sign in">
          <Col style={{ position: 'relative' }}>
            <Col style={{ position: 'absolute', left: '45%', top: '-1%' }}>
              <div
                style={{
                  width: '36px',
                  height: '5px',
                  backgroundColor: '#000',
                  borderRadius: '5px',
                }}
              ></div>
            </Col>
            <Login />
          </Col>
        </Tab>
        <Tab eventKey="register" title="Register">
          <Col style={{ position: 'relative' }}>
            <Col style={{ position: 'absolute', right: '45%', top: '-1%' }}>
              <div
                style={{
                  width: '36px',
                  height: '5px',
                  backgroundColor: '#000',
                  borderRadius: '5px',
                }}
              ></div>
            </Col>
            <Register />
          </Col>
        </Tab>
      </Tabs>
    </Col>
  );
};

export default Signin;
