import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { ReactComponent as DebitCardSvg } from 'assets/svgs/debitCard.svg';
import { ReactComponent as CreditCardSvg } from 'assets/svgs/creditCard.svg';
import { ReactComponent as CashSvg } from 'assets/svgs/cash-stack.svg';
import { ReactComponent as CheckSvg } from 'assets/svgs/checked.svg';
import { ReactComponent as UncheckSvg } from 'assets/svgs/unchecked.svg';
import { CustomText } from 'components/CustomText';
import {
  areArraysEqual,
  deepCopy,
  isSubstringInArray,
} from 'helper/formatArray';
import { capitalize, errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { useGetPaymentMethodsMutation } from 'redux/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';

export const PaymentMethodModal = ({
  visible,
  setVisible,
  selectedMethod,
  setSelectedMethod,
  excludeMethods,
  tempCountry,
}) => {
  const dispatch = useDispatch();
  const { user, country: countryInit } = useSelector((state) => state.userAuth);
  const country = tempCountry || countryInit;
  const { paymentMethods } = useSelector((state) => state.userStore);
  const [initialLoad, setInitialLoad] = useState(true);

  const closeModal = () => {
    setVisible(false);
  };
  const [
    getPaymentMethods,
    { isLoading: isLoadingPaymentMethods, isFetching },
  ] = useGetPaymentMethodsMutation();

  const fetchPaymentMethods = async () => {
    const res = await getPaymentMethods();
    if (res?.data?.status === 'success') {
      !areArraysEqual(deepCopy(paymentMethods), deepCopy(res.data?.data), [
        'id',
        'name',
        'status',
      ]) &&
        dispatch(
          saveToUserStore({
            key: 'paymentMethods',
            value: deepCopy(res.data?.data),
          })
        );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
    setInitialLoad(false);
  };

  useEffect(() => {
    if (visible) {
      fetchPaymentMethods();
    }
  }, [visible]);

  const options = useMemo(() => {
    let filtered = paymentMethods || [];
    filtered = filtered?.filter((x) => x?.status);
    if (isSubstringInArray(country?.name, ['nigeria'])) {
      filtered = filtered?.filter(
        (x) => !isSubstringInArray(x?.name, ['stripe'])
      );
    }
    if (!isSubstringInArray(country?.name, ['nigeria'])) {
      filtered = filtered?.filter((x) =>
        isSubstringInArray(x?.name, ['stripe'])
      );
    }
    if (excludeMethods) {
      filtered = filtered?.filter(
        (x) =>
          !isSubstringInArray(
            x?.name,
            Array.isArray(excludeMethods) ? excludeMethods : [excludeMethods]
          )
      );
    }
    filtered = filtered?.map((x) => {
      return {
        ...x,
        altname: capitalize(
          isSubstringInArray(x?.name, ['stripe', 'paystack'])
            ? 'Card / Online'
            : x?.name
        ),
        name: capitalize(x?.name),
        svg: isSubstringInArray(x?.name, ['paystack', 'stripe']) ? (
          <DebitCardSvg />
        ) : isSubstringInArray(x?.name, ['voucher']) ? (
          <VoucherSvg />
        ) : isSubstringInArray(x?.name, ['cash']) ? (
          <CashSvg />
        ) : isSubstringInArray(x?.name, ['pay4me']) ? (
          <Pay4MeSvg />
        ) : (
          <CreditCardSvg />
        ),
      };
    });
    // if (filtered?.length === 1) {
    //   setSelectedMethod(filtered[0]);
    //   dispatch(
    //     saveToUserStore({
    //       key: 'currentPaymentMethod',
    //       value: { ...filtered[0], svg: '' },
    //     })
    //   );
    // }
    return filtered;
  }, [paymentMethods]);

  return (
    <Modal show={visible} onHide={closeModal} centered>
      <Modal.Title className="px-4 py-4 rowcentered">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Select Payment Method"
        />
      </Modal.Title>
      <Modal.Body className="px-4 py-1">
        {isLoadingPaymentMethods ? (
          <div style={{ height: 180, width: '100%' }} className="centered">
            <Spinner variant="primary" />
          </div>
        ) : (
          <div
            style={{ maxHeight: window.innerHeight * 0.75, overflowY: 'auto' }}
            className="px-2"
          >
            {options?.map((option) => {
              return (
                <div
                  className="border border-2 border-primary rounded py-3 px-3 rowcentered mb-2"
                  onClick={() => {
                    setSelectedMethod(option);
                    dispatch(
                      saveToUserStore({
                        key: 'currentPaymentMethod',
                        value: { ...option, svg: '' },
                      })
                    );
                  }}
                >
                  <div className="d-flex align-items-center">
                    {option?.svg}
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      text={option?.altname || option?.name}
                      divClassName="ps-3"
                    />
                  </div>
                  {selectedMethod?.name == option?.name ? (
                    <CheckSvg />
                  ) : (
                    <UncheckSvg />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="px-4 py-4 justify-content-center p-1">
        <CustomButton
          variant="primary"
          type="submit"
          text="Confirm"
          className={'w-100'}
          onClick={closeModal}
        />
      </Modal.Footer>
    </Modal>
  );
};
