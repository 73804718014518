// import node module libraries
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { ReactComponent as SearchYellowSvg } from 'assets/svgs/search_yellow.svg';
import CustomButton from 'components/CustomButton';
import { Form } from 'react-bootstrap';
import { useScreenSize } from 'hooks/useScreenSize';

const GlobalFilter = ({ filter, setFilter, placeholder, className }) => {
  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <Form className={`d-flex align-items-center ${className ? className : ''}`}>
      <span className="position-absolute ps-3 search-icon">
        <SearchYellowSvg />
      </span>

      <Form.Control
        type="Search"
        id="formSearch"
        className="ps-6"
        placeholder={placeholder || 'Search'}
        style={
          isLaptop || isDesktop
            ? {
                height: 40,
                width: 400,
              }
            : { height: 40 }
        }
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </Form>
  );
};

export default GlobalFilter;
