// import node module libraries
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Image,
  Card,
} from 'react-bootstrap';


import { CustomText } from 'components/CustomText';
import { useScreenSize } from 'hooks/useScreenSize';

const CategoryCard = ({ item, minWidth }) => {
  const { isMobileL } = useScreenSize();

  return (
    <Card
      className="shadow pb-3"
      id="product-card"
      style={{ maxWidth: '222px', minWidth: minWidth }}
    >
      {/* Card body  */}
      <Card.Body className="centered">
        <Image
          src={item?.image}
          alt=""
          fluid
          className="img-fluid"
          style={{
            height: isMobileL ? '90px' : '110px',
            objectFit: 'contain',
          }}
        />
      </Card.Body>
      {/* Card Footer */}
      <Card.Footer
        className="border-primary"
        style={{ paddingTop: '11px', paddingBottom: '11px', borderWidth: 2 }}
      >
        <CustomText
          fontFamily={'Poppins'}
          fontSize={14}
          fontWeight={500}
          text={item?.name}
          // cNColor="gray"
          textClassName="text-center"
        />
      </Card.Footer>
    </Card>
  );
};

// Specifies the default values for props
CategoryCard.defaultProps = {
  free: false,
  viewby: 'grid',
  showprogressbar: false,
  extraclass: '',
};

// Typechecking With PropTypes
CategoryCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
};

export default CategoryCard;
