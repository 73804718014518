import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as AddSvg } from 'assets/svgs/add.svg';
import { ReactComponent as BankSvg } from 'assets/svgs/bank.svg';
import { ReactComponent as VoucherSvg } from 'assets/svgs/vouchers.svg';
import { ReactComponent as Pay4MeSvg } from 'assets/svgs/pay4me.svg';
import { CustomText } from 'components/CustomText';
import { useScreenSize } from 'hooks/useScreenSize';
import { ShowAddress } from './AddDeliveryAddressModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { show } from 'helper/toast';
import { capitalize, errorMessageFormat } from 'helper/formatText';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { useGetDeliveryAddressesMutation } from 'redux/features/user/userApi';

import { ReactComponent as EmptySvg } from 'assets/svgs/emptySavedItems.svg';

import EmptyState from 'components/EmptyState';
import { isArrayNonEmpty } from 'helper/formatArray';

import { ReactComponent as CheckSvg } from 'assets/svgs/checked.svg';
import { ReactComponent as UncheckSvg } from 'assets/svgs/unchecked.svg';
import { extractId } from 'helper/formatObject';

import { ReactComponent as SearchYellowSvg } from 'assets/svgs/search_yellow.svg';

export const DeliveryAddressModal = ({
  visible,
  setVisible,
  selectedAddress,
  setSelectedAddress,
  initialAddress,
  setInitialAddress,
  addDeliveryVisible,
  setAddDeliveryVisible,
  addressModalOpened,
  setAddressModalOpened,
}) => {
  const { isMobile, isMobileS } = useScreenSize();
  const dispatch = useDispatch();
  const {
    user,
    area: areaInit,
    country: countryInit,
    token,
    tempArea,
    tempCountry,
  } = useSelector((state) => state.userAuth);
  const country = tempCountry || countryInit;
  const area = tempCountry ? tempArea : areaInit;
  const { deliveryAddresses } = useSelector((state) => state.userStore);
  const [selected, setSelected] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const [getDeliveryAddresses, { isLoading: isLoadingDeliveryAddresses }] =
    useGetDeliveryAddressesMutation();

  const fetchDeliveryAddresses = async () => {
    const res = await getDeliveryAddresses({
      customer_id: user.id,
      country: country?.name,
    });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({ key: 'deliveryAddresses', value: res.data?.data })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    visible && fetchDeliveryAddresses();
  }, [user, refetch, visible]);

  const closeModal = () => {
    setVisible(false);
  };

  const EmptyComponent = () => {
    const emptyProps = {
      title: isLoadingDeliveryAddresses
        ? 'Loading delivery addresses'
        : 'No delivery address',
      subtitle: isLoadingDeliveryAddresses
        ? ''
        : 'Proceed to add a new delivery address',
      showBtn: false,
    };
    return <EmptyState {...emptyProps} />;
  };

  const options = useMemo(() => {
    let filtered = deliveryAddresses || [];

    if (area?.name) {
      filtered = filtered?.filter((x) => x?.state == area?.state_name);
      filtered = filtered?.filter((x) => x?.city == area?.name);
    }
    filtered = filtered?.filter(
      (x) => x?.country?.toLowerCase() == country?.name?.toLowerCase()
    );
    filtered = filtered?.filter((x) => x?.customer_id?.id == user?.id);
    filtered = filtered?.map((x) => {
      return {
        ...x,
        title: capitalize(x?.tag),
        name: capitalize(x?.contact_name),
        address:
          capitalize(x?.street_address) +
          (x?.apartment ? ', ' + x?.apartment : ',') +
          (x?.city ? '\n' + capitalize(x?.city) : '') +
          (x?.state ? (!x?.city ? '\n ' : ', ') + capitalize(x?.state) : '') +
          ', ' +
          capitalize(x?.country),
        phoneNumber: x?.contact_phone,
      };
    });
    return filtered;
  }, [deliveryAddresses, area, country]);

  const handleAddNew = async (option) => {
    setInitialAddress(null);
    setAddressModalOpened(true);
    closeModal();
    setAddDeliveryVisible(!addDeliveryVisible);
  };
  const handleEdit = async (option) => {
    setInitialAddress(option);
    setAddressModalOpened(true);
    closeModal();
    setAddDeliveryVisible(!addDeliveryVisible);
  };

  const handleOnSelect = async (option) => {
    dispatch(
      saveToUserStore({
        key: 'currentDeliveryMethod',
        value: {
          name: 'Door Delivery',
          altname: 'Door Delivery',
          svg: <></>,
        },
      })
    );
    dispatch(
      saveToUserStore({
        key: 'currentDeliveryAddress',
        value: option,
      })
    );
    setAddressModalOpened(false);
    closeModal();
  };
  return (
    <Modal show={visible} onHide={closeModal} centered>
      <Modal.Title className="px-4 py-4 rowcentered">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={16}
          fontWeight={600}
          text="Select Delivery Area"
        />
        <CustomText
          fontFamily={'Poppins'}
          fontSize={14}
          fontWeight={500}
          cNColor="text-primary"
          onPress={() => {
            handleAddNew();
          }}
        >
          ADD NEW +
        </CustomText>
      </Modal.Title>
      <Modal.Body className="px-4 py-1">
        {isLoadingDeliveryAddresses ? (
          <div style={{ height: 180, width: '100%' }} className="centered">
            <Spinner variant="primary" />
          </div>
        ) : (
          <div
            style={{ maxHeight: window.innerHeight * 0.75, overflowY: 'auto' }}
            className="px-2"
          >
            {!isArrayNonEmpty(options) ? (
              <EmptyComponent />
            ) : (
              options?.map((option) => {
                return (
                  <div
                    className="border-bottom py-2 rowcentered"
                    onClick={() => setSelected(option)}
                  >
                    <div className="d-flex flex-column align-items-start">
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        fontWeight={600}
                        text={option?.title}
                      />
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        text={option?.name}
                        cNColor="text-secondary"
                      />
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        text={option?.address}
                        cNColor="text-secondary"
                      />
                      {option?.postal_code && (
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          text={option?.postal_code}
                          cNColor="text-secondary"
                        />
                      )}
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        text={option?.phoneNumber}
                        cNColor="text-secondary"
                      />
                      {option?.additional_info && (
                        <>
                          <CustomText
                            removeView={true}
                            fontFamily={'Poppins'}
                            fontSize={14}
                          >
                            Delivery Instructions:
                          </CustomText>
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            cNColor="text-secondary"
                          >
                            {option?.additional_info}
                          </CustomText>
                        </>
                      )}

                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        text={option?.phoneNumber}
                        cNColor="text-primary"
                        onPress={() => {
                          handleEdit(option);
                        }}
                      >
                        EDIT
                      </CustomText>
                    </div>
                    {selectedAddress?.id == option?.id ||
                    selected?.id == option?.id ? (
                      <CheckSvg />
                    ) : (
                      <UncheckSvg />
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}
      </Modal.Body>
      {!isLoadingDeliveryAddresses && isArrayNonEmpty(options) && (
        <Modal.Footer className="px-4 py-4 justify-content-center p-1">
          <CustomButton
            variant="primary"
            type="submit"
            text="Confirm"
            className={'w-100'}
            onClick={() => handleOnSelect(selected)}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
