// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Card, Dropdown, Carousel, Form } from 'react-bootstrap';

import EmptyCart from './components/EmptyCart';
import CartItems from './components/CartItems';
import { useDispatch, useSelector } from 'react-redux';
import {
  isArrayNonEmpty,
  isSubstringInArray,
  removeObjectFromArray,
  removeStringFromArray,
  updateObjects,
} from 'helper/formatArray';
import { saveArea, saveToUserAuth } from 'redux/features/auth/authSlice';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { errorMessageFormat } from 'helper/formatText';
import { setCurrentScreen } from 'redux/features/navigation/navigationSlice';
import { extractId } from 'helper/formatObject';
import { show } from 'helper/toast';
import {
  useDeleteCartItemMutation,
  useGetAreaMutation,
  useGetCartMutation,
  useUpdateCartItemQuantityMutation,
} from 'redux/features/user/userApi';
import { useHistory } from 'react-router-dom';

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, countries, country, area, pushToken } = useSelector(
    (state) => state.userAuth
  );

  const { cart, market } = useSelector((state) => state.userStore);
  const [refetch, setRefetch] = useState(false);
  const [itemsProcessing, setItemsProcessing] = useState([]);
  const [mov, setMov] = useState(0);

  const [getCart, { isLoading: isLoadingGetCart }] = useGetCartMutation();
  const [deleteCartItem, { isLoading: isLoadingDeleteCartItem }] =
    useDeleteCartItemMutation();

  const [getArea, { isLoading: isLoadingArea }] = useGetAreaMutation();

  const fetchArea = async () => {
    const res = await getArea(extractId(area));
    if (res?.data?.status === 'success') {
      dispatch(saveArea(res.data?.data));
    } else {
      show(
        dispatch,
        errorMessageFormat(
          res,
          'Delivery area not found, reselect your delivery area'
        ),
        {
          type: 'error',
          duration: 4000,
        }
      );
      history.push('/');
    }
  };
  const fetchCart = async () => {
    setItemsProcessing([]);
    const res = await getCart({
      area_id: area?.id,
      country_id: country?.id,
      pushToken,
    });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'cart', value: res?.data?.data || [] }));
      dispatch(
        saveToUserStore({
          key: 'cartItemCount',
          value: res?.data?.data?.cart_items?.length || 0,
        })
      );
      const unavailableItems = res?.data?.data?.cart_items?.filter(
        (x) => !x?.product_id || x?.product_id?.available !== 'yes'
      );
      isArrayNonEmpty(unavailableItems) &&
        unavailableItems?.forEach(async (item) => {
          await handleDeleteCartItem({
            id: extractId(item),
            cart_id: extractId(res?.data?.data),
          });
        });
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 2000,
      });
      history.push('/');
    }
  };

  const handleDeleteCartItem = async ({
    id: cart_item_id,
    cart_id: cart_id,
  }) => {
    let remaining = removeObjectFromArray(
      [...cart?.cart_items],
      'id',
      cart_item_id
    );
    // dispatch(
    //   saveToUserStore({
    //     key: 'cart',
    //     value: {
    //       ...cart,
    //       cart_items: remaining,
    //     },
    //   })
    // );
    const res = await deleteCartItem({ cart_item_id, cart_id });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'cart', value: res.data?.data }));
      dispatch(
        saveToUserStore({
          key: 'cartItemCount',
          value: res?.data?.data?.cart_items?.length || 0,
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 2000,
      });
    }
  };

  useEffect(() => {
    dispatch(setCurrentScreen('Cart'));
    dispatch(saveToUserAuth({ key: 'tempArea', value: null }));
    dispatch(saveToUserAuth({ key: 'tempCountry', value: null }));
    dispatch(saveToUserStore({ key: 'currentDeliveryMethod', value: null }));
    dispatch(saveToUserStore({ key: 'currentDeliveryAddress', value: null }));
    dispatch(saveToUserStore({ key: 'currentPaymentMethod', value: null }));
    dispatch(saveToUserStore({ key: 'currentPaymentRef', value: null }));
    dispatch(saveToUserStore({ key: 'currentVoucher', value: null }));
  }, []);

  useEffect(() => {
    extractId(area) &&
      isSubstringInArray(country?.name, ['nigeria']) &&
      area?.market?.minimum_order_value === undefined &&
      fetchArea();
  }, [area]);

  useEffect(() => {
    if (!extractId(area) && isSubstringInArray(country?.name, ['nigeria'])) {
      // navigation.navigate('Categories');
      show(dispatch, 'Delivery area not found, please select one', {
        type: 'error',
        duration: 4000,
      });
    } else if (!isSubstringInArray(country?.name, ['nigeria'])) {
      dispatch(saveArea(null));
    }
    if (token) {
      token && fetchCart();
      if (extractId(area) && isSubstringInArray(country?.name, ['nigeria'])) {
        dispatch(
          saveToUserStore({
            key: 'market',
            value: area?.market,
          })
        );
        setMov(area?.market?.minimum_order_value);
      }
    }
  }, [token, refetch, area, country]);

  useEffect(() => {
    if (!isSubstringInArray(country?.name, ['nigeria'])) {
      dispatch(saveToUserAuth({ key: 'area', value: null }));
    }
  }, [area, country]);

  const emptyCart = !isArrayNonEmpty(cart?.cart_items);
  return emptyCart ? (
    <EmptyCart loading={isLoadingGetCart || isLoadingArea} />
  ) : (
    <CartItems items={cart?.cart_items} />
  );
};

export default Cart;
