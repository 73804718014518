import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';

import { CustomText } from 'components/CustomText';

import { useScreenSize } from 'hooks/useScreenSize';
import {
  extractObjectWithMarketId,
  removeDuplicates,
} from 'helper/formatArray';
import { extractId } from 'helper/formatObject';
import { show } from 'helper/toast';
import { errorMessageFormat, formatCurrency } from 'helper/formatText';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAddToCartMutation } from 'redux/features/user/userApi';

import { ReactComponent as FilledCheck } from 'assets/svgs/checked.svg';
import { ReactComponent as EmptyCheck } from 'assets/svgs/unchecked.svg';

import decrease from 'assets/svgs/minus.svg';
import increase from 'assets/svgs/plus.svg';

export const AddToCartModal = ({
  visible,
  handleClose,
  item,
  title,
  subtitle,
}) => {
  const { isMobile, isMobileS, isTablet, isLaptop, isDesktop } =
    useScreenSize();
  const dispatch = useDispatch();
  const { user, area, country } = useSelector((state) => state.userAuth);
  const [selected, setSelected] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(1);
  const [disableBtn, setDisableBtn] = useState(false);

  const [addToCart, { isLoading: isLoadingAddToCart }] = useAddToCartMutation();

  const handleAddToCart = async () => {
    const res = await addToCart({
      product_id: extractId(item),
      area_id: extractId(area),
      country_id: extractId(country),
      quantity: quantity,
      name: item?.name,
      priceByMarket: selected,
      totalAmount: price,
    });
    if (res?.data?.status === 'success') {
      show(dispatch, `${item?.name} has been added to cart`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(saveToUserStore({ key: 'cart', value: res?.data?.data || [] }));
      res?.data?.data?.cart_items?.length &&
        dispatch(
          saveToUserStore({
            key: 'cartItemCount',
            value: res?.data?.data?.cart_items?.length || 0,
          })
        );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const handleConfirm = async () => {
    await handleAddToCart();
    handleClose();
  };

  useEffect(() => {
    visible && selected?.price && setPrice(quantity * selected?.price);
  }, [quantity, selected?.price]);

  const filteredProduct = useMemo(() => {
    let filtered = item || null;
    if (visible) {
      let extrPriceByMarket = [];
      for (
        let i = 0;
        i <
        removeDuplicates(
          filtered?.priceByMarketId?.map((x) => extractId(x?.uom_id))
        ).length;
        i++
      ) {
        const uom_id = removeDuplicates(
          filtered?.priceByMarketId?.map((x) => extractId(x?.uom_id))
        )[i];
        const extr = extractObjectWithMarketId(
          filtered?.priceByMarketId,
          uom_id,
          extractId(area?.market)
        );
        extrPriceByMarket?.push(extr);
      }
      if (extrPriceByMarket?.length > 0) {
        setSelected(extrPriceByMarket[0]);
        setQuantity(1);
        setPrice(extrPriceByMarket[0]?.price * quantity);
      }

      filtered = { ...filtered, extrPriceByMarket };
    }
    return filtered;
  }, [area, item, visible]);

  const onQuantityChange = () => {
    if (!quantity) {
      setQuantity('1');
    } else if (parseInt(quantity) >= 1) {
      setQuantity(parseInt(quantity));
    }
    setDisableBtn(false);
  };

  const handlePress = useCallback((x) => {
    setSelected(x);
  }, []);

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      centered
      size="md"
      className="p-3"
    >
      <Modal.Header className="pt-4 border-0">
        <Col className="d-flex">
          <Col
            md="auto"
            sm={'auto'}
            xs={12}
            className={isMobile ? 'colcentered' : ''}
          >
            <Image
              src={item?.imageCover}
              alt=""
              style={{ width: 80, height: 100, objectFit: 'contain' }}
              className={isMobile ? 'mx-auto d-block' : ''}
            />
          </Col>
          <Col className="mt-3 mt-md-3 ms-md-2">
            <CustomText
              fontFamily={'Poppins'}
              fontSize={16}
              fontWeight={600}
              text={item?.name}
            />
            <span className="d-flex">
              <CustomText
                fontFamily={'Poppins'}
                // text={'Price per Unit:&nbsp;'}
                cNColor="text-secondary"
                fontSize={14}
              >
                Price per Unit:&nbsp;
              </CustomText>
              <CustomText
                fontFamily={'Poppins'}
                text={formatCurrency(
                  selected?.price,
                  2,
                  country?.currency?.symbol
                )}
                fontSize={14}
              />
            </span>
          </Col>
          <Col
            md="auto"
            sm={'auto'}
            xs="auto"
            className="mt-3 d-flex justify-content-end"
          >
            <CustomText
              fontFamily={'Poppins'}
              fontSize={18}
              fontWeight={600}
              text={formatCurrency(price, 2, country?.currency?.symbol)}
            />
          </Col>
        </Col>
      </Modal.Header>
      <Modal.Body className="px-4 py-2 pb-3">
        {filteredProduct &&
          filteredProduct?.extrPriceByMarket?.map((x, index) => (
            <UomItem
              x={x}
              index={index}
              handlePress={handlePress}
              selectedId={extractId(selected)}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          ))}
      </Modal.Body>
      <Modal.Footer className="px-4 pb-4 pt-0 justify-content-center border-0">
        <CustomButton
          text="Add to Cart"
          onPress={handleConfirm}
          loading={isLoadingAddToCart}
          variant="primary"
          fontFamily={'Poppins'}
          className="w-100"
        />
      </Modal.Footer>
    </Modal>
  );
};

const UomItem = memo(
  ({
    x,
    index,
    width,
    selectedId,
    handlePress,
    quantity,
    setQuantity,
    isMobile,
  }) => (
    <Col className="d-flex mb-3">
      <Col md="auto" className="me-2" onClick={() => handlePress(x)}>
        {selectedId == extractId(x) ? <FilledCheck /> : <EmptyCheck />}
      </Col>
      <Col className="mt-0 me-2">
        <CustomText
          fontFamily={'Poppins'}
          fontSize={14}
          fontWeight={600}
          text={`${x?.uom_id?.name}`}
        />
        <CustomText
          fontFamily={'Poppins'}
          text={`${x?.uom_id?.description}`}
          cNColor="text-secondary"
          fontSize={12}
        />
      </Col>
      <Col md="auto" className="mt-0">
        <div className={`d-flex row-centered ${'justify-content-end'}`}>
          <div className="d-flex align-items-center" style={{ maxHeight: 36 }}>
            <Image
              alt="-"
              src={decrease}
              fluid
              style={{ height: 28 }}
              onClick={() => quantity > 1 && setQuantity(quantity - 1)}
            />

            <Form.Control
              className="p-1 text-center"
              style={{ maxWidth: 40, height: 28 }}
              type="number"
              onChange={(e) => setQuantity(e?.target?.value)}
              value={quantity}
              placeholder={1}
            />

            <Image
              alt="increase"
              src={increase}
              onClick={() => setQuantity(quantity + 1)}
              fluid
              style={{ height: 28 }}
            />
          </div>
        </div>
      </Col>
    </Col>
  )
);
