import { Col, Container, Row } from 'react-bootstrap';
import GreatValuePng from 'assets/svgs/great-value.svg';
import SafePaymentPng from 'assets/svgs/safe-payment.svg';
import ShopBetterPng from 'assets/svgs/shop-better.svg';
import QuickDeliveryPng from 'assets/svgs/quick-delivery.svg';
import FeatureElement from './FeaturesElemet';
import { Fragment } from 'react';
import backgroundImage from 'assets/svgs/Illustrations.svg';

import { useScreenSize } from 'hooks/useScreenSize';
const featureItems = [
  {
    title: 'Great Value',
    text: 'We have the best price for every food item',
    icon: GreatValuePng,
  },
  {
    title: 'Safe Payment',
    text: 'Pay with the world’s most popular and secure payment methods',
    icon: SafePaymentPng,
  },
  {
    title: 'Quick Delivery',
    text: 'Fast and reliable delivery to your door step',
    icon: QuickDeliveryPng,
  },
  {
    title: 'Shop Better',
    text: 'We have an amazing shopping experience',
    icon: ShopBetterPng,
  },
];

const containerStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'repeat',
};

const Features = () => {
  const { isMobile } = useScreenSize();
  return (
    <div className="bg-white">
      <Container>
        <Row style={{ background: 'white', padding: isMobile ? 10 : 24 }}>
          {featureItems.map((item) => {
            return (
              <FeatureElement
                title={item.title}
                text={item.text}
                icon={item.icon}
              />
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Features;
