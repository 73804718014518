import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { show } from 'helper/toast';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/CustomButton';

const StripeCheckoutForm = ({ handleSuccess, loading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/checkout',
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      show(dispatch, error.message, {
        type: 'error',
        duration: 7000,
        position: 'top',
      });
    } else {
      handleSuccess();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div>{errorMessage}</div>}
      <PaymentElement />
      <CustomButton
        className={'w-100 mt-3'}
        text="Confirm"
        variant="primary"
        type="submit"
        fontWeight={500}
        fontSize={14}
        style={{
          boxShadow:
            '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        }}
        onClick={handleSubmit}
        disabled={!stripe}
        loading={loading}
      />
      {/* Show error message to your customers */}
    </form>
  );
};

export default StripeCheckoutForm;
