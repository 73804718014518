import { Card, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { CustomText } from 'components/CustomText';
import { useScreenSize } from 'hooks/useScreenSize';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = () => {
  const { isDesktop, isLaptop } = useScreenSize();
  const history = useHistory();

  return (
    <Container>
      <Row className="mb-3 mt-3 mt-lg-0 mt-md-0 bg-white p-3">
        <Col xs={12} className="d-flex align-items-center py-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={'Terms and Conditions'}
            // cNColor="gray"
          />
        </Col>
        <Col xs={12}>
          <FormattedParagraph
            type={'heading'}
            text={'Introduction'}
            noLine
            newLine={false}
          />
          <FormattedParagraph
            type={'body'}
            text={`This document is an electronic record in terms of National Information Technology Development Agency Act, 2007 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the National Information Technology Development Agency Act, 2007. This electronic record is generated by a computer system and does not require any physical or digital signatures. 

Your use of the Fooddy.ng website www.Fooddy.ng (hereinafter referred to as "Fooddy.ng.") and tools are governed by the following terms and conditions as applicable to Fooddy.ng. If you transact on Fooddy.ng, you shall be subject to the policies that are applicable to the website for such transaction. By mere use of the website you shall be contracting with Fooddy Nigeriar Limited, a company incorporated under Companies and Allied Matters Act, 2004 with head office at 12A Mabinuori Crescent, Gbagada, Lagos, Nigeria and these terms and conditions constitute your binding obligations. 

For the purpose of these terms of use, wherever the context so require ‘You’ shall mean any natural or legal person who has agreed to become a member of the Website by providing Registration Data while registering on the Website as a Registered User. 

When you use any of the services provided by Fooddy.ng, including but not limited to Product and Service Discovery, Purchases and Customer Reviews; you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such services, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. Fooddy.ng reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Use, Fooddy Online Shopping Limited grants you a personal, non-exclusive, non-transferable, limited privilege to access and use the Site. 

ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Membership Eligibility '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Use of the Fooddy.ng Website is available only to persons who can form legally binding contracts under Nigerian governing laws. If you are a minor i.e.under the age of 18 years, you shall not register as a member of Fooddy.ng and shall not transact or use Fooddy.ng website. As a minor if you wish to use or transact on 

        Fooddy.ng, such use or transaction may be made by your legal guardian or parents who have registered as users of Fooddy.ng. Fooddy.ng reserves the right to terminate your membership and refuse to provide you with access to Fooddy.ng if it is brought to Fooddy Online Shopping Limited’s notice or if it is discovered that you are under the age of 18 years. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Your Account and Registration Obligations '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`If you use Fooddy.ng, you shall be responsible for maintaining the confidentiality of your User ID and Password and you shall be responsible for all activities that occur under your User ID and Password. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete that Fooddy.ng has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, Fooddy.ng has the right to indefinitely suspend or terminate or block access of your membership with Fooddy.ng and refuse to provide you with access to the Website. `}
          />
          <FormattedParagraph type={'heading'} text={'Communications'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`When You use the Website or send emails or other data, information or communication to Fooddy.ng, You agree and understand that You are communicating with Fooddy.ng through electronic records and You consent to receive communications via electronic records from Fooddy.ng periodically and as and when required. Fooddy.ng may communicate with You by email or by such other mode of communication, electronic or otherwise. `}
          />
          <FormattedParagraph type={'heading'} text={'Charges'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Membership on Fooddy.ng is free. Fooddy.ng does not charge any fee for browsing and buying on Fooddy.ng. However, Fooddy.ng reserves the right to charge a fee and change its policies from time to time. In particular, Fooddy.ng may at its sole discretion introduce new services and modify some or all of the existing services offered on Fooddy.ng. In such an event Fooddy.ng reserves, without notice to You, the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee and related policies shall automatically become effective immediately once implemented on Fooddy.ng. Unless otherwise stated, all fees shall be quoted in Nigerian Naira. You shall be solely responsible for compliance of all applicable laws including those in Nigeria for making payments to Fooddy.ng `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Use of Fooddy.ng Website '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`You agree, undertake and confirm that your use of Fooddy.ng shall be strictly governed by the following binding principles: You shall not host, display, upload, modify, publish, transmit, update or share any information that: `}
          />
          <FormattedParagraph
            type={'body'}
            text={`Belongs to another person and to which you do not have any right 

        Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" or misleading in any way. 

Is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual; 

Harasses or advocates harassment of another person; 

Involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing or "spamming"; 

Promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous; 

Infringes upon or violates any third party's rights [(including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity]; 

Promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy- protect devices, or providing pirated music or links to pirated music files; 

Contains restricted or password -only access pages, or hidden pages or images (those not linked to or from another accessible page); 

Provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone; 

Provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;(l) Contains video, photographs, or images of another person age 18 or older without his or her express written consent and permission or those of any minor (regardless of whether you have consent from the minor or his or her legal guardian). 

Contains video, photographs, or images of another person age 18 or older without his or her express written consent and permission or those of any minor (regardless of whether you have consent from the minor or his or her legal guardian). 

Tries to gain unauthorized access or exceeds the scope of authorized access (as defined herein and in other applicable Codes of Conduct or End User Access and License Agreements) to the Sites or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Sites or solicits passwords or personal identifying information for commercial or unlawful purposes from other users; 

Engages in commercial activities and/or sales without Fooddy's prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" items related to the Sites. Throughout this Terms of Use, Fooddy "prior written consent" means a communication coming from Fooddy Legal department, specifically in response to your request, and specifically addressing the activity or conduct for which you seek authorization; 

Solicits gambling or engages in any gambling activity which Fooddy, in its sole discretion, believes is or could be construed as being illegal; 

Interferes with another user's use and enjoyment of Fooddy.ng Website or any other individual's user and enjoyment of similar services; 

Refers to any website or URL that, in the sole discretion of Fooddy, contains material that is inappropriate for the Fooddy.ng Website or any other Website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use. 

Harm minors in any way; 

Infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen items; 

Violates any law for the time being in force; 

Deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; 

Impersonate another person; 

Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information; 

Threatens the unity, integrity, defence, security or sovereignty of The Federal Republic of Nigeria, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation 

Shall not be false, inaccurate or misleading; 

Shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force. 

Shall not create liability for us or cause us to lose (in whole or in part) the services of our ISPs or other suppliers; 

You shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Website. Fooddy reserves the right to bar any such activity. 

You shall not attempt to gain unauthorized access to any portion or feature of Fooddy.ng website, or any other systems or networks connected to Fooddy.ng Website or to any Fooddy’s server, computer, network, or to any of the services offered on or through Fooddy.ng Website, by hacking, password “mining” or any other illegitimate means 

You shall not probe, scan or test the vulnerability of Fooddy website or any network connected to the Fooddy website nor breach the security or authentication measures on Fooddy.ng website or any network connected to Fooddy.ng Website. You may not reverse look up, trace or seek to trace any information on any other user of or visitor to Fooddy.ng Website, or any other customer of Fooddy.ng, including any Fooddy.ng account not owned by you, to its source, or exploit Fooddy.ng Website or any service or information made available or offered by or through Fooddy.ng Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by Fooddy.ng Website. 

You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Fooddy.ng Website, or any other customer of Fooddy.ng, including any Fooddy.ng account not owned by you, to its source, or exploit Fooddy.ng Website or any service or information made available or offered by or through Fooddy.ng Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by Fooddy.ng Website. 

You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of Fooddy.ng Website or Fooddy’s systems or networks, or any systems or networks connected to Fooddy.ng 

You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person’s use of the Website. 

You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Fooddy.ng on or through the Website or any service offered on or through the Website. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity. 

You may not use the Website or any Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Fooddy.ng or others 

Solely to enable Fooddy.ng to use the information you supply us with, so that we are not violating any rights you might have in Your Information, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in Your Information, in any media now known or not currently known, with respect to Your Information. Fooddy.ng will only use Your Information in accordance with the terms of use and Fooddy’s Privacy Policy. 

From time to time, you shall be responsible for providing information relating to the items or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects 

You shall not exaggerate or over emphasize the attributes of such items or services so as to mislead other Users in any manner. 

You shall not lift (i.e. copy and paste) images of products you want to sell on Fooddy.ng from other websites, images to be used must be original images and not stolen images or content posted on another site. And in the event that you violate this term, you hereby indemnify Fooddy of any third party claim thereon. 

You shall not engage in advertising to, or solicitation of, other users of Fooddy.ng to buy or sell any products or services, including, but not limited to, products or services related being displayed on Fooddy.ng or related to Fooddy.ng. You may not transmit any chain letters or unsolicited commercial or junk email to other users via Fooddy.ng. It shall be a violation of these Terms of Use to use any information obtained from Fooddy.ng in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person outside of Fooddy.ng without Fooddy’s prior explicit consent. In order to protect our users from such advertising or solicitation, Fooddy reserves the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which Fooddy deems appropriate in its sole discretion 

You understand that Fooddy has the right at all times to disclose any information (including the identity of the persons providing information or materials on the Fooddy.ng Website) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, we can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury 

Fooddy reserves the right, but has no obligation, to monitor the materials posted on Fooddy.ng website. Fooddy shall have the right to remove or edit any Content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right of Fooddy.ng, 

YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE Fooddy.ng WEBSITE AND IN YOUR PRIVATE MESSAGES. 

Please be advised that such Content posted does not necessarily reflect the views of Fooddy.ng. In no event shall Fooddy assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on Fooddy.ng. You hereby represent and warrant that you have all necessary rights in and to all Content you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information. 

Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through Fooddy.ng, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. Fooddy shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on Fooddy.ng. 

It is possible that other users (including unauthorized users or “hackers”) may post or transmit offensive or obscene materials on Fooddy.ng and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of Fooddy.ng, and that the recipient may use such information to harass or injure you. Fooddy does not approve of such unauthorized uses but by using the Fooddy.ng Website you acknowledge and agree that Fooddy will not be responsible for the use of any personal information that you publicly disclose or share with others on Fooddy.ng. Carefully select the type of information that you publicly disclose or share with others on Fooddy.ng. 

Fooddy shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through a group(s) of People, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services). `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Contents Posted on Site '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, “Content”), including but not limited to the design, structure, selection, coordination, expression, “look and feel” and arrangement of such Content, contained on Fooddy.ng is owned, controlled or licensed by or to Fooddy Nigeria Limited, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws. 

Except as expressly provided in these Terms of Use, no part of Fooddy.ng and no Content may be copied, reproduced, re-published, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, website or other medium for publication or distribution or for any commercial enterprise, without Fooddy’s express prior written consent. 

You may use information on Fooddy.ng products and services purposely made available by Fooddy.ng for downloading from the Site, provided that you do not remove any proprietary notice language in all copies of such documents,use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media,make no modifications to any such information, and not make any additional representations or warranties relating to such documents. You shall be responsible for any notes, messages, e-mails, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the Sites (collectively, "Content"). Such Content will become the property of Fooddy and you grant Fooddy Online Shopping Limited the worldwide, perpetual and transferable rights in such Content. Fooddy shall be entitled to, consistent with our Privacy Policy, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include Content you provide. You agree that any Content you post may be used by Fooddy, consistent with our Privacy Policy and Rules of Conduct on Site as mentioned herein, and you are not entitled to any payment or other compensation for such use. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Other Businesses '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy does not take responsibility or liability for the actions, products, content and services on Fooddy.ng website, which are linked to Affiliates and / or third party websites using Fooddy.ng APIs or otherwise. In addition, Fooddy.ng may provide links to the third party websites of affiliated companies and certain other businesses for which, Fooddy assumes no responsibility for examining or evaluating the products and services offered by them, and Fooddy does not warrant the offerings of, any of these businesses or individuals or the content of such third party website(s). Fooddy.ng does not in any way endorse any third party website(s) or content thereof. `}
          />
          <FormattedParagraph type={'heading'} text={'Links'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy.ng welcomes links to this site. You may establish a hypertext link to Fooddy.ng website, provided that the link does not state or imply any sponsorship or endorsement of your site by Fooddy.ng. You must not use on your site or in any other manner any trademarks, service marks or any other materials appearing on Fooddy.ng, including any logos or characters, without the express written consent of Fooddy.ng and the owner of the mark or materials. You must not frame or otherwise incorporate into another third party website or present in conjunction with or juxtaposed against such a website any of the content or other materials on the Fooddy.ng website without Fooddy’s prior written consent. `}
          />
          <FormattedParagraph type={'heading'} text={'Privacy'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`We view protection of Your privacy as a very important principle. We understand clearly that you and Your Personal Information is one of our most important assets. We store and process Your Information on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and rules there under. Our current Privacy Policy is available at https://www.Fooddy.ng/content/privacy- policy. If you object to your Information being transferred or used in this way, please do not use Fooddy.ng website 

Information submitted may be made available to Fooddy partners who can in turn use it for verification purposes `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'MANDATORY DECLARATION '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`You hereby declare that you have provided all necessary Legal Delivery Details for the shipment of the product to the destination specified by you in the Order. 

You further declare that the Product will be acquired for personal use of the Consignee and/or Buyer. The Product will not be sold, resold, bartered or in any way disposed for profit. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Disclaimer of Warranties and Liability '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`This website, all the materials and products (including but not limited to software) and services, included on or otherwise made available to you through this site are provided on Fooddy.ng on “as is” and “as available” basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Fooddy.ng does not warrant that: 

This website will be constantly available, or available at all; or 

The information on this website is complete, true, accurate or non misleading. 

Fooddy will not be liable to you in any way or in relation to the contents of, or use of, or otherwise in connection with, Fooddy.ng website. 

Fooddy does not warrant that this site; information, content, materials, product (including software) or services included on or otherwise made available to you through Fooddy.ng website; their servers; or electronic communication sent from Fooddy.ng are free of viruses or other harmful components. 

Nothing on Fooddy.ng constitutes, or is meant to constitute, advice of any kind. 

All the products sold on Fooddy.ng are governed by laws of The Federal Republic of Nigeria and if Fooddy is unable to deliver such products due to implications of the laws, Fooddy will return or will give credit for the amount received by Fooddy from the sale of such product, which could not be delivered to you. 

You will be required to enter a valid phone number while placing an order on Fooddy.ng. By registering your phone number with us, you consent to be contacted by Fooddy via phone calls and / or SMS notifications, in case of any order or shipment or delivery related updates. Fooddy will not use your personal information to initiate any promotional phone calls or SMS'. 
 `}
          />
          <FormattedParagraph type={'heading'} text={'Services '} noLine />
          <FormattedParagraph type={'heading'} text={'Payment'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`While availing any of the payment method(s) offered at Fooddy.ng, Fooddy will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to: 

Lack of authorization for any transaction/s, or 

Exceeding the preset limit mutually agreed by you and between your "Bank/s", or Any payment issues arising out of the transaction, or Decline of transaction for any other reason. 

All payments made against the purchases/services on Fooddy.ng by you shall be compulsorily in Nigerian Naira acceptable by the Central Bank of Nigeria. Fooddy does not accept any other form of currency with respect to the purchases made on Fooddy.ng. `}
          />
          <FormattedParagraph type={'heading'} text={'Breach'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Without limiting other remedies, Fooddy.ng may limit your activity, immediately remove your information, warn other Users of your actions, immediately temporarily/indefinitely suspend or terminate or block your membership, and/or refuse to provide you with access to Fooddy.ng website in the event, but not limited to: 

If you breach the Terms of Use or Privacy Policy or other rules and policies, if any; 

If Fooddy.ng is unable to verify or authenticate any information you provide; or 

If it is believed that your actions may cause legal liability for you, other Users or Fooddy.ng. 

Fooddy.ng may at any time at its sole discretion reinstate suspended Users. 

A User that has been suspended or blocked may not register or attempt to register with Fooddy.ng or use Fooddy.ng Website in any manner whatsoever until such time that such User is reinstated by Fooddy.ng. Notwithstanding the foregoing, if you breach the Terms of Use or Privacy Policy or other rules and policies, Fooddy.ng reserves the right to recover any amounts due and owing by you to Fooddy.ng and to take strict legal action including but not limited to a referral to the appropriate law enforcement or other authorities for initiating criminal or other proceedings against you. `}
          />
          <FormattedParagraph type={'heading'} text={'Indemnity'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`You shall indemnify and hold harmless Fooddy.ng its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including attorneys' fees, made by you or any third party or penalty imposed due to or arising out of your use of the goods or services, or in the event you breach this Terms of Use, the Privacy Policy and other Policies, or your violation of any law, rules or regulations or the rights of a third party on Fooddy.ng `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Applicable Law '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`This Agreement shall be governed by and interpreted and construed in accordance with the laws of The Federal Republic of Nigeria. The place of jurisdiction shall be exclusively in Lagos, Nigeria. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Jurisdictional Issues/Sale in Nigeria Only '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Unless otherwise specified, the material on Fooddy.ng is presented solely for the purpose of sale in Nigeria. Fooddy makes no representation that materials/services in Fooddy.ng are appropriate or available for use in other locations/Countries other than Nigeria. Those who choose to access this site from other locations/Countries other than Nigeria do so on their own initiative and Fooddy is not responsible for supply of goods/availability of services, refund for the goods or services ordered from other locations/Countries other than Nigeria, compliance with local laws, if and to the extent local laws are applicable. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Trademark, Copyright and Restriction '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`This site is controlled and operated by Fooddy Nigeria Limited. All material on this site, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights that are owned and controlled by us or by other parties that have licensed their material to us. Material on Fooddy.ng web site owned, operated, licensed or controlled by us is solely for your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by e-mail or other electronic means and whether directly or indirectly and you must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other web site or networked computer environment or use of the materials for any purpose other than personal, noncommercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Copyright complaint '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`We at Fooddy respect the intellectual property of others. In case you feel that your work has been copied in a way that constitutes copyright infringement you can write to us at hello@Fooddy.ng `}
          />
          <FormattedParagraph type={'heading'} text={'Risk of loss '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`All items purchased from Fooddy.ng are made pursuant to a shipment contract. This means that the Risk of Loss shall remain with Fooddy until the item is transferred to You. In the event that the items are damaged after receipt, the risk falls on the customer `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Product and Service Description '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy.ng tries to be as accurate as possible. However, Fooddy.ng does not warrant that product or service description or other content of this site is accurate, complete, reliable, current, or error-free. If a product or service offered on Fooddy.ng itself is not as described, your sole remedy is to return it in unused condition. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Pricing/Typographical error '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`If Fooddy.ng comes across any difference in pricing resulting from typographic errors with regards to pricing or product/service information, Fooddy.ng shall have the right to rectify the same or cancel the order(s) and refund monies, if any, collected from the customer within 10-15 business days of such corrective action taken. The mode of refund will be the same as at the time of placing the order. Cash on Delivery order refunds will be done by cheque. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Cancellation of order '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy.ng reserves the right to cancel any order without any explanation for doing so, under situation where Fooddy.ng is not able to meet the requirement of the order placed or order so placed/cancelled does not comply with the Fooddy.ng policy or for any reason. However, Fooddy.ng will ensure that any communication of cancellation of an order is intimated within appropriate time to the concerned person and any applicable refund, will be made in reasonable time. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Termination of Service '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy.ng reserves the absolute right to modify, discontinue, temporarily or permanently, any and all portion of our goods and services with or without prior communications. You hereby consent that Fooddy.ng will under no circumstance be liable to you or any third party for any modification or discontinuance of availability of goods or services on the website. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Limitation of Liability '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`IN NO EVENT SHALL Fooddy.ng BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THIS AGREEMENT, EVEN IF Fooddy.ng HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES 

FOODDY’S AGGREGATE LIABILITY TO YOU, ANY INDIVIDUAL OR ANY THIRD PARTY IN ANY MATTER ARISING HEREFROM, RELATED OR CONNECTED TO OUR GOODS AND SERVCES IS AT THE COMPANY’S SOLE DISCRETION. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'This section of our terms and conditions covers: '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Section A: “TERMS AND CONDITIONS FOR EATERIES, CAFES AND FOOD AND DRINK OUTLET MERCHANT GIFT CERTIFICATES” while Section B: “TERMS AND CONDITIONS FOR NON-EATERIES MERCHANT GIFT CERTIFICATES”. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={
              'SECTION A -TERMS AND CONDITIONS FOR EATERIES, FOOD AND DRINK OUTLET MERCHANT GIFT CERTIFICATES '
            }
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The terms and conditions in this section A and all other Terms and Conditions applicable to you as a Merchant govern all Merchants on Fooddy.ng who sell a variety of edibles and beverages as its type of business and makes such available to Customers via Fooddy.ng by giving the customers the opportunity to purchase same via Vouchers on Fooddy.ng. 

Every Voucher purchased by a customer on Fooddy.ng will clearly have printed on it the number of times it can be redeemed. Please note that this is pre- determined by the Merchant and Restaurant. 

Note the following: 

The above certificate might not be eligible for alcoholic beverages unless the Eatery expressly permits same, taking into cognizance all restrictions on alcohol as stipulated by the governing law. 

These Gift Certificates must not be used for tips, taxes, prior balances etc. unless the Resturant/Merchant expressly permits the same. The Merchant or Restaurant will not be responsible for lost, stolen certificates or their reference numbers. 

Gift Certificates may only be used as stipulated on the coupon (i.e. “Dine in Only” or “Take-away”) 

All Gift Certificates are void to the extent prohibited by law. 

The Eatery is at liberty to determine whether Vouchers purchased here in can be used in conjunction with any other gift certificate, coupons etc. 

No one has the right to purchase from the Fooddy.ng platform and resell same to individuals. 

In the event a Customer decides to redeem his Eatery Certificate without abiding with all terms and conditions herein stated, the gift certificate will be rendered invalid. 

All Fooddy.ng offers are governed by the validity dates specified on the Vouchers. 

As earlier stated all vouchers for eateries may have statutory limitations on the amount of the voucher value which can be used to redeem alcohol. All Merchants must therefore comply with same. 

A Merchant must have complied with all statutory requirements before enlisting their vouchers for purchase on Fooddy.ng. Fooddy.ng will not be held responsible for lack of compliance on the part of the Merchant. 

All Fooddy offers (including but not limited to discounts, upon expiration of the date specified in the offer becomes invalid. 
`}
          />
          <FormattedParagraph
            type={'heading'}
            text={
              'SECTION B -TERMS AND CONDITIONS FOR NON-EATERIES MERCHANTS GIFT CERTIFICATES Please note the following: '
            }
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Only one certificate may be used per order, except this is expressly stated otherwise by theMerchant. 

The Merchant is at liberty to offer credit if he wishes unless otherwise required by law. 

In the event a customer misplaces (i.e. whether stolen or lost) his or her voucher, or reference number neither the Merchant nor Fooddy will be held responsible for same. 

No one has the right to purchase from the Fooddy.ng platform and resell same to individuals. 

In the event a Customer decides to redeem his Gift Certificate without abiding with all terms and conditions herein stated, the gift certificate will be rendered invalid. 

All Gift Certificates are void to the extent prohibited by law. 

Gift Certificates cannot be combined with any other gift certificates, including but not limited to coupons, promotions, third party certificates etc., unless otherwise expressly specified by Merchant 

All Fooddy offers (including but not limited to discounts), upon expiration of the date specified in the offer becomes invalid. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Other Terms and Conditions '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Please note that all Merchants who already have goods and services, or Prospective Merchants who have sent in request to have their goods and services uploaded or advertised on Fooddy.ng in order to conduct transactions, including but not limited to; 

Merchants who sell Goods on Fooddy.ng 

Merchants who offer Services on Fooddy.ng 

Firms/Organizations who choose to advertise on Fooddy’s website (i.e. Advertisers, either on a trade by barter basis with Fooddy or on a strictly Advertisement service level) are hereby governed by the Terms and Conditions here in `}
          />
          <FormattedParagraph type={'heading'} text={'ADVERTISING '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Advertising Services offered by Fooddy.ng include, but are not limited to the display of certain goods, services and information about your business from time to time, splashing adverts, direct calls to you and sending certain information to users of Fooddy.ng via different means (i.e. SMS messages or emails). `}
          />
          <FormattedParagraph type={'heading'} text={'VOUCHERS '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`All vouchers purchased and thereafter printed from the Fooddy.ng platform are strictly promotional vouchers which are offered at prices below their face value and thereafter purchased by customers. 

In light of the above they are subject to all Terms and Conditions of Fooddy Online Shopping Limited as stipulated on this page and also the Terms and Conditions and Redemption Policies of the Merchant or Restaurant. All customers who intend to purchase on the Fooddy.ng platform are subject to the specific limitations in relation to the goods or services. (i.e. if a Merchant specifies that only one Fooddy.ng Voucher maybe purchased by a customer, the customer must abide by same, if the customer goes ahead to purchase more than stipulated and tries to redeem the excess, the Merchant is at liberty to refuse redemption of same without paying any compensation or refund whatsoever to the customer. In such an instance Fooddy.ng will not compensate the customer for any such contraventions, neither will the customer receive a refund in relation to the purchase 

An Eatery Specific Gift Voucher is strictly issued by that Eatery, who is also a Merchant on our platform and not Fooddy. The Eatery or Merchant is therefore fully responsible for any and all injuries, illnesses, damages, claims, liabilities and costs suffered by or in respect of a customer, caused in whole or in part by the restaurant or the merchant, as well as for any unclaimed property liability arising from unredeemed Vouchers. Eatery -Specific Vouchers and Merchant Vouchers are redeemable “as is” only and may not be redeemed incrementally. 

A Fooddy.ng Voucher can only be redeemed at the cash value paid by the customer for it (i.e. if you paid N500.00 for a Fooddy Voucher, but the voucher gives you N3000.00 of value to the Merchant, the cash value that you paid is N500.00 as opposed to the authentic value/actual value of N3000.00). 

Please note all expiration dates on Vouchers are valid and therefore govern the last date which you can use the VOUCHER, in the event you do not redeem your voucher before the expiration, you automatically forfeit same, unless the law provides otherwise. `}
          />
          <FormattedParagraph type={'heading'} text={'General '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`The Terms and Conditions and Privacy Policy as provided herein by Fooddy including but not limited to other guiding policies for Fooddy.ng constitute the entire agreement between the parties with respect to the subject matter hereof. In the event Fooddy or you decide to waive any breach or default hereunder, the waiver will not suffice for any preceding or subsequent breach or default. The failure of either party to exercise any of its rights under this Agreement shall not be deemed a waiver or forfeiture of such rights or any other rights provided hereunder. The failure of either party to insist immediately upon the strict compliance and or performance of any of the terms and conditions herein breached shall not constitute a waiver of the right of either party, or constitute acquiescence therein and the same shall not affect or impair the later exercise of such right or be presumed to vary this terms and conditions in any manner whatsoever. 

When you place an order on Fooddy.ng for goods or services, you hereby make an offer to us for the purchase of the goods or the vouchers for services. For more information, please view our privacy policy. 

Please note that all your purchases are either directly from Fooddy.ng if the item is “Sold by Fooddy”, or from a Merchant in the event the goods or services are offered by the Merchant. In light of the above, goods or services sold by a Merchant are strictly redeemable from Merchants while those sold by Fooddy are redeemable from Fooddy. `}
          />
          <FormattedParagraph type={'heading'} text={'Our Contact '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Please send any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Site to help@Fooddy.ng or to our office at 1A Redemption Crescent, Gbagada 100234, Lagos. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Merchant Service Agreement Introduction '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`As a demonstration of our commitment to Nigeria and Nigerian entrepreneurs, Fooddy Online Shopping Limited (“Fooddy”, “us”), Nigeria’s Largest Online Mall opened up its online platform to Nigerian businesses to enable merchants sell their products (“Mall”), giving instant nationwide access to your products. Merchants can also enjoy all the benefits that come with having an online store by being able to sell their products to customers all over Nigeria. Businesses that sign up to Fooddy’s Mall will have a free microsite provided for them, and Fooddy will provide additional value added services including but not limited to marketing, customer service and logistics partnerships to cater for customers in all 36 states in Nigeria as well as Abuja. Fooddy will also provide dedicated account managers to all merchants to enable them increase their turnover quickly. Merchants can greatly increase sales and revenue by partnering with us, while we worry about getting their products to customers all over Nigeria. We also provide convenient payment terms for the proceeds of merchants’ sales. Fooddy offers its customers both prepaid and pay -on-delivery payment options for goods purchased. All merchants have to do to enjoy all these benefits is to review and accept the terms and conditions of the Fooddy Mall Service Agreement below. Fooddy reserves the right to alter these Terms & Conditions at any point without notice, by updating the content on the Fooddy website. `}
          />
          <FormattedParagraph type={'heading'} text={'Agreement'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`In consideration of the mutual covenants set forth in this Agreement, Seller and Fooddy (jointly referred to as “Parties” and individually as a “Party”) hereby agree as follows: `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Services Rendered '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The Seller agrees to compensate Fooddy for the services rendered listed in section 2 below (“Services”). `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Delivery of Services '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy will use reasonable diligence in rendering the Services offered to the Seller, which include the following: `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Online storefront tailored to the Seller with payment gateway and escrow service at Fooddy.ng; e`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Access to SellerHQ and other account management systems; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Access to a dedicated account officer and other account management services; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Access to logistics partners;`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Provision of analytics; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Seller Services Helpline Number (Mon – Fri; 08:00 – 18:00) `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Emarketing, and other marketing services as available and appropriate`}
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy may at its sole discretion add or remove Services from the above list from time to time and some of the new services may attract separate charges, in which case the Seller will be informed before provision of the said services. 

Fooddy reserves the right to alter or vary the available Services, the nature of the Services and any charges at its sole discretion 

The Seller agrees to use reasonable diligence in providing Fooddy with genuine products, up to date product quantity information and accurate product information `}
          />

          <FormattedParagraph type={'heading'} text={'Compensation '} noLine />
          <FormattedParagraph
            type={'body'}
            text={`For all of the Services provided by Fooddy under this Agreement, the Seller shall compensate Fooddy, with commissions or other payments as required, in accordance with the terms of the ‘Commission Structure’, which will be communicated to all sellers; or agreed in advance between both parties, or detailed elsewhere on the Fooddy website. 

Every Merchant shall have an opening/ minimum balance of N10, 000.00 (Ten Thousand Naira) only in his/her Fooddy Wallet upon registration with Fooddy. Fooddy shall have the right to deduct commissions for all “Self-fulfilled Orders” directly from the Merchant’s Fooddy Wallet or from his/her designated Bank Account in the event his/her wallet is empty. 

Fooddy will from time to time review the category commission structure and will notify the Seller of any changes by communicating directly to sellers, or publishing the same on the SellerHQ portal, or on Fooddy’s website not less than fourteen (14) days before any variation in commission rate or charging structure takes place. Fooddy will remit payments for sales generated by the Seller on its platform minus the agreed commission and all applicable taxes as soon as products sold have been delivered and applicable return period has lapsed, or as agreed between the Parties.`}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Confidentiality'}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The Seller and Fooddy acknowledge and agree that the scope of work and all other documents and information related to the development of the Services (the “Confidential Information”) will constitute valuable trade secrets of Fooddy. Seller shall keep the Confidential Information in confidence and shall not at any time during or after the terms of this agreement, without Fooddy’s prior written consent, disclose or otherwise make available to anyone, either directly or indirectly, all or any part of the Confidential Information. Excluded from the “Confidential Information” definition is anything that can be seen by the public `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Independent Contractor '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy shall be deemed as an independent contractor. Fooddy will be fully responsible for payment of its own income taxes on all compensation earned under this Agreement. Seller will not withhold or pay any income tax, social security tax, or any other payroll taxes on Fooddy’s behalf. Fooddy is also not responsible for remitting or paying VAT (Value Added Taxes) on behalf of the Seller. Fooddy understands that it will not be entitled to any fringe benefits that Seller provides for its employees generally or to any statutory employment benefits, including without limitation, worker’s compensation or unemployment insurance. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Entire Agreement '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`This Agreement contains the entire agreement between the Parties relating to the subject matter hereof and supersedes any and all prior agreements or understandings, written or oral, between the parties related to the subject matter hereof. Fooddy reserves the right to alter these terms and conditions at any point without notice, by updating the content on the Fooddy website. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Indemnification '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Seller warrants that everything it gives Fooddy to use in the delivery of the Services or any deliverable is legally owned or licensed to the Seller. The Seller agrees to indemnify and hold Fooddy, its employees and associates harmless from any and all claims brought in the performance of the Services. Seller shall indemnify and hold FOODDY harmless in respect of any action that may be taken against it, or any claims that may be brought against it, for whatever reason and of whatever nature – including, but not limited to, breach of law, regulation and/or any of the obligations and warranties under this Contract – by any third party in respect of the Contents and/or the Services and/or any and all activities related and connected to the Contents and/or the Services, irrespective of any direct liability of the Seller (“Third Party Claim”). `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Limitation of Liability '}
            noLine
          />
          <FormattedParagraph
            type={'heading'}
            text={
              'A) Not withstanding applicable returns policy, Merchant further covenants to: '
            }
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Pick up fresh returned orders from the Fooddy Pick Up Centres within 7 days of notification of such return via sms or e-mail, failing which, the order will be sent back to Fooddy’s Warehouse and another notification to the merchant to request for the delivery of same at KOS rates. 

If the item remains unrequested for a while at the Fooddy Warehouse, Fooddy shall send a notification of the daily cost of demurrage to the merchant at N200.00. If the merchant fails to respond after 10 days, Fooddy shall notify merchant by sending reminder emails or smss every day, for an extra 4 days. 

In the event that the goods are not picked up from the Pick Up Centres after 7 days and still remain un-requested for after 14 days elapses, such Merchant goods shall be destroyed by Fooddy. 

Merchant hereby indemnifies Fooddy against any such claims and holds Fooddy non-liable for the disposal of such returned goods referred to in the clauses above. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={
              'B) Merchant agrees to indemnify, defend and hold harmless Fooddy against and from any third party claims (including reasonable legal or arbitration costs) arising from: '
            }
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`any breach or default on the part of the merchant of any obligations negligent act or omission of merchant; or 

wilful violation of the law, wrong description and price of products advertised; or an offence committed by merchant,provision of fake, inferior or substandard products that have been sold as genuine; provision of used, refurbished or damaged products that has been sold as new or unused; 

Listing and selling products that have not been authorized for sale by the appropriate regulatory body (including but not limited to the NCC, NAFDAC, SON etc); 

Fails to meet the Quality of Service Standards (QoS) as defined in the SellerHQ ratings system (details on request); 

Engages in any activity which would be considered illegal under Nigerian law, or engages in any activity that could be considered as fraudulent or misleading. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Term & Termination '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Either Party shall have the right to terminate this Agreement for cause immediately and without notice in the event of a breach of the obligation under this Agreement by the other Party. 

Fooddy has the right to evict the Seller from Fooddy if any, or a combination, of the following breaches are committed: `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Fails to make a product that has been sold on Fooddy Mall available for delivery within three (3) business days after it was supposed to be available for delivery;`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Provides fake, inferior or substandard products that have been sold as genuine; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Provides used, refurbished or damaged products that has been sold as new or unused; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Lists and sells products that have not been authorized for sale by the appropriate regulatory body (including but not limited to the NCC, NAFDAC etc);`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Fails to meet the Quality Of Service Standards (QoS) as defined in the SellerHQ ratings system (details on request);`}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Engages in any activity which would be considered illegal under Nigerian law, or engages in any activity that could be considered as fraudulent or misleading; `}
          />
          <FormattedParagraph
            type={'bullet'}
            text={`Engages in any activity that brings Fooddy, or any of Fooddy’s partners or other mall vendors into disrepute. `}
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy reserves the right to alter these QOS rules at any time, and in the event of any changes Fooddy will inform the Seller by publishing the same on Fooddy’s website. Fooddy will send a formal warning to the Seller after each incident. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={
              'Policy on Counterfeit, Refurbished and Unauthorized Products on Fooddy Mall '
            }
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy is committed to delivering stellar services to its customers at all times, and we strive to ensure that all products sold via the Fooddy Mall and shipped to our buyers are held to the same high quality standards. Fooddy Mall has zero tolerance for counterfeit products, therefore any store that sells unoriginal, fake/replica, refurbished or unauthorized items on Fooddy will be automatically suspended and the authenticity of the products will be investigated. If the merchant is then proven to sell these items, the store will be permanently prohibited from selling on Fooddy Mall. This policy excludes official replica items produced and sold by authorized brands and organizations. `}
          />
          <FormattedParagraph type={'heading'} text={'Insurance'} noLine />
          <FormattedParagraph
            type={'body'}
            text={`Sellers shall be responsible for insurance in relation to goods shipped to customers. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Copyright and Infringement Trademark '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`Fooddy respects all copyright and trademarks of others. As a Seller on our website you must in turn respect these rights. Fooddy will immediately terminate or suspend a seller who infringes another’s trademark or copyright. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Independent Contractors '}
            noLine
          />
          <FormattedParagraph
            type={'body'}
            text={`The Parties hereby enter into this Agreement as independent contractors, and this Agreement will not be construed to create a partnership, joint venture, or employment relationship between them. Neither Party will represent itself to be an employee of the other or enter into any agreement or legally binding commitment or statement on the other’s behalf or in the other’s name. `}
          />
          <FormattedParagraph
            type={'heading'}
            text={'Restrictions and Prohibitions '}
            noLine
          />
          <FormattedParagraph
            type={'bullet'}
            text={`All product descriptions must be true, accurate and non-misleading. `}
          />
        </Col>
      </Row>
    </Container>
  );
};

export const FormattedParagraph = ({ text, type, noLine, newLine = true }) => {
  return type == 'body' ? (
    <Row
      style={{
        marginBottom: noLine ? 0 : 10,
      }}
    >
      <CustomText fontFamily="Poppins" fontSize={12} cNColor="text-secondary">
        {text
          // .replace(/[\r\n]/gm, ' ')
          // .replace(/\s+/g, ' ')
          .trim()}
      </CustomText>
    </Row>
  ) : type == 'heading' ? (
    <Row
      style={{
        marginTop: noLine ? 0 : 10,
        marginBottom: 2,
      }}
    >
      <CustomText
        fontFamily="Poppins"
        fontSize={14}
        style={{ textDecorationLine: noLine ? 'none' : 'underline' }}
      >
        {newLine
          ? `\n` +
            text
              .replace(/[\r\n]/gm, ' ')
              .replace(/\s+/g, ' ')
              .trim()
          : text
              .replace(/[\r\n]/gm, ' ')
              .replace(/\s+/g, ' ')
              .trim()}
      </CustomText>
    </Row>
  ) : (
    type == 'bullet' && (
      <Row
        style={{
          marginTop: 5,
          paddingRight: 15,
        }}
        className="d-flex"
      >
        <Col xs="auto">
          <div
            style={{
              width: 5,
              height: 5,
              borderRadius: 2.5,
              backgroundColor: 'black',
              marginRight: 15,
              marginTop: 5,
            }}
          ></div>
        </Col>
        <Col>
          <CustomText
            fontFamily="Poppins"
            fontSize={12}
            cNColor="text-secondary"
          >
            {text
              // .replace(/[\r\n]/gm, ' ')
              // .replace(/\s+/g, ' ')
              .trim()}
          </CustomText>
        </Col>
      </Row>
    )
  );
};

export default TermsAndConditions;
