// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  InputGroup,
} from 'react-bootstrap';

// import media files
import { useFormik } from 'formik';
import { useLoginUserMutation } from '../../redux/features/auth/authApi';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  logout,
  setAuthState,
  setCredentials,
} from '../../redux/features/auth/authSlice';
import { Icon } from 'react-icons-kit';

import Logo from 'assets/svgs/logo.svg';
import BackOfficeSvg from 'assets/svgs/BackOffice.svg';
import { ReactComponent as EmailSvg } from 'assets/svgs/carbon_email.svg';
import { ReactComponent as EyeSvg } from 'assets/svgs/eye-close.svg';
import { ReactComponent as EyeOnSvg } from 'assets/svgs/eyeOpen.svg';
import { ReactComponent as EyeOffSvg } from 'assets/svgs/eyeClose.svg';
import CustomInput from 'components/CustomInput';
import { CustomText } from 'components/CustomText';
import CustomButton from 'components/CustomButton';
import continueWithGoogle from 'assets/pngs/continueWithGoogle.png';
import GoogleLogo from 'assets/pngs/google-logo.png';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useSelector } from 'react-redux';
import { useGoogleSigninMutation } from 'redux/features/auth/authApi';
import {
  saveToAuth,
  saveToken,
  saveToUserAuth,
  saveUser,
} from 'redux/features/auth/authSlice';
import { saveInitiaLoad, saveToStore } from 'redux/features/user/userSlice';
import { setCurrentScreen } from 'redux/features/navigation/navigationSlice';
import { errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    dispatch(logout());
    googleLogout();
  }, []);

  const dispatch = useDispatch();
  const { countries, country, prevLogin, pushToken } = useSelector(
    (state) => state.userAuth
  );
  const { currentScreen } = useSelector((state) => state.navigation);
  const { areas } = useSelector((state) => state.userStore);
  const [googleSigninCheck, setGoogleSigninCheck] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email address'),
    password: Yup.string().required().label('Password'),
  });

  const { values, errors, handleChange, handleBlur, handleSubmit, isValid } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validateOnMount: false,
      validateOnChange: validateOnChange,
      validationSchema,
      onSubmit: (values) => {
        handleLogin(values);
      },
    });

  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [googleSignin, { isLoading: isLoadingGoogleSignin }] =
    useGoogleSigninMutation();

  const handleLogin = async (values) => {
    const resolveValues = {
      ...values,
      pushToken,
    };
    const res = await loginUser(resolveValues);
    if (res?.data?.status === 'success') {
      dispatch(saveToken(res?.data?.token));
      dispatch(saveToAuth(['isLoggedIn', res?.data?.token]));
      dispatch(saveInitiaLoad());
      dispatch(saveUser({ ...res?.data?.user, ...res?.data?.publicApi }));
      if (rememberMe) {
        dispatch(saveToUserAuth({ key: 'prevLogin', value: values }));
      } else {
        dispatch(saveToUserAuth({ key: 'prevLogin', value: null }));
      }
      history.push(location?.state?.redirect || '/');
    } else if (res?.error?.data?.status === 'unverified') {
      dispatch(saveInitiaLoad());
      dispatch(saveUser(res?.error?.data?.data));
      if (rememberMe) {
        dispatch(saveToUserAuth({ key: 'prevLogin', value: values }));
      } else {
        dispatch(saveToUserAuth({ key: 'prevLogin', value: null }));
      }
      show(dispatch, errorMessageFormat(res) || 'Verify your email address', {
        type: 'success',
        duration: 4000,
      });
      history.push('/verify-account', {
        from: 'signin',
        formType: 'email',
      });
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setLoadingGoogle(false);
    setValidateOnChange(false);
    setGoogleSigninCheck(false);
    setModalVisible(false);
    // setRememberMe(false);
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      setGoogleSigninCheck(true);
      setToken(response.access_token);
      getUserInfo(response.access_token);
    },
    onError: () => setLoadingGoogle(false),
  });

  const getUserInfo = async (accessToken) => {
    try {
      const response = await fetch(
        'https://www.googleapis.com/userinfo/v2/me',
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const user = await response.json();
      setUserInfo(user);
      const res = await googleSignin({
        ...user,
        country: country?.name,
        pushToken,
      });
      if (res?.data?.status === 'success') {
        dispatch(saveToken(res?.data?.token));
        dispatch(saveToAuth(['isLoggedIn', res?.data?.token]));
        dispatch(saveInitiaLoad());
        dispatch(saveUser({ ...res?.data?.user, ...res?.data?.publicApi }));
        setGoogleSigninCheck(false);
        setLoadingGoogle(false);
        history.push(location?.state?.redirect || '/');
      } else {
        setGoogleSigninCheck(false);
        setLoadingGoogle(false);
        show(dispatch, errorMessageFormat(res), {
          type: 'error',
          placement: 'top',
          duration: 4000,
        });
      }
    } catch (error) {
      // Add your own error handler here
      setGoogleSigninCheck(false);
      setLoadingGoogle(false);
    }
  };

  return (
    <Fragment>
      <Row
        className="align-items-center justify-content-center g-0"
        xl={12}
        lg={12}
      >
        <Col className="py-1 align-items-md-center" style={{ maxWidth: 500 }}>
          <Form>
            <Row className="bg-white p-4">
              <Col lg={12} md={12} style={{ marginBottom: '14px' }}>
                {/* Username or email */}
                <CustomInput
                  type={'email'}
                  variant="auth"
                  required={true}
                  label={'Email Address'}
                  id={'email'}
                  placeholder={'Enter your email address'}
                  onChange={handleChange}
                  // leftIcon={<EmailSvg />}
                  value={values?.email}
                  error={errors?.email}
                  inputStyle={{
                    height: 42,
                    // marginRight: 24,
                    borderColor: '#d9d9d9',
                    borderRadius: 7,
                    paddingLeft: 24,
                  }}
                />
              </Col>
              <Col lg={12} md={12}>
                <CustomInput
                  type={passwordShown ? 'text' : 'password'}
                  required={true}
                  id={'password'}
                  variant="auth"
                  label={'Password'}
                  placeholder={'Enter password'}
                  onChange={handleChange}
                  value={values.password}
                  rightAction={togglePassword}
                  rightComp={
                    passwordShown ? (
                      <EyeSvg style={{ width: 40 }} onClick={togglePassword} />
                    ) : (
                      <EyeOnSvg
                        style={{ width: 40 }}
                        onClick={togglePassword}
                      />
                    )
                  }
                  inputStyle={{
                    height: 42,
                    paddingRight: 24,
                    borderColor: '#d9d9d9',
                    borderRadius: 7,
                    paddingLeft: 24,
                  }}
                />
              </Col>
              <Col lg={12} md={12} className="mb-3 text-end">
                <Col>
                  <Link to="/forgot-password" className="text-primary">
                    Forgot password?
                  </Link>
                </Col>
                <Col
                  style={{ paddingTop: '25px' }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <CustomButton
                    variant="primary"
                    type="submit"
                    fontWeight={'500'}
                    className="w-100"
                    text={'Sign in'}
                    disabled={!isValid}
                    loading={isLoading}
                    style={{ height: 45, marginBottom: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setValidateOnChange(true);
                      handleSubmit();
                    }}
                  />
                </Col>
              </Col>
              <Col className="pt-2">
                <div className="line"></div>
              </Col>
              <Col lg={12} md={12} className="login-seperator">
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={16}
                  fontWeight={400}
                  text={'or'}
                  divClassName="or-seperator"
                  // cNColor="gray"
                />
              </Col>
              <Col lg={12} md={12} className="d-flex justify-content-center">
                <CustomButton
                  variant="primary"
                  fontWeight={'500'}
                  className="w-100 bg-white border-1"
                  height={'45px'}
                  disabled={isLoading}
                  loading={isLoadingGoogleSignin}
                  spinnerVariant={'primary'}
                  style={{ maxWidth: 475, marginBottom: 0 }}
                  onPress={googleLogin}
                >
                  <Image src={GoogleLogo} alt="" />
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={'14px'}
                    fontWeight={500}
                    text={'Sign in with Google'}
                    ml="10px"
                  />
                </CustomButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Login;
