// import node module libraries
import React, { useState, useEffect } from 'react';

import Footer from 'components/Footer';
import Features from 'components/Features';
import Subscription from 'components/AddSubcription';
import NavbarDefault from './navbars/NavbarDefault';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import CustomToast from 'components/CustomToast';
import backgroundImage from 'assets/svgs/Illustrations.svg';

const DashboardIndex = (props) => {
  const { children, className, overflowHidden } = props;
  const { toastMessage, toastType, toastDuration } = useSelector(
    (state) => state.userStore
  );
  const [showMenu, setShowMenu] = useState(true);
  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  });

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    marginTop: 80,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat',
  };

  const contentStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Fragment>
      <NavbarDefault />
      {toastMessage && (
        <CustomToast
          text={toastMessage}
          type={toastType}
          duration={toastDuration}
        />
      )}
      <div style={containerStyle}>
        <div
          style={contentStyle}
          className={'container-fluid px-lg-4 py-lg-3 p-md-5 p-sm-2'}
        >
          {props.children}
        </div>
        <Features />
        <Subscription />
        <Footer />
      </div>
    </Fragment>
  );
};
export default DashboardIndex;
