// import node module libraries

import React, { useMemo, useState } from 'react';
import { Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
// import custom components
import { ReactComponent as HeartFilled } from 'assets/svgs/heart-filled.svg';
import { ReactComponent as HeartUnfilled } from 'assets/svgs/heart-unfilled.svg';
import decrease from 'assets/svgs/minus.svg';
import increase from 'assets/svgs/plus.svg';
// import { ReactComponent as Decrease } from "assets/svgs/minus.svg";
// import { ReactComponent as Increase } from "assets/svgs/plus.svg";
// import data files
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { CustomText } from 'components/CustomText';
import {
  extractObjectWithMarketId,
  getRandomObjectsFromArray,
  isArrayNonEmpty,
  removeDuplicates,
  removeUndefinedOrNull,
} from 'helper/formatArray';
import { extractId } from 'helper/formatObject';
import { errorMessageFormat, formatCurrency } from 'helper/formatText';
import { show } from 'helper/toast';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAddToCartMutation,
  useAddToSavedItemsMutation,
  useDeleteSavedItemMutation,
  useGetCartMutation,
  useGetProductMutation,
  useGetProductsMutation,
  useGetSavedItemsMutation,
  useGetUnrestrictedProductMutation,
  useGetUnrestrictedProductsMutation,
} from 'redux/features/user/userApi';
import { saveToStore, saveToUserStore } from 'redux/features/user/userSlice';
import ProductImage from './components/ProductImage';
import SimilarProducts from './components/SimilarProducts';
import ViewedProducts from './components/ViewedProducts';
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Link>
));

const ProductDetails = (props) => {
  const { isMobile, isMobileS, isLaptop, isDesktop } = useScreenSize();
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const { token, countries, country, area, routeParams } = useSelector(
    (state) => state.userAuth
  );
  const [refetch, setRefetch] = useState(false);
  const [selectedUom, setSelectedUom] = useState(null);
  const [uoms, setUoms] = useState([]);
  const [details, setDetails] = useState(location?.state?.item || null);
  const [quantity, setQuantity] = useState('1');

  const { products, savedItems, recentProducts } = useSelector(
    (state) => state.userStore
  );

  const [getProduct, { isLoading }] = useGetProductMutation();
  const [getUnrestrictedProduct, { isLoading: isLoadingUnrestricted }] =
    useGetUnrestrictedProductMutation();

  const [getProducts, { isLoading: isLoadingProducts }] =
    useGetProductsMutation();
  const [
    getUnrestrictedProducts,
    { isLoading: isLoadingUnrestrictedProducts },
  ] = useGetUnrestrictedProductsMutation();

  const [addToCart, { isLoading: isLoadingAddToCart }] = useAddToCartMutation();

  const [getCart, { isLoading: isLoadingGetCart }] = useGetCartMutation();
  const [getSavedItems, { isLoading: isLoadingGetSavedItems }] =
    useGetSavedItemsMutation();

  const [addToSavedItems, { isLoading: isLoadingAddToSavedItems }] =
    useAddToSavedItemsMutation();
  const [deleteSavedItem, { isLoading: isLoadingDeleteSavedItems }] =
    useDeleteSavedItemMutation();

  const handleDeleteSavedItem = async ({ item_id, saved_item_id, name }) => {
    const res = await deleteSavedItem({ item_id, saved_item_id });
    if (res?.data?.status === 'success') {
      show(dispatch, `${name} has been removed from saved items`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(
        saveToUserStore({
          key: 'savedItems',
          value: res?.data?.data,
        })
      );
      // setRefetchSavedItems(!refetchSavedItems);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };
  const handleAddToSavedItems = async ({
    product_id,
    area_id,
    country_id,
    name,
  }) => {
    const res = await addToSavedItems({ product_id, area_id, country_id });
    if (res?.data?.status === 'success') {
      show(dispatch, `${name} has been added to saved items`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(
        saveToUserStore({
          key: 'savedItems',
          value: res?.data?.data,
        })
      );
      // setRefetchSavedItems(!refetchSavedItems);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const fetchSavedItems = async () => {
    const res = await getSavedItems({
      area_id: area?.id,
      country_id: country?.id,
    });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({ key: 'savedItems', value: res.data?.data || [] })
      );
    }
  };
  const fetchCart = async () => {
    const res = await getCart({ area_id: area?.id, country_id: country?.id });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'cart', value: res.data?.data || [] }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const handleAddToCart = async ({
    product_id,
    area_id,
    country_id,
    quantity,
    name,
    priceByMarket,
    totalAmount,
  }) => {
    const res = await addToCart({
      product_id,
      area_id,
      country_id,
      quantity,
      priceByMarket,
      totalAmount,
    });
    if (res?.data?.status === 'success') {
      show(dispatch, `${name} has been added to cart`, {
        type: 'success',
        duration: 4000,
      });
      dispatch(saveToUserStore({ key: 'cart', value: res?.data?.data || [] }));
      res?.data?.data?.cart_items?.length &&
        dispatch(
          saveToUserStore({
            key: 'cartItemCount',
            value: res?.data?.data?.cart_items?.length || 0,
          })
        );
      // setRefetchCart(!refetchCart);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const handleRefetch = () => {
    fetchProduct();
  };

  const fetchProduct = async () => {
    const res = token
      ? await getProduct(
          match?.params?.id ||
            extractId(details?.product_id) ||
            extractId(details)
        )
      : await getUnrestrictedProduct(
          match?.params?.id ||
            extractId(details?.product_id) ||
            extractId(details)
        );
    if (res?.data?.status === 'success') {
      setDetails(res.data?.data);
      dispatch(
        saveToStore([
          'recentProducts',
          removeDuplicates(
            isArrayNonEmpty(recentProducts)
              ? [...recentProducts, res.data?.data]
              : [res.data?.data]
          ),
        ])
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        duration: 4000,
        type: 'error',
      });
      history.push('/');
    }
  };

  useEffect(() => {
    if (extractId(details?.country_id) !== extractId(country)) {
      console.log('first');
      // history.push('/');
    }
  }, [area, country]);

  useEffect(() => {
    setDetails(location?.state?.item);
    fetchProduct();
    token && !savedItems && fetchSavedItems();
  }, [location?.state?.item, match?.params]);

  const filteredProducts = useMemo(() => {
    let filtered = products || [];
    if (filtered && filtered?.length > 0) {
      filtered = filtered?.filter(
        (prod) =>
          extractId(prod?.category_id) == extractId(details?.category_id)
      );
      filtered = filtered?.filter(
        (prod) => extractId(prod?.country_id) == extractId(country)
      );

      filtered = filtered?.map((x) => {
        const connectedMarket = area?.market;

        let selectedObject = null;
        for (let i = 0; i < x?.priceByMarketId.length; i++) {
          const priceByMarket = x?.priceByMarketId[i];

          if (
            extractId(priceByMarket?.market_id) === extractId(connectedMarket)
          ) {
            selectedObject = priceByMarket;
            break;
          }
        }
        if (!selectedObject) {
          selectedObject = x?.priceByMarketId?.find(
            (priceByMarket) =>
              priceByMarket?.market_id?.name?.toLowerCase() === 'default'
          );
        }
        if (selectedObject) {
          return { ...x, priceByMarket: selectedObject };
        }
      });
      filtered = removeUndefinedOrNull(filtered);
      filtered = getRandomObjectsFromArray(filtered, 7);
    }

    return filtered;
  }, [products?.length, details?.name, refetch]);

  const filteredProduct = useMemo(() => {
    let filtered = details || null;
    if (filtered) {
      let extrPriceByMarket = [];
      for (
        let i = 0;
        i <
        removeDuplicates(
          filtered?.priceByMarketId?.map((x) => extractId(x?.uom_id))
        ).length;
        i++
      ) {
        const uom_id = removeDuplicates(
          filtered?.priceByMarketId?.map((x) => extractId(x?.uom_id))
        )[i];
        const extr = extractObjectWithMarketId(
          filtered?.priceByMarketId,
          uom_id,
          extractId(area?.market_id || area?.market)
        );
        extrPriceByMarket?.push(extr);
      }
      if (extrPriceByMarket?.length > 0) {
        setSelectedUom(extractId(extrPriceByMarket[0]?.uom_id));
        setUoms(
          extrPriceByMarket?.map((x) => {
            return {
              label: x?.uom_id?.name,
              value: extractId(x?.uom_id),
              id: extractId(x?.uom_id),
            };
          })
        );
      } else {
        history.push('/');
      }
      filtered = {
        ...filtered,
        extrPriceByMarket: extrPriceByMarket || [],
        images: [filtered?.imageCover, ...filtered?.images]?.map((x) => {
          return {
            original: x,
            thumbnail: x,
            originalHeight: '351px',
            originalWidth: '343px',
            originalClass: 'original',
            thumbnailClass: 'thumb',
          };
        }),
      };
    }
    return filtered;
  }, [extractId(area), details, country]);

  return (
    <Container>
      <Row
        className={`p-2 bg-white py-3 mb-3 ${!details ? 'centered' : ''}`}
        style={{ minHeight: 200 }}
      >
        {!filteredProduct ? (
          <Col className="colcentered">
            <Spinner variant="primary" />
          </Col>
        ) : (
          <>
            <Col lg={6} md={6} sm={12} className=" px-lg-3 px-md-3">
              <ProductImage images={filteredProduct?.images} />
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="py-3 d-flex flex-column align-items-stretch justify-content-between"
              style={{ maxHeight: 350 }}
            >
              <Col className="d-flex align-items-center justify-content-between">
                <Col>
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={20}
                    fontWeight={600}
                    text={filteredProduct?.name}
                    // cNColor="gray"
                  />
                </Col>
                <Col lg={1} md={1} sm={1} className="text-right">
                  {token ? (
                    isLoadingAddToSavedItems || isLoadingDeleteSavedItems ? (
                      <Spinner size="sm" variant="primary" />
                    ) : savedItems?.items &&
                      savedItems?.items?.find(
                        (x) => x?.id == extractId(filteredProduct)
                      ) ? (
                      <HeartFilled
                        onClick={() => {
                          if (token) {
                            savedItems?.items &&
                            savedItems?.items?.find(
                              (x) => x?.id == extractId(filteredProduct)
                            )
                              ? handleDeleteSavedItem({
                                  item_id: extractId(filteredProduct),
                                  saved_item_id: savedItems?.id,
                                  name: filteredProduct?.name,
                                })
                              : handleAddToSavedItems({
                                  name: filteredProduct?.name,
                                  product_id: extractId(filteredProduct),
                                  area_id: extractId(area),
                                  country_id: extractId(country),
                                });
                          } else {
                            show(
                              dispatch,
                              'Sign in to use the saved items feature',
                              { type: 'warning', duration: 4000 }
                            );
                          }
                        }}
                      />
                    ) : (
                      <HeartUnfilled
                        onClick={() => {
                          if (token) {
                            savedItems?.items &&
                            savedItems?.items?.find(
                              (x) => x?.id == extractId(filteredProduct)
                            )
                              ? handleDeleteSavedItem({
                                  item_id: extractId(filteredProduct),
                                  saved_item_id: savedItems?.id,
                                  name: filteredProduct?.name,
                                })
                              : handleAddToSavedItems({
                                  name: filteredProduct?.name,
                                  product_id: extractId(filteredProduct),
                                  area_id: extractId(area),
                                  country_id: extractId(country),
                                });
                          } else {
                            show(
                              dispatch,
                              'Sign in to use the saved items feature',
                              { type: 'warning', duration: 4000 }
                            );
                          }
                        }}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </Col>
              </Col>

              <Col className="border-top border-bottom align-items-between py-3">
                <Col className="mb-2">
                  <CustomText
                    fontFamily={'Poppins'}
                    fontSize={14}
                    fontWeight={500}
                    text={'Select Unit & Quantity'}
                  />
                </Col>
                <Col
                  className={`w-100 ${
                    isMobile ? 'colcentered gap-2' : 'd-flex rowcentered'
                  } justify-content-between`}
                >
                  <Col md={7} sm={12} className="me-lg-2 me-md-2">
                    <CustomInput
                      inputType="select"
                      label={' '}
                      value={uoms?.find((x) => x?.id === selectedUom)?.label}
                      className="border-primary"
                      placeholder={
                        uoms?.find((x) => x?.id === selectedUom)?.label
                      }
                      selectOptions={uoms}
                      onChange={(text) => setSelectedUom(text?.value || text)}
                    />
                  </Col>
                  <Col md="auto" sm={12} className="p-0">
                    <div
                      className={`d-flex row-centered ${
                        isMobile ? 'justify-content-end' : ''
                      }`}
                    >
                      <Image
                        alt="-"
                        src={decrease}
                        onClick={() => {
                          if (parseInt(quantity) > 1) {
                            setQuantity((parseInt(quantity) - 1).toString());
                          }
                        }}
                      />

                      <Form.Control
                        className="p-1 text-center"
                        style={{ width: isMobile ? '100%' : 60 }}
                        type="text"
                        onChange={(e) => setQuantity(e?.target?.value)}
                        value={quantity}
                        placeholder={1}
                      />

                      <Image
                        alt="increase"
                        src={increase}
                        onClick={() => {
                          setQuantity((parseInt(quantity) + 1).toString());
                        }}
                      />
                    </div>
                  </Col>
                </Col>
              </Col>
              <Col>
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={24}
                  fontWeight={600}
                  text={
                    filteredProduct?.extrPriceByMarket?.length > 0 &&
                    formatCurrency(
                      filteredProduct?.extrPriceByMarket?.find(
                        (x) => extractId(x?.uom_id) === selectedUom
                      )?.price * quantity,
                      2,
                      country?.name?.toLowerCase()?.includes('nigeria')
                        ? '₦'
                        : country?.currency?.symbol
                    )
                  }
                  divStyle={{ paddingTop: '20px', paddingBottom: '20px' }}
                  // cNColor="gray"
                />
              </Col>
              <Col lg="auto" className="d-block align-items-bottom mt-auto">
                <CustomButton
                  text="ADD TO CART"
                  fontWeight={600}
                  onPress={() => {
                    if (token) {
                      handleAddToCart({
                        product_id: extractId(filteredProduct),
                        area_id: extractId(area),
                        country_id: extractId(filteredProduct?.country_id),
                        quantity: parseInt(quantity),
                        name: filteredProduct?.name,
                        priceByMarket:
                          filteredProduct?.extrPriceByMarket?.find(
                            (x) => extractId(x?.uom_id) == selectedUom
                          ) || [],
                        totalAmount:
                          filteredProduct?.extrPriceByMarket?.find(
                            (x) => extractId(x?.uom_id) === selectedUom
                          )?.price * quantity,
                      });
                    } else {
                      show(
                        dispatch,
                        'Please sign in to allow us manage your cart',
                        {
                          type: 'warning',
                          duration: 4000,
                        }
                      );
                    }
                  }}
                  loading={isLoadingAddToCart}
                  className={'w-100'}
                  variant="primary"
                />
              </Col>
            </Col>
            <Col lg={12} md={12} sm={12} style={{ marginTop: '20px' }}>
              <CustomText
                fontFamily={'Poppins'}
                fontSize={16}
                fontWeight={500}
                text={'PRODUCT DESCRIPTION'}
                divClassName={'border-bottom py-2'}
                // cNColor="gray"
              />
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={400}
                divStyle={{
                  width: isLaptop || isDesktop ? '70%' : '100%',
                  paddingTop: 12,
                }}
                text={filteredProduct?.description}

                // cNColor="gray"
              />
            </Col>
          </>
        )}
      </Row>
      {filteredProducts?.filter(
        (x) =>
          extractId(x) !==
          (extractId(details?.product_id) || extractId(details))
      )?.length > 0 && (
        <SimilarProducts
          products={filteredProducts?.filter(
            (x) =>
              extractId(x) !==
              (extractId(details?.product_id) || extractId(details))
          )}
        />
      )}
      {isArrayNonEmpty(
        recentProducts?.filter((x) => x?.id !== filteredProduct?.id)
      ) ? (
        <ViewedProducts
          products={recentProducts?.filter(
            (x) => x?.id !== filteredProduct?.id
          )}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ProductDetails;
