import cabbage from 'assets/pngs/cabbage.png';
import track1 from 'assets/pngs/track1.png';
import track2 from 'assets/pngs/track2.png';
import track3 from 'assets/pngs/track3.png';
import track4 from 'assets/pngs/track4.png';
import CustomButton from 'components/CustomButton';
import { CustomCombinedText } from 'components/CustomCombinedText';
import { CustomText } from 'components/CustomText';
import { isSubstringInArray, joinWithCommaAndAnd } from 'helper/formatArray';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import {
  capitalize,
  errorMessageFormat,
  formatCurrency,
} from 'helper/formatText';
import { calculateVoucherCoverage } from 'helper/functions';
import { show } from 'helper/toast';
import { scrollToSection } from 'helper/utils';
import { useScreenSize } from 'hooks/useScreenSize';
import moment from 'moment';
import FeaturedProducts from 'pages/dashboard/components/FeaturedProducts';
import { useEffect, useMemo, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { saveToUserAuth } from 'redux/features/auth/authSlice';
import {
  useAddToTempCartMutation,
  useGetProductsMutation,
  useGetSavedItemsMutation,
  useGetUnrestrictedProductsMutation,
  useGetUserOrderMutation,
  useGetUserOrdersMutation,
} from 'redux/features/user/userApi';
import { saveToUserStore } from 'redux/features/user/userSlice';
const OrderDetails = () => {
  const history = useHistory();
  const { isDesktop, isLaptop, isMobile, isMobileS } = useScreenSize();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const id = match.params?.id;
  const order_id = location.state?.order_id;
  const { countries, country, area, areas, token } = useSelector(
    (state) => state.userAuth
  );
  const [refreshing, setRefreshing] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [showCharges, setShowCharges] = useState(false);
  const [totalKg, setTotalKg] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const changeLoadingProduct = (val) => {
    setLoadingProducts(val);
  };
  const [getUserOrder, { isLoading: isLoadingGetUserOrder }] =
    useGetUserOrderMutation();

  const [getUserOrders, { isLoading: isLoadingGetUserOrders }] =
    useGetUserOrdersMutation();

  const [getSavedItems, { isLoading: isLoadingGetSavedItems }] =
    useGetSavedItemsMutation();
  const [getProducts, { isLoading: isLoadingProducts }] =
    useGetProductsMutation();
  const [
    getUnrestrictedProducts,
    { isLoading: isLoadingUnrestrictedProducts },
  ] = useGetUnrestrictedProductsMutation();

  const fetchSavedItems = async () => {
    const res = await getSavedItems({
      area_id: area?.id,
      country_id: extractId(country),
    });
    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'savedItems', value: res.data?.data }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };
  const fetchProducts = async () => {
    const res = token
      ? await getProducts({ country_id: extractId(country) })
      : await getUnrestrictedProducts({
          country_id: extractId(country),
        });

    if (res?.data?.status === 'success') {
      dispatch(saveToUserStore({ key: 'products', value: res.data?.data }));
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  const fetchOrders = async () => {
    setInitialLoad(false);
    const res = await getUserOrders({ populate: false, selective: true });
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({
          key: 'orders',
          value:
            convertJsonStringToObject(
              JSON.parse(JSON.stringify(res.data?.data))
            ) || [],
        })
      );
    }
  };

  const fetchOrder = async () => {
    const res =
      order_id || isSubstringInArray(id, ['FDNG'])
        ? await getUserOrders({ order_id: order_id || id })
        : await getUserOrder(id);
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data[0] || res.data.data;
      const details = {
        ...extr,
        country: convertJsonStringToObject(extr?.country),
        market: convertJsonStringToObject(extr?.market),
        delivery_address: JSON.parse(extr?.delivery_address),
      };
      if (extr?.area) {
        details.area = convertJsonStringToObject(extr?.area);
        details.area.market = details.market;
      }
      if (extr?.voucher) {
        details.voucher = convertJsonStringToObject(extr?.voucher);
      }
      setOrderDetails(details);
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      history.push('/orders');
    }
  };

  useEffect(() => {
    !isLoadingGetUserOrder && fetchOrder();
  }, [token, refetch, order_id, id]);

  useEffect(() => {
    token && !isLoadingGetSavedItems && fetchSavedItems();
    !isLoadingProducts && !isLoadingUnrestrictedProducts && fetchProducts();
  }, [token, country]);

  useEffect(() => {
    changeLoadingProduct(isLoadingProducts || isLoadingUnrestrictedProducts);
  }, [isLoadingProducts, isLoadingUnrestrictedProducts]);

  const filteredData = useMemo(() => {
    let filtered = orderDetails?.items ? JSON.parse(orderDetails?.items) : [];
    filtered = filtered?.map((x) => {
      return {
        ...x,
        name: x?.product_id?.name,
        quantity: x?.quantity,
        country_name: x?.priceByMarket_id?.market_id?.country_name,
        price: x?.priceByMarket_id?.price,
        uom: x?.priceByMarket_id?.uom_id?.name,
        image: x?.product_id?.imageCover,
        product_id: x?.product_id?.id,
        total_kg: x?.priceByMarket_id?.kg * x?.quantity,
        id: x?.id,
      };
    });
    const total_kg = filtered?.reduce((acc, item) => {
      return acc + item?.total_kg;
    }, 0);

    setTotalKg(total_kg);

    return filtered;
  }, [orderDetails]);

  const handleShowCharges = () => {
    setShowCharges(!showCharges);
  };
  const copyToClipboard = (text) => {
    show(dispatch, 'Payment link copied to clipboard', {
      type: 'success',
      duration: 2000,
    });
  };

  const [addToTempCart, { isLoading: isLoadingAddToTempCart }] =
    useAddToTempCartMutation();

  const handleAddToTempCart = async (value) => {
    const res = await addToTempCart(value);
    if (res?.data?.status === 'success') {
      dispatch(
        saveToUserStore({ key: 'tempCart', value: res?.data?.data || [] })
      );
      dispatch(
        saveToUserAuth({
          key: 'tempArea',
          value: orderDetails?.area || null,
        })
      );
      dispatch(
        saveToUserAuth({
          key: 'tempCountry',
          value: orderDetails?.country,
        })
      );
      history.push('/checkout', {
        checkoutType: 'reorder',
        deliveryAddress: orderDetails?.delivery_address,
        ...value,
      });
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
      // history.push('/orders');
    }
  };
  const handleReOrder = (items) => {
    const extr = items?.map((x) => {
      return {
        product_id: x?.product_id,
        area_id: x?.area_id,
        country_id: x?.country_id,
        quantity: x?.quantity,
        name: x?.name,
        priceByMarket: x?.priceByMarket_id,
      };
    });
    handleAddToTempCart({
      cartAreaId: extr[0].area_id,
      cartCountryId: extr[0].country_id,
      cartItems: extr,
    });
  };
  const data = useMemo(() => {
    let filtered = Array(6).fill(null);
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        name: 'Cabbage',
        image: cabbage,
        accountType: 'Customer' || 'Shopper',
        price: Math.random() > 0.5 ? '1000' : '3500',
        unitOfMeasure: Math.random() < 0.5 ? 'Bucket' : 'Bowl',
        unitAmount: 10,
      };
    });
    return filtered;
  }, []);

  const options = useMemo(() => {
    if (!orderDetails) {
      return [];
    }
    return [
      {
        label: 'Status',
        value: (
          <CustomText
            fontFamily={'Poppins'}
            fontSize={14}
            fontWeight={400}
            text={capitalize(
              isSubstringInArray(orderDetails?.order_status, [
                'delivered',
                'canceled',
                'cancelled',
              ])
                ? orderDetails?.order_status
                : isSubstringInArray(orderDetails?.order_status, ['intransit'])
                ? 'in transit'
                : 'Pending'
            )}
            styleColor={
              isSubstringInArray(orderDetails?.order_status, [
                'cancelled',
                'canceled',
              ])
                ? '#FA3737'
                : isSubstringInArray(orderDetails?.order_status, ['delivered'])
                ? '#039D00'
                : '#FF8804'
            }
            divStyle={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 20,
              backgroundColor: isSubstringInArray(orderDetails?.order_status, [
                'cancelled',
                'canceled',
              ])
                ? '#FA37371A'
                : isSubstringInArray(orderDetails?.order_status, ['delivered'])
                ? 'rgba(3, 157, 0, 0.2)'
                : 'rgba(255, 200, 4, 0.2)',
            }}
            className="centered"
          />
        ),
      },
      {
        label: 'Payment method',
        value: capitalize(
          joinWithCommaAndAnd(orderDetails?.payment_method, true)
        )?.replace('And', '&'),
      },
      {
        label: 'Voucher code used',
        value: orderDetails?.voucher?.code || 'N/A',
      },
      {
        label: 'Voucher code used',
        value:
          !!orderDetails?.voucher?.amountCovered ||
          !!orderDetails?.voucher?.value
            ? formatCurrency(
                orderDetails?.voucher?.amountCovered ||
                  calculateVoucherCoverage(
                    orderDetails?.total_amount,
                    orderDetails?.voucher?.value -
                      orderDetails?.voucher?.used_value
                  ),
                2,
                orderDetails?.country_id?.name
                  ?.toLowerCase()
                  ?.includes('nigeria')
                  ? '₦'
                  : orderDetails?.country?.currency?.symbol
              )
            : 'N/A',
      },
      {
        label: 'Payment status',
        value: (
          <CustomText
            fontFamily={'Poppins'}
            fontSize={14}
            fontWeight={400}
            text={capitalize(
              orderDetails?.payment_status === 'unpaid'
                ? 'pending'
                : orderDetails?.payment_status
            )}
            styleColor={
              orderDetails?.payment_status === 'unpaid' ? '#FA3737' : '#039D00'
            }
            divStyle={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 20,
              backgroundColor:
                orderDetails?.payment_status === 'unpaid'
                  ? '#FA37371A'
                  : 'rgba(3, 157, 0, 0.2)',
            }}
            className="centered"
          />
        ),
      },
      {
        label: 'Date placed',
        value: moment(orderDetails?.createdAt).format('ddd MMM DD, yyyy'),
      },
      {
        label: (
          <Row>
            <Col>
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={400}
                textStyle={{ textTransform: 'uppercase', marginBottom: 16 }}
              >
                Order summary
              </CustomText>
            </Col>
            {!isMobile && (
              <Col lg="auto" md="auto" sm="auto">
                <CustomButton
                  className={'p-1 text-black'}
                  text="Re-order"
                  variant="primary"
                  fontWeight={500}
                  fontSize={12}
                  style={{ minWidth: 100, height: 36 }}
                  onPress={() => handleReOrder(filteredData)}
                  loading={isLoadingAddToTempCart}
                />
              </Col>
            )}
          </Row>
        ),
        type: 'col',
        className: 'py-3',
        labelStyle: { textTransform: 'uppercase', marginBottom: 16 },
        value: (
          <>
            {filteredData?.map((item) => {
              const { image, name, price, quantity, uom } = item;
              return (
                <Card
                  key={extractId(item)}
                  style={{
                    minHeight: '100px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    marginTop: '10px',
                    // marginBottom: '10px',
                    paddingTop: isMobile ? 20 : 0,
                    paddingBottom: isMobile ? 20 : 0,
                  }}
                >
                  <Row>
                    <Col
                      lg="auto"
                      md="auto"
                      sm="auto"
                      className={isMobile ? 'centered' : ''}
                    >
                      <Image
                        src={image}
                        alt=""
                        fluid
                        style={{ maxHeight: 100 }}
                      />
                    </Col>
                    <Col className="py-3 px-2 d-flex flex-column align-items-start justify-content-between">
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={14}
                        fontWeight={600}
                        text={name}
                        // cNColor="gray"
                        // textClassName="text-capitalize"
                      />
                      <CustomCombinedText
                        firstFontFamily={'Poppins'}
                        firstText="Price per Unit: "
                        firstFontWeight={300}
                        firstFontSize={12}
                        secondFontFamily={'Poppins'}
                        secondFontWeight={500}
                        secondFontSize={12}
                        secondText={`${formatCurrency(
                          price,
                          2,
                          orderDetails?.country?.name
                            ?.toLowerCase()
                            ?.includes('nigeria')
                            ? '₦'
                            : orderDetails?.country?.currency?.symbol
                        )}`}
                        // cNColor="gray"
                        // textClassName="text-capitalize"
                      />
                      <CustomCombinedText
                        firstFontFamily={'Poppins'}
                        firstText="Quantity:"
                        firstFontWeight={300}
                        firstFontSize={12}
                        secondFontFamily={'Poppins'}
                        secondFontWeight={500}
                        secondFontSize={12}
                        secondText={`${quantity} (${uom})`}
                        // cNColor="gray"
                        // textClassName="text-capitalize"
                      />
                    </Col>
                    <Col
                      lg="auto"
                      md="auto"
                      sm="auto"
                      className="mt-lg-3 mt-md-3 mt-sm-0 align-items-end"
                    >
                      <CustomText
                        fontFamily={'Poppins'}
                        fontSize={isMobile ? 16 : 14}
                        fontWeight={400}
                        text={formatCurrency(
                          item?.quantity * item?.price,
                          2,
                          orderDetails?.country?.name
                            ?.toLowerCase()
                            ?.includes('nigeria')
                            ? '₦'
                            : orderDetails?.country?.currency?.symbol
                        )}
                        textStyle={{ textAlign: 'right' }}
                      />
                    </Col>
                  </Row>
                </Card>
              );
            })}
            {isMobile && (
              <CustomButton
                className={'w-100 p-1 text-black'}
                text="Re-order"
                variant="primary"
                fontWeight={500}
                fontSize={12}
                style={{ minWidth: 100, height: 36 }}
                onPress={() => handleReOrder(filteredData)}
                loading={isLoadingAddToTempCart}
              />
            )}
          </>
        ),
      },
      {
        label: (
          <Row>
            <Col>
              <CustomText
                fontFamily={'Poppins'}
                fontSize={14}
                fontWeight={400}
                textStyle={{ textTransform: 'uppercase', marginBottom: 16 }}
              >
                Pay4Me Link
              </CustomText>
            </Col>
            {!isMobile && (
              <Col lg="auto" md="auto" sm="auto">
                <CustomButton
                  className={'p-1 text-black'}
                  text="Copy"
                  variant="primary"
                  fontWeight={500}
                  fontSize={12}
                  style={{ minWidth: 100, height: 36 }}
                  onClick={() =>
                    copyToClipboard(
                      (typeof orderDetails?.payment_link == 'string'
                        ? convertJsonStringToObject(orderDetails?.payment_link)
                        : orderDetails?.payment_link
                      )?.checkoutUrl
                    )
                  }
                />
              </Col>
            )}
          </Row>
        ),
        type: 'col',
        className: 'py-3',
        labelStyle: { textTransform: 'uppercase', marginBottom: 16 },
        value: (typeof orderDetails?.payment_link == 'string'
          ? convertJsonStringToObject(orderDetails?.payment_link)
          : orderDetails?.payment_link
        )?.checkoutUrl ? (
          <>
            <CustomText
              fontFamily={'Poppins'}
              fontSize={14}
              fontWeight={400}
              textStyle={{ marginBottom: 16 }}
            >
              {
                (typeof orderDetails?.payment_link == 'string'
                  ? convertJsonStringToObject(orderDetails?.payment_link)
                  : orderDetails?.payment_link
                )?.checkoutUrl
              }
            </CustomText>
            <Row className="rowcentered">
              <Col xs="auto">
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={14}
                  fontWeight={400}
                  textStyle={{ marginBottom: 16 }}
                >
                  {orderDetails?.payment_datetime ||
                  convertJsonStringToObject(
                    orderDetails?.additional_payment_details[0]
                  )?.eventData?.paidOn
                    ? 'Paid on'
                    : 'Expiry Date'}
                </CustomText>
              </Col>
              <Col xs="auto">
                <CustomText
                  fontFamily={'Poppins'}
                  fontSize={14}
                  fontWeight={400}
                  textStyle={{ marginBottom: 16 }}
                >
                  {orderDetails?.payment_datetime ||
                  convertJsonStringToObject(
                    orderDetails?.additional_payment_details[0]
                  )?.eventData?.paidOn
                    ? moment(
                        orderDetails?.payment_datetime ||
                          convertJsonStringToObject(
                            orderDetails?.additional_payment_details[0]
                          )?.eventData?.paidOn
                      ).format('ddd MMM DD, yyyy')
                    : moment(
                        (typeof orderDetails?.payment_link == 'string'
                          ? convertJsonStringToObject(
                              orderDetails?.payment_link
                            )
                          : orderDetails?.payment_link
                        )?.expiryDate
                      ).format('ddd MMM DD, yyyy')}
                </CustomText>
              </Col>
            </Row>
            {isMobile && (
              <CustomButton
                className={'w-100 p-1 text-black'}
                text="Copy link"
                variant="primary"
                fontWeight={500}
                fontSize={12}
                style={{ minWidth: 100, height: 36 }}
                onClick={() =>
                  copyToClipboard(
                    (typeof orderDetails?.payment_link == 'string'
                      ? convertJsonStringToObject(orderDetails?.payment_link)
                      : orderDetails?.payment_link
                    )?.checkoutUrl
                  )
                }
              />
            )}
          </>
        ) : (
          'N/A'
        ),
      },
      {
        label: 'Item total',
        value: formatCurrency(
          orderDetails?.total_item_amount,
          2,
          orderDetails?.country_id?.name?.toLowerCase()?.includes('nigeria')
            ? '₦'
            : orderDetails?.country?.currency?.symbol
        ),
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: `Delivery fee${
          totalKg &&
          !isSubstringInArray(orderDetails?.country?.name, ['nigeria']) &&
          !isSubstringInArray(orderDetails?.delivery_method || '#', ['Door'])
            ? '(' +
              totalKg +
              'kg at ' +
              (isSubstringInArray(orderDetails?.country?.name, ['nigeria'])
                ? '₦'
                : orderDetails?.country?.currency?.symbol) +
              orderDetails?.country?.delivery_fee_by_kg +
              '/kg)'
            : ''
        }`,
        value: formatCurrency(
          orderDetails?.total_delivery_fee,
          2,
          orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
            ? '₦'
            : orderDetails?.country?.currency?.symbol
        ),
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: 'Service charge',
        value: !!orderDetails?.total_service_charge
          ? formatCurrency(
              orderDetails?.total_service_charge,
              2,
              orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : orderDetails?.country?.currency?.symbol
            )
          : 'N/A',
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: `VAT (${orderDetails?.country?.vat}%)`,
        value: !!orderDetails?.total_vat
          ? formatCurrency(
              orderDetails?.total_vat,
              2,
              orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : orderDetails?.country?.currency?.symbol
            )
          : 'N/A',
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: `GST/PST (${
          (orderDetails?.total_country_tax * 100) /
          orderDetails?.total_item_amount
        }%)`,
        value: !!orderDetails?.total_country_tax
          ? formatCurrency(
              orderDetails?.total_country_tax,
              2,
              orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : orderDetails?.country?.currency?.symbol
            )
          : 'N/A',
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: `Others`,
        value: !!orderDetails?.total_other_charges
          ? formatCurrency(
              orderDetails?.total_other_charges,
              2,
              orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
                ? '₦'
                : orderDetails?.country?.currency?.symbol
            )
          : 'N/A',
        valueStyle: { fontFamily: 'Poppins', fontSize: 14, fontWeight: 600 },
      },
      {
        label: 'TOTAL',
        value: formatCurrency(
          orderDetails?.total_amount,
          2,
          orderDetails?.country?.name?.toLowerCase()?.includes('nigeria')
            ? '₦'
            : orderDetails?.country?.currency?.symbol
        ),
        valueStyle: { fontFamily: 'Poppins', fontSize: 16, fontWeight: 600 },
      },
    ]?.filter((x) => x?.value && x?.value !== 'N/A');
  }, [orderDetails, isLoadingGetUserOrder]);

  const statusOptions = useMemo(() => {
    const status = orderDetails?.order_status;
    if (!status) {
      return [];
    }
    return [
      {
        label: 'Placed',
        textColor: '#039D00',
        image: track1,
        lineColor:
          status !== 'open'
            ? '#039D00'
            : status === 'open'
            ? '#FFC804'
            : '#D9D9D9',
      },
      {
        label: 'In progress',
        image:
          status === 'open' || status === 'accepted' || status === 'shopped'
            ? track2
            : status === 'open'
            ? track3
            : track1,
        textColor:
          status === 'open' || status === 'accepted' || status === 'shopped'
            ? '#FFC804'
            : status === 'open'
            ? '#D9D9D9'
            : '#039D00',
        lineColor:
          (status !== 'accepted' &&
            status !== 'shopped' &&
            status !== 'open' &&
            status !== 'pending') ||
          status === 'intransit'
            ? '#039D00'
            : status === 'accepted' || status === 'shopped'
            ? '#FFC804'
            : '#D9D9D9',
      },
      {
        label: 'In transit',
        image:
          status === 'intransit'
            ? track2
            : status === 'delivered' ||
              status === 'cancelled' ||
              status === 'canceled'
            ? track1
            : track3,
        textColor:
          status === 'intransit'
            ? '#FFC804'
            : status === 'delivered' ||
              status === 'cancelled' ||
              status === 'canceled'
            ? '#039D00'
            : '#D9D9D9',
        lineColor:
          status === 'canceled' ||
          status === 'cancelled' ||
          status === 'delivered'
            ? '#039D00'
            : status === 'intransit'
            ? '#FFC804'
            : '#D9D9D9',
      },
      {
        label: capitalize(status),
        image:
          status === 'delivered'
            ? track1
            : status === 'canceled' || status === 'cancelled'
            ? track4
            : track3,
        textColor:
          status === 'delivered'
            ? '#039D00'
            : status === 'canceled' || status === 'cancelled'
            ? '#FA3737'
            : '#D9D9D9',
        lineColor: null,
      },
    ];
  }, [orderDetails]);

  return (
    <Container>
      <Row className="mb-3 mt-3 mt-lg-0 mt-md-0">
        <Col lg="auto" md={12} className="d-flex align-items-center py-2">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={16}
            fontWeight={600}
            text={'Order details'}
            // cNColor="gray"
          />
        </Col>
      </Row>
      {isLoadingGetUserOrder || !orderDetails ? (
        <Row style={{ height: 200 }} className="centered">
          <Spinner variant="primary" />
        </Row>
      ) : (
        <Row>
          <Col xl={6} lg={12}>
            <Card className="border-0 mb-4 mb-lg-0 mb-md-0">
              <Card.Header>
                <Row>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={16}
                      fontWeight={500}
                      text={'Order ID'}
                    />
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={18}
                      fontWeight={600}
                      text={orderDetails?.order_id}
                    />
                  </Col>
                  {!isDesktop && (
                    <Col className="ms-auto d-flex justify-content-end">
                      <CustomButton
                        className={'p-1 text-black'}
                        text="Track Order"
                        variant="primary"
                        fontWeight={500}
                        fontSize={12}
                        style={{ minWidth: 100, height: 36 }}
                        onClick={() => scrollToSection('track-order')}
                      />
                    </Col>
                  )}
                </Row>
              </Card.Header>
              <Card.Body className="py-0">
                <div>
                  <ListGroup variant="flush">
                    {options?.map((x) => (
                      <ListGroup.Item
                        className={`${
                          x?.type === 'col' ? 'd-block' : 'd-flex'
                        } align-items-center justify-content-between px-0 py-0 ${
                          x?.className ? x?.className : ''
                        }`}
                        style={
                          x?.style
                            ? { minHeight: 50, ...x?.style }
                            : { minHeight: 50 }
                        }
                      >
                        {typeof x?.label === 'string' ? (
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={400}
                            textClassName={x?.labelClassName}
                            textStyle={x?.labelStyle}
                          >
                            {x?.label}
                          </CustomText>
                        ) : (
                          x?.label
                        )}
                        {typeof x?.value === 'string' ? (
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={500}
                            textClassName={x?.valueClassName}
                            textStyle={x?.valueStyle}
                          >
                            {x?.value}
                          </CustomText>
                        ) : (
                          x?.value
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={12}>
            <Card
              className="border-0 mb-4 mb-lg-0 mb-md-0 mt-4 mt-xl-0"
              id="track-order"
            >
              <Card.Header>
                <Row>
                  <Col>
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={16}
                      fontWeight={500}
                      text={'Track Order'}
                    />
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={500}
                      text={'Order ID: ' + orderDetails?.order_id}
                    />
                  </Col>
                  <Col className="ms-auto d-flex justify-content-end">
                    <CustomButton
                      className={'p-1 text-black'}
                      text="Call Support"
                      variant="primary"
                      fontWeight={500}
                      fontSize={12}
                      style={{ minWidth: 100, height: 36 }}
                      onClick={() =>
                        window.open(
                          `tel:${orderDetails?.country?.contact_phone}`,
                          '_blank'
                        )
                      }
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div>
                  <div
                    className={`${'d-flex'} align-items-center justify-content-stretch px-0 py-0`}
                    style={{ minHeight: 50, marginBottom: 30 }}
                  >
                    {statusOptions.map((x) => {
                      const { label, image, textColor, lineColor } = x;
                      return (
                        <>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <CustomText
                              fontFamily={'Poppins'}
                              fontSize={isMobile ? 10 : 14}
                              styleColor={textColor}
                            >
                              {label}
                            </CustomText>
                            <Image
                              src={image}
                              alt="Done"
                              fluid
                              style={{
                                height: isLaptop ? 100 : isMobileS ? 30 : 50,
                                width: isLaptop ? 100 : isMobileS ? 30 : 50,
                              }}
                            />
                          </div>
                          {lineColor && (
                            <div
                              className="flex-grow-1"
                              style={{
                                height: 4,
                                marginTop: 20,
                                backgroundColor: lineColor,
                              }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div
                    className={`${'d-block'} align-items-center justify-content-between px-0 py-0`}
                    style={{ minHeight: 50 }}
                  >
                    <CustomText
                      fontFamily={'Poppins'}
                      fontSize={14}
                      fontWeight={400}
                      // textClassName={x?.labelClassName}
                      textStyle={{
                        textTransform: 'uppercase',
                        marginBottom: 16,
                      }}
                    >
                      Delivery Address
                    </CustomText>

                    <>
                      {orderDetails?.delivery_address?.tag && (
                        <CustomText
                          fontFamily={'Poppins'}
                          fontSize={14}
                          fontWeight={600}
                        >
                          {orderDetails?.delivery_address?.tag}
                        </CustomText>
                      )}

                      <CustomText fontFamily={'Poppins'} fontSize={14}>
                        {orderDetails?.delivery_address?.contact_name}
                      </CustomText>
                      <CustomText fontFamily={'Poppins'} fontSize={14}>
                        {`${
                          orderDetails?.delivery_address?.street_address
                            ? orderDetails?.delivery_address?.street_address +
                              ', '
                            : ''
                        }${orderDetails?.delivery_address?.city}, ${
                          orderDetails?.delivery_address?.state_name ||
                          orderDetails?.delivery_address?.state_code ||
                          orderDetails?.delivery_address?.state
                        }, ${
                          orderDetails?.delivery_address?.country_name ||
                          orderDetails?.delivery_address?.country_code ||
                          orderDetails?.delivery_address?.country
                        }`}
                      </CustomText>
                      {orderDetails?.delivery_address?.postal_code && (
                        <CustomText fontFamily={'Poppins'} fontSize={14}>
                          {orderDetails?.delivery_address?.postal_code}
                        </CustomText>
                      )}
                      {orderDetails?.delivery_address?.contact_phone && (
                        <CustomText fontFamily={'Poppins'} fontSize={14}>
                          {orderDetails?.delivery_address?.contact_phone}
                        </CustomText>
                      )}
                      {orderDetails?.delivery_address?.additional_info && (
                        <div>
                          <CustomText
                            fontFamily={'Poppins'}
                            fontSize={14}
                            fontWeight={600}
                          >
                            Delivery Instructions:
                          </CustomText>
                          <CustomText fontFamily={'Poppins'} fontSize={14}>
                            {orderDetails?.delivery_address?.additional_info}
                          </CustomText>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </Card.Body>
            </Card>
            {/* {isDesktop && (
              <FeaturedProducts
                loadingProducts={loadingProducts}
                setLoadingProducts={setLoadingProducts}
              />
            )} */}
          </Col>
        </Row>
      )}
      {/* {!isDesktop && ( */}
      <FeaturedProducts
        loadingProducts={loadingProducts}
        setLoadingProducts={setLoadingProducts}
      />
      {/* )} */}
    </Container>
  );
};

export default OrderDetails;
