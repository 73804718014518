import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CustomText } from './CustomText';

import { ReactComponent as FacebookSVG } from 'assets/svgs/facebook.svg';
import { ReactComponent as TwitterSVG } from 'assets/svgs/twitter.svg';
import { ReactComponent as InstagramSVG } from 'assets/svgs/instagram.svg';
import { ReactComponent as YoutubeSVG } from 'assets/svgs/youtube.svg';
import { ReactComponent as PhoneSVG } from 'assets/svgs/phone.svg';
import { Fragment, useEffect, useMemo } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { saveToUserStore } from 'redux/features/user/userSlice';
import { errorMessageFormat } from 'helper/formatText';
import { show } from 'helper/toast';
import { convertJsonStringToObject, extractId } from 'helper/formatObject';
import { useGetSupportsMutation } from 'redux/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';

import phonePng from 'assets/pngs/phone.png';
import emailPng from 'assets/pngs/email.png';
import facebookPng from 'assets/pngs/facebook.png';
import whatsappPng from 'assets/pngs/whatsapp.png';
import instagramPng from 'assets/pngs/instagram.png';
import twitterPng from 'assets/pngs/x.png';
import { isSubstringInArray } from 'helper/formatArray';

const Footer = () => {
  const { userSupport } = useSelector((state) => state.userStore);

  const dispatch = useDispatch();
  const [getSupports, { isLoading: isLoadingSupport }] =
    useGetSupportsMutation();

  const fetchSupport = async () => {
    const res = await getSupports({
      status: true,
    });
    if (res?.data?.status === 'success') {
      const extr = res?.data?.data?.map((x) => {
        return convertJsonStringToObject({ ...x });
      });
      dispatch(
        saveToUserStore({
          key: 'userSupport',
          value: extr,
        })
      );
    } else {
      show(dispatch, errorMessageFormat(res), {
        type: 'error',
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    fetchSupport();
  }, []);

  const icons = [
    { type: 'comm', icon: phonePng },
    { type: 'whats', icon: whatsappPng },
    { type: 'email', icon: emailPng },
    { type: 'twitter', icon: twitterPng },
    { type: 'facebook', icon: facebookPng },
    { type: 'phone', icon: phonePng },
    { type: 'telephone', icon: phonePng },
    { type: 'instagram', icon: instagramPng },
  ];

  const filteredOptions = useMemo(() => {
    let filtered = userSupport || [];
    filtered = filtered?.map((x) => {
      return {
        id: extractId(x),
        ...x,
        icon: (
          icons?.find((y) => isSubstringInArray(y?.type, [x?.type])) || icons[0]
        )?.icon,
      };
    });
    return filtered;
  }, [userSupport]);

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  const help = [
    { name: 'Help Center', link: '/help' },
    { name: 'Contact Us', link: 'https://fooddy.ng/contact', type: 'external' },
  ];
  const policy = [
    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: 'Terms & Conditions', link: '/terms-and-conditions' },
  ];
  const about = [
    { name: 'Fooddy', link: 'https://fooddy.ng/index.html', type: 'external' },

    {
      name: 'Fooddy Shopper',
      link: 'https://fooddy.ng/contact',
      type: 'external',
    },
  ];
  return (
    <div className="bg-black">
      <Container>
        <Row className="m-0 p-4 d-flex align-items-top bg-black">
          {/* <Section key="Categories" title="Categories" list={categories} /> */}
          <Section key="Policy" title="Policy" list={policy} />
          <Section key="About" title="About us" list={about} />
          <Section key="Help" title="Help" list={help} />
          <Section key="Stay connected" title="Stay connected">
            <Col className="rowcentered w-100">
              {filteredOptions.map((x) => (
                <Col
                  className="text-center"
                  onClick={() => handleClick(x?.link)}
                >
                  <Image src={x.icon} style={{ width: 40 }} />
                </Col>
              ))}
              {/* <Col>
                <FacebookSVG />
              </Col>
              <Col>
                <TwitterSVG />
              </Col>
              <Col>
                <InstagramSVG />
              </Col>
              <Col>
                <YoutubeSVG />
              </Col>
              <Col>
                <PhoneSVG />
              </Col> */}
            </Col>
          </Section>

          {/* <Col lg={12} md={12} sm={12} xs={12} className="colcentered bg-primary">
        <CustomText
          divClassName="mb-5 text-center"
          fontFamily={'Inter'}
          fontSize={20}
          fontWeight={700}
          text="Stay Connected"
          cNColor="white"
        />
      </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Footer;

const Section = ({ title, list, children }) => {
  const { isMobile, isMobileS } = useScreenSize();

  return (
    <Col
      lg={3}
      md={6}
      sm={12}
      xs={12}
      className="d-flex flex-column align-items-center py-3"
    >
      <CustomText
        fontFamily={'Inter'}
        fontSize={16}
        fontWeight={500}
        text={title}
        divClassName="mb-3"
        cNColor="text-white"
      />

      {children ||
        list.map((item) =>
          item?.type == 'external' ? (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mb-2"
            >
              <CustomText
                fontFamily={'Inter'}
                fontSize={14}
                fontWeight={400}
                text={item.name}
                cNColor="text-white"
              />
            </a>
          ) : (
            <Link to={item.link || '#'} className="mb-2">
              <CustomText
                fontFamily={'Inter'}
                fontSize={14}
                fontWeight={400}
                text={item.name}
                cNColor="text-white"
              />
            </Link>
          )
        )}
    </Col>
  );
};
