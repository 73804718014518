// import node module libraries
// import custom components
// import { ReactComponent as Decrease } from "assets/svgs/minus.svg";
// import SavedProducts from "./components/SavedProducts";

import { isArrayNonEmpty } from 'helper/formatArray';
import { useSelector } from 'react-redux';
import EmptySection from './EmptySection';
import Sections from './Sections';

const ProfileSections = ({ type, loading }) => {
  const { savedItems, userNotifications } = useSelector(
    (state) => state.userStore
  );

  const emptySection =
    type === 'notifications'
      ? !isArrayNonEmpty(userNotifications)
      : !isArrayNonEmpty(savedItems?.items);

  return emptySection ? (
    <EmptySection
      type={type}
      loading={loading}
      loadingText={'Loading ' + type?.replace('-', ' ')}
    />
  ) : (
    <Sections type={type} loading={loading} />
  );
};

export default ProfileSections;
