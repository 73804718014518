import { CustomText } from 'components/CustomText';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as NotificationSvg } from 'assets/svgs/notificationcard.svg';
import { useScreenSize } from 'hooks/useScreenSize';
import { formatDate } from 'helper/formatText';
import { useHistory } from 'react-router-dom';

const Notifications = ({
  title,
  body,
  status,
  id,
  user_id,
  createdAt,
  updatedAt,
  data,
  ...props
}) => {
  const history = useHistory();
  const { isMobile, isTablet } = useScreenSize();
  return (
    <Col
      className="px-4 py-2 mb-2 d-flex flex-column justify-content-center"
      style={{ minHeight: '80px' }}
      onClick={() => {
        props?.order_id &&
          history.push(
            props?.order_id ? '/orders/' + props?.order_id : '/orders'
          );
      }}
    >
      <Col sm={12} className="d-flex">
        <Col sm={'auto'}>
          <NotificationSvg />
        </Col>
        <Col className="ps-3">
          <CustomText
            fontFamily={'Poppins'}
            fontSize={12}
            fontWeight={600}
            text={title}
          />
        </Col>
        <Col sm={'auto'}>
          <CustomText
            fontFamily={'Poppins'}
            fontSize={12}
            text={formatDate(createdAt)}
          />
        </Col>
      </Col>
      <Col sm={12} className={`m-0 pt-1 ${isMobile ? 'ps-2' : 'ps-6'}`}>
        <CustomText
          fontFamily={'Poppins'}
          fontSize={12}
          fontWeight={400}
          text={body}
          // cNColor="gray"
          // textClassName="text-capitalize"
        />
      </Col>
    </Col>
  );
};

export default Notifications;
