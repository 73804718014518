// import node module libraries
import React, { Fragment, useEffect } from 'react';

// import layouts
import NavbarDefault from 'layouts/navbars/NavbarDefault';
import FooterSignin from 'layouts/footers/FooterSignin';
import CustomToast from 'components/CustomToast';
import { useSelector } from 'react-redux';
import backgroundImage from 'assets/svgs/Illustrations.svg';

const AuthLayout = (props) => {
  const { toastMessage, toastType, toastDuration } = useSelector(
    (state) => state.userStore
  );
  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
  });

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    marginTop: 80,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat',
  };

  const contentStyle = {
    flex: 1,
  };

  return (
    <Fragment>
      <NavbarDefault login />
      {toastMessage && (
        <CustomToast
          text={toastMessage}
          type={toastType}
          duration={toastDuration}
        />
      )}
      <div style={containerStyle}>
        <div
          style={contentStyle}
          className={'container-fluid px-lg-14 py-lg-3 p-md-5 p-sm-2'}
        >
          {props.children}
        </div>
        <FooterSignin />
      </div>
    </Fragment>
  );
};

export default AuthLayout;
